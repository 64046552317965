import { uniq } from 'underscore';
import {system_function} from './templates';
import { updateField } from "vuex-map-fields";

function hydrateProduct(p) {
    let system_function = { ...p };

    return system_function;
}

export default {
    async loaded(state, data) {
        state.system_functions = uniq([...data.system_functions], 'uuid');
    },
    async loadedMore(state, data) {
        state.system_functions = uniq([...data.system_functions, ...state.system_functions], 'uuid');
    },
    async loadedOne(state, data) {
        state.system_functions = uniq([...state.system_functions, data.system_function], 'uuid');
    },
    async updated(state, { data }) {
        state.system_function = { ...state.system_function, ...data.system_function };
        state.system_functions = state.system_functions.map(system_function => system_function.uuid === state.system_function.uuid ? ({ ...state.system_function }) : system_function);
    },
    async created(state, { data, response }) {
        state.system_function = { ...state.system_function, ...data, uuid: response.system_function.uuid };
        state.system_functions = [...state.system_functions, state.system_function];
    },
    async deleted(state, { response, id }) {
        state.system_functions = state.system_functions.filter(system_function => system_function.uuid !== id);
    },
    async reset(state) {
        state.system_function = { ...system_function };
    },
    async select(state, id) {
        if (state.system_functions) {
            const system_function = state.system_functions.find(system_function => system_function.uuid === id);

            if (system_function) {
                state.system_function = hydrateProduct(system_function);
            }
        }
    },
    updateField
};
