<template>
  <section>
    <div class="grey-box">
      <h4 class="headline headline_4">
        {{ $t("offer.offer.steps.shop.cart") }}
      </h4>

      <div>
        <vue-element-loading :active="loading" spinner="bar-fade-scale"/>

        <data-table :fields="fields" :data="positions">
          <div slot="last-col" slot-scope="{ row }" class="inline right">
            <img
              src="images/trash-icon.png"
              alt="remove-from-cart"
              class="table-action-icon trash-icon"
              :title="$t('offer.remove_from_cart')"
              @click="removePosition(row)"
            />
          </div>
          <tr slot="empty">
            <td :colspan="fields.length" class="text-center">
              {{ $t("offer.offer.steps.shop.cart_empty") }}
            </td>
          </tr>
        </data-table>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";

import DotMenu from "../../../shared/components/modal/dot-menu";
import DataTable from "../../../shared/components/table/table";
import VueElementLoading from "vue-element-loading";

export default {
  name: "cart",
  components: {
    DataTable,
    DotMenu,
    VueElementLoading,
  },
  data() {
    return {
      limit: 10,
      loading: false,
      fields: [
        {label: "offer.products.table.id", key: "product.article_number"},
        {
          label: "offer.products.table.name",
          key: "product.title",
          type: "product:tooltip",
          tooltipKey: "product.tooltip",
          productLinkKey: "product.product_link",
          underline: (row) => this.renderSystemFunctions(row),
          hint: (row) => row.product.product_hint,
        },
        {
          label: "offer.products.table.category",
          key: "product.category.key",
          transform: (key) => this.$t("offer.category." + key),
        },
        {
          label: "offer.products.table.color",
          key: "product.color",
          default: "",
        },
        {
          label: "offer.products.table.hek",
          key: "price.hek",
          transform: (price, product) =>
            this.formatPrice(
              price,
              product.price ? product.price.currency : null
            ),
          renderCondition: () => !!this.$route.params.distributor,
        },
        {
          label: "offer.products.table.amount",
          key: "amount",
          type: "input:number",
          delay: 2000,
          options: {
            min: 1,
            classList: ["cart-amount-input"]
          },
          showCondition: (row) => {
            let categoriesNotToShow = ["PBX"];
            return !categoriesNotToShow.includes(row.product.category.key);
          },
          callback: async ({_, row, value}) => {
            const id = this.$store.state.offer.offer.uuid;

            const position = {...row, amount: value};
            await this.$store.dispatch("offer/updatePosition", {
              id,
              position,
            });

            if (this.$attrs.hasOwnProperty("data-update-pbx")) {
              await this.updatePossiblePbx();
            } else if (this.$attrs.hasOwnProperty("data-update-accessory")) {
              await this.updatePossibleAccessory();
            } else {
              await this.updatePossibleProducts();
            }
          },
        },
        {
          label: "offer.products.table.used_port",
          key: "port",
          options: {
            classList: ["small"],
          },
          showCondition: (row) => {
            let sub_category = row.product.sub_category;
            let categoriesToShow = ["TELEPHONE_ISDN"];

            if (!sub_category) {
              return false;
            }

            return categoriesToShow.includes(sub_category.key);
          },
          transform: (port) => {
            if (port) {
              return port.toUpperCase();
            }

            return "";
          },
        },
        {
          label: "offer.products.table.image",
          key: "product.image.uuid",
          transform: (image) => {
            if (!image) {
              return "";
            }

            const url = this.$store.getters["asset/getPublicUrl"](
              {uuid: image},
              {height: 50, width: 50}
            );

            return `<img class="no-margin" src="${url}" alt="product image">`;
          },
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      positions: (state) => {
        if (!state.offer.offer || !state.offer.offer.positions) return [];

        return [...state.offer.offer.positions].map((position) => ({
          ...position,
          id: position.uuid,
        }));
      },
    }),
  },
  methods: {
    async updatePossibleProducts() {
      this.loading = true;
      const id = this.$store.state.offer.offer.uuid;

      this.$store.commit("offer/resetProductPagination");
      //this.$store.dispatch('offer/loadPossibleProducts', { id, params: { limit: this.limit } });
      await this.$store.dispatch("offer/balanceAccessory", {id: id});
      await this.$store.dispatch("offer/checkPbxPossibility", {id: id});
      await this.$store.dispatch("offer/loadOne", {id: id});

      this.loading = false;
    },
    async updatePossibleAccessory() {
      this.loading = true;
      const id = this.$store.state.offer.offer.uuid;

      this.$store.commit("offer/resetProductPagination");
      //this.$store.dispatch('offer/loadPossibleProducts', { id, params: { limit: this.limit, category: 'ACCESSORY', ...params } });
      this.$store.dispatch("offer/balanceAccessory", {id: id});
      await this.$store.dispatch("offer/checkPbxPossibility", {id: id});
      await this.$store.dispatch("offer/loadOne", {id: id});

      this.loading = false;
    },
    async removePosition(position) {
      this.loading = true;
      const id = this.$store.state.offer.offer.uuid;

      await this.$store.dispatch("offer/removePosition", {id, position});

      if (this.$attrs.hasOwnProperty("data-update-pbx")) {
        await this.updatePossiblePbx();
      } else if (this.$attrs.hasOwnProperty("data-update-accessory")) {
        await this.updatePossibleAccessory();
      } else {
        await this.updatePossibleProducts();
      }
      this.loading = false;
    },
    async updatePossiblePbx() {
      this.loading = true;
      const id = this.$store.state.offer.offer.uuid;

      this.$store.commit("offer/resetPbxPagination");
      await this.$store.dispatch("offer/loadPossiblePbx", {
        id,
        params: {limit: this.limit},
      });
      this.$store.state.offer.possibleProducts.forEach((pbx) => {
        this.$store.dispatch("offer/loadPbxModules", {
          id: this.$route.params.id,
          pbx: pbx.uuid,
        });
      });
      this.$store.dispatch("offer/balanceAccessory", {id: id});
      await this.$store.dispatch("offer/checkPbxPossibility", {id: id});
      await this.$store.dispatch("offer/loadOne", {id: id});

      this.loading = false;
    },
    renderSystemFunctions(row) {
      let title = "";

      if (row.selected_system_functions) {
        row.selected_system_functions.forEach(function (systemFunction) {
          if (!systemFunction.title) {
            const selectedSystemFunction = row.product.selectable_system_functions.find(
              (selectableSystemFunction) =>
                selectableSystemFunction.uuid === systemFunction.product
            );
            systemFunction.title =
              selectedSystemFunction && selectedSystemFunction.title;
          }

          if (systemFunction.title) {
            title += `<div class="caption no-margin">${systemFunction.title} (${systemFunction.value})</div>`;
          }
        });
      }

      return title;
    },
    formatPrice(price = 0, currency = "GBP") {
      const locale = this.$i18n.locale;

      if (!currency) {
        currency = "GBP";
      }

      return Number(price).toLocaleString(locale, {
        style: "currency",
        currency,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.trash-icon {
  width: 60%;
}
</style>
