<template>
  <div class="inline">
    <div @click="$refs.modal.open()">
      <slot/>
    </div>

    <modal ref="modal">
      <div class="import-modal-body">
        <h3 class="headline headline_3">{{ $t("distributor.modal.import.headline") }}</h3>

        <div class="input">
          <label class="file-upload-label">{{ $t("distributor.modal.import.upload_csv") }}</label>
          <file-upload :value="filename" @change="file = $event" :accept="allowedFiles"></file-upload>
        </div>

        <div>
          <button class="button primary small" @click="startImport()">{{ $t("distributor.modal.import.buttons.import") }}</button>
          <button class="button secondary small" @click="$refs.modal.close()">{{ $t("distributor.modal.import.buttons.cancel") }}</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import Modal from '../../shared/components/modal/modal';
  import FileUpload from '../../shared/components/form/file-upload';

  export default {
    name: "import-prices-modal",
    components: {
      Modal,
      FileUpload
    },
    data() {
      return {
        file: null,
        allowedFiles: '.csv'
      }
    },
    computed: {
      filename() {
        return this.file ? this.file.name : null;
      }
    },
    methods: {
      async startImport() {
        if (this.file) {
          await this.$store.dispatch('distributor/product/import', this.file);
        }

        this.file = null;
        this.$refs.modal.close();
        await this.$store.dispatch('distributor/product/load');
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";
  @import "../../../res/less/core/config/constants";

  .import-modal-body {
    padding: @space5 @space5 calc(@space5 - @standard-space) @space5;
    min-width: 400px;
    background-color: @white-100;

    .file-upload-label {
      margin-bottom: @space1;
      color: @brand-1-100;
    }
  }

  @media(max-width: @phone) {
    .inline { width: 100%; }
  }
</style>
