import { uniq } from "underscore";
import { pagination, product } from "./templates";

export default {
    async loaded(state, data) {
        state.products = uniq([...data.products], 'uuid');
        state.pagination = { ...(data.pagination || pagination) };
    },
    async loadedMore(state, data) {
        state.products = uniq([...data.products, ...state.products], 'uuid');
        state.pagination = { ...data.pagination };
    },
    async updatePrice(state, { id, price }) {
        state.products = state.products.map(product => product.uuid === id ? { ...product, price } : product);
    }
};
