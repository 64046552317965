<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">
        {{ $t("faq.update_faq.headline") }}
      </h2>

      <div class="inline right">
        <button class="button secondary">{{ $t("actions.save") }}</button>
        <button class="button secondary">{{ $t("actions.discard") }}</button>
      </div>
    </div>

    <notifications :max="3" />

    <section class="sub">
      <div class="input">
        <label>{{ $t("faq.update_faq.label.topic") }}</label>
        <dropdown
          :data="topics"
          @change="faq.topic = $event ? $event.value : null"
          :placeholder="$t('faq.update_faq.placeholder.topic')"
        ></dropdown>
      </div>

      <div class="input">
        <label for="question">{{ $t("faq.update_faq.label.question") }}</label>
        <input id="question" type="text" v-model="faq.question" />
      </div>

      <div class="input">
        <label for="answer">{{ $t("faq.update_faq.label.answer") }}</label>
        <textarea id="answer" v-model="faq.answer"></textarea>
      </div>
    </section>

    <button class="button secondary">{{ $t("actions.save") }}</button>
    <button class="button secondary">{{ $t("actions.discard") }}</button>
  </section>
</template>

<script>
import Dropdown from "../../shared/components/form/dropdown";
import Notifications from "../../shared/components/notifications";

export default {
  name: "add-faq",
  components: { Notifications, Dropdown },
  data() {
    return {
      topics: [
        { label: "Allgemein", value: "Allgemein" },
        { label: "Thema 1", value: "Thema 1" },
        { label: "Thema 2", value: "Thema 2" },
      ],
      faq: {
        topic: "",
        question: "",
        answer: "",
      },
    };
  },
};
</script>

<style lang="less" scoped>
textarea {
  min-height: 300px;
}
</style>
