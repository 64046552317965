class DistributorModule {
	boot() {
		//
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');

		return { de, en };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			'distributor/product': require('./store/product').default,
			'distributor/profile': require('./store/profile').default
		};
	}
}

export default DistributorModule;
