import AuthGuard from "../../shared/router/guard/auth";
import UpdateProfileComponent from '../views/profile';

export default [
	{
		path: '/profile',
		component: UpdateProfileComponent,
		meta: {
			title: 'profile.routes.update'
		},
		beforeEnter: AuthGuard
	},
];
