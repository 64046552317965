export const offer = {
	// connections
	needs_isdn_amt: 0,
	needs_analog_amt: 0,
	needs_voip_amt: 0,

	needs_isdn_spare: 0,
	needs_up0_spare: 0,
	needs_analog_spare: 0,
	needs_voip_spare: 0,

	foreign_voip: 0,
	foreign_dect: 0,
	foreign_s0: 0,
	foreign_analog: 0,
	foreign_tfs: 0,

	overcommitment_voip: 100,
	overcommitment_dect: 100,

	// functions
	mail_boxes_total: 0,
	fax_boxes_total: 0,
	vmf_channels_total: 0,
	voice_mail_boxes_total: 0,
	lan_tapi_clients_total: 0,
	automatic_central: false,
	call_through_connections_total: 0,
	hotel_function_phones_total: 0,
	call_data_records_total: 0,
	lcr: 0,
	enable_project: false,

	// general
	offer_number: "",
	title: '',
	contact_person: '',
	project: {
		number: '',
		title: ''
	},
	customer: {
		number: '',
		name: '',
		contact: '',
		address: {
			street: '',
			zip: '',
			city: ''
		},
		contact_data: {
			phone: '',
			fax: '',
			email: '',
			web: ''
		}
	},
	region_settings: {
		currency_factor: '',
		currency: '',
		language: '',
		tax: ''
	},
	region: {},
	distributor: {},

	// shop
	positions: [],
	system_function_requirements: []
};

export const pagination = {
	current_page: 0,
	limit: null,
	pages: null,
	max_count: null,
	next_page: null
};

export const result = {
	category_listing: {},
	components: [],
	exchange_lines: {},
	positions: [],
	foreign_devices: [],
	total: {}
};
