<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">
        {{ mode === "add" ? "Neuer Händler" : "Händler bearbeiten" }}
      </h2>

      <div class="inline right">
        <button class="button secondary" @click="save()">Speichern</button>
        <button class="button secondary" @click="discard()">Verwerfen</button>
      </div>
    </div>

    <notifications :max="3" />

    <div class="grid">
      <div class="col6">
        <section>
          <h3 class="headline headline_3">Kontaktdaten</h3>

          <div class="input">
            <label for="reseller_id">Händlernummer</label>
            <input
              id="reseller_id"
              type="text"
              v-model="reseller_number"
              disabled
            />
          </div>

          <div class="input">
            <label for="reseller_name">Name</label>
            <input id="reseller_name" type="text" v-model="title" />
          </div>

          <div class="input">
            <label for="reseller_phone">Telefon</label>
            <input id="reseller_phone" type="text" v-model="phone" />
          </div>

          <div class="input">
            <label for="reseller_fax">Fax</label>
            <input id="reseller_fax" type="text" v-model="fax" />
          </div>

          <div class="input">
            <label for="reseller_email">E-Mail</label>
            <input id="reseller_email" type="text" v-model="email" />
          </div>

          <div class="input">
            <label for="reseller_web">Website</label>
            <input id="reseller_web" type="text" v-model="website" />
          </div>
        </section>

        <section>
          <h3 class="headline headline_3">Konto</h3>

          <div class="input">
            <label for="reseller_username">Benutzername</label>
            <input
              type="text"
              id="reseller_username"
              v-model="username"
              :disabled="mode === 'edit'"
            />
          </div>

          <div class="input">
            <label>Rolle</label>
            <dropdown v-model="role" :data="roles"></dropdown>
          </div>

          <div class="input">
            <label for="reseller_password">Passwort</label>
            <input type="password" v-model="password" id="reseller_password" />
            <!-- TODO(soerenuhrbach): Implement Account Logic -->
          </div>

          <div class="input">
            <label for="reseller_distributor_alias">Alias / URL-Segment</label>
            <input
              type="text"
              id="reseller_distributor_alias"
              :value="alias"
              @change="distributor_alias = $event.target.value"
            />
          </div>

          <div class="input">
            <label for="reseller_distributor_secret"
              >Passwort für die Distributor-Plattform</label
            >
            <input
              type="text"
              id="reseller_distributor_secret"
              v-model="distributor_password"
              @change="distributor_password = $event.target.value"
            />
          </div>

          <div class="input">
            <label>Region</label>
            <dropdown v-model="region" :data="regions"></dropdown>
          </div>
        </section>
      </div>

      <div class="col6">
        <section>
          <h3 class="headline headline_3">Adresse</h3>

          <div class="input">
            <label for="reseller_company">Unternehmen</label>
            <input id="reseller_company" type="text" v-model="company" />
          </div>

          <div class="input">
            <label for="reseller_street">Straße und Hausnummer</label>
            <input id="reseller_street" type="text" v-model="street" />
          </div>

          <div class="input">
            <label for="reseller_zip">PLZ</label>
            <input id="reseller_zip" type="text" v-model="zip" />
          </div>

          <div class="input">
            <label for="reseller_city">Stadt</label>
            <input id="reseller_city" type="text" v-model="city" />
          </div>

          <div class="input">
            <label for="reseller_country">Land</label>
            <input id="reseller_country" type="text" v-model="country" />
          </div>
        </section>
      </div>
    </div>

    <button class="button secondary" @click="save()">Speichern</button>
    <button class="button secondary" @click="discard()">Verwerfen</button>
  </section>
</template>

<script>
import slugify from "slugify";
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

import Dropdown from "../../shared/components/form/dropdown";
import Notifications from "../../shared/components/notifications";

export default {
  name: "reseller",
  components: {
    Notifications,
    Dropdown,
  },
  data() {
    return {
      mode: "add",
    };
  },
  computed: {
    ...mapFields("reseller", {
      reseller_number: "reseller.reseller_number",
      title: "reseller.title",
      phone: "reseller.phone",
      fax: "reseller.fax",
      email: "reseller.email",
      website: "reseller.website",
      company: "reseller.company",
      street: "reseller.street",
      zip: "reseller.zip",
      city: "reseller.city",
      country: "reseller.country",
      username: "reseller.username",
      service_contact: "reseller.service_contact",
      region: "reseller.region",
      role: "reseller.role",
      distributor_alias: "reseller.distributor_alias",
      distributor_password: "reseller.distributor_password",
      password: "reseller.password",
    }),
    ...mapState({
      countries(state) {
        return [...state.countries];
      },
      contacts(state) {
        const contacts = [...state["reseller/service-contact"].contacts];

        return contacts.map((contact) => ({
          value: contact,
          label: `${contact.forename} ${contact.surname}`,
        }));
      },
      regions(state) {
        const regions = [...state["reseller/region"].regions];
        return regions.map((region) => ({
          value: region,
          label: `${region.title} (${region.shortcut})`,
        }));
      },
      roles() {
        return [
          { value: "ROLE_RESELLER", label: "Reseller" },
          { value: "ROLE_DISTRIBUTOR", label: "Distributor" },
          { value: "ROLE_ADMIN", label: "Admin" },
        ];
      },
    }),
    alias() {
      if (!this.distributor_alias) {
        this.distributor_alias = slugify(this.username, "_");
      }
      return this.distributor_alias;
    },
  },
  methods: {
    async save() {
      const reseller = this.$store.state.reseller.reseller;

      if (reseller.uuid) {
        await this.$store.dispatch("reseller/update", {
          id: reseller.uuid,
          payload: { reseller },
        });
      } else {
        await this.$store.dispatch("reseller/create", {
          payload: { reseller },
        });
      }

      this.$router.push("/admin/reseller");
    },
    discard() {
      this.$store.commit("reseller/reset");
      this.$router.push("/admin/reseller");
    },
  },
  async mounted() {
    const params = this.$route.params;

    if (params.id) {
      this.mode = "edit";
      await this.$store.dispatch("reseller/loadOne", { id: params.id });
      this.$store.commit("reseller/select", params.id);
    }

    this.$store.dispatch("reseller/region/load");
  },
};
</script>

<style scoped>
</style>
