import { port_restriction } from "../templates";

export default {
	addPortRestriction(state) {
		state.product.port_restrictions = [...state.product.port_restrictions, { ...port_restriction }];
	},
	removePortRestriction(state, index) {
		state.product.port_restrictions = state.product.port_restrictions.filter((restriction, i) => i !== index);
	},
	updatePortRestriction(state, { index, changes }) {
		state.product.port_restrictions = state.product.port_restrictions.map((restriction, i) => i === index ? { ...restriction, ...changes } : restriction);
	}
};
