<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.limits.headline') }}</h4>

    <div class="separator" v-for="(limit, index) of limits" :key="index">
      <div class="input">
        <label :for="'limit-product-' + index">{{ $t('product.product.tabs.rules.sections.limits.product') }}</label>
        <dropdown :id="'limit-product-' + index" :value="limit.limited_product" @change="updateLimit(index, { limited_product: $event })" :data="products" :placeholder="$t('product.product.tabs.rules.sections.limits.placeholder')">
          <dot-menu slot="options" :object="{ index }" :options="options"></dot-menu>
        </dropdown>
      </div>

      <div class="input">
        <label :for="'limit-max-' + index">{{ $t('product.product.tabs.rules.sections.limits.max') }}</label>
        <input type="number" min="0" :id="'limit-max-' + index" :value="limit.limit" @change="updateLimit(index, { limit: $event.target.value })">
      </div>
    </div>

    <button class="button secondary" @click="addLimit()">{{ $t('product.product.tabs.rules.sections.limits.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';

  export default {
    name: "limits",
    components: {
      DotMenu,
      Dropdown
    },
    data() {
      return {
        options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removeLimit', index)
          }
        ]
      }
    },
    methods: {
      addLimit() {
        this.$store.commit('product/addLimit');
      },
      updateLimit(index, changes) {
        this.$store.commit('product/updateLimit', { index, changes });
      }
    },
    computed: {
      ...mapState({
        products(state) {
          return [...state.product.short_products].map(product => ({ value: product.uuid, label: '[' + product.article_number + '] ' + product.title }));
        },
        limits(state) {
          return [...state.product.product.limits];
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
