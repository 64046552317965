<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.accessories.headline') }}</h4>

    <div class="separator" v-for="(equipment, index) of equipments" :key="index">
      <div class="input">
        <label :for="`equipment${index}`">{{ $t('product.product.tabs.rules.sections.accessories.product') }}</label>
        <dropdown :data="products" :value="equipment" @change="updateEquipment(index, $event)" :placeholder="$t('product.product.tabs.rules.sections.accessories.placeholder')">
          <dot-menu slot="options" :object="{ index }" :options="options"></dot-menu>
        </dropdown>
      </div>
    </div>

    <button class="button secondary" @click="addEquipment()">{{ $t('product.product.tabs.rules.sections.accessories.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';

  export default {
    name: "accessories",
    components: {
      DotMenu,
      Dropdown
    },
    data() {
      return {
        options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removeEquipment', index)
          }
        ]
      }
    },
    methods: {
      addEquipment() {
        this.$store.commit('product/addEquipment');
      },
      updateEquipment(index, value) {
        this.$store.commit('product/updateEquipment', { index, value });
      }
    },
    computed: {
      ...mapState({
        products(state) {
          return [...state.product.short_products].map(product => ({ label: product.title, value: product.uuid }));
        },
        equipments(state) {
          return [...state.product.product.possible_accessories];
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
