import AdminGuard from '../../shared/router/guard/admin';

import ListSystemFunctionsComponent from '../views/list';
import UpdateSystemFunctionComponent from '../views/update';

export default [
	{
		path: '/admin/system_functions',
		component: ListSystemFunctionsComponent,
		meta: {
			title: "system_function.routes.system_functions"
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/system_functions/add',
		component: UpdateSystemFunctionComponent,
		meta: {
			title: 'system_function.routes.add_system_function'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/system_functions/:id',
		component: UpdateSystemFunctionComponent,
		meta: {
			title: 'system_function.routes.edit_system_function'
		},
		beforeEnter: AdminGuard
	}
];
