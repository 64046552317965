import { uniq } from "underscore";
import { updateField } from "vuex-map-fields";
import { pagination, region } from "./templates";

export default {
	async loaded(state, data) {
		state.regions = uniq([...data.regions], 'uuid');
		state.pagination = { ...(data.pagination || pagination) };
	},
	async loadedMore(state, data) {
		state.regions = uniq([...data.regions, ...state.regions], 'uuid');
		state.pagination = { ...data.pagination };
	},
	async loadedOne(state, data) {
		state.regions = uniq([...state.regions, data.region], 'uuid');
	},
	async updated(state, { data }) {
		state.region = { ...state.region, ...data.region };
		state.regions = state.regions.map(region => region.uuid === state.region.uuid ? ({ ...state.region }) : region);
	},
	async created(state, { data, response }) {
		state.region = { ...state.region, ...data.region, uuid: response.region.uuid };
		state.regions = [...state.regions, state.region];
	},
	async deleted(state, { response, id }) {
		state.regions = state.regions.filter(region => region.uuid !== id);
	},
	async reset(state) {
		state.region = { ...region };
	},
	async select(state, id) {
		if (state.regions) {
			let region = state.regions.find(region => region.uuid === id);

			if (region) {
				if (region.accounts && region.accounts.first()) {
					region.username = region.accounts.first().username;
				}

				state.region = region;
			}
		}
	},
	async resetPagination(state) {
		state.pagination = { ...pagination };
	},
	updateField
};
