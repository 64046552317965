<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">
        {{
          mode === "add"
            ? $t("offer.offer.headline")
            : $t("offer.offer.edit_headline")
        }}
        <div class="offer-title">
          {{ $t("offer.title_label") }}: {{ title }}
        </div>
      </h2>

      <div class="inline right">
        <!--
        <button class="button secondary" @click="save(true)">{{ $t("actions.continue_later") }}</button>
        <button class="button secondary" @click="discard">{{ $t("actions.discard") }}</button>
        -->
      </div>
    </div>

    <notifications :max="3"/>

    <steps ref="steps" @beforeChange="save(false)" @afterChange="loadTabData">
      <!--<tab :name="$t('offer.offeroffer.steps.general.tab_name')">
        <general />
      </tab>-->
      <tab
        :name="$t('offer.offer.steps.shop.tab_name')"
        :firstactive="isStepSetActiveByParam(1)"
        data-autobalance-accessory
        data-load-possible-products
        data-check-pbx-possibility
      >
        <shop/>
      </tab>
      <tab
        :name="$t('offer.offer.steps.connections.tab_name')"
        :firstactive="isStepSetActiveByParam(2)"
        :disabled="!title"
        data-check-pbx-possibility
      >
        <connections/>
      </tab>
      <tab
        :name="$t('offer.offer.steps.accessory.tab_name')"
        :firstactive="isStepSetActiveByParam(3)"
        data-autobalance-accessory
        data-load-accessory
        data-check-pbx-possibility
        :disabled="!title"
      >
        <accessory/>
      </tab>
      <tab
        :name="$t('offer.offer.steps.functions.tab_name')"
        :firstactive="isStepSetActiveByParam(4)"
        data-autobalance-accessory
        data-check-pbx-possibility
        :disabled="!title"
      >
        <functions/>
      </tab>
      <tab
        :name="$t('offer.offer.steps.pbx.tab_name')"
        :firstactive="isStepSetActiveByParam(5)"
        data-autobalance-accessory
        data-check-pbx-possibility
        data-load-possible-pbx
        :disabled="!title"
      >
        <pbx/>
      </tab>
      <tab
        :name="$t('offer.offer.steps.complete_offer.tab_name')"
        :firstactive="isStepSetActiveByParam(6)"
        data-autobalance-accessory
        data-check-pbx-possibility
        data-load-result
        :disabled="!title"
      >
        <complete-offer/>
      </tab>
    </steps>
  </section>
</template>

<script>
import {mapState} from "vuex";

import Tab from "../../shared/components/tabs/tab";
import Shop from "../components/shop";
import Pbx from "../components/pbx";
import Steps from "../../shared/components/tabs/steps";
import General from "../components/general";
import Devices from "../components/devices";
import Accessory from "../components/accessory";
import Functions from "../components/functions";
import Connections from "../components/connections";
import CompleteOffer from "../components/complete-offer";
import Notifications from "../../shared/components/notifications";
import {wait} from "../../../res/helper/wait";

export default {
  name: "offer",
  components: {
    Tab,
    Shop,
    Pbx,
    Steps,
    General,
    Devices,
    Functions,
    Connections,
    CompleteOffer,
    Notifications,
    Accessory,
  },
  data() {
    return {
      mode: "add",
    };
  },
  methods: {
    async discard() {
      this.$store.commit("offer/reset");

      this.$router.push("/offer");
    },
    isStepSetActiveByParam(step) {
      const params = this.$route.params;
      return !!params.step && parseInt(params.step) === step;
    },
    async save(navigate = false) {
      this.$store.dispatch('notification/set', []);
      const $steps = this.$refs.steps;
      const $currentStep = $steps.getActiveTab();

      if ($currentStep) {
        const $content = $currentStep.$children[0];

        if ($content && $content.save && typeof $content.save === "function") {
          await $content.save(false);
          if (
            $currentStep.$attrs.hasOwnProperty("data-check-pbx-possibility")
          ) {
            await this.$store.dispatch("offer/checkPbxPossibility", {
              id: this.$route.params.id,
            });
          }
        }

        if (navigate) {
          this.$router.push("/offer");
        }
      }
    },
    async loadTabData() {
      //give time to the api to update the offer
      await wait(1500);
      const $steps = this.$refs.steps;
      const $currentStep = $steps.getActiveTab();

      if (this.$store.state.offer.offer && this.$store.state.offer.offer.uuid) {
        if ($currentStep.$attrs.hasOwnProperty("data-load-possible-products")) {
          const $content = $currentStep.$children[0];
          $content.updatePossibleProducts({reset: true});
        }

        if ($currentStep.$attrs.hasOwnProperty("data-load-accessory")) {
          this.$store.commit("offer/resetProductPagination");
          await this.$store.dispatch("offer/loadPossibleProducts", {
            id: this.$route.params.id,
            params: {page: 1, limit: 10, category: "ACCESSORY"},
          });
        }

        if ($currentStep.$attrs.hasOwnProperty("data-autobalance-accessory")) {
          await this.$store.dispatch("offer/balanceAccessory", {
            id: this.$route.params.id,
          });
        }

        if ($currentStep.$attrs.hasOwnProperty("data-load-possible-pbx")) {
          this.$store.commit("offer/resetPbxPagination");
          await this.$store.dispatch("offer/loadPossiblePbx", {
            id: this.$route.params.id,
            params: {page: 1, limit: 10},
          });

          this.$store.state.offer.possibleProducts.forEach((pbx) => {
            this.$store.dispatch("offer/loadPbxModules", {
              id: this.$route.params.id,
              pbx: pbx.uuid,
            });
          });
        }

        if ($currentStep.$attrs.hasOwnProperty("data-load-result")) {
          const $content = $currentStep.$children[0];
          $content.showSpinner = true;
          await this.$store.dispatch("offer/getResult", {
            id: this.$route.params.id,
          });
          $content.showSpinner = false;
        } else {
          this.$store.dispatch("offer/resetResult");
        }
      }
    },
  },
  computed: {
    ...mapState({
      title(state) {
        return state.offer.offer.title;
      },
    }),
  },
  async mounted() {
    const params = this.$route.params;

    this.$store.dispatch("offer/product/loadShort");

    if (this.$store.state.offer.offer) {
      this.$store.commit("offer/reset");
    }

    if (params.id) {
      await this.$store.dispatch("offer/balanceAccessory", {
        id: this.$route.params.id,
      });
      this.mode = "edit";
      await this.$store.dispatch("offer/loadOne", {id: params.id});
      this.$store.commit("offer/select", params.id);
    }

    // this.$store.commit('offer/resetProductPagination');
    // await this.$store.dispatch('offer/loadPossibleProducts', { id: this.$route.params.id, params: { page: 1, limit: 10, sub_category: 'TELEPHONE_VOIP' } });
  },
};
</script>

<style lang="less" scoped>
.steps {
  margin-bottom: 0;
}

.offer-title {
  font-size: 0.4em;
  margin: 0;
  padding: 0;
  line-height: 1em;
  color: #555;
}
</style>
