<template>
  <section class="sub">
    <h4 class="headline headline_4">Am Gerät wählbare Systemfunktionen/Zubehör</h4>

    <div class="separator" v-for="(systemFunction, index) of selectableSystemFunctions" :key="index">
      <div class="input">
        <label :for="`sf${index}`">{{ $t('product.product.tabs.rules.sections.selectable_system_functions.product') }}</label>
        <dropdown :data="products" :value="systemFunction.product" @change="updateSelectableSystemFunction(index, { product: $event })" :placeholder="$t('product.product.tabs.rules.sections.selectable_system_functions.placeholder')">
          <dot-menu slot="options" :object="{ index }" :options="options"></dot-menu>
        </dropdown>
      </div>

      <div class="input">
        <label :for="`sf${index}sorting`">Sortierung</label>
        <input type="number" :id="`sf${index}sorting`" :value="systemFunction.sorting ? systemFunction.sorting : index+1" @change="updateSelectableSystemFunction(index, { sorting: $event.target.value })" />
      </div>

      <div class="input">
        <label :for="`sf${index}type`">{{ $t('product.product.tabs.rules.sections.selectable_system_functions.type') }}</label>
        <dropdown @change="updateSelectableSystemFunction(index, { type: $event })" :data="types" :value="systemFunction.type" :for="`sf${index}type`" />
      </div>

      <div v-if="systemFunction.type === 'number'" class="input">
        <label :for="`sf${index}value`">{{ $t('product.product.tabs.rules.sections.selectable_system_functions.value_number') }}</label>
        <input type="number" :id="`sf${index}value`" :value="systemFunction.value" @change="updateSelectableSystemFunction(index, { value: $event.target.value })" />
      </div>
    </div>

    <button class="button secondary" @click="addSelectableSystemFunction()">{{ $t('product.product.tabs.rules.sections.selectable_system_functions.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';

  export default {
    name: "system-functions-selectable",
    components: {
      DotMenu,
      Dropdown
    },
    data() {
      return {
        options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removeSelectableSystemFunction', index)
          }
        ],
        types: [
          {
            value: 'checkbox',
            label: this.$t('product.product.tabs.rules.sections.selectable_system_functions.types.checkbox')
          },
          {
            value: 'number',
            label: this.$t('product.product.tabs.rules.sections.selectable_system_functions.types.number')
          }
        ]
      }
    },
    methods: {
      addSelectableSystemFunction() {
        this.$store.commit('product/addSelectableSystemFunction');
      },
      updateSelectableSystemFunction(index, changes) {
        this.$store.commit('product/updateSelectableSystemFunction', { index, changes });
      }
    },
    computed: {
      ...mapState({
        products(state) {
          let products = [...state.product.short_products].filter(product => {
            return (product.sub_category && product.sub_category.key === 'SYSTEM_FUNCTION') || (product.category && product.category.key === 'ACCESSORY')
          });
          return products.map(product => ({ value: product.uuid, label: '[' + product.article_number + '] ' + product.title }));
        },
        selectableSystemFunctions(state) {
          return [...state.product.product.selectable_system_functions];
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
