import AdminGuard from '../../shared/router/guard/admin';

import ListServiceContactsComponent from '../views/list';
import UpdateServiceContactComponent from '../views/update';

export default [
	{
		path: '/admin/contact',
		component: ListServiceContactsComponent,
		meta: {
			title: 'service-contact.routes.list'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/contact/add',
		component: UpdateServiceContactComponent,
		meta: {
			title: 'service-contact.routes.add'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/contact/:id',
		component: UpdateServiceContactComponent,
		meta: {
			title: 'service-contact.routes.edit'
		},
		beforeEnter: AdminGuard
	}
];
