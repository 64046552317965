<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">{{ mode === 'add' ? "Neue Region" : "Region editieren" }}</h2>

      <div class="inline right">
        <button class="button secondary" @click="save()">Speichern</button>
        <button class="button secondary" @click="discard()">Verwerfen</button>
      </div>
    </div>

    <notifications :max="3" />

    <div class="grid">
      <div class="col6">
        <section>
          <h3 class="headline headline_3">Region</h3>

          <language-tabs>
            <tab name="DE" language="de">
              <div class="input">
                <label for="region_title_de">Titel</label>
                <input type="text" id="region_title_de" v-model="title" />
              </div>
            </tab>
            <tab name="EN (AU)" language="en">
              <div class="input">
                <label for="region_title_en_au">Titel</label>
                <input type="text" id="region_title_en_au" v-model="title_en_au" />
              </div>
            </tab>
            <tab name="EN (FON)" language="en">
              <div class="input">
                <label for="region_title_en">Titel</label>
                <input type="text" id="region_title_en" v-model="title_en" />
              </div>
            </tab>
            <tab name="FR" language="fr">
              <div class="input">
                <label for="region_title_fr">Titel</label>
                <input type="text" id="region_title_fr" v-model="title_fr" />
              </div>
            </tab>
          </language-tabs>

          <div class="input">
            <label for="region_shortcut">Kürzel</label>
            <input type="text" id="region_shortcut" v-model="shortcut" />
          </div>

          <div class="input">
            <label for="region_tax">Steuer</label>
            <input type="number" id="region_tax" v-model="tax" />
          </div>

          <div class="input">
            <label for="region_currency">Währung</label>
            <dropdown id="region_currency" :data="currencies" v-model="currency" placeholder="Währung"></dropdown>
          </div>

          <div class="input">
            <label for="region_client">Client</label>
            <dropdown id="region_client" :data="clients" v-model="client" placeholder="Client"></dropdown>
          </div>

          <div class="input">
            <checkbox  id="region_hidden" name="hidden" v-model="hidden">Im Frontend ausblenden</checkbox>
          </div>
        </section>
      </div>
    </div>

    <button class="button secondary" @click="save()">Speichern</button>
    <button class="button secondary" @click="discard()">Verwerfen</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { mapFields } from 'vuex-map-fields';

  import Tab from "../../shared/components/tabs/tab";
  import LanguageTabs from "../../shared/components/tabs/language-tabs";
  import Dropdown from '../../shared/components/form/dropdown';
  import Notifications from "../../shared/components/notifications";
  import Checkbox from "../../shared/components/form/checkbox";

  export default {
    name: "region",
    components: {
        Checkbox,
      Notifications,
      LanguageTabs,
      Dropdown,
      Tab
    },
    data() {
      return {
        mode: 'add'
      }
    },
    computed: {
      ...mapFields('region', {
        title: 'region.title',
        title_fr: 'region.title_fr',
        title_en: 'region.title_en',
        title_en_au: 'region.title_en_au',

        shortcut: 'region.shortcut',
        tax: 'region.tax',
        currency: 'region.currency',
        client: 'region.client',
        hidden: 'region.hidden'
      }),
      ...mapState({
        currencies(state) {
          return state.currencies;
        },
        clients(state) {
          return state.clients;
        }
      })
    },
    methods: {
      async save() {
        const region = this.$store.state.region.region;

        if (region.uuid) {
          await this.$store.dispatch('region/update', { id: region.uuid, payload: { region } });
        } else {
          await this.$store.dispatch('region/create', { payload: { region } })
        }

        this.$router.push('/admin/regions');
      },
      discard() {
        this.$store.commit('region/reset');
        this.$router.push('/admin/regions');
      }
    },
    async mounted() {
      const params = this.$route.params;

      if (params.id) {
        this.mode = 'edit';
        await this.$store.dispatch('region/loadOne', { id: params.id });
        this.$store.commit('region/select', params.id);
      }
    }
  }
</script>

<style scoped>

</style>
