class SystemFunctionModule {
	boot() {
		//
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');

		return { de, en };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			'systemFunctions': require('./store/system_function').default
		};
	}

	/**
	 * @return {string[]}
	 */
	getDependencies() {
		return [
			'shared'
		];
	}
}

export default SystemFunctionModule;
