import { uniq } from "underscore";

export default {
    loaded(state, data) {
        state.assets = uniq([...state.assets, data.asset], 'uuid');
    },
    uploaded(state, data) {
        state.assets = uniq([...state.assets, data.asset], 'uuid');
    }
};
