<template>
  <div class="tab" v-show="active">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "tab",
    props: {
      name: {
        type: String,
        required: true
      },
      propId: {
        type: [String, Number],
        required: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      substep: {
        type: Boolean,
        default: false
      },
      language: {
        type: String,
        required: false
      },
      firstactive: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        active: false
      }
    },
    computed: {
      id() {
        return `tab-${this._uid}`;
      }
    }
  }
</script>

<style scoped>

</style>
