import AdminGuard from '../../shared/router/guard/admin';
import RegionListComponent from '../views/list';
import RegionUpdateComponent from '../views/update';

export default [
	{
		path: '/admin/regions',
		component: RegionListComponent,
		meta: {
			title: 'region.routes.regions'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/regions/add',
		component: RegionUpdateComponent,
		meta: {
			title: 'region.routes.add_region'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/regions/:id',
		component: RegionUpdateComponent,
		meta: {
			title: 'region.routes.edit_region'
		},
		beforeEnter: AdminGuard
	}
];
