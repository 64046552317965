import axios from "axios/index";
import config from "../../../../config";

export default {
    async requestToken({ commit }, { alias, secret }) {
        try {
            const response = await axios.get(`${config.api.endpoint}/distributor/alias/${alias}?secret=${secret}`);
            let data = response.data;
            let token = { uuid: data.distributor.uuid, alias };
            localStorage.setItem('distributor', JSON.stringify(token));
            commit('setDistributor', token);
            return true;
        } catch (exception) {
            return false;
        }
    }
};
