import Application from '../../index';

import LoginComponent from '../views/login';
import ImprintComponent from '../views/imprint';
import PrivacyPolicyComponent from '../views/privacy-policy';
import RegionSelectComponent from '../views/region-select';
import StyleGuideComponent from '../views/style-guide';

export default [
	{
		path: '/',
		component: RegionSelectComponent,
		meta: {
			title: 'routes.index'
		}
	},
	{
		path: '/login',
		component: LoginComponent,
		meta: {
			title: 'routes.login'
		}
	},
	{
		path: '/logout',
		component: LoginComponent,
		beforeEnter: async (to, from, next) => {
			const store = Application.getStore();
			await store.dispatch('auth/logout');

			next('/login');
		}
	},
	{
		path: '/logout-distributor/:distributor',
		component: LoginComponent,
		beforeEnter: async (to, from, next) => {
			const store = Application.getStore();
			await store.dispatch('auth/logout');
			next(`/${from.params.region}/${to.params.distributor}/login`);
		}
	},

	{
		path: '/imprint',
		component: ImprintComponent,
		meta: {
			title: 'routes.imprint'
		}
	},
	{
		path: '/privacy-policy',
		component: PrivacyPolicyComponent,
		meta: {
			title: 'routes.privacy-policy'
		}
	},
	{
		path: '/style-guide',
		component: StyleGuideComponent,
		meta: {
			title: 'Style-Guide'
		}
	}
];
