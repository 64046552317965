class ProductModule {
	boot() {
		//
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');

		return { de, en };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			'product': require('./store/product').default,
			'product/region': require('./store/region').default,
			'product/systemFunctions': require('./store/system_functions').default,
		};
	}
}

export default ProductModule;
