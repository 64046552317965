class OfferModule {
	boot() {
		require('./router/middleware/distributor-token');
		require('./router/middleware/region');
	}

	/**
	 * @return {string[]}
	 */
	getDependencies() {
		return [
			'page'
		];
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');
		const fr = require('./i18n/fr.json');

		return { de, en, fr };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			'offer': require('./store/offer').default,
			'offer/region': require('./store/region').default,
			'offer/product': require('./store/product').default,
			'offer/distributor': require('./store/distributor').default,
			'offer/category': require('./store/category').default,
			'offer/manual': require('./store/manual').default
		};
	}
}

export default OfferModule;
