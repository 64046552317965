import axios from 'axios';
import { Service } from 'axios-middleware';
import Application from '../../../index';

new Service(axios).register({
	onRequest(config) {
		const router = Application.getRouter();
		const route = router.history.current;

		if (route.params.region) {
			config.headers['X-Region'] = route.params.region;
		}

		return config;
	}
});
