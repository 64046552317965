<template>
  <div class="dropdown-tabs">
    <div class="header">
      <div class="input">
        <label v-if="label">{{ label }}</label>
        <dropdown ref="dropdown" :forceHasOptions="true" :data="formattedTabs" v-model="currentTab">
          <template slot="options" slot-scope="scope">
            <slot name="options" :data="scope.selected"></slot>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import Tabs from './tabs';
  import Dropdown from '../form/dropdown';
  import {wait} from "../../../../res/helper/wait";

  export default {
    extends: Tabs,
    components: {
      Dropdown
    },
    props: {
      label: String
    },
    data() {
      return {
        currentTab: 0
      }
    },
    computed: {
      formattedTabs() {
        return [...this.tabs.map(tab => ({ value: tab.id, label: tab.name, id: tab.propId }))];
      }
    },
    name: "dropdown-tabs",
    watch: {
      currentTab(tabId) {
        this.selectTab(tabId);
        this.$refs.dropdown.close();
      }
    },
    async mounted() {
      const tab = this.tabs[0];

      if (tab) {
        this.$refs.dropdown.select({ value: tab.id, label: tab.name, id: tab.propId });
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";

  .dropdown-tabs {
    margin-bottom: 0;

    .body {
      padding-left: @space4;
    }
  }
</style>
