import axios from "axios";
import config from "../../../../config";

export default {
	async load({ commit }, params = {}) {
		try {
			const response = await axios.get(`${config.api.endpoint}/pages`, { params });
			const data = response.data;

			commit('loaded', data);
		} catch (exception) {
			this.dispatch('notification/fromRequestException', exception);
		}
	},
	async update({ commit }, { id, payload, params = {} }) {
		try {
			const response = await axios.put(`${config.api.endpoint}/pages/${id}`, { ...payload }, { params });
			const data = response.data;

			commit('updated', { response: data, data: payload });

			this.dispatch('notification/set', {
				level: 'success',
				title: data.message
			});
		} catch (exception) {
			this.dispatch('notification/fromRequestException', exception);
		}
	}
};
