<template>
  <div class="file-upload-wrapper">
    <label :for="id" class="file-upload">
      {{ value ? value : $t("components.file-upload.placeholder") }}
      <span class="button secondary small">{{ $t("components.file-upload.browse") }}</span>
    </label>
    <input :id="id" type="file" @change="handleChange" :accept="accept">
  </div>
</template>

<script>
  export default {
    name: "file-upload",
    computed: {
      id() {
        return 'file-upload-' + this._uid;
      }
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      accept: {
        type: String
      }
    },
    methods: {
      handleChange($event) {
        const file = $event.target.files[0];

        if (file) {
          this.$emit('input', file.name);
          this.$emit('change', file);
        } else {
          this.$emit('input', null);
          this.$emit('change', null);
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";
  @import "../../../../res/less/core/config/constants";

  .file-upload-wrapper {
    input {
      display: none;
    }

    .file-upload {
      display: block;
      overflow: hidden;
      line-height: 30rem;
      position: relative;
      min-height: @space6;
      padding: @space1 @space1 @space1 @space2;

      .button {
        position: absolute;
        right: @space1;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
</style>
