import AuthGuard from '../../shared/router/guard/auth';
import DashboardComponent from '../views/dashboard';

export default [
	{
		path: '/dashboard',
		component: DashboardComponent,
		meta: {
			title: 'dashboard.routes.dashboard'
		},
		beforeEnter: AuthGuard
	}
];
