<template>
  <section>
    <h2 class="headline headline_2">Seiten & Handbuch</h2>

    <notifications :max="3" />

    <section class="sub">
      <div>
        <vue-element-loading :active="loading" spinner="bar-fade-scale" />

        <data-table :fields="fields" :data="pages">
          <tr slot="empty">
            <td :colspan="fields.length" class="text-center">
              Es wurden keine Seiten gefunden.
            </td>
          </tr>
          <div class="inline right" slot="last-col" slot-scope="{ row }">
            <dot-menu :object="row" :options="options"></dot-menu>
          </div>
        </data-table>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from "vuex";
import DotMenu from "../../shared/components/modal/dot-menu";
import DataTable from "../../shared/components/table/table";
import Notifications from "../../shared/components/notifications";
import VueElementLoading from "vue-element-loading";

export default {
  name: "pages",
  components: {
    Notifications,
    DotMenu,
    DataTable,
    VueElementLoading,
  },
  data() {
    return {
      loading: false,
      fields: [
        { label: "ID", key: "id" },
        {
          label: "Typ",
          key: "category",
          transform: (cat) => this.$t("page.category." + cat),
        },
        { label: "Titel (DE)", key: "title.title_de" },
      ],
      options: [
        {
          title: "actions.edit",
          callback: (page) => this.$router.push(`/admin/page/${page.id}`),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      pages(state) {
        return state.page.pages;
      },
    }),
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("page/load");
    this.loading = false;
  },
};
</script>

<style scoped>
</style>
