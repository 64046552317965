<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.incompatible_with_pbxs.headline') }}</h4>

    <div v-for="(incompatibleWithPbx, itemIndex) of incompatibleWithPbxs" :key="itemIndex"
         class="grid">
      <div class="col6">
        <div class="input">
          <label>{{ $t('product.product.tabs.rules.sections.incompatible_with_pbxs.pbx') }}</label>
          <dropdown
            :data="products"
            :value="incompatibleWithPbx.product"
            @change="updateCollectionItem(incompatibleWithPbx.id,itemIndex, {product:$event})"
            :placeholder="$t('product.product.tabs.rules.sections.incompatible_with_pbxs.placeholder')">
            <dot-menu slot="options" :options="productOptions"
                      :object="{ itemId: incompatibleWithPbx.id, itemIndex }"></dot-menu>
          </dropdown>
        </div>
      </div>
    </div>
    <button class="button secondary" @click="addNewItem">
      {{ $t('product.product.tabs.rules.sections.incompatible_with_pbxs.add_product') }}
    </button>
  </section>
</template>

<script>
import {mapState} from 'vuex';
import {mapFields} from 'vuex-map-fields';

import Dropdown from "../../../shared/components/form/dropdown";
import DotMenu from "../../../shared/components/modal/dot-menu";

export default {
  name: "incompatible-with-pbxs",
  components: {
    Dropdown,
    DotMenu
  },
  data() {
    return {
      productOptions: [
        {
          title: 'actions.delete',
          callback: ({
                       itemId,
                       itemIndex
                     }) => this.$store.commit('product/removeIncompatibleWithPbxItem', {itemId, itemIndex})
        }
      ]
    }
  },
  computed: {
    ...mapState({
      products(state) {
        return [...state.product.short_products]
          .filter(product => product.category && product.category.key === "PBX")
          .map(product => ({
            value: product.uuid,
            label: '[' + product.article_number + '] ' + product.title
          }));
      }
    }),
    ...mapFields('product', {
      incompatibleWithPbxs: 'product.incompatible_with_pbxs'
    })
  },
  methods: {
    addNewItem() {
      this.$store.commit('product/addIncompatibleWithPbxItem');
    },
    async updateCollectionItem(itemId, itemIndex, changes) {
      this.$store.commit('product/updateIncompatibleWithPbxCollectionItem', {itemId, itemIndex, changes});
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../../res/less/core/config/constants";
@import "../../../../res/less/themes/theme";

.separator {
  &:not(:last-of-type) {
    border-bottom: 1px dashed @brand-2-100;
    margin-bottom: @standard-space;
  }
}
</style>
