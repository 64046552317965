import Application from '../../../index';

export default async function validateRegionExists(to, from, next) {
	const store = Application.getStore();
	const state = store.state['region'];
	const region = to.params.region;

	if (!state.loaded) {
		await store.dispatch('offer/region/load');
	}

	if (state.regions.find(r => r.shortcut === region)) {
		next();
	} else {
		next('/');
	}
}
