<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.required_products.headline') }}</h4>

    <div class="separator" v-for="(product, index) of requiredProducts" :key="index">
      <div class="input">
        <label :for="`sf${index}`">{{ $t('product.product.tabs.rules.sections.required_products.product') }}</label>
        <dropdown :data="products" :value="product.product" @change="updateRequiredProduct(index, {product: $event})" :placeholder="$t('product.product.tabs.rules.sections.required_products.placeholder')">
          <dot-menu slot="options" :object="{ index }" :options="options"></dot-menu>
        </dropdown>
      </div>

      <div class="input">
        <label :for="`min${index}`">{{ $t('product.product.tabs.rules.sections.required_products.min') }}</label>
        <input :id="`min${index}`" type="number" min="0" :value="product.min" @change="updateRequiredProduct(index, { min: $event.target.value })" />
      </div>
      <div class="input">
        <checkbox id="exact-count" v-model="product.exact_count">Der Wert muss exakt sein (Nicht "Minimum")</checkbox>
      </div>
      <div class="input">
        <checkbox id="counts-per-position" v-model="product.counts_per_position">Zählt einzeln für jede Position</checkbox>
      </div>
    </div>

    <button class="button secondary" @click="addRequiredProduct()">{{ $t('product.product.tabs.rules.sections.required_products.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';
  import Checkbox from "../../../shared/components/form/checkbox";

  export default {
    name: "required-products",
    components: {
      Checkbox,
      DotMenu,
      Dropdown
    },
    data() {
      return {
        options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removeRequiredProduct', index)
          }
        ]
      }
    },
    methods: {
      addRequiredProduct() {
        this.$store.commit('product/addRequiredProduct');
      },
      updateRequiredProduct(index, changes) {
        this.$store.commit('product/updateRequiredProduct', { index, changes });
      }
    },
    computed: {
      ...mapState({
        products(state) {
          let products = [...state.product.short_products].filter(product => {
            return (product.sub_category && product.sub_category.key === 'SYSTEM_FUNCTION') || (product.category && product.category.key === 'ACCESSORY') || (product.category && product.category.key === 'EXTENSION');
          });
          return products.map(product => ({ value: product.uuid, label: '[' + product.article_number + '] ' + product.title }));
        },
        requiredProducts(state) {
          return [...state.product.product.required_products];
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
