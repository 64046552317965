<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">{{ company }}</h2>

      <div class="inline right">
        <button class="button secondary" @click="save()">
          {{ $t("actions.save") }}
        </button>
        <button class="button secondary" @click="discard()">
          {{ $t("actions.discard") }}
        </button>
      </div>
    </div>

    <notifications :max="3" />

    <tabs>
      <tab :name="$t('profile.profile.tabs.contact.headline')">
        <section class="sub">
          <div class="grid">
            <div class="col6">
              <h3 class="headline headline_3">
                {{ $t("profile.profile.tabs.contact.headline") }}
              </h3>

              <div class="input">
                <label for="reseller-id">{{
                  $t("profile.profile.tabs.contact.input.id")
                }}</label>
                <input
                  type="text"
                  id="reseller-id"
                  v-model="reseller_number"
                  disabled
                />
              </div>

              <div class="input">
                <label for="reseller-name">{{
                  $t("profile.profile.tabs.contact.input.name")
                }}</label>
                <input type="text" id="reseller-name" v-model="title" />
              </div>

              <div class="input">
                <label for="reseller-phone">{{ $t("phone") }}</label>
                <input type="text" id="reseller-phone" v-model="phone" />
              </div>

              <div class="input">
                <label for="reseller-fax">{{
                  $t("profile.profile.tabs.contact.input.fax")
                }}</label>
                <input type="text" id="reseller-fax" v-model="fax" />
              </div>

              <div class="input">
                <label for="reseller-mail">{{
                  $t("profile.profile.tabs.contact.input.mail")
                }}</label>
                <input type="text" id="reseller-mail" v-model="email" />
              </div>

              <div class="input">
                <label for="reseller-homepage">{{
                  $t("profile.profile.tabs.contact.input.homepage")
                }}</label>
                <input type="text" id="reseller-homepage" v-model="website" />
              </div>
            </div>
            <div class="col6">
              <h3 class="headline headline_3">
                {{ $t("profile.profile.tabs.contact.address") }}
              </h3>

              <div class="input">
                <label for="reseller-company">{{
                  $t("profile.profile.tabs.contact.input.company")
                }}</label>
                <input type="text" id="reseller-company" v-model="company" />
              </div>

              <div class="input">
                <label for="reseller-street">{{
                  $t("profile.profile.tabs.contact.input.street")
                }}</label>
                <input type="text" id="reseller-street" v-model="street" />
              </div>

              <div class="input">
                <label for="reseller-zip">{{
                  $t("profile.profile.tabs.contact.input.zip")
                }}</label>
                <input type="text" id="reseller-zip" v-model="zip" />
              </div>

              <div class="input">
                <label for="reseller-city">{{
                  $t("profile.profile.tabs.contact.input.city")
                }}</label>
                <input type="text" id="reseller-city" v-model="city" />
              </div>

              <div class="input">
                <label>{{
                  $t("profile.profile.tabs.contact.input.country")
                }}</label>
                <dropdown
                  :placeholder="
                    $t('profile.profile.tabs.contact.placeholder.country')
                  "
                  v-model="country"
                  :data="countries"
                ></dropdown>
              </div>

              <div class="input">
                <label>{{
                  $t("profile.profile.tabs.contact.input.logo")
                }}</label>
                <file-upload
                  :value="filename"
                  @change="uploadFile($event)"
                ></file-upload>
              </div>
            </div>
          </div>
        </section>

        <button class="button secondary" @click="save()">
          {{ $t("actions.save") }}
        </button>
        <button class="button secondary" @click="discard()">
          {{ $t("actions.discard") }}
        </button>
      </tab>

      <tab :name="$t('profile.profile.tabs.agb.headline')">
        <section class="sub">
          <div class="grid">
            <div class="col6">
              <h3 class="headline headline_3">
                {{ $t("profile.profile.tabs.agb.headline") }}
              </h3>

              <button class="button secondary">
                {{ $t("profile.profile.tabs.agb.add_language") }}
              </button>

              <dropdown-tabs :label="$t('profile.profile.tabs.agb.language')">
                <tab
                  v-for="(language, index) of languages"
                  :name="language.label"
                  :key="index"
                >
                  {{ language }}
                </tab>
                <dot-menu slot="options" :options="[]"></dot-menu>
              </dropdown-tabs>

              <div class="input">
                <!-- TODO(soerenuhrbach): Tabcordion -->
                <dropdown
                  :placeholder="
                    $t('profile.profile.tabs.agb.placeholder.language')
                  "
                  :data="languages"
                ></dropdown>
              </div>

              <div class="input">
                <label for="agb">{{
                  $t("profile.profile.tabs.agb.agb")
                }}</label>
                <textarea id="agb" v-model="agb"></textarea>
              </div>

              <div class="input">
                <label>{{ $t("profile.profile.tabs.agb.upload_agb") }}</label>
                <file-upload></file-upload>
                <img v-if="image" class="no-margin" :src="image" />
              </div>
            </div>
            <div class="col6"></div>
          </div>
        </section>

        <button class="button secondary" @click="save()">
          {{ $t("actions.save") }}
        </button>
        <button class="button secondary" @click="discard()">
          {{ $t("actions.discard") }}
        </button>
      </tab>

      <tab :name="$t('profile.profile.tabs.price_factor.headline')">
        <section class="sub">
          <div class="grid">
            <div class="col6">
              <h3 class="headline headline_3">
                {{ $t("profile.profile.tabs.price_factor.headline") }}
              </h3>

              <div
                v-for="(category, index) in categories"
                :key="index"
                class="input"
              >
                <label :for="'factor-' + index">{{ category.title }}</label>
                <input
                  :id="'factor-' + index"
                  type="number"
                  step="0.01"
                  :value="getPriceFactor(category.key)"
                  @input="updatePriceFactor(category.key, $event.target.value)"
                />
              </div>
            </div>
            <div class="col6"></div>
          </div>
        </section>

        <button class="button secondary" @click="save()">
          {{ $t("actions.save") }}
        </button>
        <button class="button secondary" @click="discard()">
          {{ $t("actions.discard") }}
        </button>
      </tab>
    </tabs>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

import Tab from "../../shared/components/tabs/tab";
import Tabs from "../../shared/components/tabs/tabs";
import FileUpload from "../../shared/components/form/file-upload";
import Dropdown from "../../shared/components/form/dropdown";
import DropdownTabs from "../../shared/components/tabs/dropdown-tabs";
import DotMenu from "../../shared/components/modal/dot-menu";
import Notifications from "../../shared/components/notifications";

export default {
  name: "profile",
  components: {
    Notifications,
    DotMenu,
    DropdownTabs,
    Dropdown,
    Tab,
    Tabs,
    FileUpload,
  },
  data() {
    return {
      agb: `FONtevo is a Trademark of

Auerswald GmbH & Co. KG
Vor den Grashöfen 1
38162 Cremlingen
Germany

Tel.: +49 (53 06) 92 00-710
Fax: +49 (53 06) 92 00-99
E-Mail: info@fontevo.com

Represented by:
p. h. G. Auerswald Geschäftsführungsgesellschaft mbH
Managing Directors: Dipl.-Ing. Gerhard Auerswald, Dipl.-Kfm. (FH)  Christian Auerswald

Commercial Register entries:`,
      image: null,
      imageDimensions: {
        height: 80,
        width: 80,
      },
    };
  },
  computed: {
    ...mapFields("profile", {
      reseller_number: "profile.reseller_number",
      title: "profile.title",
      phone: "phone",
      fax: "profile.fax",
      email: "profile.email",
      website: "profile.website",
      company: "profile.company",
      street: "profile.street",
      zip: "profile.zip",
      city: "profile.city",
      country: "profile.country",
      logo: "profile.logo",
    }),
    ...mapState({
      countries(state) {
        return state.countries;
      },
      languages(state) {
        return state.languages;
      },
      categories(state) {
        return state.profile.categories;
      },
      filename(state) {
        const image = state.profile.profile.logo;

        if (!image) {
          return "";
        }

        return image.filename + (image.extension ? "." + image.extension : "");
      },
      image(state) {
        const logo = state.profile.profile.logo;
        console.log(state.profile);

        return logo
          ? this.$store.getters["asset/getPublicUrl"](
              logo,
              this.imageDimensions
            )
          : null;
      },
    }),
  },
  methods: {
    async save() {
      const profile = { ...this.$store.state.profile.profile };

      await this.$store.dispatch("profile/update", { payload: { profile } });
      this.$router.push("/dashboard");
    },
    async discard() {
      this.$router.push("/dashboard");
      await this.$store.dispatch("profile/load");
    },
    async uploadFile(file) {
      this.logo = await this.$store.dispatch("asset/upload", file);
    },
    getPriceFactor(category) {
      const factors = [...this.$store.state.profile.profile.price_factors];
      const price = factors.find((factor) => factor.category === category);

      return price ? price.factor : 1;
    },
    updatePriceFactor(category, factor) {
      this.$store.commit("profile/updatePriceFactor", { category, factor });
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/load");
    await this.$store.dispatch("profile/loadCategories");
  },
};
</script>

<style lang="less" scoped>
#agb {
  min-height: 400rem;
}

.tabs {
  margin-bottom: 0;
}
</style>
