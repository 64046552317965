export default {
    i18n: {
        locale: 'en',
        fallbackLocale: 'de'
    },
    api: {
        endpoint: 'https://sc-api.fontevo.com/api/v1',
        client: 'fontevo'
    },
    env: {
        name: 'live',
        isFontevo: true
    },
};
