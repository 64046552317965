<template>
  <section>
    <h2 class="headline headline_2">Produkte</h2>

    <section class="sub">
      <div>
        <router-link to="/admin/product/add" class="button primary" tag="button"
          >Produkt hinzufügen</router-link
        >

        <import-prices-modal>
          <button class="button secondary trigger">Preise importieren</button>
        </import-prices-modal>

        <export-prices-modal>
          <button class="button secondary">Preise exportieren</button>
        </export-prices-modal>
      </div>

      <notifications :max="3" />

      <div class="search">
        <input type="text" placeholder="Suche" v-model="search" />
      </div>

      <div>
        <vue-element-loading :active="loading" spinner="bar-fade-scale" />

        <data-table :fields="fields" :data="products">
          <div class="inline right" slot="last-col" slot-scope="{ row }">
            <dot-menu :options="options" :object="row"></dot-menu>
          </div>
          <tr slot="empty">
            <td :colspan="fields.length" class="text-center">
              {{ $t("product.products.not_found") }}
            </td>
          </tr>
        </data-table>
      </div>
    </section>

    <button class="button secondary" v-if="hasMore" @click="loadMore">
      Mehr laden
    </button>
  </section>
</template>

<script>
import { wait } from "../../../res/helper/wait";
import { mapState } from "vuex";

import DataTable from "../../shared/components/table/table";
import DotMenu from "../../shared/components/modal/dot-menu";
import Notifications from "../../shared/components/notifications";
import ImportPricesModal from "../components/import-prices-modal";
import ExportPricesModal from "../components/export-prices-modal";
import VueElementLoading from "vue-element-loading";

export default {
  name: "products",
  components: {
    ExportPricesModal,
    Notifications,
    DotMenu,
    DataTable,
    ImportPricesModal,
    VueElementLoading,
  },
  data() {
    return {
      limit: 10,
      search: "",
      loading: false,
      fields: [
        { label: "product.products.table.number", key: "article_number" },
        { label: "product.products.table.name", key: "title" },
        {
          label: "product.products.table.regions",
          key: "regions",
          transform: (regions, product) => {
            return regions
              .map((region) => region.region.title)
              .filter((value, index, self) => self.indexOf(value) === index)
              .join(", ");
          },
        },
        { label: "product.products.table.category", key: "category.title" },
        { label: "product.products.table.color", key: "color" },
        {
          label: "product.products.table.hek",
          key: "price.hek",
          transform: (price, product) =>
            this.formatPrice(
              price,
              product.price ? product.price.currency : null
            ),
        },
        {
          label: "product.products.table.uvp",
          key: "price.uvp",
          transform: (price, product) =>
            this.formatPrice(
              price,
              product.price ? product.price.currency : null
            ),
        },
        {
          label: "product.products.table.image",
          key: "image",
          transform: (image) => {
            if (!image || !image.uuid) {
              return "";
            }

            const url = this.$store.getters["asset/getPublicUrl"](image, {
              width: 50,
              height: 50,
            });

            return `<img class="no-margin" src="${url}">`;
          },
          sortable: false,
        },
      ],
      options: [
        {
          title: "actions.edit",
          callback: (row) => this.$router.push(`/admin/product/${row.uuid}`),
        },
        {
          title: "actions.delete",
          callback: (row) => this.deleteProduct(row.uuid),
        },
        {
          title: "actions.duplicate",
          callback: (row) => this.duplicateProduct(row.uuid),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      products(state) {
        const search = this.search.toLowerCase();
        const products = [...state.product.products];

        return products
          .map((product) => ({ ...product, id: product.uuid }))
          .filter((product) => {
            const constraints = [
              product.article_number.toLowerCase().includes(search),
              product.title.toLowerCase().includes(search),
            ];

            return constraints.reduce((m, matching) => matching || m);
          });
      },
      hasMore(state) {
        return !!state.product.pagination.next_page;
      },
    }),
  },
  methods: {
    async loadMore() {
      this.loading = true;
      await this.$store.dispatch("product/loadMore", { search: this.search });
      this.loading = false;
    },
    deleteProduct(uuid) {
      // TODO: implement styled modal
      if (confirm("Soll der Artikel wirklich gelöscht werden?")) {
        this.$store.dispatch("product/delete", { id: uuid });
      }
    },
    async duplicateProduct(uuid) {
      // TODO: implement styled modal
      if (confirm("Soll der Artikel wirklich dupliziert werden?")) {
        await this.$store.dispatch("product/duplicate", { id: uuid });
        await this.$store.dispatch("product/load", { search: this.search });
      }
    },
    formatPrice(price = 0, currency = "EUR") {
      const locale = this.$i18n.locale;

      if (!currency) {
        currency = "EUR";
      }

      return Number(price).toLocaleString(locale, {
        style: "currency",
        currency,
      });
    },
  },
  watch: {
    async search(search) {
      await wait(1000);

      if (search === this.search) {
        let params = search
          ? { search, limit: this.limit }
          : { limit: this.limit };

        this.$store.commit("product/resetPagination");
        this.loading = true;
        await this.$store.dispatch("product/load", params);
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("product/load", { limit: this.limit });
    this.loading = false;
  },
};
</script>

<style lang="less" scoped>
@import "../../../res/less/core/config/constants";

.trigger {
  margin-right: @space3;
}
</style>
