import { selectable_system_function } from "../templates";

export default {
	addSelectableSystemFunction(state) {
		let selectableSystemFunction = { ...selectable_system_function };
		selectableSystemFunction.sorting = state.product.selectable_system_functions.length + 1;
		state.product.selectable_system_functions = [...state.product.selectable_system_functions, { ...selectableSystemFunction }];
	},
	removeSelectableSystemFunction(state, index) {
		state.product.selectable_system_functions = state.product.selectable_system_functions.filter((product, i) => index !== i);
	},
	updateSelectableSystemFunction(state, { index, changes }) {
		state.product.selectable_system_functions = state.product.selectable_system_functions.map((product, i) => index === i ? { ...product, ...changes } : product);
	}
};
