<template>
  <section class="sub">
    <h3 class="headline headline_3">{{ $t("dashboard.faq.headline") }}</h3>

    <section class="sub">
      <accordion v-for="(question, index) of questions" :key="index">
        <span slot="header">{{ question.question }}</span>
        <div slot="body" v-html="question.answer"></div>
      </accordion>
    </section>

    <router-link tag="button" class="button secondary" to="/faq">{{ $t("dashboard.faq.show_all") }}</router-link>
  </section>
</template>

<script>
  import Accordion from '../../shared/components/accordion';

  export default {
    name: "faq",
    components: {
      Accordion
    },
    data() {
      return {
        questions: [
          {
            id: 1,
            question: 'Wie kann ich ein neues Angebot erstellen?',
            answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut'
          },
          {
            id: 2,
            question: 'Wie kann ich meine bisherigen Geräte hinterlegen?',
            answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut'
          },
          {
            id: 3,
            question: 'Wie kann ich Angebote exportieren?',
            answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut'
          }
        ]
      }
    }
  }
</script>

<style lang="less" scoped></style>
