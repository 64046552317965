<template>
  <div>
    <div>
      <h3 :class="`inline headline headline_${headline}`">{{ $t("dashboard.my_information.headline") }}</h3>

      <div class="inline right">
        <dot-menu :options="options"></dot-menu>
      </div>
    </div>

    <section>
      <div class="logo-headline">{{ $t("dashboard.my_information.logo") }}</div>

      <img class="no-margin" :src="logo" :alt="profile.company">
    </section>

    <section class="sub">
      <div class="company-headline">{{ $t("dashboard.my_information.company.headline") }}</div>

      <div class="text">
        <b>{{ $t("dashboard.my_information.company.id") }}: {{ profile.reseller_number }}</b> <br><br>

        {{ profile.company }} <br>
        {{ profile.title }} <br>

        <div>
          {{ profile.street }} <br>
          {{ profile.zip }} {{ profile.city }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import DotMenu from "../../shared/components/modal/dot-menu";

  export default {
    name: "my-information",
    components: {
      DotMenu
    },
    props: {
      headline: {
        type: Number,
        default: 3
      }
    },
    data() {
      return {
        options: [
          { title: 'actions.edit', callback: () => this.$router.push('/my-information') }
        ]
      }
    },
    computed: {
      ...mapState({
        profile(state) {
          return state.profile.profile;
        },
        logo(state) {
          return this.$store.getters['asset/getPublicUrl'](state.profile.profile.logo, { height: 100, width: 100 });
        }
      })
    },
    mounted() {
      this.$store.dispatch('profile/load');
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";

  .logo-headline, .company-headline {
    color: @brand-1-100;
    margin-bottom: @space2;
  }

  .text {
    line-height: @space3;
    margin-bottom: @standard-space;
  }
</style>
