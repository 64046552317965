import axios from 'axios/index';
import config from '../../../../config';
import {wait} from "../../../../res/helper/wait";

const hooks = {
    async create(payload) {
        const offer = {...payload.offer};

        if (offer.customer && offer.customer.uuid) {
            offer.customer = offer.customer.uuid;
        }

        if (offer.project && offer.project.uuid) {
            offer.project = offer.project.uuid;
        }

        return {offer};
    },
    async update(payload) {
        const offer = {...payload.offer};

        offer.positions = offer.positions.map(position => ({...position, product: position.product.uuid}));

        if (offer.customer && offer.customer.uuid) {
            offer.customer = offer.customer.uuid;
        }

        if (offer.project && offer.project.uuid) {
            offer.project = offer.project.uuid;
        }

        return {offer};
    }
};

export default {
    async load({commit}, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers`, {params});
            const data = response.data;

            commit('loaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadMore({commit, state}, params = {}) {
        params = {...state.pagination, ...params};

        if (!params.page) {
            params.page = params.current_page + 1;
        }

        try {
            const response = await axios.get(`${config.api.endpoint}/offers`, {params});
            const data = response.data;

            commit('loadedMore', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadOne({commit}, {id, params = {}}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers/${id}`, {params});
            const data = response.data;

            commit('loadedOne', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async update({commit}, {id, payload, params = {}}) {
        let p = await hooks.update({...payload});

        try {
            const response = await axios.put(`${config.api.endpoint}/offers/${id}`, p, {params});
            const data = response.data;

            commit('updated', {response: data, data: payload});

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async delete({commit}, {id, params = {}}) {
        try {
            const response = await axios.delete(`${config.api.endpoint}/offers/${id}`, {params});
            const data = response.data;

            commit('deleted', {id, response: data});

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async create({commit}, {payload, params = {}}) {
        let p = await hooks.create({...payload});

        try {
            const response = await axios.post(`${config.api.endpoint}/offers`, p, {params});
            const data = response.data;

            commit('created', {response: data, data: payload});

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async getSystemFunctions({commit, state}, {id, params = {}}) {
        const response = await axios.get(`${config.api.endpoint}/offers/${id}/system-functions`, {params: params});
        commit('gotSystemFunctions', response.data);
    },
    async balanceAccessory({commit, state}, {id, params = {}}) {
        try {
            const response = await axios.put(`${config.api.endpoint}/offers/${id}/balance-accessory`, {params: params});
            const offerResponse = await axios.get(`${config.api.endpoint}/offers/${id}`);
            const data = offerResponse.data;
            commit('updated', {response, data});
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async checkPbxPossibility({commit, state}, {id, params = {}}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers/${id}/check_pbx`, {params: params});
            const messages = response.data.messages;
            if (messages.length) {
                this.dispatch('notification/set', messages.shift());
            }
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadPossibleProducts({commit, state}, {id, distributor = null, params = {}}) {
        const pagination = {...state.possibleProductsPagination, ...params};

        if (pagination.current_page && !params.page) {
            pagination.page = ++pagination.current_page;
        }

        try {
            const response = await axios.get(`${config.api.endpoint}/offers/${id}/products`, {params: pagination});

            commit('possibleProductsLoaded', response.data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadPossiblePbx({commit, state}, {id, params = {}}) {
        const pagination = {...state.possiblePbxPagination, ...params};

        if (pagination.current_page && !params.page) {
            pagination.page = ++pagination.current_page;
        }

        try {
            const response = await axios.get(`${config.api.endpoint}/offers/${id}/pbx`, {params: pagination});
            commit('possiblePbxLoaded', response.data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadPbxModules({commit, state}, {id, pbx}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers/${id}/needed_modules/${pbx}`);
            commit('neededPbxModulesLoaded', {pbx: pbx, modules: response.data.modules});
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async addPosition({commit}, {id, position, params = {}}) {
        try {
            const response = await axios.post(`${config.api.endpoint}/offers/${id}/positions`, {position}, {params});
            const offerResponse = await axios.get(`${config.api.endpoint}/offers/${id}`);
            const data = offerResponse.data;
            commit('updated', {response, data});
            await this.dispatch("notification/set", response.data.messages);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async updatePosition({commit}, {id, position}) {
        try {
            const p = {...position, product: position.product.uuid};
            const response = await axios.put(`${config.api.endpoint}/offers/${id}/positions/${p.uuid}`, {position: p});

            commit('positionUpdated', {...position});
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async removePosition({commit}, {id, position, params = {}}) {
        try {
            const response = await axios.delete(`${config.api.endpoint}/offers/${id}/positions/${position.uuid}`, {params});

            commit('positionRemoved', position);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async addResellerProduct({commit}, {id, position, product, params = {}}) {
        try {
            const response = await axios.post(`${config.api.endpoint}/offers/${id}/reseller-products`, {position}, {params});
            const data = response.data;

            commit('resellerProductPositionAdded', {...position, product, uuid: data.position.uuid});
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async removeResellerProduct({commit}, {id, position, params = {}}) {
        try {
            const response = await axios.delete(`${config.api.endpoint}/offers/${id}/reseller-products/${position.uuid}`, {params});

            commit('resellerProductPositionRemoved', position);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async updateResellerProduct({commit}, {id, position, params = {}}) {
        try {
            const p = {...position, product: position.product.uuid};
            const response = await axios.put(`${config.api.endpoint}/offers/${id}/reseller-products/${position.uuid}`, {position: p}, {params});

            commit('resellerProductPositionUpdated', {...position});
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async getResult({commit}, {id, params = {}}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers/${id}/result`, {params});

            commit('offerResultLoaded', response.data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async resetResult({commit}) {
        commit('offerResultResetted');
    },
    async loadProjects({commit}, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers`, {params: {grouped: 1, ...params}});
            const data = response.data;

            commit('projectsLoaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async generateEditCode({commit}, {id, email, params = {}}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers/${id}/code?email=${email}`, {params});

            commit('generatedEditCode', {data: response.data, id});

            return response.data.code;
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);

            return null;
        }
    },
    async findByEditCode({commit}, {code = '', params = {}}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/offers/code/${code}`, {params});

            commit('foundEditCode', {data: response.data, code})
        } catch (exception) {
            this.dispatch('notification/fromRequestException');
        }
    }
};
