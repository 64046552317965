import axios from 'axios/index';
import config from '../../../../config';
import DownloadService from '../../../shared/service/download';

export default {
    async load({ commit }, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/distributor/products`, { params });
            const data = response.data;

            commit('loaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async updateAll({ state }) {
        const products = state.products;

        try {
            const response = await axios.put(`${config.api.endpoint}/distributor/products`, { products });
            const data = response.data;

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async import({ state }, file) {
        const payload = new FormData();

        payload.append('file', file);

        try {
            const headers = { 'Content-Type': 'multipart/form-data' };
            const response = await axios.post(`${config.api.endpoint}/distributor/products/import`, payload, { headers });
            const data = response.data;

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (e) {
            this.dispatch('notification/fromRequestException', e);
        }
    },
    async export() {
        try {
            await DownloadService.downloadFileByUrl(`${config.api.endpoint}/distributor/products/export`, 'export.csv');
        } catch (e) {
            this.dispatch('notification/fromRequestException', e);
        }
    }
};
