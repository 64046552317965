<template>
  <div :class="(open ? 'accordion open ' : 'accordion ') + layout">
    <div class="header" @click="toggle">
      <slot name="header"></slot>
    </div>
    <div class="body" v-show="open">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "accordion",
    props: {
      layout: {
        type: String,
        default: 'grey',
        validator(layout) {
          return ['grey', 'transparent', 'white'].includes(layout);
        }
      }
    },
    data() {
      return {
        open: false
      }
    },
    methods: {
      toggle() {
        this.open = !this.open;
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";
  @import "../../../res/less/core/config/mixins";
  @import "../../../res/less/core/config/constants";

  @iconSize: @space2;

  .accordion {
    padding: 14rem @space2;
    border-radius: @borderRadiusAccordion;
    background-color: @black-05;

    .header {
      line-height: 1;
      cursor: pointer;
      position: relative;
      color: @brand-1-100;
      font-size: @fontSize;
      padding-left: @iconSize + 10rem;

      > * { margin-bottom: 0; }

      &:before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: @iconSize;
        height: @iconSize;
        position: absolute;
        .icon('accordion/accordion-plus');
      }

      .dot-menu {
        float: right;
      }
    }

    &:not(:last-child) {
      margin-bottom: @space1;
    }

    &.open .header:before {
      .icon('accordion/accordion-minus');
    }

    .body {
      margin-top: @space2;

      *:last-child {
        margin-bottom: 0;
      }
    }

    &.transparent {
      background-color: transparent;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: @standard-space;
      }
    }

    &.white { background-color: @white-100; }
  }
</style>
