<template>

</template>

<script>
  export default {
    name: "tab-child",
    methods: {
      isTab(tab) {
        return tab.$options._componentTag === 'tab';
      },
      isTabWrap(wrap) {
        return wrap.$options._componentTag === 'tabs' || wrap.$options._componentTag === 'steps';
      },
      nextTab() {
        const tab = this.$parent;

        if (tab && this.isTab(tab) && this.isTabWrap(tab.$parent)) {
          const wrap = tab.$parent;
          const tabs = wrap.tabs;
          const tabIndex = tabs.findIndex(_tab => _tab.id === tab.id);
          const nextTab = tabs[tabIndex + 1];

          if (nextTab) {
            wrap.selectTab(nextTab.id);
            // scroll to top
            window.scrollTo(0,0);
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
