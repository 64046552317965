<template>
  <div>
    <ccm-tag></ccm-tag>
    <matomo-tag></matomo-tag>
    <div class="app">
      <application-header/>

      <div class="content-wrapper">
        <section class="main-content">
          <router-view></router-view>
        </section>
      </div>

      <div class="wave"></div>

      <application-footer/>
    </div>
  </div>
</template>

<script>
import ApplicationFooter from './shared/components/layout/footer';
import ApplicationHeader from './shared/components/layout/header';
import CcmTag from './shared/components/ccm-tag';
import MatomoTag from "./shared/components/matomo-tag.vue";

export default {
  name: "App",
  components: {
    MatomoTag,
    ApplicationFooter,
    ApplicationHeader,
    CcmTag
  },
}
</script>

<style lang="less" scoped>

</style>
