<template>
  <div class="dashboard">
    <h2 class="headline headline_2">{{ $t("dashboard.headline") }}</h2>

    <section>
      <div class="grid">
        <div class="col8">
          <notifications :max="3" />

          <offers></offers>
        </div>

        <div class="col4">
          <section class="sub">
            <section class="sub">
              <my-information></my-information>
            </section>

            <contact-person></contact-person>
          </section>
        </div>
      </div>
    </section>

    <section>
      <div class="grid">
        <div class="col12">
          <faq></faq>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Faq from "../components/faq";
import Offers from "../components/offers";
import MyInformation from "../components/my-information";
import Notifications from "../../shared/components/notifications";
import ContactPerson from "../../shared/components/contact-person";

export default {
  name: "dashboard",
  components: {
    Faq,
    Offers,
    Notifications,
    ContactPerson,
    MyInformation,
  },
};
</script>

<style lang="less" scoped></style>
