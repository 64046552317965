<template>
  <div class="inline">
    <div @click="$refs.modal.open()">
      <slot/>
    </div>

    <modal ref="modal">
      <div class="import-modal-body">
        <h3 class="headline headline_3">Preise exportieren</h3>

        <div class="input">
          <label>Region</label>
          <dropdown v-model="region" :data="regions"></dropdown>
        </div>

        <div>
          <button class="button primary small" @click="startExport()">Exportieren</button>
          <button class="button secondary small" @click="$refs.modal.close()">Abbrechen</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Modal from '../../shared/components/modal/modal';
  import Dropdown from "../../shared/components/form/dropdown";

  export default {
    name: "export-prices-modal",
    components: {
      Dropdown,
      Modal
    },
    data() {
      return {
        region: null,
      }
    },
    computed: {
      ...mapState({
        regions(state) {
          const regions = [...state['product/region'].regions];
          return regions.map(region => ({ label: region.title, value: region.uuid }));
        }
      })
    },
    methods: {
      async startExport() {
        if (this.region) {
          const regions = [...this.$store.state['product/region'].regions];
          const region = regions.find(region => region.uuid === this.region);
          const date = new Date();
          const dateString = ("0" + date.getDate()).slice(-2) + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + date.getFullYear();
          const name = `export-${region.shortcut}-${dateString}.csv`;
          await this.$store.dispatch('product/export', {region: this.region, name: name});

          this.$refs.modal.close();
        }
      }
    },
    mounted() {
      this.$store.dispatch('product/region/load', {ignore_client: true});
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";
  @import "../../../res/less/core/config/constants";

  .import-modal-body {
    padding: @space5 @space5 calc(@space5 - @standard-space) @space5;
    min-width: 400px;
    background-color: @white-100;
  }

  @media(max-width: @phone) {
    .inline { width: 100%; }
  }
</style>
