<template>
  <div
    class="dropdown"
    tabindex="0"
    :class="{ open, disabled, 'has-options': hasOptions }"
    @blur="close()"
    @keydown="searchChanged"
  >
    <div class="selected-wrapper" @click="toggle">
      <div class="selected" v-if="open && search">{{ search }}</div>
      <div class="selected" v-else>
        {{
          selected && selected.label && data.length > 0
            ? $t(selected.label)
            : placeholder
        }}
      </div>

      <div class="dropdown-options">
        <slot name="options" :selected="selected"></slot>
      </div>
    </div>
    <div class="options" v-show="open">
      <div
        class="option"
        v-for="(option, i) in filtered"
        :key="i"
        @click="select(option)"
        :class="{ selected: option.value === selected.value }"
      >
        {{ $t(option.label) }}
      </div>
    </div>
  </div>
</template>

<script>
import {isMatch} from "underscore";

const keyBlacklist = [
  8,
  13,
  16,
  17,
  18,
  19,
  20,
  27,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  46,
  91,
  93,
  112,
  113,
  114,
  115,
  116,
  117,
  118,
  119,
  12,
  121,
  122,
  123,
  145,
  192,
  224,
];

export default {
  name: "dropdown",
  props: {
    data: {
      type: Array,
      required: true,
      validator(data) {
        if (data.length === 0) {
          return true;
        }

        return data
          .map(
            (option) =>
              option.hasOwnProperty("label") && option.hasOwnProperty("value")
          )
          .reduce((a, b) => a && b);
      },
    },
    forceHasOptions: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [Object, String, Number],
    sortType: {
      type: String,
      default: "String"
    },
    sortBy: {
      type: String,
      default: "label"
    }
  },
  data() {
    return {
      open: false,
      search: "",
    };
  },
  computed: {
    hasOptions() {
      return !!this.$slots.options || this.forceHasOptions;
    },
    selected() {
      const option = this.data.find((option) => {
        if (typeof option.value === "object" && this.value !== null) {
          return isMatch(option.value, this.value);
        } else {
          return option.value === this.value;
        }
      });

      return {
        ...option,
        value: this.value,
        label: option ? option.label : "",
      };
    },
    filtered() {
      const search = this.search.toLowerCase();

      const filtered = this.data
        .filter((option) => option.label.toLowerCase().includes(search));
      if (this.sortType === "number") {
        return filtered.sort((opt1, opt2) => {
          return opt1[this.sortBy] - opt2[this.sortBy]
        });
      } else {
        return filtered.sort((opt1, opt2) =>
          opt1[this.sortBy].toLowerCase().localeCompare(opt2[this.sortBy].toLowerCase())
        );
      }
    }
    ,
  },
  methods: {
    toggle() {
      if (this.disabled) {
        this.open = false;
      } else {
        this.open = !this.open;
      }
    }
    ,
    select(option) {
      this.close();
      this.$emit("change", option.value);
      this.$emit("input", option.value);
    }
    ,
    close() {
      this.open = false;
      this.search = "";
    }
    ,
    searchChanged($event) {
      $event.preventDefault();

      console.log($event.keyCode, $event.key);

      if ($event.keyCode === 8) {
        this.search = this.search.slice(0, this.search.length - 1);
        return;
      }

      if (keyBlacklist.includes($event.keyCode) || this.open === false) {
        return;
      }

      this.search += $event.key;
    }
    ,
  }
  ,
}
;
</script>

<style lang="less" scoped>
@import "../../../../res/less/themes/theme";
@import "../../../../res/less/core/config/mixins";
@import "../../../../res/less/core/config/constants";

.dropdown {
  width: 100%;
  height: @space6;
  padding: @space2;
  outline: none;
  z-index: @dropdownIndex;
  position: relative;
  color: @brand-2-100;
  max-height: @space6;
  font-size: 0.8em;
  background-color: @white-100;

  &.has-options {
    .selected-wrapper:before {
      right: @space2 * 2;
    }
  }

  &.disabled {
    background-color: @black-05;

    .selected-wrapper {
      cursor: not-allowed;
    }
  }

  &.open {
    z-index: @dropdownIndex + 1;

    .options {
      border-top: none;
    }
  }

  .selected-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: @borderRadiusInput;
    cursor: pointer;
    position: absolute;
    border: 1rem solid @brand-2-100;

    &:before {
      .icon("arrow/arrow-1");

      top: 50%;
      width: 6px;
      content: "";
      height: 10px;
      right: @space2;
      position: absolute;
      background-size: cover;
      transform: translateY(-50%) rotate(90deg);
    }

    .selected {
      top: 50%;
      padding: @space2;
      position: absolute;
      transform: translateY(-50%);
    }
  }

  .options {
    left: 0;
    top: 100%;
    width: 100%;
    overflow: auto;
    padding: @space3 @space2 @space2 @space2;
    border: 1px solid @brand-2-100;
    position: absolute;
    max-height: calc(@space5 + @space15);
    background-color: @white-100;
    border-bottom-left-radius: @borderRadiusInput;
    border-bottom-right-radius: @borderRadiusInput;

    .option {
      cursor: pointer;
      position: relative;

      &:hover {
        color: @brand-1-100;
      }

      &:not(:last-child) {
        margin-bottom: @space3;
      }

      &.disabled {
        color: @black-40;
      }
    }
  }

  .dropdown-options {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
