import AdminGuard from '../../shared/router/guard/admin';

import ListPagesComponent from '../views/list';
import UpdatePagesComponent from '../views/update';

export default [
	{
		path: '/admin/page',
		component: ListPagesComponent,
		meta: {
			title: "manual.meta_title"
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/page/:id',
		component: UpdatePagesComponent,
		meta: {
			title: "manual.meta_title"
		},
		beforeEnter: AdminGuard
	}
];
