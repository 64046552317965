<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">
        {{ $t("distributor.profile.headline") }}
      </h2>

      <div class="inline right">
        <button class="button secondary" @click="save()">
          {{ $t("actions.save") }}
        </button>
        <button class="button secondary" @click="discard()">
          {{ $t("actions.discard") }}
        </button>
      </div>
    </div>

    <notifications :max="3" />

    <section>
      <div class="grid">
        <div class="col6">
          <h3 class="headline headline_3">
            {{ $t("distributor.profile.tabs.contact.headline") }}
          </h3>

          <div class="input">
            <label for="reseller-id">{{
              $t("distributor.profile.tabs.contact.input.id")
            }}</label>
            <input
              type="text"
              id="reseller-id"
              v-model="reseller_number"
              disabled
            />
          </div>

          <div class="input">
            <label for="reseller-name">{{
              $t("distributor.profile.tabs.contact.input.name")
            }}</label>
            <input type="text" id="reseller-name" v-model="title" />
          </div>

          <div class="input">
            <label for="reseller-phone">{{ $t("phone") }}</label>
            <input type="text" id="reseller-phone" v-model="phone" />
          </div>

          <div class="input">
            <label for="reseller-fax">{{
              $t("distributor.profile.tabs.contact.input.fax")
            }}</label>
            <input type="text" id="reseller-fax" v-model="fax" />
          </div>

          <div class="input">
            <label for="reseller-mail">{{
              $t("distributor.profile.tabs.contact.input.mail")
            }}</label>
            <input type="text" id="reseller-mail" v-model="email" />
          </div>

          <div class="input">
            <label for="reseller-homepage">{{
              $t("distributor.profile.tabs.contact.input.homepage")
            }}</label>
            <input type="text" id="reseller-homepage" v-model="website" />
          </div>
        </div>
        <div class="col6">
          <h3 class="headline headline_3">
            {{ $t("distributor.profile.tabs.contact.address") }}
          </h3>

          <div class="input">
            <label for="reseller-company">{{
              $t("distributor.profile.tabs.contact.input.company")
            }}</label>
            <input type="text" id="reseller-company" v-model="company" />
          </div>

          <div class="input">
            <label for="reseller-street">{{
              $t("distributor.profile.tabs.contact.input.street")
            }}</label>
            <input type="text" id="reseller-street" v-model="street" />
          </div>

          <div class="input">
            <label for="reseller-zip">{{
              $t("distributor.profile.tabs.contact.input.zip")
            }}</label>
            <input type="text" id="reseller-zip" v-model="zip" />
          </div>

          <div class="input">
            <label for="reseller-city">{{
              $t("distributor.profile.tabs.contact.input.city")
            }}</label>
            <input type="text" id="reseller-city" v-model="city" />
          </div>

          <div class="input">
            <label for="reseller-country">{{
              $t("distributor.profile.tabs.contact.input.country")
            }}</label>
            <input type="text" id="reseller-country" v-model="country" />
          </div>

          <div class="input">
            <label>{{
              $t("distributor.profile.tabs.contact.input.logo")
            }}</label>
            <file-upload
              :value="filename"
              @change="uploadFile($event)"
            ></file-upload>
            <img v-if="image" class="no-margin" :src="image" />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="grid">
        <div class="col6">
          <h3 class="headline headline_3">
            {{ $t("reseller.reseller.account.headline") }}
          </h3>

          <div class="input">
            <label for="reseller_password">{{
              $t("reseller.reseller.account.label.password")
            }}</label>
            <input type="password" v-model="password" id="reseller_password" />
            <!-- TODO(soerenuhrbach): Implement Account Logic -->
          </div>

          <div class="input">
            <label for="reseller_distributor_secret">{{
              $t("reseller.reseller.account.distributor_password")
            }}</label>
            <input
              type="text"
              id="reseller_distributor_secret"
              v-model="distributor_password"
              @change="distributor_password = $event.target.value"
            />
          </div>
        </div>
      </div>
    </section>

    <button class="button secondary" @click="save()">
      {{ $t("actions.save") }}
    </button>
    <button class="button secondary" @click="discard()">
      {{ $t("actions.discard") }}
    </button>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

import Tab from "../../shared/components/tabs/tab";
import Tabs from "../../shared/components/tabs/tabs";
import FileUpload from "../../shared/components/form/file-upload";
import Dropdown from "../../shared/components/form/dropdown";
import DropdownTabs from "../../shared/components/tabs/dropdown-tabs";
import DotMenu from "../../shared/components/modal/dot-menu";
import Notifications from "../../shared/components/notifications";

export default {
  name: "profile",
  components: {
    Notifications,
    DotMenu,
    DropdownTabs,
    Dropdown,
    Tab,
    Tabs,
    FileUpload,
  },
  data() {
    return {
      imageDimensions: {
        height: 80,
        width: 80,
      },
    };
  },
  computed: {
    ...mapFields("distributor/profile", {
      reseller_number: "profile.reseller_number",
      title: "profile.title",
      phone: "phone",
      fax: "profile.fax",
      email: "profile.email",
      website: "profile.website",
      company: "profile.company",
      street: "profile.street",
      zip: "profile.zip",
      city: "profile.city",
      country: "profile.country",
      logo: "profile.logo",
      password: "profile.password",
      distributor_password: "profile.distributor_password",
    }),
    ...mapState({
      countries(state) {
        return state.countries;
      },
      languages(state) {
        return state.languages;
      },
      filename(state) {
        const image = state["distributor/profile"].profile.logo;

        if (!image) {
          return "";
        }

        return image.filename + (image.extension ? "." + image.extension : "");
      },
      image(state) {
        const logo = state["distributor/profile"].profile.logo;

        return logo
          ? this.$store.getters["asset/getPublicUrl"](
              logo,
              this.imageDimensions
            )
          : null;
      },
    }),
  },
  methods: {
    async save() {
      const profile = { ...this.$store.state["distributor/profile"].profile };

      await this.$store.dispatch("distributor/profile/update", {
        payload: { profile },
      });
      this.$router.push("/distributor");
    },
    async discard() {
      this.$router.push("/distributor");
      await this.$store.dispatch("distributor/profile/load");
    },
    async uploadFile(file) {
      this.logo = await this.$store.dispatch("asset/upload", file);
    },
  },
  async mounted() {
    await this.$store.dispatch("distributor/profile/load");
  },
};
</script>

<style lang="less" scoped>
#agb {
  min-height: 400rem;
}

.tabs {
  margin-bottom: 0;
}
</style>
