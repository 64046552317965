export const product = {
    "dont_show_in_frontend_selection_list": false,
    "article_number": "",
    "title": "",
    "product_link": "",
    "category": {
        key: "",
        title: ""
    },
    "sub_category": {
        key: "",
        title: ""
    },
    "regions": [
        {
            price: '',
            region: null,
            link: ''
        }
    ],

    "tooltip_de": "",
    "tooltip_en_au": "",
    "tooltip_en": "",
    "tooltip_fr": "",

    "product_hint_de": "",
    "product_hint_en_au": "",
    "product_hint_en": "",
    "product_hint_fr": "",

    "color_de": "",
    "color_en_au": "",
    "color_en": "",
    "color_fr": "",

    "title_en_au": "",
    "title_en": "",
    "title_fr": "",

    /**
     * Rules
     */
    "limits": [],
    "only_with_collections": [],
    "incompatible_with_pbxs": [],
    "attribute_combination_collections": [],
    "port_restrictions": [],
    "possible_accessories": [],
    "selectable_system_functions": [],
    "required_products": [],
    "system_functions": [],
    "max_system_functions": [],
    "possible_dect_servers": [],
    "possible_dect_bases": [],
    "possible_dect_extensions": [],

    "can_be_added_multiple_times": false,
    "maximum_dect_devices": 0,
    "minimum_dect_channels": 0,
    "maximum_dect_channels": 0,

    "maximum_amt_channels": 0,
    /**
     * voip
     */
    "voip": 0,
    "voip_override_by_modules": false,
    "voip_max": 0,
    "voip_channels": 0,
    "voip_module_ports": 0,
    "needs_voip_module_ports": 0,
    "maximum_voip_devices": 0,

    /**
     * TFE
     */
    "tfe_max": 0,

    /**
     * Relays
     */
    "relay_max": 0,

    /**
     * s0
     */
    "s0": 0,
    "s0_max": 0,

    "s0_amt": 0,
    "s0_amt_max": 0,

    /**
     * up0
     */
    "up0": 0,
    "up0_max": 0,

    /**
     * vmf
     */
    "vmf": 0,
    "vmf_max": 0,
    'vmf_voip_voipamt_sum': 0,
    "s0_up0_sum": 0,


    /**
     * analog
     */
    "analog_amt": 0,
    "analog_amt_max": 0,
    "analog_ports": 0,
    "needs_analog_ports": 0,
    "analog_ports_max": 0,

    /**
     * modules
     */
    "module_ports": 0,
    "module_ports_max": 0,
    "needs_module_ports": 0,

    /**
     * more functions
     */
    "time_slots": 0,
    "needs_time_slots": 0,
    "foreign_product_activation": {},

    image: {
        filename: '',
        extension: '',
        uuid: null
    }
};

export const pagination = {
    current_page: 0,
    limit: null,
    pages: null,
    max_count: null,
    next_page: null
};

/**
 * Rule Template - Only With
 */
export const requirementCollectionItem = {
    minimum: 0,
    only_with_product: null
};

export const requirementCollection = {
    id: null,
    name: '',
    only_with: [{ ...requirementCollectionItem }]
};

/**
 * Rule Template - Limit
 */
export const limit = {
    limit: 0,
    limited_product: null
};

/**
 * Rule Template - system function
 */
export const systemFunction = {
    product: null,
    value: 0,
    type: '',
    override_by_modules: false
};

/**
 * Rule Template - system function
 */
export const maxSystemFunction = {
    product: null,
    max: 1,
};

/**
 * Rule Template - Port Restriction
 */
export const port_restriction = {
    adapters: 0,
    port: '',
    up0: 0,
    s0: 0
};

/**
 * @type {{product: null, type: string, value: null}}
 */
export const selectable_system_function = {
    product: null,
    type: 'checkbox',
    value: null,
    sorting: 0
};

/**
 * @type {{product: null, min: null}}
 */
export const required_product = {
    product: null,
    min: null,
    exact_count: null,
    counts_per_position: null
};
