<template>
  <language-tabs>
    <!-- TODO: Make editor an own whole component! -->
    <tab name="DE" language="de">
      <div class="input">
        <label for="render_mode_de">Ausgabetyp</label>
        <dropdown id="render_mode_de" :data="renderModes" :value="render_mode" @input="updateRenderMode" placeholder="Ausgabetyp"></dropdown>
      </div>
      <div class="input" v-if="showTitle()">
        <label for="title_de">Titel</label>
        <input type="text" id="title_de" v-model="title_de" @input="updateProperty('title', 'title_de')">
      </div>
      <div class="input" v-if="showImage()">
        <label>Bild</label>
        <file-upload :value="this.image_de ? this.image_de.filename : ''" @change="uploadFile('image_de', $event)" />
      </div>
      <div class="input" v-if="showLink()">
        <label for="link_de">Link</label>
        <input type="text" id="link_de" v-model="target_de" @input="updateProperty('link', 'target_de')">
      </div>
      <div class="input" v-if="showBody()">
        <label for="body_de">Inhalt</label>
        <editor-menu-bar :editor="editorDe" v-slot="{ commands, isActive }">
          <div class="rte-menu">
            <button
              class="button primary small"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <span class="rte-bold">b</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <span class="rte-italic">i</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <span class="rte-underline">u</span>
            </button>
          </div>
        </editor-menu-bar>
        <div>
          <div>
            <editor-menu-bubble class="menububble" :editor="editorDe" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
              <div
                class="menububble"
                :class="{ 'is-active': menu.isActive }"
                :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
              >

                <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                  <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                  <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                    [x]
                  </button>
                </form>

                <template v-else>
                  <button
                    class="button primary small"
                    @click="showLinkMenu(getMarkAttrs('link'))"
                    :class="{ 'is-active': isActive.link() }"
                  >
                    <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                  </button>
                </template>

              </div>
            </editor-menu-bubble>
          </div>
        </div>
        <div>
          <editor-content class="rte" :editor="editorDe" id="body_de"></editor-content>
        </div>
      </div>
    </tab>
    <tab name="EN (AU)" language="en">
      <div class="input">
        <label for="render_mode_en_au">Ausgabetyp</label>
        <dropdown id="render_mode_en_au" :data="renderModes" :value="render_mode" @input="updateRenderMode" placeholder="Ausgabetyp"></dropdown>
      </div>
      <div class="input" v-if="showTitle()">
        <label for="title_en_au">Titel</label>
        <input type="text" id="title_en_au" v-model="title_en_au" @input="updateProperty('title', 'title_en_au')">
      </div>
      <div class="input" v-if="showImage()">
        <label>Bild</label>
        <file-upload :value="this.image_en_au ? this.image_en_au.filename : ''" @change="uploadFile('image_en_au', $event)" />
      </div>
      <div class="input" v-if="showLink()">
        <label for="link_en_au">Link</label>
        <input type="text" id="link_en_au" v-model="target_en_au" @input="updateProperty('link', 'target_en_au')">
      </div>
      <div class="input" v-if="showBody()">
        <label for="body_en_au">Inhalt</label>
        <editor-menu-bar :editor="editorEnAu" v-slot="{ commands, isActive }">
          <div class="rte-menu">
            <button
              class="button primary small"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <span class="rte-bold">b</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <span class="rte-italic">i</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <span class="rte-underline">u</span>
            </button>
          </div>
        </editor-menu-bar>
        <div>
          <editor-menu-bubble class="menububble" :editor="editorEnAu" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
            <div
              class="menububble"
              :class="{ 'is-active': menu.isActive }"
              :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >

              <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                  [x]
                </button>
              </form>

              <template v-else>
                <button
                  class="button primary small"
                  @click="showLinkMenu(getMarkAttrs('link'))"
                  :class="{ 'is-active': isActive.link() }"
                >
                  <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                </button>
              </template>

            </div>
          </editor-menu-bubble>
        </div>
        <div>
          <editor-content class="rte" :editor="editorEnAu" id="body_en_au"></editor-content>
        </div>
      </div>
    </tab>
    <tab name="EN (FON)" language="en">
      <div class="input">
        <label for="render_mode_en">Ausgabetyp</label>
        <dropdown id="render_mode_en" :data="renderModes" :value="render_mode" @input="updateRenderMode" placeholder="Ausgabetyp"></dropdown>
      </div>
      <div class="input" v-if="showTitle()">
        <label for="title_en">Titel</label>
        <input type="text" id="title_en" v-model="title_en" @input="updateProperty('title', 'title_en')">
      </div>
      <div class="input" v-if="showImage()">
        <label>Bild</label>
        <file-upload :value="this.image_en ? this.image_en.filename : ''" @change="uploadFile('image_en', $event)" />
      </div>
      <div class="input" v-if="showLink()">
        <label for="link_en">Link</label>
        <input type="text" id="link_en" v-model="target_en" @input="updateProperty('link', 'target_en')">
      </div>
      <div class="input" v-if="showBody()">
        <label for="body_en">Inhalt</label>
        <editor-menu-bar :editor="editorEn" v-slot="{ commands, isActive }">
          <div class="rte-menu">
            <button
              class="button primary small"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <span class="rte-bold">b</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <span class="rte-italic">i</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <span class="rte-underline">u</span>
            </button>
          </div>
        </editor-menu-bar>
        <div>
          <editor-menu-bubble class="menububble" :editor="editorEn" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
            <div
              class="menububble"
              :class="{ 'is-active': menu.isActive }"
              :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >

              <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                  [x]
                </button>
              </form>

              <template v-else>
                <button
                  class="button primary small"
                  @click="showLinkMenu(getMarkAttrs('link'))"
                  :class="{ 'is-active': isActive.link() }"
                >
                  <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                </button>
              </template>

            </div>
          </editor-menu-bubble>
        </div>
        <div>
          <editor-content class="rte" :editor="editorEn" id="body_en"></editor-content>
        </div>
      </div>
    </tab>
    <tab name="FR" language="fr">
      <div class="input">
        <label for="render_mode_fr">Ausgabetyp</label>
        <dropdown id="render_mode_fr" :data="renderModes" :value="render_mode" @input="updateRenderMode" placeholder="Ausgabetyp"></dropdown>
      </div>
      <div class="input" v-if="showTitle()">
        <label for="title_fr">Titel</label>
        <input type="text" id="title_fr" v-model="title_fr" @input="updateProperty('title', 'title_fr')">
      </div>
      <div class="input" v-if="showImage()">
        <label>Bild</label>
        <file-upload :value="this.image_fr ? this.image_fr.filename : ''" @change="uploadFile('image_fr', $event)" />
      </div>
      <div class="input" v-if="showLink()">
        <label for="link_fr">Link</label>
        <input type="text" id="link_fr" v-model="target_fr" @input="updateProperty('link', 'target_fr')">
      </div>
      <div class="input" v-if="showBody()">
        <label for="body_fr">Inhalt</label>
        <editor-menu-bar :editor="editorFr" v-slot="{ commands, isActive }">
          <div class="rte-menu">
            <button
              class="button primary small"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <span class="rte-bold">b</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <span class="rte-italic">i</span>
            </button>

            <button
              class="button primary small"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <span class="rte-underline">u</span>
            </button>
          </div>
        </editor-menu-bar>
        <div>
          <editor-menu-bubble class="menububble" :editor="editorFr" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
            <div
              class="menububble"
              :class="{ 'is-active': menu.isActive }"
              :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >

              <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                  [x]
                </button>
              </form>

              <template v-else>
                <button
                  class="button primary small"
                  @click="showLinkMenu(getMarkAttrs('link'))"
                  :class="{ 'is-active': isActive.link() }"
                >
                  <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                </button>
              </template>

            </div>
          </editor-menu-bubble>
        </div>
        <div>
          <editor-content class="rte" :editor="editorFr" id="body_fr"></editor-content>
        </div>
      </div>
    </tab>
  </language-tabs>
</template>

<script>
    import Tab from "../../../shared/components/tabs/tab";
    import DotMenu from "../../../shared/components/modal/dot-menu";
    import Dropdown from "../../../shared/components/form/dropdown";
    import Notifications from "../../../shared/components/notifications";
    import LanguageTabs from "../../../shared/components/tabs/language-tabs";
    import FileUpload from "../../../shared/components/form/file-upload";
    import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
    import {
        Bold,
        Italic,
        Underline,
        Link
    } from 'tiptap-extensions'
    import { mapState } from 'vuex';

    export default {
        name: "content-and-image",
        props: {
            namespace: {
                type: String
            },
            show_title: {
                type: Boolean,
                default: true
            },
            show_image: {
                type: Boolean,
                default: false
            }
        },
        components: {
            LanguageTabs,
            Notifications,
            DotMenu,
            Dropdown,
            Tab,
            EditorContent,
            EditorMenuBar,
            EditorMenuBubble,
            FileUpload
        },
        data() {
            return {
                title_de: '',
                title_en: '',
                title_en_au: '',
                title_fr: '',
                body_de: '',
                body_en: '',
                body_en_au: '',
                body_fr: '',
                image_de: '',
                image_en: '',
                image_en_au: '',
                image_fr: '',
                target_de: '',
                target_en: '',
                target_en_au: '',
                target_fr: '',
                editorDe: null,
                editorEnAu: null,
                editorEn: null,
                editorFr: null,
                render_mode: '',
                renderModes: [
                   // {label: "Text und Bild", value: 'text_image'},
                    { label: "Text", value: 'text' },
                    { label: "Banner", value: 'banner' },
                    { label: "Leer lassen", value: 'empty' }
                ],
                linkUrl: null,
                linkMenuIsActive: false,
            }
        },
        methods: {
            async save() {
                const page = { ...this.$store.state.page.page };
                await this.$store.dispatch('page/update', { id: page.id, payload: { page } });
            },
            async discard() {
                this.$router.push('/admin/page');
            },
            async updateProperty(group, property) {
              this.$store.commit("page/updateField", {path: 'page.'+this.namespace+'.'+group+'.'+property, value: this[property]});
            },
            async uploadFile(field, file) {
                const img = await this.$store.dispatch('asset/upload', file);
                this[field] = img;
                this.$store.commit("page/updateField", { path: 'page.'+this.namespace+'.image.'+field, value: img.uuid});
            },
            async updateRenderMode(value) {
                this.render_mode = value;
                this.$store.commit('page/updateField', { path: 'page.'+this.namespace+'.render_mode', value: value });
            },
            showTitle() {
                return this.render_mode === 'text' && this.show_title;
            },
            showImage() {
                return this.render_mode === 'banner' || (this.render_mode === 'text' && this.show_image) ;
            },
            showBody() {
                return this.render_mode === 'text';
            },
            showLink() {
                return this.render_mode === 'banner';
            },
            showLinkMenu(attrs) {
                this.linkUrl = attrs.href
                this.linkMenuIsActive = true
                this.$nextTick(() => {
                    this.$refs.linkInput.focus()
                })
            },
            hideLinkMenu() {
                this.linkUrl = null;
                this.linkMenuIsActive = false;
            },
            setLinkUrl(command, url) {
                command({ href: url });
                this.hideLinkMenu();
            },
        },
        computed: {
          ...mapState('page', ['page'])
        },
        watch: {
            page: {
                deep: true,
                handler(prev, current) {
                    const { page } = this;

                    if (prev.id !== current.id) {
                        this.editorDe.setContent(page.body.body_de);
                        this.editorFr.setContent(page.body.body_fr);
                        this.editorEn.setContent(page.body.body_en);
                        this.editorEnAu.setContent(page.body.body_en_au);
                    }
                }
            }
        },
        async mounted() {
            const editorConfig = {
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Link()
                ],
            };

            const page = this.$store.state.page.page[this.namespace];

            this.title_de = page.title.title_de;
            this.title_en = page.title.title_en;
            this.title_en_au = page.title.title_en_au;
            this.title_fr = page.title.title_fr;
            this.target_de = page.link.target_de;
            this.target_en = page.link.target_en;
            this.target_en_au = page.link.target_en_au;
            this.target_fr = page.link.target_fr;
            this.render_mode = page.render_mode;

            if (page.image.image_de) {
                await this.$store.dispatch('asset/load', {id: page.image.image_de});
                this.image_de = this.$store.state.asset.assets.find(asset => asset.uuid === page.image.image_de);
            }
            if (page.image.image_en) {
                await this.$store.dispatch('asset/load', {id: page.image.image_en});
                this.image_en = this.$store.state.asset.assets.find(asset => asset.uuid === page.image.image_en);
            }
            if (page.image.image_en_au) {
                await this.$store.dispatch('asset/load', {id: page.image.image_en_au});
                this.image_en_au = this.$store.state.asset.assets.find(asset => asset.uuid === page.image.image_en_au);
            }
            if (page.image.image_fr) {
                await this.$store.dispatch('asset/load', {id: page.image.image_fr});
                this.image_fr = this.$store.state.asset.assets.find(asset => asset.uuid === page.image.image_fr);
            }

            this.editorDe = new Editor({
                ...editorConfig,
                onUpdate: ({getHTML}) => {
                    this.$store.commit("page/updateField", {path: 'page.'+this.namespace+'.body.body_de', value: getHTML()});
                }
            });
            this.editorFr = new Editor({...editorConfig,
                onUpdate: ({getHTML}) => {
                    this.$store.commit("page/updateField", {path: 'page.'+this.namespace+'.body.body_fr', value: getHTML()});
                }
            });
            this.editorEn = new Editor({...editorConfig,
                onUpdate: ({getHTML}) => {
                    this.$store.commit("page/updateField", {path: 'page.'+this.namespace+'.body.body_en', value: getHTML()});
                }
            });
            this.editorEnAu = new Editor({...editorConfig,
                onUpdate: ({getHTML}) => {
                    this.$store.commit("page/updateField", {path: 'page.'+this.namespace+'.body.body_en_au', value: getHTML()});
                }
            });

            this.editorDe.setContent(this.$store.state.page.page[this.namespace].body.body_de);
            this.editorFr.setContent(this.$store.state.page.page[this.namespace].body.body_fr);
            this.editorEn.setContent(this.$store.state.page.page[this.namespace].body.body_en);
            this.editorEnAu.setContent(this.$store.state.page.page[this.namespace].body.body_en_au);
        },
        beforeDestroy() {
            this.editorDe.destroy();
            this.editorFr.destroy();
            this.editorEn.destroy();
            this.editorEnAu.destroy();
        }
    }
</script>

<style lang="less" scoped>

</style>
