export default {
	addPossibleDectBase(state) {
		state.product.possible_dect_bases = [...state.product.possible_dect_bases, null];
	},
	removePossibleDectBase(state, index) {
		state.product.possible_dect_bases = state.product.possible_dect_bases.filter((product, i) => index !== i);
	},
	updatePossibleDectBase(state, { index, value }) {
		state.product.possible_dect_bases = state.product.possible_dect_bases.map((product, i) => index === i ? value : product);
	}
};
