import axios from "axios/index";
import config from "../../../../config";

const hooks = {
    async update(payload) {
        const profile = { ...payload.profile };

        if (profile.logo) {
            profile.logo= profile.logo.uuid;
        }

        return { profile };
    }
};

export default {
    async load({ commit, dispatch }, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/profile`, { params });
            const data = response.data;

            commit('loaded', data);

            dispatch('loadCustomers', { reseller: data.profile });
            dispatch('loadProjects', { reseller: data.profile });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async update({ commit }, { payload, params = {} }) {
        let p = hooks.update && typeof hooks.update === "function" ? await hooks.update({ ...payload }) : payload;

        try {
            const response = await axios.put(`${config.api.endpoint}/profile`, p, { params });
            const data = response.data;

            commit('updated', { response: data, data: payload });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadCategories({ commit }, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/profile/categories`, { params });
            const data = response.data;

            commit('categoriesLoaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadProjects({ commit }, { reseller, params = {} }) {
        try {
            const response = await axios.get(`${config.api.endpoint}/resellers/${reseller.uuid}/projects`, { params });
            const data = response.data;

            commit('projectsLoaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadCustomers({ commit }, { reseller, params = {} }) {
        try {
            const response = await axios.get(`${config.api.endpoint}/resellers/${reseller.uuid}/customers`,  { params });
            const data = response.data;

            commit('customersLoaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    }
};
