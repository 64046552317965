<template>
  <div>
    <section class="sub icon-headline">
      <h3 class="headline headline_3" data-index="1">
        {{ $t("offer.offer.steps.shop.headline") }}
      </h3>
      <router-link
        :class="'icon help ' + getClient()"
        :to="baseUrl() + '/manual#step_1'"
      ></router-link>
    </section>

    <cart/>

    <section class="sub">
      <h4 class="headline headline_4">
        1.1 {{ $t("offer.offer.steps.shop.select_products") }}
      </h4>

      <tabs v-on:beforeChange="beforeTabChange($event)">
        <tab
          v-for="category in categories"
          :prop-id="category.key"
          :name="$t('offer.category.' + category.key)"
          v-bind:key="category.key"
        >
          <div class="search">
            <input type="text" :placeholder="$t('search')" v-model="search"/>
          </div>

          <vue-element-loading :active="showSpinner" spinner="bar-fade-scale"/>

          <!-- TODO: Find a clean way to handle those fields dynamically -->
          <div class="grid">
            <div class="col6">
              <div class="input" v-if="category.key === 'TELEPHONE_VOIP'">
                <label for="foreign_voip">{{
                    $t("offer.offer.steps.shop.field.foreign_voip" + client())
                  }}</label>
                <input
                  min="0"
                  type="number"
                  id="foreign_voip"
                  v-model="foreign_voip"
                />
              </div>
              <div class="input" v-else-if="category.key === 'TELEPHONE_DECT'">
                <label for="foreign_dect">{{
                    $t("offer.offer.steps.shop.field.foreign_dect" + client())
                  }}</label>
                <input
                  min="0"
                  type="number"
                  id="foreign_dect"
                  v-model="foreign_dect"
                />
              </div>
              <div class="input" v-else-if="category.key === 'TELEPHONE_ISDN'">
                <label for="foreign_s0">{{
                    $t("offer.offer.steps.shop.field.foreign_s0" + client())
                  }}</label>
                <input
                  min="0"
                  type="number"
                  id="foreign_s0"
                  v-model="foreign_s0"
                />
              </div>
              <div
                class="input"
                v-else-if="category.key === 'TELEPHONE_ANALOG'"
              >
                <label for="foreign_analog">{{
                    $t("offer.offer.steps.shop.field.foreign_analog" + client())
                  }}</label>
                <input
                  min="0"
                  type="number"
                  id="foreign_analog"
                  v-model="foreign_analog"
                />
              </div>
              <div class="input" v-else-if="category.key === 'TFS'">
                <label for="foreign_tfs">{{
                    $t("offer.offer.steps.shop.field.foreign_tfs" + client())
                  }}</label>
                <input
                  min="0"
                  type="number"
                  id="foreign_tfs"
                  v-model="foreign_tfs"
                />
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col6">
              <div class="input" v-if="category.key === 'TELEPHONE_VOIP'">
                <label for="overcommitment_voip">{{
                    $t("offer.offer.steps.shop.field.overcommitment_voip")
                  }}</label>
                <dropdown
                  id="overcommitment_voip"
                  v-model="overcommitment_voip"
                  :data="overcommitmentSteps"
                  sort-field="value"
                  sort-type="number"
                  @change="saveOfferAndBalanceAccessory"
                ></dropdown>
              </div>
              <div class="input" v-else-if="category.key === 'TELEPHONE_DECT'">
                <label for="overcommitment_dect">{{
                    $t("offer.offer.steps.shop.field.overcommitment_dect")
                  }}</label>
                <dropdown
                  id="overcommitment_dect"
                  v-model="overcommitment_dect"
                  :data="overcommitmentSteps"
                  sort-field="value"
                  sort-type="number"
                  @change="saveOfferAndBalanceAccessory"
                ></dropdown>
              </div>
            </div>
          </div>

          <data-table :fields="fields" :data="products">
            <div
              slot="last-col"
              slot-scope="{ row }"
              class="inline add-to-cart"
              style="line-height: 2;">
              <i
                class="table-action-icon fa fa-plus-square fa-lg"
                :title="$t('offer.add_to_cart')"
                @click="addToCart(row)"
              ></i>
            </div>
            <tr slot="empty">
              <td :colspan="fields.length" class="text-center">
                {{ $t("offer.offer.steps.shop.no_articles") }}
              </td>
            </tr>
          </data-table>
        </tab>
      </tabs>

      <button class="button secondary" v-if="hasMoreProducts" @click="loadMore">
        {{ $t("actions.load_more") }}
      </button>
    </section>

    <button class="button primary" @click="save">
      {{ $t("offer.offer.next_step", ["2", "6"]) }}
    </button>

    <!--
    <button class="button secondary" @click="saveAndClose()">{{ $t("actions.continue_later") }}</button>
    <button class="button secondary" @click="discard()">{{ $t("actions.discard") }}</button>
    -->
  </div>
</template>

<script>
import {wait} from "../../../res/helper/wait";
import {mapState} from "vuex";
import {mapFields} from "vuex-map-fields";
import TabChild from "../../shared/components/tabs/tab-child";
import DataTable from "../../shared/components/table/table";
import DotMenu from "../../shared/components/modal/dot-menu";
import Cart from "./shop/cart";
import VueElementLoading from "vue-element-loading";
import Tabs from "../../shared/components/tabs/tabs";
import Tab from "../../shared/components/tabs/tab";
import Dropdown from "../../shared/components/form/dropdown";

export default {
  extends: TabChild,
  name: "shop",
  components: {
    Dropdown,
    Tab,
    Tabs,
    Cart,
    DotMenu,
    DataTable,
    VueElementLoading,
  },
  data() {
    return {
      client: function () {
        const global = require("../../../config/index");
        return global.default.api.client === "fontevo" ? "" : "_au";
      },
      showSpinner: false,
      limit: 15,
      search: "",
      fields: [
        {label: "offer.products.table.id", key: "article_number"},
        {
          label: "offer.products.table.name",
          key: "title",
          type: "mixed-multiple-check",
          checkProp: "selectable_system_functions",
          checkPropCallback: (checked, item, field, row) =>
            this.$store.commit("offer/updateProductSelectableSystemFunction", {
              product: row,
              item: item,
              checked: checked,
            }),
          hint: (product) => product.product_hint,
        },
        {
          label: "offer.products.table.category",
          key: "sub_category.key",
          transform: (key) => this.$t("offer.category." + key),
        },
        {label: "offer.products.table.color", key: "color", default: ""},
        {
          label: "offer.products.table.hek",
          key: "price.hek",
          transform: (price, product) => {
            const currency = this.$store.state["offer"].offer.region.currency;
            return this.formatPrice(price, product.price ? currency : null);
          },
          renderCondition: () => !!this.$route.params.distributor,
        },
        // { label: 'offer.offer.products.table.uvp', key: 'price.uvp', transform: (price, product) => this.formatPrice(price, product.price ? product.price.currency : null) },
        {
          label: "offer.products.table.used_port",
          showCondition: (product) => {
            let sub_category = product.sub_category;
            let categoriesToShow = ["TELEPHONE_ISDN"];

            if (!sub_category) {
              return false;
            }

            return categoriesToShow.includes(sub_category.key);
          },
          key: "port",
          default: "s0",
          type: "input:select",
          options: [
            {label: "S0", value: "s0"},
            {label: "UP0", value: "up0"},
          ],
          callback: ({value, row}) =>
            this.$store.commit("offer/updateProductPort", {
              product: row,
              port: value,
            }),
        },
        {
          label: "offer.products.table.image",
          key: "image",
          transform: (image) => {
            if (!image || !image.uuid) {
              return "";
            }

            const url = this.$store.getters["asset/getPublicUrl"](image, {
              height: 50,
              width: 50,
            });

            return `<img alt="product image" class="no-margin" src="${url}">`;
          },
          sortable: false,
        },
        {
          label: "offer.products.table.amount",
          type: "input:number",
          key: "amount",
          options: {
            min: 1,
            classList: ["product-amount-input"]
          },
          sortable: false,
          callback: ({value, row}) =>
            this.$store.commit("offer/updateProductAmount", {
              product: row,
              amount: value,
            }),
        },
      ],
      sub_category: "",
      overcommitmentSteps: [
        {label: "10%", value: 10},
        {label: "20%", value: 20},
        {label: "30%", value: 30},
        {label: "40%", value: 40},
        {label: "50%", value: 50},
        {label: "60%", value: 60},
        {label: "70%", value: 70},
        {label: "80%", value: 80},
        {label: "90%", value: 90},
        {label: "100%", value: 100},
      ],
    };
  },
  computed: {
    ...mapFields("offer", {
      foreign_voip: "offer.foreign_voip",
      foreign_dect: "offer.foreign_dect",
      foreign_s0: "offer.foreign_s0",
      foreign_analog: "offer.foreign_analog",
      foreign_tfs: "offer.foreign_tfs",
      overcommitment_voip: "offer.overcommitment_voip",
      overcommitment_dect: "offer.overcommitment_dect",
    }),
    ...mapState({
      products(state) {
        const products = [...state.offer.possibleProducts];
        const search = this.search.toLowerCase();

        return products
          .map((p) => ({...p, id: p.uuid}))
          .filter((product) => {
            const constraints = [
              product.title.toLowerCase().includes(search),
              product.category
                ? product.category.title.toLowerCase().includes(search)
                : false,
              product.article_number.toLowerCase().includes(search),
            ];

            return constraints.reduce((m, matching) => m || matching);
          });
      },
      hasMoreProducts(state) {
        return !!state.offer.possibleProductsPagination.next_page;
      },
      categories(state) {
        return [...state["offer/category"].categories];
      },
    }),
  },
  methods: {
    getClient() {
      const global = require("../../../config/index");
      return global.default.api.client;
    },
    baseUrl() {
      const {region, distributor, id} = this.$route.params;

      let target = "/offer";

      if (distributor) {
        target = "/" + distributor + target;
      }

      if (region) {
        target = "/" + region + target;
      }

      return target + "/" + id;
    },
    async save(navigate = true) {
      const offer = {...this.$store.state.offer.offer};

      await this.$store.dispatch("offer/update", {
        id: offer.uuid,
        payload: {offer},
      });

      this.$children
        .find((child) => child.$options._componentTag === "tabs")
        .selectTab(0);

      if (navigate) {
        this.nextTab();
      }
    },
    async saveAndClose() {
      await this.save(false);

      this.$router.push("/offer");
    },
    async discard() {
      const $tab = this.$parent;
      const $tabs = $tab.$parent;
      const $page = $tabs.$parent;

      await $page.discard();
    },
    async addToCart(product) {
      const id = this.$store.state.offer.offer.uuid;
      const position = {
        product: product.uuid,
        price: product.price,
        exists: false,
        amount: product.amount,
        port: product.port,
        selected_system_functions: product.selectedSystemFunctions
          ? product.selectedSystemFunctions
          : [],
      };
      await this.$store.dispatch("offer/addPosition", {
        id,
        position,
      });

      this.$store.commit("offer/updateProductAmount", {product, amount: 1});
      this.$store.commit("offer/updateProductPort", {product, port: null});
    },
    loadMore() {
      return this.updatePossibleProducts({reset: false});
    },
    async updatePossibleProducts({reset = false, params = {}}) {
      const id = this.$route.params.id;

      if (reset) {
        this.$store.commit("offer/resetProductPagination");
      }

      this.showSpinner = true;
      await this.$store.dispatch("offer/loadPossibleProducts", {
        id,
        params: {
          limit: this.limit,
          sub_category: this.sub_category,
          ...params,
        },
      });
      this.showSpinner = false;
    },
    async saveOfferAndBalanceAccessory() {
      this.showSpinner = true;
      // wait for mapFields to update store
      await wait(500);
      await this.save(false);
      // wait for api to save offer
      await wait(1000);
      await this.$store.dispatch("offer/balanceAccessory", {
        id: this.$route.params.id,
      });
      this.showSpinner = false;
    },
    formatPrice(price = 0, currency = "EUR") {
      const locale = this.$i18n.locale;

      if (!currency) {
        currency = "EUR";
      }

      return Number(price).toLocaleString(locale, {
        style: "currency",
        currency,
      });
    },
    async beforeTabChange($event) {
      this.search = "";
      await this.loadProducts($event.propId);
    },
    async loadProducts(category) {
      this.sub_category = category;
      await this.updatePossibleProducts({reset: true});
    },
  },
  watch: {
    async search(search) {
      await wait(1000);

      if (search === this.search) {
        let params = search ? {search} : {};

        await this.updatePossibleProducts({params, reset: true});
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../res/less/core/config/constants";

@media (max-width: @landscape) {
  .add-to-cart {
    float: right;
  }
}
</style>
