<template>
  <nav class="meta-navigation" v-show="show">
    <meta-navigation-item icon="language">
      <language-toggle />
    </meta-navigation-item>

    <meta-navigation-item icon="help" v-if="showHelp">
      <help-dropdown />
    </meta-navigation-item>

    <template v-if="isAdminRoute">
      <meta-navigation-item icon="help">
        <div class="help-wrap overlay-content-wrap">
          <admin-help />
        </div>
      </meta-navigation-item>

      <meta-navigation-item icon="add-user">
        <ul class="add-document">
          <li>
            <h4
              class="headline headline_4"
              @click="addReseller()"
            >{{ $t("header.meta_navigation.overlays.add_reseller") }}</h4>
          </li>
        </ul>
      </meta-navigation-item>

      <meta-navigation-item icon="settings">
        <ul class="settings">
          <router-link tag="li" to="/logout">
            <h4 class="headline headline_4">{{ $t("header.meta_navigation.overlays.logout") }}</h4>
          </router-link>
        </ul>
      </meta-navigation-item>

      <router-link
        v-if="userIsAdmin"
        tag="div"
        to="/home"
        class="module-toggle"
      >{{ $t("header.meta_navigation.reseller") }}</router-link>
    </template>
    <template v-else-if="isDistributorRoute">
      <meta-navigation-item icon="account">
        <ul class="add-document">
          <router-link tag="li" to="/distributor/profile">
            <h4 class="headline headline_4">{{ $t("header.meta_navigation.overlays.edit_profile") }}</h4>
          </router-link>
        </ul>
      </meta-navigation-item>

      <meta-navigation-item icon="settings">
        <ul class="settings">
          <router-link tag="li" to="/logout">
            <h4 class="headline headline_4">{{ $t("header.meta_navigation.overlays.logout") }}</h4>
          </router-link>
        </ul>
      </meta-navigation-item>

      <router-link
        v-if="userIsDistributor"
        tag="div"
        to="/home"
        class="module-toggle"
      >{{ $t("header.meta_navigation.reseller") }}</router-link>
    </template>
    <template v-else-if="isDistributorPlatform">
      <meta-navigation-item icon="settings">
        <ul class="settings">
          <router-link tag="li" :to="'/logout-distributor/'+this.$route.params.distributor">
            <h4 class="headline headline_4">{{ $t("header.meta_navigation.overlays.logout") }}</h4>
          </router-link>
        </ul>
      </meta-navigation-item>

      <router-link
        v-if="userIsDistributor"
        tag="div"
        to="/distributor/dashboard"
        class="module-toggle"
      >{{ $t("header.meta_navigation.distributor") }}</router-link>
      <router-link
        v-if="userIsAdmin"
        tag="div"
        to="/admin/dashboard"
        class="module-toggle"
      >{{ $t("header.meta_navigation.admin") }}</router-link>
    </template>
    <template v-else-if="isContentRoute">
      <router-link tag="div" to="/">
        <meta-navigation-item icon="home"></meta-navigation-item>
      </router-link>

      <router-link
        v-if="userIsDistributor"
        tag="div"
        to="/distributor/dashboard"
        class="module-toggle"
      >{{ $t("header.meta_navigation.distributor") }}</router-link>
      <router-link
        v-if="userIsAdmin"
        tag="div"
        to="/admin/dashboard"
        class="module-toggle"
      >{{ $t("header.meta_navigation.admin") }}</router-link>
    </template>
    <template v-else>
      <meta-navigation-item v-if="showContact" icon="contact">
        <div class="contact-wrap overlay-content-wrap">
          <contact-form :headline="4" />
        </div>
      </meta-navigation-item>
      <router-link
        v-if="userIsAdmin"
        style="margin-top: 1em;"
        tag="div"
        to="/admin/dashboard"
        class="module-toggle"
      >{{ $t("header.meta_navigation.admin") }}</router-link>
      <router-link
        v-if="userIsDistributor"
        tag="div"
        to="/distributor/dashboard"
        class="module-toggle"
      >{{ $t("header.meta_navigation.distributor") }}</router-link>
    </template>
  </nav>
</template>

<script>
import Help from "./meta-navigation/help";
import AdminHelp from "./meta-navigation/admin-help";
import ContactForm from "./meta-navigation/contact-form";
import MyInformation from "../../../dashboard/components/my-information";
import LanguageToggle from "./meta-navigation/language-toggle";
import HelpDropdown from "./meta-navigation/help-dropdown";
import MetaNavigationItem from "./meta-navigation-item";

export default {
  name: "meta-navigation",
  components: {
    AdminHelp,
    Help,
    ContactForm,
    MyInformation,
    LanguageToggle,
    HelpDropdown,
    MetaNavigationItem
  },
  methods: {
    addOffer() {
      // this.$router.push('/offer/add');
      this.$router.push("/home");
    },
    addReseller() {
      this.$router.push("/admin/reseller/add");
    }
  },
  computed: {
    isDistributorPlatform() {
      return !!this.$route.params.distributor;
    },
    isAdminRoute() {
      return this.$route.path.startsWith("/admin");
    },
    isDistributorRoute() {
      return this.$route.path.startsWith("/distributor");
    },
    userIsAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    userIsDistributor() {
      return this.$store.getters["auth/isDistributor"];
    },
    isContentRoute() {
      const contentRoutes = ["/privacy-policy", "/imprint"];

      return contentRoutes.includes(this.$route.path);
    },
    show() {
      return !this.$route.path.includes("login");
    },
    showContact() {
      return !!this.$store.state.profile.profile.service_contact;
    },
    client() {
      const global = require("../../../../config/index");
      return global.default.api.client;
    },
    showHelp() {
      return !!this.$route.meta.show_manual;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../res/less/core/config/constants";
@import "../../../../res/less/core/config/mixins";
@import "../../../../res/less/themes/theme";

.meta-navigation {
  top: @metaNavigationTop;
  left: 25px;
  position: fixed;


  .overlay-content-wrap {
    padding: @space3 @space3 0 @space3;
  }
  .my-information-wrap,
  .contact-wrap {
    width: 512px;
  }
  .help-wrap {
    width: 1200px;
  }

  .add-document,
  .settings {
    margin-bottom: 0;

    li {
      padding: @space3;
      position: relative;
      white-space: nowrap;

      &:first-child {
        max-height: 70rem;
      }

      .headline {
        transform: translateY(-3rem);
        cursor: pointer;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .module-toggle {
    color: @white-100;
    font-family: "RBNo2.1b-Book", sans-serif;
    font-size: @space3;
    line-height: @space4;
    text-align: center;
    cursor: pointer;
  }

  @media (max-width: @landscape) {
    display: none;
  }
}
</style>
