<template>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th class="checkbox">
            <checkbox v-if="checkAll && !radio" @change="emitAll" :value="this.value.length > 0" :partly="this.value.length > 0 && this.value.length < data.length"></checkbox>
          </th>
          <th v-for="field in fields" @click="sortBy(field)" :class="{ sortable: field.sortable === undefined || field.sortable }">{{ $t(field.label) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in sortedData">
          <td class="checkbox">
            <radio v-if="radio" :value="isChecked(row)" @change="handleCheck(row, $event)"></radio>
            <checkbox v-else :value="isChecked(row)" ref="checkbox" @change="handleCheck(row, $event)"/>
          </td>
          <template v-for="(field, index) in fields">
            <td>
              <template v-if="field.type === 'input:text' || field.type === 'input'">
                <input
                  type="text"
                  :value="getValue(row, field.key, field.transform)"
                  :placeholder="field.options ? field.options.placeholder : ''"
                  :class="field.options ? field.options.classList : {}"
                  :style="field.options ? field.options.styles : {}"
                  @change="fieldChanged($event, field, row)" />
              </template>

              <template v-else-if="field.type === 'input:number'">
                <input
                  type="number"
                  :value="getValue(row, field.key, field.transform)"
                  :placeholder="field.options ? field.options.placeholder : ''"
                  :class="field.options ? field.options.classList : {}"
                  :style="field.options ? field.options.styles : {}"
                  @change="fieldChanged($event, field, row)" />
              </template>

              <template v-else-if="field.type === 'input:select'">
                <dropdown
                  v-show="evaluate(row, field.showCondition)"
                  :class="field.options ? field.options.classList : {}"
                  :style="field.options ? field.options.styles : {}"
                  @input="selectFieldChanged($event, field, row)"
                  :value="getValue(row, field.key, field.transform)"
                  :data="field.options">
                </dropdown>
              </template>

              <template v-else>
                <div class="inline" v-html="getValue(row, field.key, field.transform)"></div>
              </template>

              <slot name="last-col" v-if="index === fields.length - 1" :row="row"></slot>
            </td>
          </template>
        </tr>
        <slot name="empty" v-if="sortedData.length === 0"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
  import Radio from "../form/radio";
  import Checkbox from "../form/checkbox";
  import DataTable from './table';

  export default {
    components: {
      Radio,
      Checkbox
    },
    extends: DataTable,
    name: "checkable-table",
    props: {
      radio: {
        type: Boolean,
        default: false
      },
      checkAll: {
        type: Boolean,
        default: true
      },
      value: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      id() {
        return this._uid;
      }
    },
    methods: {
      handleCheck(data, checked) {
        let rows = this.radio ? [] : [...this.value];

        if (checked) {
          rows = [...rows, data];
        } else {
          rows = rows.filter(row => row.id !== data.id);
        }

        this.$emit('input', rows);
      },
      emitAll() {
        if (this.value.length === this.data.length) {
          this.$emit('input', []);
        } else {
          this.$emit('input', this.data);
        }
      },
      isChecked(row) {
        return !!this.value.find(r => row.id === r.id);
      }
    }
  }
</script>

<style scoped>

</style>
