<template>
  <div>
    <div class="grid">
      <div class="col6">
        <h3 class="headline headline_3">Informationen</h3>

        <div class="input">
          <checkbox
            id="dont_show_in_frontend_selection_list"
            v-model="dont_show_in_frontend_selection_list"
          >
            Produkt nicht in der Frontend-Auswahl anzeigen
          </checkbox>
        </div>

        <div class="input">
          <label for="product_number">Artikelnummer</label>
          <input type="text" id="product_number" v-model="article_number" />
        </div>

        <div class="input">
          <label for="product_category">Kategorie</label>
          <dropdown
            id="product_category"
            :data="categories"
            :value="category"
            @input="updateCategory"
            placeholder="Kategorie"
          ></dropdown>
        </div>

        <div v-if="subCategories" class="input">
          <label for="product_subcategory">Unterkategorie</label>
          <dropdown
            id="product_subcategory"
            :data="subCategories"
            :value="subCategory"
            @input="updateSubCategory"
            placeholder="Unterkategorie"
          ></dropdown>
        </div>

        <div class="input">
          <label>Bild</label>
          <file-upload :value="filename" @change="uploadFile($event)" />
        </div>

        <language-tabs>
          <tab name="DE" language="de">
            <div class="input">
              <label for="product_title_de">Titel</label>
              <input type="text" id="product_title_de" v-model="title_de" />
            </div>

            <div class="input">
              <label for="product_tooltip_de">Tooltip</label>
              <input
                type="text"
                id="product_tooltip_de"
                v-model="tooltip_de"
                maxlength="120"
              />
            </div>

            <div class="input">
              <label for="product_color_de">Farbe</label>
              <input type="text" id="product_color_de" v-model="color_de" />
            </div>

            <div class="input">
              <label for="product_product_hint_de">Hinweis</label>
              <rich-text-editor
                :content="productHintDe"
                @onUpdate="productHintDeUpdated"
              />
            </div>
          </tab>
          <tab name="EN (AU)" language="en">
            <div class="input">
              <label for="product_title_en_au">Titel</label>
              <input
                type="text"
                id="product_title_en_au"
                v-model="title_en_au"
              />
            </div>

            <div class="input">
              <label for="product_tooltip_en_au">Tooltip</label>
              <input
                type="text"
                id="product_tooltip_en_au"
                v-model="tooltip_en_au"
                maxlength="120"
              />
            </div>

            <div class="input">
              <label for="product_color_en_au">Farbe</label>
              <input
                type="text"
                id="product_color_en_au"
                v-model="color_en_au"
              />
            </div>

            <div class="input">
              <label for="product_product_hint_en_au">Hinweis</label>
              <rich-text-editor
                :content="productHintEnAu"
                @onUpdate="productHintEnAuUpdated"
              />
            </div>
          </tab>
          <tab name="EN (FON)" language="en">
            <div class="input">
              <label for="product_title_en">Titel</label>
              <input type="text" id="product_title_en" v-model="title_en" />
            </div>

            <div class="input">
              <label for="product_tooltip_en">Tooltip</label>
              <input
                type="text"
                id="product_tooltip_en"
                v-model="tooltip_en"
                maxlength="120"
              />
            </div>

            <div class="input">
              <label for="product_color_en">Farbe</label>
              <input type="text" id="product_color_en" v-model="color_en" />
            </div>

            <div class="input">
              <label for="product_product_hint_en">Hinweis</label>
              <rich-text-editor
                :content="productHintEn"
                @onUpdate="productHintEnUpdated"
              />
            </div>
          </tab>
          <tab name="FR" language="fr">
            <div class="input">
              <label for="product_title_fr">Titel</label>
              <input type="text" id="product_title_fr" v-model="title_fr" />
            </div>

            <div class="input">
              <label for="product_tooltip_fr">Tooltip</label>
              <input
                type="text"
                id="product_tooltip_fr"
                v-model="tooltip_fr"
                maxlength="120"
              />
            </div>

            <div class="input">
              <label for="product_color_fr">Farbe</label>
              <input type="text" id="product_color_fr" v-model="color_fr" />
            </div>

            <div class="input">
              <label for="product_product_hint_fr">Hinweis</label>
              <rich-text-editor
                :content="productHintFr"
                @onUpdate="productHintFrUpdated"
              />
            </div>
          </tab>
        </language-tabs>
      </div>

      <div class="col6">
        <prices />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

import Dropdown from "../../shared/components/form/dropdown";
import FileUpload from "../../shared/components/form/file-upload";
import Prices from "./prices";
import Tabs from "../../shared/components/tabs/tabs";
import Tab from "../../shared/components/tabs/tab";
import LanguageTabs from "../../shared/components/tabs/language-tabs";
import Checkbox from "../../shared/components/form/checkbox";
import RichTextEditor from "../../shared/components/form/rich-text-editor";

export default {
  name: "information",
  components: {
    Checkbox,
    LanguageTabs,
    Tab,
    Tabs,
    Prices,
    Dropdown,
    FileUpload,
    RichTextEditor,
  },
  data() {
    return {
      productHintDe: null,
      productHintEn: null,
      productHintEnAu: null,
      productHintFr: null,
      isLoaded: false,
    };
  },
  watch: {
    product_hint_de: {
      handler(val, oldVal) {
        if (!this.isLoaded) {
          this.productHintDe = this.product_hint_de;
          this.productHintEn = this.product_hint_en;
          this.productHintEnAu = this.product_hint_en_au;
          this.productHintFr = this.product_hint_fr;
          this.isLoaded = true;
        }
      },
    },
  },
  computed: {
    ...mapFields("product", {
      article_number: "product.article_number",
      title: "product.title",
      color: "product.color",
      hek: "product.hek",
      uvp: "product.uvp",
      category: "product.category.key",
      subCategory: "product.sub_category.key",
      image: "product.image",
      tooltip: "product.tooltip",
      product_link: "product.product_link",
      dont_show_in_frontend_selection_list:
        "product.dont_show_in_frontend_selection_list",

      title_de: "product.title",
      color_de: "product.color_de",
      tooltip_de: "product.tooltip_de",
      product_hint_de: "product.product_hint_de",

      title_en_au: "product.title_en_au",
      color_en_au: "product.color_en_au",
      tooltip_en_au: "product.tooltip_en_au",
      product_hint_en_au: "product.product_hint_en_au",

      title_en: "product.title_en",
      color_en: "product.color_en",
      tooltip_en: "product.tooltip_en",
      product_hint_en: "product.product_hint_en",

      title_fr: "product.title_fr",
      color_fr: "product.color_fr",
      tooltip_fr: "product.tooltip_fr",
      product_hint_fr: "product.product_hint_fr",
    }),
    ...mapState({
      categories(state) {
        const categories = [...state.product.categories];

        return categories.map((category) => ({
          label: category.title,
          value: category.key,
        }));
      },
      subCategories(state) {
        const subCategories = [...state.product.subCategories];

        if (subCategories.length > 0) {
          return subCategories.map((subCategory) => ({
            label: subCategory.title,
            value: subCategory.key,
          }));
        }

        return null;
      },
      filename(state) {
        const image = state.product.product.image;

        if (!image) {
          return "";
        }

        return image.filename + (image.extension ? "." + image.extension : "");
      },
    }),
  },
  methods: {
    updateCategory(key) {
      const category = this.$store.state.product.categories.find(
        (category) => category.key === key
      );

      this.$store.commit("product/updateField", {
        path: "product.category",
        value: category,
      });
      this.$store.dispatch("product/loadCategories", { parent: key });
    },
    updateSubCategory(key) {
      const subCategory = this.$store.state.product.subCategories.find(
        (category) => category.key === key
      );
      this.$store.commit("product/updateField", {
        path: "product.sub_category",
        value: subCategory,
      });
    },
    async uploadFile(file) {
      this.image = await this.$store.dispatch("asset/upload", file);
    },
    productHintDeUpdated(value) {
      this.product_hint_de = value;
    },
    productHintEnUpdated(value) {
      this.product_hint_en = value;
    },
    productHintEnAuUpdated(value) {
      this.product_hint_en_au = value;
    },
    productHintFrUpdated(value) {
      this.product_hint_fr = value;
    },
  },
};
</script>

<style scoped>
/deep/ .rte > div {
  min-height: 150px;
}
</style>
