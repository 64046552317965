<template>
  <div class="table-wrapper">
    <table>
      <thead>
      <tr>
        <th
          v-for="field in fields"
          :style="`width: ${field.width ? field.width : 'auto'}`"
          @click="sortBy(field)"
          :class="{
              sortable: field.sortable === undefined || field.sortable,
            }"
          v-if="evaluate({}, field.renderCondition)"
        >
          {{ $t(field.label, field.labelData) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in sortedData">
        <template v-for="(field, index) in fields">
          <td v-if="evaluate({}, field.renderCondition)"
              :class="{'last-col': (index === fields.length-1)}">
            <template
              v-if="field.type === 'input:text' || field.type === 'input'"
            >
              <input
                type="text"
                :value="getValue(row, field.key, field.transform)"
                :placeholder="field.options ? field.options.placeholder : ''"
                :class="field.options ? field.options.classList : {}"
                :style="field.options ? field.options.styles : {}"
                @change="fieldChanged($event, field, row)"
              />
            </template>

            <template v-else-if="field.type === 'input:number'">
              <input
                v-show="evaluate(row, field.showCondition)"
                type="number"
                :value="getValue(row, field.key, field.transform)"
                :placeholder="field.options ? field.options.placeholder : ''"
                :class="field.options ? field.options.classList : {}"
                :style="field.options ? field.options.styles : {}"
                :min="field.options ? field.options.min : {}"
                :max="field.options ? field.options.max : {}"
                :step="field.options ? field.options.step : {}"
                @change="fieldChanged($event, field, row)"
              />
            </template>

            <template v-else-if="field.type === 'input:select'">
              <dropdown
                v-show="evaluate(row, field.showCondition)"
                :class="field.options ? field.options.classList : {}"
                :style="field.options ? field.options.styles : {}"
                @input="selectFieldChanged($event, field, row)"
                :value="getValueFromField(row, field, field.transform)"
                :data="field.options"
              ></dropdown>
            </template>

            <template v-else-if="field.type === 'mixed-multiple-check'">
              <div class="check-label">
                  <span v-if="row.product_link && getValue(row, field.key)">
                    <a
                      class="product-title-link"
                      :href="row.product_link"
                      :title="getValue(row, field.key)"
                      target="_blank"
                    >
                      <span
                        v-html="getValue(row, field.key, field.transform)"
                      ></span>
                    </a>
                    <div
                      v-if="
                        field.undertitleline &&
                        getValue(row, 'this', field.undertitleline)
                      "
                      v-html="getValue(row, 'this', field.undertitleline)"
                    ></div>
                  </span>
                <span v-else>{{
                    getValue(row, field.key, field.transform)
                  }}</span>

                <tooltip v-if="row.tooltip">
                  <template slot="toggle">
                    <i class="fa fa-info-circle"></i>
                  </template>
                  <template slot="body">{{ row.tooltip }}</template>
                </tooltip>
              </div>
              <div
                class="mixed-multiple-check-container"
                v-for="sF in getValue(row, field.checkProp)"
              >
                <div v-if="sF.type === 'number'">
                  <input
                    type="number"
                    :id="`label-sF-${sF.uuid}`"
                    class="table-number-input"
                    min="0"
                    :max="sF.value"
                    @change="checkPropChanged($event, sF, field, row)"
                    value="0"
                  />
                  <label :for="`label-sF-${sF.uuid}`">{{ sF.title }}</label>
                </div>
                <checkbox
                  v-else-if="sF.type === 'checkbox' || sF.type === ''"
                  @change="checkPropChanged($event, sF, field, row)"
                  :value="false"
                >{{ sF.title }}
                </checkbox
                >
              </div>
              <div
                v-if="
                    field.underline && getValue(row, 'this', field.underline)
                  "
                v-html="getValue(row, 'this', field.underline)"
              ></div>
              <div
                class="product_hint"
                v-if="field.hint && getValue(row, 'this', field.hint)"
                v-html="getValue(row, 'this', field.hint)"
              ></div>
            </template>

            <template v-else-if="field.type === 'product:tooltip'">
                <span v-if="getValue(row, field.productLinkKey)">
                  <a
                    class="product-title-link"
                    :href="getValue(row, field.productLinkKey)"
                    :title="getValue(row, field.key)"
                    target="_blank"
                  >{{ getValue(row, field.key) }}</a
                  >
                </span>
              <span v-else>{{ getValue(row, field.key) }}</span>
              <div
                class="underline"
                v-if="
                    field.undertitleline &&
                    getValue(row, 'this', field.undertitleline)
                  "
                v-html="getValue(row, 'this', field.undertitleline)"
              ></div>

              <tooltip v-if="getValue(row, field.tooltipKey)">
                <template slot="toggle">
                  <i class="fa fa-info-circle"></i>
                </template>
                <template slot="body">{{
                    getValue(row, field.tooltipKey)
                  }}
                </template>
              </tooltip>
              <div
                class="underline"
                v-if="
                    field.underline && getValue(row, 'this', field.underline)
                  "
                v-html="getValue(row, 'this', field.underline)"
              ></div>
              <div
                class="product_hint"
                v-if="field.hint && getValue(row, 'this', field.hint)"
                v-html="getValue(row, 'this', field.hint)"
              ></div>
            </template>

            <template v-else>
              <div
                class="inline"
                v-html="getValue(row, field.key, field.transform)"
                v-show="evaluate(row, field.showCondition)"
              ></div>
            </template>

            <slot
              name="last-col"
              v-if="index === fields.length - 1"
              :row="row"
            />
          </td>
        </template>
      </tr>
      <slot name="empty" v-if="sortedData.length === 0"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
import {wait} from "../../../../res/helper/wait";
import Dropdown from "../form/dropdown";
import Checkbox from "../form/checkbox";
import Tooltip from "../tooltip";

export default {
  name: "data-table",
  components: {Tooltip, Dropdown, Checkbox},
  props: {
    fields: {
      type: Array,
      required: true,
      validator(fields) {
        if (fields.length > 0) {
          return fields
            .map(
              (field) =>
                field.hasOwnProperty("label") && field.hasOwnProperty("key")
            )
            .reduce((a, b) => a && b);
        }

        return true;
      },
    },
    data: {
      type: Array,
      required: true,
      validator(values) {
        if (values.length > 0) {
          return values
            .map(
              (value) =>
                value.hasOwnProperty("id") &&
                value.id !== null &&
                value.id !== undefined
            )
            .reduce((a, b) => a && b);
        }

        return true;
      },
    },
  },
  data() {
    return {
      sort: {
        field: null,
        direction: "ASC",
      },
    };
  },
  computed: {
    sortedData() {
      if (this.sort.field) {
        let data = [...this.data];

        data = data.sort((a, b) => {
          if (this.sort.direction === "ASC") {
            return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
          } else {
            return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
          }
        });

        return data;
      } else {
        return this.data;
      }
    },
  },
  methods: {
    async fieldChanged($event, field, row) {
      const value = $event.target.value;

      if (field.callback && typeof field.callback === "function") {
        if (field.delay) {
          await wait(field.delay);
        }

        if (value === $event.target.value) {
          field.callback({$event, row, value, field});
        }
      }
    },
    async checkPropChanged($event, item, field, row) {
      if (
        field.checkPropCallback &&
        typeof field.checkPropCallback === "function"
      ) {
        field.checkPropCallback($event, item, field, row);
      }
    },
    async selectFieldChanged($event, field, row) {
      if (field.callback && typeof field.callback === "function") {
        field.callback({value: $event, row, field});
      }
    },
    walkThrough(row, field) {
      let data = row;

      while (field) {
        const index = field.indexOf(".");
        const key = field.slice(0, index === -1 ? field.length : index);

        if (data[key] !== null && data[key] !== undefined) {
          data = data[key];
          field = field.slice(index + 1, field.length + 1);
        } else {
          break;
        }
      }

      return data instanceof Object ? field.default || "" : data;
    },
    getValueFromField(row, field) {
      let value = this.getValue(row, field.key, field.transform);
      if (!value) {
        return field.default;
      }
      return value;
    },
    getValue(row, field, transform = (d) => d) {
      let data = row;

      if (field !== "this") {
        data = row[field];

        if (!data && field.includes(".")) {
          data = this.walkThrough(row, field);
        }
      }

      data = transform(data, row);

      return data === null || data === undefined ? "" : data;
    },
    evaluate(row, showCondition = (d) => d) {
      let data = false;
      data = showCondition(row);
      return data;
    },
    sortBy(field) {
      const key = field.key;
      const isSortable = field.sortable === undefined ? true : field.sortable;

      if (isSortable) {
        if (this.sort.field === key) {
          const direction = this.sort.direction === "ASC" ? "DESC" : "ASC";

          this.sort.field = key;
          this.sort.direction = direction;
        } else {
          this.sort.field = key;
          this.sort.direction = "ASC";
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../res/less/core/config/constants";

.check-label {
  margin-bottom: 1em;
}

.checkbox label {
  line-height: 1.1em;
}

.tooltip {
  margin-bottom: unset;
}

.table-number-input {
  width: 45px;
  height: 20px;
  padding: 0;
  text-align: center;
  margin-bottom: 5px;
}

.product-title-link {
  padding: 0;
}

.product-title-link:before {
  display: none;
}

.underline {
  margin-top: @space1;
}

.product_hint {
  padding-top: 15px;
}

.cart-amount-input, .product-amount-input {
  min-width: 68px;
  max-width: 50%;
}

.last-col {
  white-space: nowrap;
}


@media (max-width: @landscape) {
  .cart-amount-input {
    min-width: 68px;
    max-width: 100%;
  }
}
</style>
