import AdminGuard from '../../shared/router/guard/admin';

import DashboardComponent from '../views/dashboard';

export default [
	{
		path: '/admin',
		redirect: '/admin/dashboard'
	},
	{
		path: '/admin/dashboard',
		component: DashboardComponent,
		meta: {
			title: 'admin-dashboard.routes.dashboard'
		},
		beforeEnter: AdminGuard
	},
];
