import axios from "axios/index";
import config from "../../../../config";

export default {
    async load({ commit }, { id, format = 'json', params = {} }) {
        const response = await axios.get(`${config.api.endpoint}/assets/${id}.${format}`, { params });
        const data = response.data;

        commit('loaded', data);
    },
    async upload({ commit }, file) {
        const payload = new FormData();

        payload.append('asset', file);

        try {
            const headers = { 'Content-Type': 'multipart/form-data' };
            const response = await axios.post(`${config.api.endpoint}/assets`, payload, { headers });
            const data = response.data;

            commit('uploaded', data);

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });

            return data.asset;
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);

            return null;
        }
    }
};
