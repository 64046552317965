<template>
  <footer>
    <nav class="footer-navigation">
      <div v-if="isFontevo">
        <div class="item" v-for="{ key, link } in FontevoLinks" :key="key">
          <a :href="$t(link)" tag="span" class="footer-links">{{ $t(key) }}</a>
        </div>
        <div class="item">
          <a href="#" @click.prevent="openCcmSettings" class="footer-links">{{ $t('footer.navigation.cookie-settings') }}</a>
        </div>
      </div>
      <div v-else>
        <div class="item" v-for="{ key, link } in AuLinks" :key="key">
          <a :href="$t(link)" tag="span" class="footer-links">{{ $t(key) }}</a>
        </div>
        <div class="item">
          <a href="#" @click.prevent="openCcmSettings" class="footer-links">{{ $t('footer.navigation.cookie-settings') }}</a>
        </div>
      </div>
    </nav>
    <div class="wave"></div>
  </footer>
</template>

<script>
export default {
  name: "application-footer",
  methods: {
    openCcmSettings(e) {
      CCM.openWidget()
    }
  },
  data() {
    return {
      isFontevo: location.toString().indexOf('fontevo.com') > 0,
      FontevoLinks: [
        {
          key: 'footer.navigation.fontevo.imprint',
          link: 'footer.navigation.fontevo.imprint-url'
        },
        {
          key: 'footer.navigation.fontevo.privacy-policy',
          link: 'footer.navigation.fontevo.privacy-policy-url'
        },
      ],
      AuLinks: [
        {
          key: 'footer.navigation.auerswald.imprint',
          link: 'footer.navigation.auerswald.imprint-url'
        },
        {
          key: 'footer.navigation.auerswald.privacy-policy',
          link: 'footer.navigation.auerswald.privacy-policy-url'
        }
      ]
    }
  },
}
</script>

<style lang="less" scoped>
@import "../../../../res/less/themes/theme";
@import "../../../../res/less/core/config/constants";

footer {
  z-index: 1;
  position: relative;
  margin-top: @space15;

  nav {
    z-index: 2;
    position: relative;
    padding: @space5 0;
    border-top: 1px solid @white-100;

    .item {
      color: @white-100;
      display: inline;

      &:not(:last-child) {
        margin-right: @space6;
      }

      span {
        cursor: pointer;
      }
    }
  }

  .wave {
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 320px;
    position: absolute;
    display: if(@displayFooterWave, block, none);
    background-size: cover;
    background: url(../../../../res/assets/waves/wave-footer.svg) no-repeat;
  }

  .footer-links {
    color: white;
  }

  @media (max-width: @phone) {
    margin-top: @space5;
  }
}

</style>
