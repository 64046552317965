export default {
	addPossibleDectServer(state) {
		state.product.possible_dect_servers = [...state.product.possible_dect_servers, null];
	},
	removePossibleDectServer(state, index) {
		state.product.possible_dect_servers = state.product.possible_dect_servers.filter((product, i) => index !== i);
	},
	updatePossibleDectServer(state, { index, value }) {
		state.product.possible_dect_servers = state.product.possible_dect_servers.map((product, i) => index === i ? value : product);
	}
};
