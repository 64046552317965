import config from "../../../../config";

export default {
    getPublicUrl(state) {
        return function (asset, { format = 'jpg', width = null, height = null, crop = null } = { format: 'jpg', width: null, height: null, crop: null }) {
            let query = '';

            if (width !== null && width !== undefined) {
                query += `dimensions[width]=${width}&`;
            }

            if (height !== null && height !== undefined) {
                query += `dimensions[height]=${width}&`;
            }

            if (crop !== null && crop !== undefined) {
                query += `dimensions[crop]=${crop}`;
            }

            return `${config.api.endpoint}/assets/${asset.uuid}.${format}?${query}`;
        }
    }
};
