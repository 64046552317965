import { product, pagination } from './templates';

export default {
	attributes: [
		{ id: 'tfe', name: 'data.product_attributes.tfe_ports' },
		{ id: 'klin', name: 'data.product_attributes.klin_ports' },
		{ id: 'to_relay', name: 'data.product_attributes.to_relays' },
		{ id: 'ts_relay', name: 'data.product_attributes.ts_relays' },
		{ id: 'relay', name: 'data.product_attributes.relays' },
		{ id: 'isdn_amt', name: 'data.product_attributes.isdn_amt' },
		{ id: 'analog_amt', name: 'data.product_attributes.pots_amt' },
		{ id: 'alarm_in', name: 'data.product_attributes.alarm' },
		{ id: 's0', name: 'data.product_attributes.s0' },
		{ id: 'up0', name: 'data.product_attributes.up0' },
		{ id: 'voip_amt', name: 'data.product_attributes.voip_amt' },
		{ id: 'voip', name: 'data.product_attributes.voip' },
		{ id: 'vmf', name: 'data.product_attributes.vmf' }
	],
	products: [],
	product: { ...product },
	short_products: [],
	pagination: { ...pagination },
	categories: [],
	subCategories: []
};
