<template>
  <div>
    <h2 class="headline headline_2">{{ pageTitle(page) }}</h2>
    <div v-html="pageBody(page)"></div>
  </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
      name: "privacy-policy",
      computed: {
          ...mapState({
              page(state) {
                  return state.page.pages.find(page => page.id === 'privacy-policy');
              }
          }),
          client() {
              const global = require('../../../config/index');
              return global.default.api.client;
          },
          language() {
              return this.$i18n.locale;
          }
      },
      methods: {
          pageTitle(page) {
              if (page) {
                  const lang = this.$i18n.locale;
                  const key = 'title_'+lang;
                  return page.title[key];
              }

              return '';
          },
          pageBody(page) {
              if (page) {
                  const lang = this.$i18n.locale;
                  const key = 'body_'+lang;
                  return page.body[key];
              }

              return '';
          },
      },
      async mounted() {
          await this.$store.dispatch('page/load');
      }
  }
</script>

<style scoped>

</style>
