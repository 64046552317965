var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"sub"},[_c('h5',[_vm._v(_vm._s(_vm.$t("offer.offer.steps.complete_offer.tabs.offer.devices")))]),_vm._v(" "),_c('data-table',{attrs:{"fields":_vm.fields,"data":_vm.positions}},[_c('tr',{attrs:{"slot":"empty"},slot:"empty"},[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.fields.length}},[_vm._v("\n        "+_vm._s(_vm.$t("offer.offer.steps.complete_offer.tabs.offer.no_products"))+"\n      ")])])]),_vm._v(" "),_c('data-table',{attrs:{"fields":_vm.foreignFields,"data":_vm.foreignDevices}},[_c('tr',{attrs:{"slot":"empty"},slot:"empty"},[_c('td',{staticClass:"text-center",attrs:{"aria-colspan":_vm.fields.length}},[_vm._v("\n        "+_vm._s(_vm.$t("offer.offer.steps.complete_offer.tabs.offer.no_products"))+"\n      ")])])]),_vm._v(" "),_c('h5',[_vm._v("\n    "+_vm._s(_vm.$t("offer.offer.steps.complete_offer.tabs.offer.modules_extensions"))+"\n  ")]),_vm._v(" "),_c('data-table',{attrs:{"fields":_vm.moduleFields,"data":_vm.components}},[_c('tr',{attrs:{"slot":"empty"},slot:"empty"},[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.fields.length}},[_vm._v("\n        "+_vm._s(_vm.$t("offer.offer.steps.complete_offer.tabs.offer.no_modules"))+"\n      ")])])]),_vm._v(" "),_c('h5',[_vm._v(_vm._s(_vm.$t("offer.offer.steps.complete_offer.tabs.offer.amt")))]),_vm._v(" "),_c('data-table',{attrs:{"fields":_vm.fields_ports,"data":_vm.ports}},[_c('tr',{attrs:{"slot":"empty"},slot:"empty"},[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.fields_ports.length}},[_vm._v("\n        "+_vm._s(_vm.$t(
            "offer.offer.steps.complete_offer.tabs.description.sections.port_assignment.no_data_found"
          ))+"\n      ")])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!this.$route.params.distributor),expression:"!!this.$route.params.distributor"}],staticClass:"grid"},[_c('div',{staticClass:"col7"}),_vm._v(" "),_c('div',{staticClass:"col3"},[_c('h5',[_vm._v(_vm._s(_vm.$t("offer.offer.steps.complete_offer.tabs.offer.total")))]),_vm._v(" "),_c('div',{staticClass:"table-wrapper"},[_c('table',[_c('tr',[_c('td',[_c('strong',[_vm._v(_vm._s(_vm.$t(
                  "offer.offer.steps.complete_offer.tabs.description.sections.total.total_net"
                )))])]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.formatPrice(
                  _vm.prices.totalNet,
                  this.$store.state["offer"].offer.region.currency
                ))+"\n            ")])]),_vm._v(" "),_c('tr',[_c('td',[_c('strong',[_vm._v(_vm._s(_vm.prices.tax)+" %\n                "+_vm._s(_vm.$t(
                    "offer.offer.steps.complete_offer.tabs.description.sections.total.tax"
                  )))])]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.formatPrice(
                  _vm.prices.tax_price,
                  this.$store.state["offer"].offer.region.currency
                ))+"\n            ")])]),_vm._v(" "),_c('tr',[_c('td',[_c('strong',[_vm._v(_vm._s(_vm.$t(
                  "offer.offer.steps.complete_offer.tabs.description.sections.total.total"
                )))])]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(_vm.formatPrice(
                  _vm.prices.total,
                  this.$store.state["offer"].offer.region.currency
                ))+"\n            ")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }