import '../res/helper/helper';
import * as deepmerge from 'deepmerge';

import App from './app.vue';
import axios from 'axios';
import config from '../config';

import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';


Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueRouter);

/**
 * @private {object} i18n
 * @private {object} stores
 * @private {array} routes
 * @private {object} modules
 * @private {VueRouter} $router
 * @private {VueI18n} $i18n
 * @private {Vue} $instance
 */
class Application {

    constructor() {
        this.i18n = {};
        this.stores = {};
        this.routes = [];
        this.modules = {};

        this.$i18n = null;
        this.$store = null;
        this.$router = null;
        this.$instance = null;

        this.registerModule('shared');
    }

    /**
     * @private
     */
    boot() {
        axios.defaults.headers.common['X-Auth-Token'] = localStorage.getItem('token') || null;
        axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('locale') || config.i18n.locale;

        for (const name in this.modules) {
            if (this.modules.hasOwnProperty(name)) {
                const module = this.modules[name];

                module.boot();
            }
        }
    }

    /**
     *
     */
    verifyDependencies() {
        for (const key in this.modules) {
            if (this.modules.hasOwnProperty(key)) {
                const module = this.modules[key];

                if (module.getDependencies && typeof module.getDependencies === "function") {
                    const dependencies = module.getDependencies();
                    const hasAllDependencies = dependencies.map(dependency => this.modules.hasOwnProperty(dependency)).reduce((a, b) => a && b, true);

                    if (!hasAllDependencies) {
                        throw new Error(`Missing dependencies for module '${key}'`);
                    }
                }
            }
        }
    }

    /**
     * @public
     * @param {string} name
     * @return {Application}
     */
    registerModule(name = '') {
        const moduleImport = require(__dirname + `/${name}/index.js`);
        const module = new moduleImport.default;

        this.i18n = deepmerge(this.i18n, module.getI18n());
        this.routes = [...this.routes, ...module.getRoutes()];
        this.stores = {...this.stores, ...module.getStores()};

        this.modules[name] = module;

        return this;
    }

    /**
     * @public
     * @return {VueI18n}
     */
    getI18n() {
        if (this.$i18n) {
            return this.$i18n;
        } else {
            const locale = localStorage.getItem('locale') || config.i18n.locale;

            return new VueI18n({
                locale,
                messages: {...this.i18n},
                fallbackLocale: config.i18n.fallbackLocale,
                silentTranslationWarn: true
            });
        }
    }

    /**
     * @return {Store<any>|null}
     */
    getStore() {
        if (this.$store) {
            return this.$store;
        } else {
            let global = {};

            if (this.stores.global) {
                global = this.stores.global;
                delete this.stores.global;
            }

            return new Vuex.Store({
                ...global,
                modules: this.stores
            });
        }
    }

    /**
     * @public
     * @return {VueRouter}
     */
    getRouter() {
        if (this.$router) {
            return this.$router;
        } else {
            return new VueRouter({mode: 'history', routes: this.routes});
        }
    }

    /**
     * @public
     * @return {null|Vue}
     */
    getInstance() {
        return this.$instance;
    }

    /**
     * @public
     * @param {string} $mount
     */
    init($mount = '#app') {
        if (!this.$instance) {
            this.verifyDependencies();

            this.$router = new VueRouter({
                mode: 'history',
                routes: this.routes,
                scrollBehavior(to, from, savedPosition) {
                    return ({x: 0, y: 0})
                }
            });
            this.$i18n = this.getI18n();
            this.$store = this.getStore();
            this.$router = this.getRouter();


            this.boot();

            this.$instance = new Vue({
                router: this.$router,
                store: this.$store,
                i18n: this.$i18n,
                render: h => h(App)
            }).$mount($mount);

            console.log(`App Environment: ${config.env.name}`);

            //clear notification on page change
            this.$router.beforeEach((to, from, next) => {
                this.$store.dispatch('notification/set', []);
                next();
            });
        }

        return this;
    }
}

export default new Application();
