<template>
  <div>
    <h3 class="headline headline_3">Regionen</h3>

    <button class="button secondary" @click="addPrice">Region hinzufügen</button>

    <dropdown-tabs ref="tabs" label="Regionen">
      <dot-menu slot="options" slot-scope="scope" :object="scope.data" :options="regionOptions"></dot-menu>
      <tab v-for="(price, index) in prices" :name="getPriceTitle(price, index)" :key="index">
        <div class="input">
          <label for="product_region">Region</label>
          <dropdown id="product_region" :data="regions" :value="price.region" @input="updatePrice(price, index, { region: $event })" placeholder="Region"></dropdown>
        </div>

        <div class="input">
          <label for="product_price">Preis</label>
          <input type="number" id="product_price" :value="price.price" @input="updatePrice(price, index, { price: $event.target.value })">
        </div>

        <div class="input">
          <label for="product_link">Produktlink</label>
          <input type="text" id="product_link" :value="price.link" @input="updatePrice(price, index, { link: $event.target.value })">
        </div>
      </tab>
    </dropdown-tabs>
  </div>
</template>

<script>
  import { wait } from "../../../res/helper/wait";
  import { mapState } from 'vuex';

  import { product as template } from "../store/product/templates";
  import Dropdown from '../../shared/components/form/dropdown';
  import DropdownTabs from "../../shared/components/tabs/dropdown-tabs";
  import Tab from "../../shared/components/tabs/tab";
  import DotMenu from "../../shared/components/modal/dot-menu";

  export default {
    name: "prices",
    components: {
      DotMenu,
      Tab,
      DropdownTabs,
      Dropdown
    },
    data() {
      return {
        regionOptions: [
          {
            title: 'actions.delete',
            callback: region => {
              this.$store.commit('product/removeRegionByTitle', { title: region.label });
            }
          }
        ]
      }
    },
    computed: {
      ...mapState({
        regions(state) {
          const regions = [...state['product/region'].regions];
          return regions.map(region => ({ value: region, label: region.title }));
        },
        prices(state) {
          if (state.product.product.regions.length > 0) {
            return [...state.product.product.regions];
          } else {
            return [...template.regions];
          }
        },
      })
    },
    methods: {
      async updatePrice(p, index, changes = {}) {
        const price = { ...p, ...changes };
        const prices = this.prices.map((p, i) => i === index ? price : p);

        this.$store.commit('product/updatePrices', prices);
      },
      async addPrice() {
        const price = {
          region: null,
          price: ''
        };

        this.$store.commit('product/addPrice', price);
        await this.updateAndSelectLast();
      },
      async updateAndSelectLast() {
        return this.asyncUpdateTabs()
          .then(tabs => tabs.length > 0 ? this.$refs.tabs.currentTab = tabs.last().id : null);
      },
      async asyncUpdateTabs() {
        const $tabs = this.$refs.tabs;

        if ($tabs) {
          await wait();
          $tabs.update();
          return Promise.resolve($tabs.tabs);
        }

        return Promise.resolve([]);
      },
      getPriceTitle(price, index) {
        if (!price.region) {
          return this.$t('product.product.tabs.information.label.price') + ' ' + (index + 1);
        }

        let regionId = price.region.uuid ? price.region.uuid : price.region;

        const region = this.regions.find(region => region.value.uuid === regionId);
        return `${region.label}`;
      }
    },
    mounted() {
      this.$store.dispatch('product/region/load', { ignore_client: true });
    }
  }
</script>

<style scoped>

</style>
