import { render, staticRenderFns } from "./matomo-tag.vue?vue&type=template&id=17cbe462&scoped=true&"
import script from "./matomo-tag.vue?vue&type=script&lang=js&"
export * from "./matomo-tag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17cbe462",
  null
  
)

export default component.exports