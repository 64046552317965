import { required_product } from "../templates";

export default {
	addRequiredProduct(state) {
		state.product.required_products = [...state.product.required_products,  { ...required_product }];
	},
	removeRequiredProduct(state, index) {
		state.product.required_products = state.product.required_products.filter((product, i) => index !== i);
	},
	updateRequiredProduct(state, { index, changes }) {
		state.product.required_products = state.product.required_products.map((product, i) => index === i ? { ...product, ...changes } : product);
	}
};
