<template>
  <div class="home">
    <section>
      <section class="sub">
        <h2 class="headline headline_2">{{ $t("manual.headline") }}</h2>
        <notifications :max="3" />
      </section>

      <section class="section-description">
        <div class="manual-page">
          <h4
            v-if="pageTitle(descriptionPage)"
            class="manual-page-title headline headline_4"
          >
            {{ pageTitle(descriptionPage) }}
          </h4>
          <div
            class="manual-page-content"
            v-html="pageBody(descriptionPage)"
          ></div>
        </div>
      </section>

      <section class="section-toc">
        <h3 class="headline headline_3">{{ $t("manual.toc_header") }}</h3>
        <ul class="toc">
          <li v-for="(page, index) in manualPages">
            <a
              class="toc-link"
              @click="scrollFix('#step_' + ++index)"
              href="javascript:return false;"
            >
              {{ pageTitle(page) }}
            </a>
          </li>
        </ul>
      </section>

      <section class="section-content">
        <div class="grid">
          <div class="col12">
            <h3 class="headline headline_3">{{ $t("manual.manual_pages") }}</h3>
          </div>
        </div>
        <div
          class="grid"
          :id="`step_${++index}`"
          v-for="(page, index) in manualPages"
        >
          <div class="col12">
            <div class="manual-page">
              <h4
                v-if="pageTitle(page)"
                class="manual-page-title headline headline_4"
              >
                {{ pageTitle(page) }}
              </h4>
              <div class="manual-page-content" v-html="pageBody(page)"></div>
              <router-link class="button primary small" :to="stepUrl(index)">{{
                $t("offer.jump_to_step")
              }}</router-link>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Notifications from "../../shared/components/notifications";
import Alert from "../../shared/components/alert";

export default {
  name: "manual",
  components: {
    Alert,
    Notifications,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      manualPages(state) {
        return state["offer/manual"].pages.filter(
          (page) => page.id !== "description"
        );
      },
      descriptionPage(state) {
        return state["offer/manual"].pages.find(
          (page) => page.id === "description"
        );
      },
    }),
    baseUrl() {
      const { region, distributor, id } = this.$route.params;

      let target = "/offer";

      if (distributor) {
        target = "/" + distributor + target;
      }

      if (region) {
        target = "/" + region + target;
      }

      return target + "/" + id;
    },
  },
  methods: {
    stepUrl(step) {
      return this.baseUrl + "/" + step;
    },
    pageTitle(page) {
      if (page) {
        const lang = this.$i18n.locale;
        const key = "title_" + lang;
        return page[key];
      }

      return "";
    },
    pageBody(page) {
      if (page) {
        const lang = this.$i18n.locale;
        const key = "body_" + lang;
        return page[key];
      }

      return "";
    },
    scrollFix: function (hashbang) {
      document.getElementById(hashbang.slice(1)).scrollIntoView();
    },
  },
  async mounted() {
    await this.$store.dispatch("offer/manual/load");

    if (this.$route.hash) {
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../../res/less/core/config/constants";
@import "../../../res/less/themes/theme";

.section-toc {
  border-bottom: 1px solid @black-40;
}
.toc li {
  line-height: 2em;
}

.manual-page {
  margin-bottom: 40rem;
}
</style>
