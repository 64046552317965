<template>
  <div class="contact-person-wrapper" v-if="contact">
    <div class="content">
      <h4 class="headline headline_4">{{ $t("content.dashboard.my_information.contact_person.headline") }}</h4>

      <div class="image">
        <img v-if="image" class="no-margin" :src="image" :alt="contact.full_name">
      </div>

      <div class="contact">
        <b>{{ contact.full_name }}</b> <br>
        {{ contact.phone }} <br>
        {{ contact.email }} <br>
      </div>
    </div>

    <div class="wave"></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: "contact-person",
    data() {
      return {
        imageDimensions: {
          height: 80,
          width: 80
        }
      }
    },
    computed: {
      ...mapState({
        contact(state) {
          return state.profile.profile.service_contact;
        },
        image(state) {
          const contact = state.profile.profile.service_contact;

          return contact.picture ? this.$store.getters['asset/getPublicUrl'](contact.picture, this.imageDimensions) : null;
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";
  @import "../../../res/less/core/config/constants";

  .contact-person-wrapper {
    padding: @space3;
    position: relative;
    overflow: hidden;
    background-color: @brand-3-100;

    .content {
      position: relative;
      z-index: 2;

      .headline, .contact {
        color: @white-100;
      }

      .image, .contact {
        display: inline-block;
      }

      .image img {
        border-radius: 100%;
      }

      .contact {
        vertical-align: top;
        margin-left: @space3;
        line-height: @space3;
        font-size: @fontSize;
      }
    }

    .wave {
      z-index: 1;
      width: 1920px;
      height: 916px;
      top: -282px;
      left: -1120px;
      position: absolute;
      background: url(../../../res/assets/waves/wave-content.svg);
    }
  }
</style>
