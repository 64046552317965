import axios from "axios";
import config from "../../../../config";

const hooks = {
    async create(payload) {
        const contact = { ...payload.contact };

        if (contact.picture) {
            contact.picture = contact.picture.uuid;
        }

        if (contact.resellers) {
            contact.resellers = contact.resellers.map(reseller => reseller.uuid);
        }

        return { contact };
    },
    async update(payload) {
        const contact = { ...payload.contact };

        if (contact.picture) {
            contact.picture = contact.picture.uuid;
        }

        if (contact.resellers) {
            contact.resellers = contact.resellers.map(reseller => reseller.uuid);
        }

        return { contact };
    }
};

export default {
    async load({ commit }, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/service-contacts`, { params });
            const data = response.data;

            commit('loaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadMore({ commit, state }, params = {}) {
        params = { ...state.pagination, ...params };

        if (!params.page) {
            params.page = params.current_page + 1;
        }

        try {
            const response = await axios.get(`${config.api.endpoint}/service-contacts`, { params });
            const data = response.data;

            commit('loadedMore', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadOne({ commit }, { id, params = {} }) {
        try {
            const response = await axios.get(`${config.api.endpoint}/service-contacts/${id}`, { params });
            const data = response.data;

            commit('loadedOne', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async update({ commit }, { id, payload, params = {} }) {
        let p = await hooks.update({ ...payload });

        try {
            const response = await axios.put(`${config.api.endpoint}/service-contacts/${id}`, p, { params });
            const data = response.data;

            commit('updated', { response: data, data: payload });

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async delete({ commit }, { id, params = {} }) {
        try {
            const response = await axios.delete(`${config.api.endpoint}/service-contacts/${id}`, { params });
            const data = response.data;

            commit('deleted', { id, response: data });

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async create({ commit }, { payload, params = {} }) {
        let p = await hooks.create({ ...payload });

        try {
            const response = await axios.post(`${config.api.endpoint}/service-contacts`, p, { params });
            const data = response.data;

            commit('created', { response: data, data: payload });

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
};
