<template>
  <div class="tabs">
    <div class="header">
      <div class="tab-name"
           v-for="tab in tabs"
           :style="{ width: (100 / tabs.length) + '%' }"
           :class="{ disabled: tab.disabled, active: tab.active }"
           @click="selectTab(tab.id)">
        <i v-if="tab.language" class="flag" :class="tab.language"></i>
        {{ tab.name }}
      </div>
    </div>
    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import Tabs from './tabs';

  export default {
    extends: Tabs,
    name: "language-tabs",
    mounted() {
      this.useTimeout = false;
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";

  .tabs {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: @black-05;
      border-top-left-radius: @borderRadiusTab;
      border-top-right-radius: @borderRadiusTab;

      .tab-name {

        &:first-child {
          border-top-left-radius: @borderRadiusTab;
        }

        &:last-child {
          border-top-right-radius: @borderRadiusTab;
        }

        cursor: pointer;
        border: 1px solid @black-05;
        color: @black-65;
        padding: @space2;
        text-align: center;

        &.active {
          color: @white-100;
          background-color: @brand-1-100;
          border-color: @brand-1-100;
        }

        &.disabled {
          color: @black-40;
        }

        &:hover:not(.active) {
          color: @brand-1-100;
          border-bottom: 1px solid @brand-1-100;
        }
      }
    }

    .body {
      background-color: @black-05;
      padding: @space3 @space3 0 @space3;
    }
  }

  .flag {
    width: 16px;
    height: 12px;

    &.de {
      .icon('flags/flag-de');
    }
    &.en {
      .icon('flags/flag-en');
    }
    &.fr {
      .icon('flags/flag-fr');
    }
  }
</style>
