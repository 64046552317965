import axios from 'axios/index';

export default {
    setToken(state, tokenResponse) {
        state.token = tokenResponse;
        if (typeof (tokenResponse) === 'object') {
            tokenResponse = JSON.stringify(tokenResponse);
        }
        localStorage.setItem('token', tokenResponse);
        axios.defaults.headers.common['X-Auth-Token'] = tokenResponse;
    },
    unsetDistributor(state, token) {
        localStorage.removeItem('distributor');
    }
};
