<template>
  <div>
    <section class="sub icon-headline">
      <h3 class="headline headline_3" data-index="4">{{ $t('offer.offer.steps.functions.headline') }}</h3>
      <router-link :class="'icon help '+client()" :to="baseUrl()+'/manual#step_4'"></router-link>
    </section>

    <section class="sub">
      <div class="grid">
        <div class="col5">
          <div class="input" v-for="(item, index) in systemFunctions">
            <span v-if="item.type === 'number'">
              <label :for="`system_functions_${index}`">{{item.title}}</label>
              <input type="number" min="0" :id="`system_functions_${index}`" :value="systemFunctionValue(item)" @change="handleSystemFunction($event, item)">
            </span>
            <span v-else>
              <checkbox :value="systemFunctionValue(item)" @change="handleSystemFunction($event, item)">
                {{item.title}}
              </checkbox>
            </span>
          </div>
        </div>
      </div>
    </section>

    <button class="button primary" @click="save">{{ $t("offer.offer.next_step", ['5', '6']) }}</button>
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields';

  import TabChild from '../../shared/components/tabs/tab-child';
  import Checkbox from "../../shared/components/form/checkbox";

  export default {
    extends: TabChild,
    name: "functions",
    components: {
      Checkbox
    },
    computed: {
      ...mapFields('offer', {
        system_function_requirements: 'offer.system_function_requirements'
      }),
      systemFunctions: function() {
        return this.$store.state.offer.systemFunctions || [];
      }
    },
    methods: {
      client() {
          const global = require('../../../config/index');
          return global.default.api.client;
      },
      baseUrl() {
          const { region, distributor, id } = this.$route.params;

          let target = '/offer';

          if (distributor) {
              target = '/' + distributor + target;
          }

          if (region) {
              target = '/' + region + target;
          }

          return target + '/' + id;
      },
      async calculateVmf() {
          const fax = this.fax_boxes;
          const mail = this.mail_boxes;
          const vmf = this.vmf_channels;

          if ((fax > 0 || mail > 0) && vmf <= 0) {
            this.vmf_channels = 1;
          }
      },
      async save(navigate = true) {
        const offer = { ...this.$store.state.offer.offer };
        await this.$store.dispatch('offer/update', { id: offer.uuid, payload: { offer } });

        if (navigate) {
          this.nextTab();
        }
      },
      async saveAndClose() {
        await this.save(false);

        this.$router.push('/offer');
      },
      async discard() {
        const $tab = this.$parent;
        const $tabs = $tab.$parent;
        const $page = $tabs.$parent;

        await $page.discard();
      },
      async handleSystemFunction(event, item) {
        const offer = { ...this.$store.state.offer.offer };
        await this.$store.commit('offer/handleSystemFunctionRequirement', { id: offer.uuid, systemFunction: item, event: event });
      },
      systemFunctionValue(item) {
        let requirement = this.system_function_requirements.find(req => req.system_function === item.uuid);

        if (requirement) {
          return requirement.min;
        } else {
          return 0;
        }
      }
    },
    async mounted() {
      const offer = { ...this.$store.state.offer.offer };
      await this.$store.dispatch('offer/getSystemFunctions', { id: offer.uuid });
    }
  }
</script>

<style scoped>

</style>
