export const reseller = {
	title: '',
	accounts: [],
	reseller_number: '',
	phone: '',
	fax: '',
	email: '',
	website: '',
	company: '',
	street: '',
	zip: '',
	city: '',
	country: '',
	logo: '',
	username: '',
	service_contact: null,
	region: null,
	role: '',
	distributor_alias: '',
	distributor_secret: '',
	password: ''
};

export const pagination = {
	current_page: 0,
	limit: null,
	pages: null,
	max_count: null,
	next_page: null
};
