import uuid4 from 'uuid4';
import { requirementCollection, requirementCollectionItem } from "../templates";

export default {
	addRequirementCollection(state) {
		state.product.only_with_collections = [
			...state.product.only_with_collections,
			{ ...requirementCollection, id: uuid4() }
		];
	},
	removeRequirementCollection(state, collectionId) {
		state.product.only_with_collections = state.product.only_with_collections.filter(
			collection => String(collection.id) !== String(collectionId)
		);
	},
	updateRequirementCollectionNames(state) {
		state.product.only_with_collections = state.product.only_with_collections.map(collection => {
			const name = collection.only_with
				.map(item => {
					const product = this.getters['product/getProductByUuid'](item.only_with_product);

					return product ? `${product.title} (${item.minimum})` : '';
				})
				.filter(name => name)
				.join(', ');

			return { ...collection, name };
		});
	},
	addRequirementCollectionItem(state, collectionId) {
		let collection = state.product.only_with_collections.find(collection => collection.id === collectionId);
		collection.only_with = [...collection.only_with, { ...requirementCollectionItem }];
		state.product.only_with_collections = state.product.only_with_collections.map(c => c.id === collection.id ? collection : c);
	},
	removeRequirementCollectionItem(state, { collectionId, itemIndex }) {
		let collection = state.product.only_with_collections.find(collection => collection.id === collectionId);
		collection.only_with = collection.only_with.filter((only_with, i) => i !== itemIndex);
		state.product.only_with_collections = state.product.only_with_collections.map(c => c.id === collection.id ? collection : c);
	},
	updateRequirementCollectionItem(state, { collectionId, itemIndex, changes }) {
		let collection = state.product.only_with_collections.find(collection => collection.id === collectionId);
		let item = collection.only_with[itemIndex];

		if (item) {
			item = { ...item, ...changes };

			collection.only_with = collection.only_with.map((only_with, index) => index === itemIndex ? item : only_with);
		}

		state.product.only_with_collections = state.product.only_with_collections.map(c => c.id === collection.id ? collection : c);
	}
};
