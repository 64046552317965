<template>
  <div class="create-edit-code-modal inline">
    <div @click="$refs.modal.open()">
      <slot/>
    </div>

    <modal ref="modal">
      <div class="import-modal-body">

        <h3 class="headline headline_3">{{ $t("offer.modal.generate_edit_code.headline") }}</h3>

        <alert level="success" v-if="code" :title="$t('offer.modal.generate_edit_code.code_generated.title')">
          <div v-html="$t('offer.modal.generate_edit_code.code_generated.body', { code })"></div>
          <br/>
          <a :href="$t('offer.modal.generate_edit_code.formular_link.url',{code})"
             v-html="$t('offer.modal.generate_edit_code.formular_link.text')"></a>
        </alert>

        <div class="input">
          <label for="edit-code-email">{{ $t("offer.modal.generate_edit_code.email") }}</label>
          <input id="edit-code-email" type="email" v-model="email" :disabled="code"/>
        </div>

        <div>
          <button class="button primary small inline" @click="generateCode()" :disabled="code || !email">
            {{ $t("offer.modal.generate_edit_code.buttons.generate") }}
          </button>
          <button class="button secondary small inline" @click="close()">
            {{ $t("offer.modal.generate_edit_code.buttons.cancel") }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '../../shared/components/modal/modal';
import FileUpload from '../../shared/components/form/file-upload';
import Alert from "../../shared/components/alert";
import Notifications from "../../shared/components/notifications";

export default {
  name: "create-edit-code-modal",
  components: {
    Notifications,
    Alert,
    Modal,
    FileUpload
  },
  data() {
    return {
      email: '',
      code: null,
    }
  },
  props: {
    offerId: {
      type: String
    }
  },
  methods: {
    async generateCode() {
      this.code = await this.$store.dispatch('offer/generateEditCode', {id: this.offerId, email: this.email});
    },
    close() {
      this.code = null;
      this.email = '';
      this.$refs.modal.close()
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../res/less/themes/theme";
@import "../../../res/less/core/config/constants";

.import-modal-body {
  padding: @space5 @space5 calc(@space5 - @standard-space) @space5;
  min-width: 400px;
  background-color: @white-100;

  .file-upload-label {
    margin-bottom: @space1;
    color: @brand-1-100;
  }
}

@media (max-width: @phone) {
  .inline {
    width: 100%;
  }
}
</style>
