import { uniq } from "underscore";
import { updateField } from "vuex-map-fields";

export default {
	async loaded(state, data) {
		state.pages = uniq([...data.data], 'id');
		state.loaded = true;
	},
	async select(state, id) {
		if (state.pages) {
			const page = state.pages.find(page => page.id === id);
			if (page) {
				state.page = { ...page };
			}
		}
	},
	updateField
};
