export default {
    addIncompatibleWithPbxItem(state) {
        state.product.incompatible_with_pbxs = [
            ...state.product.incompatible_with_pbxs,
            {...{id: uuid4(), product: null}}
        ];
    },
    removeIncompatibleWithPbxItem(state, data) {
        state.product.incompatible_with_pbxs = state.product.incompatible_with_pbxs.filter(
            item => String(item.id) !== String(data.itemId)
        );
    },
    updateIncompatibleWithPbxCollectionItem(state, {itemId, itemIndex, changes}) {
        let item = state.product.incompatible_with_pbxs.find(x => x.id === itemId);
        if (item) {
            item = {...item, ...changes};
            state.product.incompatible_with_pbxs = state.product.incompatible_with_pbxs
                .map((x, index) => index === itemIndex ? item : x);
        }
    }
}
