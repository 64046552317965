import AdminGuard from "../../shared/router/guard/admin";

import ListFaqComponent from '../views/list';
import UpdateFaqComponent from '../views/update';

export default [
	{
		path: '/admin/faq',
		component: ListFaqComponent,
		meta: {
			title: 'faq.routes.list'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/faq/add',
		component: UpdateFaqComponent,
		meta: {
			title: 'faq.routes.add'
		},
		beforeEnter: AdminGuard
	},
];
