<template>
  <section>
    <h2 class="headline headline_2">Systemfunktionen</h2>

    <notifications :max="3" />

    <section class="sub">
      <router-link
        to="/admin/system_functions/add"
        class="button primary"
        tag="button"
        >Neue Systemfunktion</router-link
      >

      <!--
      <div class="search">
        <input type="text" placeholder="Suche">
      </div>--->

      <div>
        <vue-element-loading :active="loading" spinner="bar-fade-scale" />

        <data-table :fields="fields" :data="system_functions">
          <tr slot="empty">
            <td :colspan="fields.length" class="text-center">
              Es wurden keine Systemfunktionen gefunden.
            </td>
          </tr>
          <div class="inline right" slot="last-col" slot-scope="{ row }">
            <dot-menu :object="row" :options="options"></dot-menu>
          </div>
        </data-table>
      </div>
    </section>

    <button class="button secondary disabled" disabled>Mehr laden</button>
  </section>
</template>

<script>
import { mapState } from "vuex";
import DotMenu from "../../shared/components/modal/dot-menu";
import DataTable from "../../shared/components/table/table";
import Notifications from "../../shared/components/notifications";
import VueElementLoading from "vue-element-loading";

export default {
  name: "system_functions",
  components: {
    Notifications,
    DotMenu,
    DataTable,
    VueElementLoading,
  },
  data() {
    return {
      loading: false,
      fields: [
        { label: "system_function.system_functions.table.title", key: "title" },
        { label: "system_function.system_functions.table.type", key: "type" },
        {
          label: "system_function.system_functions.table.selection_type",
          key: "selection_type",
        },
      ],
      options: [
        {
          title: "actions.edit",
          callback: (system_function) =>
            this.$router.push(
              `/admin/system_functions/${system_function.uuid}`
            ),
        },
        {
          title: "actions.delete",
          callback: (system_function) =>
            this.$store.dispatch("systemFunctions/delete", {
              id: system_function.uuid,
            }),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      system_functions(state) {
        const system_functions = [...state.systemFunctions.system_functions];

        return system_functions.map((system_function) => ({
          ...system_function,
          id: system_function.uuid,
        }));
      },
    }),
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("systemFunctions/load");
    this.loading = false;
  },
};
</script>

<style scoped>
</style>
