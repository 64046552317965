<template>
  <section>
    <h2 class="headline headline_2">Händler</h2>

    <router-link tag="button" class="button primary" to="/admin/reseller/add"
      >Händler hinzufügen</router-link
    >

    <notifications :max="3" />

    <div class="search">
      <input type="text" v-model="search" placeholder="Suche" />
    </div>

    <section class="sub">
      <vue-element-loading :active="loading" spinner="bar-fade-scale" />

      <accordion v-for="(reseller, index) of resellers" :key="index">
        <template slot="header">
          {{ reseller.title }}, {{ reseller.reseller_number }}

          <div class="inline right">
            <dot-menu :options="options" :object="reseller" />
          </div>
        </template>

        <div slot="body" class="grid">
          <div :class="reseller.service_contact ? 'col3' : 'col4'">
            <h4 class="headline headline_4">Adresse</h4>

            <p>
              {{ reseller.company }} <br />
              {{ reseller.street }} <br />
              {{ reseller.zip }} {{ reseller.city }}
            </p>
          </div>
          <div :class="reseller.service_contact ? 'col3' : 'col4'">
            <h4 class="headline headline_4">Kontaktdaten</h4>

            <p>
              {{ reseller.title }} <br />
              Telefon: {{ reseller.phone }} <br />
              Fax: {{ reseller.fax }} <br />
              E-Mail: {{ reseller.email }} <br />
              Website: {{ reseller.website }} <br />
            </p>
          </div>
          <div
            v-if="reseller.service_contact"
            :class="reseller.service_contact ? 'col3' : 'col4'"
          >
            <h4 class="headline headline_4">Ansprechpartner</h4>

            <p>
              {{ reseller.service_contact.full_name }}<br />
              Telefon: {{ reseller.service_contact.phone }} <br />
              E-Mail: {{ reseller.service_contact.email }} <br />
            </p>
          </div>
          <div :class="reseller.service_contact ? 'col3' : 'col4'">
            <h4 class="headline headline_4">Konto</h4>

            <p v-for="(account, index) of reseller.accounts" :key="index">
              Benutzername: {{ account.username }} <br />
              Passwort: ********** <br />
            </p>
          </div>
        </div>
      </accordion>
    </section>

    <button class="button secondary" v-if="hasMore" @click="loadMore">
      Mehr laden
    </button>
  </section>
</template>

<script>
import { wait } from "../../../res/helper/wait";
import { mapState } from "vuex";
import Accordion from "../../shared/components/accordion";
import DotMenu from "../../shared/components/modal/dot-menu";
import VueElementLoading from "vue-element-loading";
import Notifications from "../../shared/components/notifications";

export default {
  name: "resellers",
  components: {
    Notifications,
    DotMenu,
    Accordion,
    VueElementLoading,
  },
  data() {
    return {
      search: "",
      limit: 15,
      loading: false,
      options: [
        {
          title: "actions.edit",
          callback: (reseller) =>
            this.$router.push(`/admin/reseller/${reseller.uuid}`),
        },
        {
          title: "actions.delete",
          callback: (reseller) =>
            this.$store.dispatch("reseller/delete", { id: reseller.uuid }),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      resellers(state) {
        const resellers = [...state.reseller.resellers];

        return resellers
          .map((reseller) => ({ ...reseller, id: reseller.uuid }))
          .filter((reseller) => {
            const search = this.search.toLowerCase();
            const constraints = [
              reseller.title.toLowerCase().includes(search),
              reseller.company.toLowerCase().includes(search),
            ];

            return constraints.reduce(
              (ruleMatches, matches) => ruleMatches || matches
            );
          });
      },
      hasMore(state) {
        return !!state.reseller.pagination.next_page;
      },
    }),
  },
  methods: {
    async loadMore() {
      await this.$store.dispatch("reseller/loadMore");
    },
  },
  watch: {
    async search(search) {
      await wait(1000);

      if (search === this.search) {
        let params = search
          ? { search, limit: this.limit }
          : { limit: this.limit };

        this.$store.commit("reseller/resetPagination");
        await this.$store.dispatch("reseller/load", params);
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("reseller/load", { limit: this.limit });
    this.loading = false;
  },
};
</script>

<style scoped>
</style>
