<template>
  <div>
    <section class="sub icon-headline">
      <h3 class="headline headline_3" data-index="3">
        {{ $t("offer.offer.steps.accessory.headline") }}
      </h3>
      <router-link
        :class="'icon help ' + client()"
        :to="baseUrl() + '/manual#step_3'"
      ></router-link>
    </section>

    <cart data-update-accessory/>

    <section class="sub">
      <h4 class="headline headline_4">
        3.1 {{ $t("offer.offer.steps.accessory.select_products") }}
      </h4>

      <div class="search">
        <input type="text" :placeholder="$t('search')" v-model="search"/>
      </div>

      <div>
        <vue-element-loading :active="loading" spinner="bar-fade-scale"/>

        <data-table :fields="fields" :data="products">
          <div
            slot="last-col"
            slot-scope="{ row }"
            class="inline add-to-cart"
            style="line-height: 2;">
            <i
              class="table-action-icon fa fa-plus-square fa-lg"
              :title="$t('offer.add_to_cart')"
              @click="addToCart(row)"
            ></i>
          </div>
          <tr slot="empty">
            <td :colspan="fields.length" class="text-center">
              {{ $t("offer.offer.steps.shop.no_articles") }}
            </td>
          </tr>
        </data-table>
      </div>

      <button class="button secondary" v-if="hasMoreProducts" @click="loadMore">
        {{ $t("actions.load_more") }}
      </button>
    </section>

    <button class="button primary" @click="save">
      {{ $t("offer.offer.next_step", ["4", "6"]) }}
    </button>
  </div>
</template>

<script>
import {wait} from "../../../res/helper/wait";
import {mapState} from "vuex";
import TabChild from "../../shared/components/tabs/tab-child";
import DataTable from "../../shared/components/table/table";
import DotMenu from "../../shared/components/modal/dot-menu";
import VueElementLoading from "vue-element-loading";
import Cart from "./shop/cart";

export default {
  extends: TabChild,
  name: "accessory",
  components: {
    Cart,
    DotMenu,
    DataTable,
    VueElementLoading,
  },
  data() {
    return {
      limit: 10,
      loading: false,
      search: "",
      fields: [
        {label: "offer.products.table.id", key: "article_number"},
        {
          label: "offer.products.table.name",
          key: "title",
          type: "mixed-multiple-check",
          checkProp: "selectable_system_functions",
          checkPropCallback: (checked, item, field, row) =>
            this.$store.commit("offer/updateProductSelectableSystemFunction", {
              product: row,
              item: item,
              checked: checked,
            }),
          hint: (product) => product.product_hint,
        },
        {
          label: "offer.products.table.category",
          key: "category.key",
          transform: (key) => this.$t("offer.category." + key),
        },
        {label: "offer.products.table.color", key: "color", default: ""},
        {
          label: "offer.products.table.hek",
          key: "price.hek",
          transform: (price, product) => {
            const currency = this.$store.state["offer"].offer.region.currency;
            return this.formatPrice(price, product.price ? currency : null);
          },
          renderCondition: () => !!this.$route.params.distributor,
        },
        // { label: 'offer.offer.products.table.uvp', key: 'price.uvp', transform: (price, product) => this.formatPrice(price, product.price ? product.price.currency : null) },
        {
          label: "offer.products.table.image",
          key: "image",
          transform: (image) => {
            if (!image || !image.uuid) {
              return "";
            }

            const url = this.$store.getters["asset/getPublicUrl"](image, {
              height: 50,
              width: 50,
            });

            return `<img alt="product image" class="no-margin" src="${url}">`;
          },
          sortable: false,
        },
        {
          label: "offer.products.table.amount",
          type: "input:number",
          key: "amount",
          options: {
            min: 1,
            classList: ["product-amount-input"]
          },
          sortable: false,
          callback: ({value, row}) =>
            this.$store.commit("offer/updateProductAmount", {
              product: row,
              amount: value,
            }),
        }
      ],
    };
  },
  computed: {
    ...mapState({
      products(state) {
        const products = [...state.offer.possibleProducts];
        const search = this.search.toLowerCase();

        return products
          .map((product) => ({...product, id: product.uuid}))
          .filter((product) => {
            const constraints = [
              product.title.toLowerCase().includes(search),
              product.category
                ? product.category.title.toLowerCase().includes(search)
                : false,
              product.article_number.toLowerCase().includes(search),
            ];

            return constraints.reduce((m, matching) => m || matching);
          });
      },
      hasMoreProducts(state) {
        return !!state.offer.possibleProductsPagination.next_page;
      },
    }),
  },
  methods: {
    client() {
      const global = require("../../../config/index");
      return global.default.api.client;
    },
    baseUrl() {
      const {region, distributor, id} = this.$route.params;

      let target = "/offer";

      if (distributor) {
        target = "/" + distributor + target;
      }

      if (region) {
        target = "/" + region + target;
      }

      return target + "/" + id;
    },
    async save(navigate = true) {
      const offer = {...this.$store.state.offer.offer};

      await this.$store.dispatch("offer/update", {
        id: offer.uuid,
        payload: {offer},
      });

      if (navigate) {
        this.nextTab();
      }
    },
    async saveAndClose() {
      await this.save(false);

      this.$router.push("/offer");
    },
    async addToCart(product) {
      this.loading = true;
      const id = this.$store.state.offer.offer.uuid;

      const position = {
        product: product.uuid,
        price: product.price,
        exists: false,
        amount: product.amount,
        port: product.port,
        selected_system_functions: product.selectedSystemFunctions
          ? product.selectedSystemFunctions
          : [],
      };

      await this.$store.dispatch("offer/addPosition", {
        id,
        position,
      });
      this.$store.commit("offer/updateProductPort", {product, port: null});
      await this.updatePossibleProducts({reset: true});
    },
    async discard() {
      const $tab = this.$parent;
      const $tabs = $tab.$parent;
      const $page = $tabs.$parent;

      await $page.discard();
    },
    loadMore() {
      return this.updatePossibleProducts({reset: false});
    },
    async updatePossibleProducts({reset = false, params = {}}) {
      this.loading = true;
      const id = this.$store.state.offer.offer.uuid;

      if (reset) {
        this.$store.commit("offer/resetProductPagination");
      }

      await this.$store.dispatch("offer/loadPossibleProducts", {
        id,
        params: {limit: this.limit, category: "ACCESSORY", ...params},
      });
      this.loading = false;
    },
    formatPrice(price = 0, currency = "EUR") {
      const locale = this.$i18n.locale;

      if (!currency) {
        currency = "EUR";
      }

      return Number(price).toLocaleString(locale, {
        style: "currency",
        currency,
      });
    },
  },
  watch: {
    async search(search) {
      await wait(1000);

      if (search === this.search) {
        let params = search ? {search} : {};

        this.updatePossibleProducts({params, reset: true});
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../res/less/core/config/constants";

@media (max-width: @landscape) {
  .add-to-cart {
    float: right;
  }
}
</style>
