<template>
  <section>
    <h2 class="headline headline_2">
      {{ $t("service-contact.list.headline") }}
    </h2>

    <notifications :max="3" />

    <section class="sub">
      <router-link
        to="/admin/contact/add"
        class="button primary"
        tag="button"
        >{{ $t("service-contact.list.add") }}</router-link
      >

      <div class="search">
        <input type="text" :placeholder="$t('search')" />
      </div>

      <data-table :fields="fields" :data="contacts">
        <tr slot="empty">
          <td :colspan="fields.length" class="text-center">
            {{ $t("service-contact.list.not_found") }}
          </td>
        </tr>
        <div class="inline right" slot="last-col" slot-scope="{ row }">
          <dot-menu :object="row" :options="options"></dot-menu>
        </div>
      </data-table>
    </section>

    <button class="button secondary disabled" disabled>
      {{ $t("actions.load_more") }}
    </button>
  </section>
</template>

<script>
import { mapState } from "vuex";
import DotMenu from "../../shared/components/modal/dot-menu";
import DataTable from "../../shared/components/table/table";
import Notifications from "../../shared/components/notifications";

export default {
  name: "contacts",
  components: {
    Notifications,
    DotMenu,
    DataTable,
  },
  data() {
    return {
      fields: [
        {
          label: "service-contact.list.table.image",
          key: "picture",
          transform: (picture) => {
            if (!picture || !picture.uuid) {
              return "";
            }

            const url = this.$store.getters["asset/getPublicUrl"](picture, {
              width: 50,
              height: 50,
            });

            return `<img class="no-margin" src="${url}">`;
          },
          sortable: false,
        },
        {
          label: "service-contact.list.table.name",
          key: "forename",
          transform: (name, contact) =>
            `${contact.forename} ${contact.surname}`,
        },
        { label: "phone", key: "phone" },
        { label: "service-contact.list.table.email", key: "email" },
        {
          label: "service-contact.list.table.reseller",
          key: "resellers",
          transform: (resellers) => resellers.length,
        },
      ],
      options: [
        {
          title: "actions.edit",
          callback: (contact) =>
            this.$router.push(`/admin/contact/${contact.uuid}`),
        },
        {
          title: "actions.delete",
          callback: (contact) =>
            this.$store.dispatch("service-contact/delete", {
              id: contact.uuid,
            }),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      contacts(state) {
        const contacts = [...state["service-contact"].contacts];

        return contacts.map((contact) => ({ ...contact, id: contact.uuid }));
      },
    }),
  },
  mounted() {
    this.$store.dispatch("service-contact/load");
  },
};
</script>

<style scoped>
</style>
