import axios from "axios";
import config from "../../../../config";
import DownloadService from "../../../shared/service/download";

const hooks = {
    async create(payload) {
        let product = {...payload.product};

        if (product.category) {
            product.category = product.category.key;
        }

        if (product.sub_category) {
            product.sub_category = product.sub_category.key;
        }

        if (product.image) {
            product.image = product.image.uuid;
        }

        if (product.regions) {
            product.regions = product.regions.filter(price => !!price.region).map(price => {
                if (price.region && typeof price.region === "object") {
                    price.region = price.region.uuid;
                }

                return price;
            });
        }

        return {product};
    },
    async update(payload) {
        let product = {...payload.product};

        if (product.category) {
            product.category = product.category.key;
        }

        if (product.sub_category) {
            product.sub_category = product.sub_category.key;
        }

        if (product.image) {
            product.image = product.image.uuid;
        }

        if (product.regions) {
            product.regions = product.regions.map(price => {
                if (typeof price.region === "object") {
                    price.region = price.region.uuid;
                }

                return price;
            });
        }

        return {product};
    }
};

export default {
    async load({commit}, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/products`, {params});
            const data = response.data;

            commit('loaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadMore({commit, state}, params = {}) {
        params = {...state.pagination, ...params};

        if (!params.page) {
            params.page = params.current_page + 1;
        }

        try {
            const response = await axios.get(`${config.api.endpoint}/products`, {params});
            const data = response.data;

            commit('loadedMore', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadOne({commit}, {id, params = {}}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/products/${id}`, {params});
            const data = response.data;

            commit('loadedOne', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async update({commit}, {id, payload, params = {}}) {
        let p = hooks.update && typeof hooks.update === "function" ? await hooks.update({...payload}) : payload;
        this.dispatch('notification/set', {level: 'success', title: 'saving'});

        try {
            const response = await axios.put(`${config.api.endpoint}/products/${id}`, p, {params});
            const data = response.data;

            commit('updated', {response: data, data: payload});

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async delete({commit}, {id, params = {}}) {
        try {
            const response = await axios.delete(`${config.api.endpoint}/products/${id}`, {params});
            const data = response.data;

            commit('deleted', {id, response: data});

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async duplicate({commit}, {id}) {
        try {
            const response = await axios.post(`${config.api.endpoint}/products/${id}`);
            const data = response.data;

            commit('duplicated', {response: data});

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async create({commit}, {payload, params = {}}) {
        let p = hooks.create && typeof hooks.create === "function" ? await hooks.create({...payload}) : payload;

        try {
            const response = await axios.post(`${config.api.endpoint}/products`, p, {params});
            const data = response.data;

            commit('created', {response: data, data: payload});

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadShort({commit}, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/products.short`, {params});
            const data = response.data;

            commit('loaded_short', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async loadCategories({commit}, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/products/categories`, {params});
            const data = response.data;

            if (params.parent) {
                commit('loaded_product_subcategories', data);
            } else {
                commit('loaded_product_categories', data);
            }
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async import({state}, {file, region}) {
        const payload = new FormData();

        payload.append('file', file);
        payload.append('region', region);

        try {
            const headers = {'Content-Type': 'multipart/form-data'};
            const response = await axios.post(`${config.api.endpoint}/products/import`, payload, {headers});
            const data = response.data;

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            });
        } catch (e) {
            this.dispatch('notification/fromRequestException', e);
        }
    },
    async export({state}, {region, name}) {
        try {
            const date = new Date();
            await DownloadService.downloadFileByUrl(`${config.api.endpoint}/products/export?region=${region}`, name);
        } catch (e) {
            this.dispatch('notification/fromRequestException', e);
        }
    }
};
