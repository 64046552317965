import { uniq } from 'underscore';
import { contact } from "./template";
import { updateField } from "vuex-map-fields";

export default {
    async loaded(state, data) {
        state.contacts = uniq([...data.contacts], 'uuid');
    },
    async loadedOne(state, data) {
        state.contacts = uniq([...state.contacts, data.contact], 'uuid');
    },
    async updated(state, { data }) {
        state.contact = { ...state.contact, ...data.contact };
        state.contacts = state.contacts.map(contact => contact.uuid === state.contact.uuid ? ({ ...state.contact }) : contact);
    },
    async created(state, { data, response }) {
        state.contact = { ...state.contact, ...data.contact, uuid: response.contact.uuid };
        state.contacts = uniq([...state.contacts, { ...state.contact}], 'uuid');
    },
    async deleted(state, { response, id }) {
        state.contacts = state.contacts.filter(contact => contact.uuid !== id);
    },
    async reset(state) {
        state.contact = { ...contact };
    },
    async select(state, id) {
        if (state.contacts) {
            const contact = state.list.find(contact => contact.uuid === id);

            if (contact) {
                state.contact = { ...contact }; // TODO(soerenuhrbach): hydrate contact
            }
        }
    },
    updateField
};
