<template>
  <div class="inline dot-menu" tabindex="1" @blur="close()" >
    <div @click.stop="toggle" class="reset toggle"></div>

    <ul v-show="shown" class="options" @click.stop="noop">
      <li v-for="(option, index) in options" :key="index" @click.stop="execute(option)">{{ $t(option.title) }}</li>
    </ul>
  </div>
</template>

<script>
  import Modal from './modal';

  export default {
    name: "dot-menu",
    components: {
      Modal
    },
    props: {
      options: {
        type: Array,
        default: [],
        validator(value) {
          if (value.length === 0) {
            return true;
          }

          return value
            .map(value => value.hasOwnProperty('title') && value.hasOwnProperty('callback'))
            .reduce((a, b) => a && b);
        }
      },
      object: Object
    },
    data() {
      return {
        shown: false
      }
    },
    methods: {
      show() {
        this.shown = true;
      },
      close() {
        this.shown = false;
      },
      toggle() {
        this.shown = !this.shown;
      },
      noop() {
      },
      execute(option) {
        option.callback(this.object);
        this.close();
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";
  @import "../../../../res/less/core/config/mixins";
  @import "../../../../res/less/core/config/constants";

  .dot-menu {
    position: relative;

    .toggle {
      height: @space2;
      width: @space2;
      float: right;
      cursor: pointer;

      &:before {
        content: "";
        width: 4rem;
        height: 16rem;
        .icon('more/more-filled');
        background-size: initial;
      }
    }

    ul {
      right: 0;
      top: @space3;
      cursor: initial;
      list-style: none;
      padding: @space2;
      color: @white-100;
      position: absolute;
      z-index: @dotMenuIndex;
      background-color: @brand-2-100;

      li {
        cursor: pointer;
        white-space: nowrap;

        &:not(:last-child) {
          margin-bottom: @space3;
        }
      }
    }
  }
</style>
