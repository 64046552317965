import { uniq } from "underscore";
import { updateField } from "vuex-map-fields";
import { pagination, reseller } from ".//templates";

export default {
	async loaded(state, data) {
		state.resellers = uniq([...data.resellers], 'uuid');
		state.pagination = { ...(data.pagination || pagination) };
	},
	async loadedMore(state, data) {
		state.resellers = uniq([...data.resellers, ...state.resellers], 'uuid');
		state.pagination = { ...data.pagination };
	},
	async loadedOne(state, data) {
		state.resellers = uniq([...state.resellers, data.reseller], 'uuid');
	},
	async updated(state, { data }) {
		state.reseller = { ...state.reseller, ...data.reseller };
		state.resellers = state.resellers.map(reseller => reseller.uuid === state.reseller.uuid ? ({ ...state.reseller }) : reseller);
	},
	async created(state, { data, response }) {
		state.reseller = { ...state.reseller, ...data.reseller, uuid: response.reseller.uuid, accounts: [{ username: state.reseller.username }] };
		state.resellers = [...state.resellers, state.reseller];
	},
	async deleted(state, { response, id }) {
		state.resellers = state.resellers.filter(reseller => reseller.uuid !== id);
	},
	async reset(state) {
		state.reseller = { ...reseller };
	},
	async select(state, id) {
		if (state.resellers) {
			let reseller = state.resellers.find(reseller => reseller.uuid === id);

			if (reseller) {
				if (reseller.accounts && reseller.accounts.first()) {
					reseller.username = reseller.accounts.first().username;
				}

				state.reseller = reseller;
			}
		}
	},
	async resetPagination(state) {
		state.pagination = { ...pagination };
	},
	updateField
};
