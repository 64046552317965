import AuthGuard from '../../shared/router/guard/auth';
import ResellerGuard from "./guard/reseller";
import RegionGuard from "./guard/region";

import multiguard from 'vue-router-multiguard';
import HomeComponent from '../views/home';
import ListOfferComponent from '../views/list';
import UpdateOfferComponent from '../views/update';
import DistributorLoginComponent from '../views/login';
import ManualComponent from '../views/manual';

export default [
	{
		path: '/:region',
		redirect: '/:region/home'
	},
	{
		path: '/:region/home',
		component: HomeComponent,
		meta: {
			title: 'offer.routes.home'
		},
		beforeEnter: multiguard([AuthGuard, RegionGuard])
	},
	{
		path: '/:region/offer',
		component: ListOfferComponent,
		meta: {
			title: 'offer.routes.offers'
		},
		beforeEnter: multiguard([AuthGuard, RegionGuard])
	},
	{
		path: '/:region/offer/add',
		component: UpdateOfferComponent,
		meta: {
			title: 'offer.routes.add_offer'
		},
		beforeEnter: multiguard([AuthGuard, RegionGuard])
	},
	{
		path: '/:region/offer/:id',
		component: UpdateOfferComponent,
		meta: {
			show_manual: true,
			title: 'offer.routes.edit_offer'
		},
		beforeEnter: multiguard([AuthGuard])
	},
	{
		path: '/:region/offer/:id/manual',
		component: ManualComponent,
		meta: {
			show_manual: true,
			title: 'manual.meta_title',
		},
		beforeEnter: multiguard([AuthGuard])
	},
	{
		path: '/:region/:distributor/offer/:id/manual',
		component: ManualComponent,
		meta: {
			show_manual: true,
			title: 'manual.meta_title',
		},
		beforeEnter: multiguard([ResellerGuard,AuthGuard])
	},
	{
		path: '/:region/offer/:id/:step',
		component: UpdateOfferComponent,
		meta: {
			show_manual: true,
			title: 'offer.routes.edit_offer'
		},
		beforeEnter: multiguard([AuthGuard])
	},
	{
		path: '/:region/:distributor',
		component: HomeComponent,
		meta: {
			title: 'offer.routes.distributor.dashboard'
		},
		beforeEnter: multiguard([ResellerGuard, RegionGuard])
	},
	{
		path: '/:region/:distributor/home',
		component: HomeComponent,
		meta: {
			title: 'offer.routes.distributor.dashboard'
		},
		beforeEnter: multiguard([ResellerGuard, RegionGuard])
	},
	{
		path: '/:region/:distributor/login',
		component: DistributorLoginComponent,
		meta: {
			title: 'login'
		},
		beforeEnter: multiguard([AuthGuard])
	},
	{
		path: '/:region/:distributor/offer/add',
		component: UpdateOfferComponent,
		meta: {
			title: 'offer.routes.add_offer'
		},
		beforeEnter: multiguard([ResellerGuard, RegionGuard])
	},
	{
		path: '/:region/:distributor/offer/:id',
		component: UpdateOfferComponent,
		meta: {
			show_manual: true,
			title: 'offer.routes.edit_offer'
		},
		beforeEnter: multiguard([ResellerGuard, RegionGuard])
	},
	{
		path: '/:region/:distributor/offer/:id/:step',
		component: UpdateOfferComponent,
		meta: {
			show_manual: true,
			title: 'offer.routes.edit_offer'
		},
		beforeEnter: multiguard([ResellerGuard, RegionGuard])
	}
];
