<template>
  <div>
    <div class="ce-text" v-if="renderType() === 'text'">
      <h4 class="home-text-headline headline headline_4">{{ title() }}</h4>
      <div class="home-text-text" v-html="body()"></div>
    </div>
    <div class="ce-banner" v-if="renderType() === 'banner'">
      <span v-if="link()">
        <a :href="link()" target="_blank">
          <img v-if="image()" class="no-margin" :src="image()">
        </a>
      </span>
      <span v-else>
        <img v-if="image()" class="no-margin" :src="image()">
      </span>
    </div>
  </div>
</template>

<script>
  export default {
      name: "content-element",
      components: {

      },
      props: [
          'field'
      ],
      data() {
          return {
              imageDimensions: {
                  height: 415,
                  width: 350
              }
          }
      },
      methods: {
          renderType() {
              if (this.field) {
                  return this.field.render_mode;
              }
          },
          title() {
              if (this.field) {
                  const lang = this.$i18n.locale;
                  const key = 'title_'+lang;
                  return this.field.title[key];
              }
          },
          body() {
              if (this.field) {
                  const lang = this.$i18n.locale;
                  const key = 'body_'+lang;
                  return this.field.body[key];
              }
          },
          image() {
              if (this.field) {
                  const lang = this.$i18n.locale;
                  const key = 'image_'+lang;
                  const asset = {
                      uuid: this.field.image[key]
                  };
                  return this.field.image[key] ? this.$store.getters['asset/getPublicUrl'](asset, this.imageDimensions) : null;
              }
          },
          link() {
              if (this.field) {
                  const lang = this.$i18n.locale;
                  const key = 'target_'+lang;
                  return this.field.link[key];
              }
              return null;
          }
      }
  }
</script>

<style lang="less" scoped>
  .ce-banner a:before { display: none; }
  .ce-banner a:hover { text-decoration: none; border: none; }
  .ce-banner img { max-width: 100%; height: auto; }
</style>
