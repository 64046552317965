<template>
  <div class="checkbox">
    <input :name="name" :id="id" type="checkbox" :disabled="disabled" :checked="value" @change="handleChange" :class="{ partly: partly }">
    <label :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script>
  export default {
    name: "checkbox",
    props: {
      name: String,
      disabled: Boolean,
      partly: Boolean,
      value: [Boolean, String, Number]
    },
    computed: {
      id() {
        return 'checkbox' + this._uid;
      }
    },
    methods: {
      handleChange(event) {
        this.$emit('input', event.target.checked);
        this.$emit('change', event.target.checked);
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";

  .checkbox {
    input {
      position: absolute;
      left: -999999px;
      opacity: 0;
    }

    label {
      line-height: 1.1;
      cursor: pointer;
      color: @brand-2-100;
      font-size: @fontSize;
      position: relative;

      padding-left: @checkboxSize + @space1;

      &:before {
        top: 0;
        left: 0;
        content: "";
        position: absolute;
        width: @checkboxSize;
        height: @checkboxSize;
        .icon('checkbox/checkbox-initial');
      }
    }

    label:before {
      .icon('checkbox/checkbox-initial');
    }

    input:disabled + label {
      color: @black-40;
    }

    input:checked + label:before {
      .icon('checkbox/checkbox-selected');
    }

    input:disabled + label:before {
      .icon('checkbox/checkbox-inactive');
    }

    input:checked:disabled + label:before {
      .icon('checkbox/checkbox-inactive-selected');
    }

    input.partly:checked + label:before {
      .icon('checkbox/checkbox-multiselect');
    }
  }
</style>
