<template>
  <div>
    <div class="backdrop" v-show="open"></div>
    <div
      tabindex="0"
      v-if="false === !!this.to"
      @click.self="show()"
      @blur="close()"
      class="icon"
      :class="icon + (open ? ' open' : '')"
    >
      <div class="overlay">
        <slot />
      </div>
    </div>
    <router-link
      v-else-if="this.to"
      :to="this.to"
      class="icon"
      :class="icon + (open ? ' open' : '')"
    >&nbsp;</router-link>
  </div>
</template>

<script>
export default {
  name: "meta-navigation-item",
  props: {
    icon: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    show() {
      this.open = true;
    },
    close() {
      this.open = false;
    },
    toggle() {
      this.open = !this.open;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../res/less/core/config/constants";
@import "../../../../res/less/core/config/mixins";
@import "../../../../res/less/themes/theme";

.icon {
  width: 70rem;
  outline: none;
  height: 70rem;
  cursor: pointer;
  position: relative;
  display: block;
  margin-bottom: @space2;
  border-radius: @metaNavigationBorderRadius;
  background-color: @metaNavigationBackgroundColor;

  &.open {
    background-color: @metaNavigationBackgroundColorOpen;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    .overlay {
      display: block;
    }
  }

  &:before {
    top: 50%;
    left: 50%;
    content: "";
    width: @space4;
    height: @space4;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  &.help:before {
    .icon("menu/menu-help");
  }

  &.contact:before {
    .icon("menu/menu-contact");
  }

  &.language:before {
    .icon("menu/menu-language");
  }

  &.add-user:before {
    .icon("menu/menu-add-user");
  }

  &.add-document:before {
    .icon("menu/menu-add-document");
  }

  &.settings:before {
    .icon("settings");
  }

  &.account:before {
    .icon("menu/menu-account");
  }

  &.home:before {
    .icon("menu/menu-home");
  }

  .overlay {
    display: none;
    cursor: initial;
    position: absolute;
    left: calc(100% ~"+" @space2);
    background-color: @metaNavigationBackgroundColor;

    &:before {
      content: "";
      width: @space2;
      left: -@space2;
      height: 70rem;
      position: absolute;
      background-color: @metaNavigationBackgroundColor;
    }
  }
}

.backdrop {
  metanavigationbackgroundcolorwidth: 100%;
  height: 100%;
  background-color: fade(@brand-2-100, 25%);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
</style>
