<template>
  <div class="inline">
    <div @click="$refs.modal.open()">
      <slot/>
    </div>

    <modal ref="modal">
      <div class="import-modal-body">
        <h3 class="headline headline_3">{{ $t("offer.modal.import_offer.headline") }}</h3>

        <div class="input">
          <label class="file-upload-label">{{ $t("offer.modal.import_offer.upload_xml") }}</label>
          <file-upload></file-upload>
        </div>

        <div>
          <button class="button primary small" @click="importOffer()">{{ $t("offer.modal.import_offer.buttons.import") }}</button>
          <button class="button secondary small" @click="$refs.modal.close()">{{ $t("offer.modal.import_offer.buttons.cancel") }}</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import Modal from '../../shared/components/modal/modal';
  import FileUpload from '../../shared/components/form/file-upload';

  /**
   * @unused
   */
  export default {
    name: "import-offer-modal",
    components: {
      Modal,
      FileUpload
    },
    methods: {
      importOffer() {
        // TODO(soerenuhrbach): Start Import
        // TODO(soerenuhrbach): Show Loading Screen

        this.$refs.modal.close();
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";
  @import "../../../res/less/core/config/constants";

  .import-modal-body {
    padding: @space5 @space5 calc(@space5 - @standard-space) @space5;
    min-width: 400px;
    background-color: @white-100;

    .file-upload-label {
      margin-bottom: @space1;
      color: @brand-1-100;
    }
  }

  @media(max-width: @phone) {
    .inline { width: 100%; }
  }
</style>
