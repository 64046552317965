<template>
  <div class="">
    <section class="sub">
      <h4 class="headline headline_4">{{ $t("offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.headline") }}</h4>

      <data-table :fields="fields_pbx" :data="pbx">
        <tr slot="empty">
          <td :colspan="fields_pbx.length" class="text-center">{{ $t('offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.no_data_found') }}</td>
        </tr>
      </data-table>

      <data-table :fields="fields_ports" :data="ports">
        <tr slot="empty">
          <td :colspan="fields_ports.length" class="text-center">{{ $t('offer.offer.steps.complete_offer.tabs.description.sections.port_assignment.no_data_found') }}</td>
        </tr>
      </data-table>

      <data-table :fields="fields_functions" :data="functions">
        <tr slot="empty">
          <td :colspan="fields_functions.length" class="text-center">{{ $t('offer.offer.steps.complete_offer.tabs.description.sections.functions.no_data_found') }}</td>
        </tr>
      </data-table>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import DataTable from "../../../shared/components/table/table";

  export default {
    name: "description",
    components: {
      DataTable
    },
    data() {
      return {
        fields_pbx: [
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.amount', key: 'amount', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.article', key: 'description', sortable: false }
        ],
        fields_ports: [
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.ports', key: 'name', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.max', key: 'available', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.available', key: 'unused', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.used', key: 'used', sortable: false }
        ],
        fields_functions:  [
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.functions', key: 'name', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.available', key: 'amount', sortable: false },
        ]
      }
    },
    computed: {
      ...mapState({
        functions(state) {
          const functions = [];

          for (let name in state.offer.result.activation_overview) {
            if (state.offer.result.activation_overview.hasOwnProperty(name)) {
              functions.push({
                id: functions.length,
                name: this.$t(`content.offer.steps.complete_offer.tabs.description.sections.functions.${name}`),
                amount: state.offer.result.activation_overview[name]
              });
            }
          }

          return functions;
        },
        ports(state) {
          const ports = [];

          for (let name in state.offer.result.port_assignment) {
            if (state.offer.result.port_assignment.hasOwnProperty(name)) {
              ports.push({
                id: ports.length,
                name: this.$t(`content.offer.steps.complete_offer.tabs.description.sections.port_assignment.${name}`),
                ...state.offer.result.port_assignment[name]
              });
            }
          }

          return ports;
        },
        pbx(state) {
          const pbx = { ...state.offer.result.pbx };

          if (!pbx.uuid) {
            return [];
          }

          return [
            {
              id: pbx.uuid,
              amount: 1,
              description: pbx.title + '<br><br>' + pbx.description
            }
          ];
        }
      })
    }
  }
</script>

<style scoped>

</style>
