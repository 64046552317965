<template>
  <div>
    <h3 class="headline headline_3">Regeln</h3>

    <div class="grid">
      <div class="col6">
        <limits/>
        <requirements/>
        <foreign-product-activation v-if="category() === 'PBX'"/>
        <incompatible-with-pbxs/>
      </div>

      <div class="col6">
        <required-products/>
        <system-functions/>
        <system-functions-selectable/>
        <max-system-functions/>
        <possible-dect-servers/>
        <possible-dect-bases/>
        <possible-dect-extensions/>
      </div>
    </div>
  </div>
</template>

<script>
import Limits from "./rules/limits";
import Requirements from "./rules/requirements";
import PortRestrictions from "./rules/port-restrictions";
import Accessories from "./rules/accessories";
import AttributeCombinations from "./rules/attribute-combinations";
import SystemFunctionsSelectable from "./rules/system-functions-selectable";
import RequiredProducts from "./rules/required-products";
import SystemFunctions from "./rules/system-functions";
import MaxSystemFunctions from "./rules/max-system-functions";
import ForeignProductActivation from "./rules/foreign-product-activation";
import PossibleDectServers from "./rules/possible-dect-servers";
import PossibleDectBases from "./rules/possible-dect-bases";
import PossibleDectExtensions from "./rules/possible-dect-extensions";
import IncompatibleWithPbxs from "./rules/incompatible-with-pbxs";


export default {
  name: "rules",
  components: {
    IncompatibleWithPbxs,
    PossibleDectExtensions,
    PossibleDectBases,
    PossibleDectServers,
    MaxSystemFunctions,
    AttributeCombinations,
    Accessories,
    PortRestrictions,
    Requirements,
    Limits,
    SystemFunctionsSelectable,
    RequiredProducts,
    SystemFunctions,
    ForeignProductActivation
  },
  data() {
    return {
      category: function () {
        let category = this.$store.state.product.product.category.key;
        let subCategory = this.$store.state.product.product.sub_category.key;
        if (category === 'PBX' && subCategory === 'MODULE' || (category === 'SYSTEM_FUNCTION')) {
          return 'MODULE';
        }

        if (category === 'PBX') {
          return 'PBX';
        }
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
