<template>
  <div class="home">
    <PageHeader :content="contentTop" :pageTitle="pageTitle" />

    <section>
      <equalizer :classes="['home-text-headline', 'home-text-text']">
        <div class="grid home-texts">
          <div class="col4">
            <content-element :field="contentLeft"></content-element>
          </div>
          <div class="col4">
            <content-element :field="contentCenter"></content-element>
          </div>
          <div class="col4">
            <content-element :field="contentRight"></content-element>
          </div>
        </div>
      </equalizer>

      <div class="grid">
        <div class="col4">
          <content-element :field="contentMidleft"></content-element>
        </div>
        <div class="col4">
          <notifications :max="3" />
          <div>
            <h3>{{ $t("offer.home.offer.headline") }}</h3>
            <input
              type="text"
              v-model="title"
              :placeholder="$t('offer.home.offer.title')"
            />
            <checkbox id="checkbox-1" v-model="data_protection">
              <span v-html="$t('offer.home.offer.data_protection')"></span>
            </checkbox>
            <button @click="createOffer()" class="button primary">
              {{ $t("offer.home.offer.create_offer") }}
            </button>
            <div class="error" v-show="!data_protection && show_error">
              {{ $t("offer.home.offer.data_protection_error") }}
            </div>
            <div class="error" v-show="!title && show_error">
              {{ $t("offer.home.offer.title_required_error") }}
            </div>
          </div>

          <div>
            <h3>{{ $t("offer.home.offer.edit.headline") }}</h3>

            <alert
              level="danger"
              v-if="show_token_not_found"
              :title="$t('offer.home.offer.edit.error')"
              >{{ $t("offer.home.offer.edit.token_not_found") }}</alert
            >

            <input
              type="text"
              v-model="code"
              :placeholder="$t('offer.home.offer.edit.token')"
            />
            <button
              @click="editOffer()"
              class="button secondary"
              :disabled="!code"
            >
              {{ $t("offer.home.offer.edit.edit") }}
            </button>
          </div>
        </div>
        <div class="col4">
          <content-element :field="contentMidright"></content-element>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapState } from "vuex";
import Notifications from "../../shared/components/notifications";
import Checkbox from "../../shared/components/form/checkbox";
import Alert from "../../shared/components/alert";
import equalizer from "vue-equalizer";
import ContentElement from "../components/page/content-element";
import PageHeader from "../components/page/header";

export default {
  name: "home",
  components: {
    Alert,
    Notifications,
    Checkbox,
    equalizer,
    ContentElement,
    PageHeader,
  },
  data: function () {
    return {
      title: "",
      data_protection: false,
      show_error: false,
      code: "",
      show_token_not_found: false,
    };
  },
  computed: {
    ...mapState({
      pageTitle(state) {
        const page = state.page.pages.find((page) => page.id === "home");
        if (page) {
          return page.title;
        }
        return null;
      },
      contentTop(state) {
        const page = state.page.pages.find((page) => page.id === "home");
        if (page) {
          return page.content_top;
        }
        return null;
      },
      contentLeft(state) {
        const page = state.page.pages.find((page) => page.id === "home");
        if (page) {
          return page.content_left;
        }
        return null;
      },
      contentCenter(state) {
        const page = state.page.pages.find((page) => page.id === "home");
        if (page) {
          return page.content_center;
        }
        return null;
      },
      contentRight(state) {
        const page = state.page.pages.find((page) => page.id === "home");
        if (page) {
          return page.content_right;
        }
        return null;
      },
      contentMidleft(state) {
        const page = state.page.pages.find((page) => page.id === "home");
        if (page) {
          return page.content_midleft;
        }
        return null;
      },
      contentMidright(state) {
        const page = state.page.pages.find((page) => page.id === "home");
        if (page) {
          return page.content_midright;
        }
        return null;
      },
    }),
    client() {
      const global = require("../../../config/index");
      return global.default.api.client === "fontevo" ? "FON" : "AU";
    },
    baseUrl() {
      const { region, distributor } = this.$route.params;

      let target = "/offer";

      if (distributor) {
        target = "/" + distributor + target;
      }

      if (region) {
        target = "/" + region + target;
      }

      return target;
    },
  },
  methods: {
    async createOffer() {
      if (!this.title || !this.data_protection) {
        this.show_error = true;
        return;
      }

      const offer = {
        title: this.title,
      };

      let store = this.$store;
      let router = this.$router;

      const url = this.baseUrl;

      await this.$store.dispatch("offer/create", { payload: { offer } });
      await this.$store.dispatch("offer/category/load", {
        id: store.state.offer.offer.uuid,
      });

      router.push(url + "/" + store.state.offer.offer.uuid);
    },
    async editOffer() {
      const { code } = this;
      const getUuidForEditCode = this.$store.getters[
        "offer/getUuidForEditCode"
      ];

      await this.$store.dispatch("offer/findByEditCode", { code });
      const uuid = getUuidForEditCode(code);

      if (uuid) {
        await this.$store.dispatch("offer/category/load", { id: uuid });
        this.$router.push(this.baseUrl + "/" + uuid);
      }

      this.show_token_not_found = !uuid;
    },
  },
  async mounted() {
    await this.$store.dispatch("page/load");
    const eq = this.$children.find(
      (child) => child.$el.className === "eq-container"
    );
    eq.equalize();
  },
};
</script>

<style lang="less" scoped>
.error {
  padding-bottom: 8px;
}

.home-texts .headline {
  height: 52rem;
}

@media only screen and (max-width: 1424px) {
  .home-texts .headline {
    height: 72rem;
  }
}

@media only screen and (max-width: 1198px) {
  .home-texts .headline {
    height: 52rem;
  }
}

@media only screen and (max-width: 897px) {
  .home-texts .headline {
    height: auto;
  }
}
</style>
