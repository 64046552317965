import { systemFunction } from "../templates";

export default {
	addSystemFunction(state) {
		state.product.system_functions = [...state.product.system_functions, { ...systemFunction }];
	},
	removeSystemFunction(state, index) {
		state.product.system_functions = state.product.system_functions.filter((systemFunction, i) => i !== index);
	},
	updateSystemFunction(state, { index, changes }) {
		state.product.system_functions = state.product.system_functions.map((systemFunction, i) => index === i ? { ...systemFunction, ...changes } : systemFunction);
	}
};
