<template>
  <div class="tooltip" :data-position="position" @click="toggle" @mouseover="shown = true" @mouseleave="shown = false">
    <div class="toggle">
      <slot name="toggle"></slot>
    </div>

    <div class="body" v-show="shown">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "tooltip",
    props: {
      position: {
        type: String,
        default: 'top',
        validator: value => ['top', 'bottom'].includes(value)
      }
    },
    data() {
      return {
        shown: false
      }
    },
    methods: {
      toggle() {
        this.shown = !this.shown;
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";

  .tooltip {
    display: inline-block;
    cursor: pointer;
    position: relative;

    .body {
      width: auto;
      color: @white-100;
      position: absolute;
      display: inline-block;
      padding: @space1 @space3;
      background-color: @brand-1-100;

      &:before {
        left: 50%;
        content: "";
        width: 11rem;
        height: 11rem;
        display: block;
        position: absolute;
        background-color: @brand-1-100;
      }
    }

    &[data-position="bottom"] .body {
      left: 50%;
      bottom: 0;
      min-width: 170px;
      transform: translate(-50%, ~"calc(100% + @{space1})");

      &:before {
        top: 0;
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }

    &[data-position="top"] .body {
      top: 0;
      left: 50%;
      min-width: 170px;
      transform: translate(-50%, ~"calc(-100% - @{space1})");

      &:before {
        bottom: 0;
        transform: translate(-50%, 50%) rotate(-135deg);
      }
    }
  }

  .toggle { line-height: 1.5em; }
</style>
