<template>
  <ul class="languages">
    <li class="head">
      <h4 class="headline headline_4">{{ $t("header.meta_navigation.language") }}</h4>
    </li>

    <li v-for="{ key, language } in languages" :key="key" :class="buildClass(key)" @click="setLanguage(key)">
      {{ language }}
    </li>
  </ul>
</template>

<script>
  export default {
    name: "language-toggle",
    methods: {
      buildClass(language) {
        if (language === this.$i18n.locale) {
          return `${language} active`;
        } else {
          return language;
        }
      },
      setLanguage(locale) {
        localStorage.setItem('locale', locale);
        window.location.reload(true);

        this.closeParent();
      },
      closeParent() {
        this.$parent.close();
      }
    },
    computed: {
      languages() {
        const locale = this.$i18n.locale;
        const global = require('../../../../../config/index');
        const client = global.default.api.client;
        const useLanguages = [];

        const languages = {
            "auerswald": ['de', 'en'],
            "fontevo": ['en', 'fr']
        };

        for (let language in this.$i18n.messages) {
          if (this.$i18n.messages.hasOwnProperty(language)) {
            if (languages[client].includes(language)) {
                useLanguages.push({
                    key: language,
                    language: this.$i18n.messages[locale]['languages'][language]
                });
            }
          }
        }

        return useLanguages;
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../../res/less/core/config/constants";
  @import "../../../../../res/less/core/config/mixins";
  @import "../../../../../res/less/themes/theme";

  ul {
    margin-bottom: 0;

    li {
      cursor: pointer;
      position: relative;
      padding: @space2 @space3 @space2 @space6;

      &.head {
        cursor: initial;
        padding: @space2 @space3;
        min-height: 70rem;
      }

      .headline {
        margin-bottom: 0;
      }

      &.active {
        background-color: @metaNavigationBackgroundColorActive;
      }

      &:before {
        top: 50%;
        left: @space3;
        content: "";
        width: @space2;
        height: 12rem;
        position: absolute;
        transform: translateY(-50%);
      }

      &.de:before { .icon('flags/flag-de'); }
      &.en:before { .icon('flags/flag-en'); }
      &.fr:before { .icon('flags/flag-fr'); }
    }
  }
</style>
