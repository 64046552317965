<template>
  <div>
    <h3 class="headline headline_3">Technische Daten</h3>

    <div class="grid">
      <div class="col6">
        <section class="sub">
          <h4 class="headline headline_4">Allgemein</h4>

          <div class="input">
            <label for="amt_channels"
            >Anzahl an externen Kanälen (ISDN + VoIP + Analog)</label
            >
            <input type="number" id="amt_channels" v-model="amt_channels"/>
          </div>
          <div class="input">
            <label for="maximum_amt_channels"
            >Maximale Anzahl an externen Kanälen (ISDN + VoIP + Analog)</label
            >
            <input
              type="number"
              id="maximum_amt_channels"
              v-model="maximum_amt_channels"
            />
          </div>
        </section>
        <section class="sub">
          <h4 class="headline headline_4">COMtrexx User Lizenz</h4>
          <div class="input">
            <label for="comtrexx_users_per_device">Anzahl an User Lizenz pro Gerät</label>
            <input type="number" id="comtrexx_users_per_device" v-model="comtrexx_users_per_device"/>
          </div>
        </section>
        <section class="sub">
          <h4 class="headline headline_4">COMpact User Lizenz</h4>
          <div class="input">
            <label for="compact_users_provided">Anzahl der bereitgestellte Users</label>
            <input type="number" id="compact_users_provided" v-model="compact_users_provided"/>
          </div>
          <div class="input">
            <label for="compact_users_needed">Anzahl der benötigte Users</label>
            <input type="number" id="compact_users_needed" v-model="compact_users_needed"/>
          </div>
        </section>
        <section class="sub">
          <h4 class="headline headline_4">VoIP</h4>

          <div class="input">
            <label for="voip">VoIP-Kanäle</label>
            <input type="number" id="voip" v-model="voip">
          </div>

          <div class="input">
            <label for="voip_max">Max. VoIP-Kanäle</label>
            <input type="number" id="voip_max" v-model="voip_max">
          </div>

          <div class="input">
            <label for="voip_module_ports">VoIP Modulsteckplätze</label>
            <input type="number" id="voip_module_ports" v-model="voip_module_ports">
          </div>

          <div class="input">
            <label for="needs_voip_module_ports">Benötigte VoIP Modulsteckplätze</label>
            <input type="number" id="needs_voip_module_ports" v-model="needs_voip_module_ports">
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">S0 / ISDN</h4>

          <div class="input">
            <label for="s0">S0-Kanäle (Intern)</label>
            <input type="number" id="s0" v-model="s0">
          </div>

          <div class="input">
            <label for="s0_max">Max. S0-Kanäle (Intern)</label>
            <input type="number" id="s0_max" v-model="s0_max">
          </div>

          <div class="input">
            <label for="s0_amt">S0-Amtkanäle</label>
            <input type="number" id="s0_amt" v-model="s0_amt">
          </div>

          <div class="input">
            <label for="s0_amt_max">Max. S0-Amtkanäle</label>
            <input type="number" id="s0_amt_max" v-model="s0_amt_max">
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">UP0-Ports
          </h4>

          <div class="input">
            <label for="up0">UP0-Ports</label>
            <input type="number" id="up0" v-model="up0">
          </div>

          <div class="input">
            <label for="up0_max">Max. UP0-Ports</label>
            <input type="number" id="up0_max" v-model="up0_max">
          </div>

          <div class="input">
            <label for="s0_up0_sum">Max. Summe S0/UP0</label>
            <input type="number" id="s0_up0_sum" v-model="s0_up0_sum">
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">Modulsteckplätze</h4>

          <div class="input">
            <label for="module_ports">Modulsteckplätze</label>
            <input type="number" id="module_ports" v-model="module_ports">
          </div>

          <div class="input">
            <label for="module_ports_max">Max. Modulsteckplätze</label>
            <input type="number" id="module_ports_max" v-model="module_ports_max">
          </div>

          <div class="input">
            <label for="needs_module_ports">Benötigte Modulsteckplätze</label>
            <input type="number" id="needs_module_ports" v-model="needs_module_ports">
          </div>
        </section>
      </div>

      <div class="col6">
        <section class="sub">
          <h4 class="headline headline_4">VMF</h4>

          <div class="input">
            <label for="vmf">VMF-Kanäle</label>
            <input type="number" id="vmf" v-model="vmf">
          </div>

          <div class="input">
            <label for="vmf_max">Max. VMF-Kanäle</label>
            <input type="number" id="vmf_max" v-model="vmf_max">
          </div>

          <div class="input">
            <label for="vmf_voip_voipamt_sum">Summe VoIP / VoIP-Amt / VMF</label>
            <input type="number" id="vmf_voip_voipamt_sum" v-model="vmf_voip_voipamt_sum">
          </div>

          <h4 class="headline headline_4">DECT (Für Server, Basen, Lizenzen)</h4>
          <div class="input">
            <checkbox id="can_be_added_multiple_times" v-model="can_be_added_multiple_times">Kann mehrfach hinzugefügt
              werden
            </checkbox>
          </div>

          <div class="input">
            <label for="maximum_dect_devices">Maximale DECT-Geräte</label>
            <input type="number" id="maximum_dect_devices" v-model="maximum_dect_devices">
          </div>

          <div class="input">
            <label for="minimum_dect_channels">Min. DECT-Kanäle</label>
            <input type="number" id="minimum_dect_channels" v-model="minimum_dect_channels">
          </div>

          <div class="input">
            <label for="maximum_dect_channels">Max. DECT-Kanäle</label>
            <input type="number" id="maximum_dect_channels" v-model="maximum_dect_channels">
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">Analoge Ports</h4>

          <div class="input">
            <label for="analog_amt">Analoge Amtanschlüsse</label>
            <input type="number" id="analog_amt" v-model="analog_amt">
          </div>

          <div class="input">
            <label for="analog_amt_max">Max. Analoge Amtanschlüsse</label>
            <input type="number" id="analog_amt_max" v-model="analog_amt_max">
          </div>

          <div class="input">
            <label for="analog_ports">Analoge Ports</label>
            <input type="number" id="analog_ports" v-model="analog_ports">
          </div>

          <div class="input">
            <label for="needs_analog_ports">Benötigte Analoge Ports</label>
            <input type="number" id="needs_analog_ports" v-model="needs_analog_ports">
          </div>

          <div class="input">
            <label for="analog_ports_max">Max. Analoge Ports</label>
            <input type="number" id="analog_ports_max" v-model="analog_ports_max">
          </div>

          <div class="input">
            <label for="tfe_max">Max. TFE-Ports</label>
            <input type="number" id="tfe_max" v-model="tfe_max">
          </div>

          <div class="input">
            <label for="relay_max">Max. Schaltrelais</label>
            <input type="number" id="relay_max" v-model="relay_max">
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">Weitere Funktionen</h4>

          <div class="input">
            <label for="time_slots">Timeslots</label>
            <input type="number" id="time_slots" v-model="time_slots">
          </div>

          <div class="input">
            <label for="needs_time_slots">Benötigte Timeslots</label>
            <input type="number" id="needs_time_slots" v-model="needs_time_slots">
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import Checkbox from "../../../shared/components/form/checkbox";

export default {
  name: "technical-data",
  components: {Checkbox},
  computed: {
    ...mapFields("product", {
      amt_channels: "product.amt_channels",
      maximum_amt_channels: "product.maximum_amt_channels",
      /* Comtrexx User License */
      comtrexx_users_per_device: "product.comtrexx_users_per_device",
      /* COMpact User License */
      compact_users_provided: "product.compact_users_provided",
      compact_users_needed: "product.compact_users_needed",
      /* VoIP */
      voip: "product.voip",
      voip_max: "product.voip_max",
      voip_channels: "product.voip_channels",
      voip_module_ports: "product.voip_module_ports",
      needs_voip_module_ports: "product.needs_voip_module_ports",
      maximum_voip_devices: "product.maximum_voip_devices",

      can_be_added_multiple_times: "product.can_be_added_multiple_times",
      maximum_dect_devices: "product.maximum_dect_devices",
      minimum_dect_channels: "product.minimum_dect_channels",
      maximum_dect_channels: "product.maximum_dect_channels",

      /* TFE */
      tfe_max: "product.tfe_max",

      /* Relay */
      relay_max: "product.relay_max",

      /* S0 */
      s0: "product.s0",
      s0_max: "product.s0_max",
      s0_amt: "product.s0_amt",
      s0_amt_max: "product.s0_amt_max",

      /* UP0 */
      up0: "product.up0",
      up0_max: "product.up0_max",
      s0_up0_sum: "product.s0_up0_sum",

      /* VMF */
      vmf: "product.vmf",
      vmf_max: "product.vmf_max",
      vmf_voip_voipamt_sum: "product.vmf_voip_voipamt_sum",

      /* Analog */
      analog_amt: "product.analog_amt",
      analog_amt_max: "product.analog_amt_max",
      analog_ports: "product.analog_ports",
      needs_analog_ports: "product.needs_analog_ports",
      analog_ports_max: "product.analog_ports_max",

      /* Module ports */
      module_ports: "product.module_ports",
      module_ports_max: "product.module_ports_max",
      needs_module_ports: "product.needs_module_ports",

      /* more */
      time_slots: "product.time_slots",
      needs_time_slots: "product.needs_time_slots",
    }),
  },
};
</script>

<style scoped>
</style>
