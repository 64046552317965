class ProfileModule {
	boot() {
		//
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');
		const fr = require('./i18n/fr.json');

		return { de, en, fr };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			'profile': require('./store/profile').default,
		};
	}
}

export default ProfileModule;
