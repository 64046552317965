<template>
  <language-tabs>
    <tab name="DE" language="de">
      <div class="input">
        <label for="title_de">Titel</label>
        <input type="text" id="title_de" v-model="title_de">
      </div>
    </tab>
    <tab name="EN (AU)" language="en">
      <div class="input">
        <label for="title_en_au">Titel</label>
        <input type="text" id="title_en_au" v-model="title_en_au">
      </div>
    </tab>
    <tab name="EN (FON)" language="en">
      <div class="input">
        <label for="title_en">Titel</label>
        <input type="text" id="title_en" v-model="title_en">
      </div>
    </tab>
    <tab name="FR" language="fr">
      <div class="input">
        <label for="title_fr">Titel</label>
        <input type="text" id="title_fr" v-model="title_fr">
      </div>
    </tab>
  </language-tabs>
</template>

<script>
    import { mapFields } from 'vuex-map-fields';
    import Tab from "../../../shared/components/tabs/tab";
    import LanguageTabs from "../../../shared/components/tabs/language-tabs";

    export default {
        name: "page-title",
        components: {
            LanguageTabs,
            Tab
        },
        computed: {
            ...mapFields('page', {
                title_de: 'page.title.title_de',
                title_en: 'page.title.title_en',
                title_en_au: 'page.title.title_en_au',
                title_fr: 'page.title.title_fr',
            })
        }
    }
</script>

<style scoped>

</style>
