<template>
  <div v-if="contact">
    <div class="contact">
      <div class="inline">
        <img class="image no-margin" :src="image" alt="">
      </div>
      <div class="inline information">
        <b class="block">{{ contact.full_name }}</b>
        <p class="block">{{ contact.phone }}</p>1
        <p class="block">{{ contact.email }}</p>
      </div>
    </div>

    <section class="sub" v-if="showText">
      <p class="blue">
        Donec id elit non mi porta gravida at eget metus. Cras justo odio, dapibus ac facilisis in, egestas eget
        quam.
      </p>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: "contact",
    props: {
      showText: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        imageDimensions: {
          height: 80,
          width: 80
        }
      }
    },
    computed: {
      ...mapState({
        contact(state) {
          return state.profile.profile.service_contact;
        },
        image(state) {
          const contact = state.profile.profile.service_contact;

          return contact && contact.picture ? this.$store.getters['asset/getPublicUrl'](contact.picture, this.imageDimensions) : null;
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../../res/less/themes/theme";
  @import "../../../../../res/less/core/config/constants";

  .contact {
    .image {
      border-radius: 100%;
    }

    .information {
      vertical-align: top;
      margin-left: @space3;

      > * {
        margin-bottom: 0;
        color: @brand-2-100;
        font-size: @fontSize;
        line-height: @space3;

        &:before {
          display: none;
        }
      }

      a {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .blue {
    line-height: @space3;
    color: @brand-2-100
  }
</style>
