<template>
  <div>
    <h3 class="headline headline_3">Technische Daten</h3>

    <div class="grid">
      <div class="col6">
        <section class="sub">
          <h4 class="headline headline_4">Allgemein</h4>

          <div class="input">
            <label for="amt_channels"
              >Anzahl an externen Kanälen (ISDN + VoIP + Analog)</label
            >
            <input type="number" id="amt_channels" v-model="amt_channels" />
          </div>
          <div class="input">
            <label for="maximum_amt_channels"
              >Maximale Anzahl an externen Kanälen (ISDN + VoIP + Analog)</label
            >
            <input
              type="number"
              id="maximum_amt_channels"
              v-model="maximum_amt_channels"
            />
          </div>
        </section>
        <section class="sub">
          <h4 class="headline headline_4">VoIP</h4>

          <div class="input">
            <label for="voip">VoIP-Kanäle</label>
            <input type="number" id="voip" v-model="voip" />
          </div>

          <div class="input">
            <label for="voip_max">Max. VoIP-Kanäle</label>
            <input type="number" id="voip_max" v-model="voip_max" />
          </div>

          <div class="input">
            <label for="voip_module_ports">VoIP Modulsteckplätze</label>
            <input
              type="number"
              id="voip_module_ports"
              v-model="voip_module_ports"
            />
          </div>

          <div class="input">
            <label for="needs_voip_module_ports"
              >Benötigte VoIP Modulsteckplätze</label
            >
            <input
              type="number"
              id="needs_voip_module_ports"
              v-model="needs_voip_module_ports"
            />
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">S0 / ISDN</h4>

          <div class="input">
            <label for="s0">S0-Kanäle (Intern)</label>
            <input type="number" id="s0" v-model="s0" />
          </div>

          <div class="input">
            <label for="s0_amt">S0-Amtkanäle</label>
            <input type="number" id="s0_amt" v-model="s0_amt" />
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">UP0-Ports</h4>

          <div class="input">
            <label for="up0">UP0-Ports</label>
            <input type="number" id="up0" v-model="up0" />
          </div>

          <div class="input">
            <label for="s0_up0_sum">Max. Summe S0/UP0</label>
            <input type="number" id="s0_up0_sum" v-model="s0_up0_sum" />
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">Modulsteckplätze</h4>

          <div class="input">
            <label for="module_ports">Modulsteckplätze</label>
            <input type="number" id="module_ports" v-model="module_ports" />
          </div>

          <div class="input">
            <label for="needs_module_ports">Benötigte Modulsteckplätze</label>
            <input
              type="number"
              id="needs_module_ports"
              v-model="needs_module_ports"
            />
          </div>
        </section>
      </div>

      <div class="col6">
        <section class="sub">
          <h4 class="headline headline_4">VMF</h4>

          <div class="input">
            <label for="vmf">VMF-Kanäle</label>
            <input type="number" id="vmf" v-model="vmf" />
          </div>

          <div class="input">
            <label for="vmf_voip_voipamt_sum"
              >Summe VoIP / VoIP-Amt / VMF</label
            >
            <input
              type="number"
              id="vmf_voip_voipamt_sum"
              v-model="vmf_voip_voipamt_sum"
            />
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">Analoge Ports</h4>

          <div class="input">
            <label for="analog_amt">Analoge Amtanschlüsse</label>
            <input type="number" id="analog_amt" v-model="analog_amt" />
          </div>

          <div class="input">
            <label for="analog_ports">Analoge Ports</label>
            <input type="number" id="analog_ports" v-model="analog_ports" />
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">Weitere Funktionen</h4>

          <div class="input">
            <label for="time_slots">Timeslots</label>
            <input type="number" id="time_slots" v-model="time_slots" />
          </div>

          <div class="input">
            <label for="needs_time_slots">Benötigte Timeslots</label>
            <input
              type="number"
              id="needs_time_slots"
              v-model="needs_time_slots"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import Checkbox from "../../../shared/components/form/checkbox";

export default {
  name: "technical-data-module",
  components: { Checkbox },
  computed: {
    ...mapFields("product", {
      amt_channels: "product.amt_channels",
      maximum_amt_channels: "product.maximum_amt_channels",

      /* VoIP */
      voip: "product.voip",
      voip_max: "product.voip_max",
      voip_channels: "product.voip_channels",
      voip_module_ports: "product.voip_module_ports",
      needs_voip_module_ports: "product.needs_voip_module_ports",
      maximum_voip_devices: "product.maximum_voip_devices",

      /* TFE */
      tfe_max: "product.tfe_max",

      /* Relay */
      relay_max: "product.relay_max",

      /* S0 */
      s0: "product.s0",
      s0_max: "product.s0_max",
      s0_amt: "product.s0_amt",
      s0_amt_max: "product.s0_amt_max",

      /* UP0 */
      up0: "product.up0",
      up0_max: "product.up0_max",
      s0_up0_sum: "product.s0_up0_sum",

      /* VMF */
      vmf: "product.vmf",
      vmf_max: "product.vmf_max",
      vmf_voip_voipamt_sum: "product.vmf_voip_voipamt_sum",

      /* Analog */
      analog_amt: "product.analog_amt",
      analog_amt_max: "product.analog_amt_max",
      analog_ports: "product.analog_ports",
      needs_analog_ports: "product.needs_analog_ports",
      analog_ports_max: "product.analog_ports_max",

      /* Module ports */
      module_ports: "product.module_ports",
      module_ports_max: "product.module_ports_max",
      needs_module_ports: "product.needs_module_ports",

      /* more */
      time_slots: "product.time_slots",
      needs_time_slots: "product.needs_time_slots",
    }),
  },
};
</script>

<style scoped>
</style>
