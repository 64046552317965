import axios from "axios";
import config from "../../../../config";

export default {
	async load({ commit }, params = {}) {
		try {
			const response = await axios.get(`${config.api.endpoint}/manuals`, { params });
			const data = response.data;

			commit('loaded', data);
		} catch (exception) {
			this.dispatch('notification/fromRequestException', exception);
		}
	},
};
