export const region = {
	uuid: '',
	title_out: '',
	title: '',
	title_fr: '',
	title_en: '',
	title_en_au: '',
	shortcut: '',
	currency: '',
	tax: '',
	client: 'fontevo',
	hidden: false
};

export const pagination = {
	current_page: 0,
	limit: null,
	pages: null,
	max_count: null,
	next_page: null
};
