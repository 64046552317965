<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.requirements.headline') }}</h4>

    <button class="button secondary" @click="addCollection()">{{ $t('product.product.tabs.rules.sections.requirements.add') }}</button>

    <dropdown-tabs ref="requirementTabs" v-if="collections.length > 0" :label="$t('product.product.tabs.rules.sections.requirements.combination')">
      <tab v-for="(collection, index) of collections" :key="index" :propId="collection.id" :name="collection.name">
        <div v-for="(only_with, itemIndex) of collection.only_with" :key="itemIndex" class="grid">
          <div class="col9">
            <div class="input">
              <label>{{ $t('product.product.tabs.rules.sections.requirements.product') }}</label>
              <dropdown :data="products" :value="only_with.only_with_product" @change="updateCollectionItem(collection.id, itemIndex, { only_with_product: $event })" :placeholder="$t('product.product.tabs.rules.sections.requirements.placeholder')">
                <dot-menu slot="options" :options="productOptions" :object="{ collectionId: collection.id, itemIndex }"></dot-menu>
              </dropdown>
            </div>
          </div>
          <div class="col3">
            <div class="input">
              <label>{{ $t('product.product.tabs.rules.sections.requirements.min') }}</label>
              <input type="number" :value="only_with.minimum" min="0" @change="updateCollectionItem(collection.id, itemIndex, { minimum: $event.target.value })">
            </div>
          </div>
        </div>

        <button class="button secondary" @click="addCollectionItem(collection.id)">{{ $t('product.product.tabs.rules.sections.requirements.add_product') }}</button>
      </tab>

      <dot-menu slot="options" slot-scope="scope" :object="scope.data" :options="requirementOptions" ></dot-menu>
    </dropdown-tabs>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { wait } from "../../../../res/helper/wait";

  import Tab from '../../../shared/components/tabs/tab';
  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';
  import DropdownTabs from '../../../shared/components/tabs/dropdown-tabs';

  export default {
    name: "requirements",
    components: {
      Tab,
      DotMenu,
      Dropdown,
      DropdownTabs
    },
    data() {
      return {
        productOptions: [
          {
            title: 'actions.delete',
            callback: ({ collectionId, itemIndex }) => this.$store.commit('product/removeRequirementCollectionItem', { collectionId, itemIndex })
          }
        ],
        requirementOptions: [
          {
            title: 'actions.delete',
            callback: async data => {
              const collectionId = data.id;
              this.$store.commit('product/removeRequirementCollection', collectionId);
              await this.updateTabs();
              await this.selectLastTab();
            }
          }
        ]
      }
    },
    methods: {
      async updateTabs() {
        await wait();
        this.$refs.requirementTabs.update();
      },
      async selectLastTab() {
        this.$refs.requirementTabs.currentTab = this.$refs.requirementTabs.tabs.last().id;
      },
      async addCollection() {
        this.$store.commit('product/addRequirementCollection');
        this.$store.commit('product/updateRequirementCollectionNames');

        await this.updateTabs();
        await this.selectLastTab();
      },
      async addCollectionItem(collectionId) {
        this.$store.commit('product/addRequirementCollectionItem', collectionId);
        this.$store.commit('product/updateRequirementCollectionNames');
        await this.updateTabs();
      },
      async updateCollectionItem(collectionId, itemIndex, changes) {
        this.$store.commit('product/updateRequirementCollectionItem', { collectionId, itemIndex, changes });
        this.$store.commit('product/updateRequirementCollectionNames');
        await this.updateTabs();
      }
    },
    computed: {
      ...mapState({
        products(state) {
          return [...state.product.short_products].map(product => ({ value: product.uuid, label: '[' + product.article_number + '] ' + product.title }));
        },
        collections(state) {
          const collections = [...state.product.product.only_with_collections];

          return collections.map((collection, index) => {
            let name = collection.name;

            if (!name) {
              name = (index + 1) + ': ' + this.$t('product.product.tabs.rules.sections.requirements.combination') + ' [' + (collection.id) + ']';
            }

            return { ...collection, name };
          });
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
