class ResellerModule {
	boot() {
		//
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');
		const fr = require('./i18n/fr.json');

		return { de, en, fr };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			'reseller': require('./store/reseller').default,
			'reseller/service-contact': require('./store/service-contact').default,
			'reseller/region': require('./store/region').default
		};
	}
}

export default ResellerModule;
