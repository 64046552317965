<template>
</template>

<script>

export default {
  name: "ccm-tag",
  mounted() {
    const global = require("../../../config/index")
    const isEnabled = global.default.env.name === 'live'
    if (isEnabled) {
      console.debug('Ccm isEnabled ' + this.isEnabled)
      const localeKey = global.default.env.isFontevo ? 'ccm.fontevo_url' : 'ccm.auerswald_url'

      let ccmScript = document.createElement('script')
      ccmScript.setAttribute('referrerpolicy', 'origin')
      ccmScript.setAttribute('src', this.$i18n.t(localeKey))
      document.head.appendChild(ccmScript)
    }
  }
}
</script>

<style scoped>

</style>
