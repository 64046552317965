<template>
  <div>
    <section class="sub">
      <h4 class="headline headline_4">{{ $t("offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.headline") }}</h4>

      <data-table :fields="modules_and_activations_fields" :data="modules_and_activations">
        <tr slot="empty">
          <td :colspan="category_listing_fields.length" class="text-center">{{ $t("offer.offer.steps.complete_offer.tabs.costs.no_activations") }}</td>
        </tr>
      </data-table>
    </section>

    <section class="sub">
      <h4 class="headline headline_4">{{ $t("offer.offer.steps.complete_offer.tabs.costs.sections.system.headline") }}</h4>

      <data-table :fields="category_listing_fields" :data="categories">
        <tr slot="empty">
          <td :colspan="category_listing_fields.length" class="text-center">{{ $t("offer.offer.steps.complete_offer.tabs.costs.no_categories") }}</td>
        </tr>
      </data-table>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import DataTable from "../../../shared/components/table/table";

  export default {
    name: "costs",
    components: {
      DataTable
    },
    data() {
      return {
        category_listing_fields: [
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.system.table.category', key: 'category.title', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.system.table.factor', key: 'price_factor', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.system.table.hek', key: 'hek', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.system.table.uvp', key: 'uvp', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.system.table.margin', key: 'marge', sortable: false }
        ],
        modules_and_activations_fields: [
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.table.article_number', key: 'article_number', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.table.title', key: 'title', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.table.category', key: 'category.title', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.table.color', key: 'color', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.table.hek', key: 'hek', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.table.uvp', key: 'uvp', sortable: false },
          { label: 'offer.offer.steps.complete_offer.tabs.costs.sections.modules_and_activations.table.image', key: 'image', sortable: false }
        ],
      };
    },
    computed: {
      ...mapState({
        categories(state) {
          const categories = [];

          for (let key in state.offer.result.category_listing) {
            if (state.offer.result.category_listing.hasOwnProperty(key)) {
              const category = state.offer.result.category_listing[key];

              categories.push({ ...category, id: category.category.graph_id });
            }
          }

          return categories;
        },
        modules_and_activations(state) {
          const modules_and_activations = [...state.offer.result.modules_and_activations];

          return modules_and_activations.map(product => ({ ...product, id: product.uuid }));
        }
      })
    }
  }
</script>

<style scoped>

</style>
