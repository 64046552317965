<template>
  <section class="sub">
    <h4 class="headline headline_4">Mögliche DECT-Basen</h4>

    <div class="separator" v-for="(possible, index) of possibles" :key="index">
      <div class="input">
        <label :for="`possible_dect_bases_${index}`">Produkt</label>
        <dropdown :data="products" :value="possible" @change="updatePossible(index, $event)" placeholder="Produkte">
          <dot-menu slot="options" :object="{ index }" :options="options"></dot-menu>
        </dropdown>
      </div>
    </div>

    <button class="button secondary" @click="addPossible()">{{ $t('product.product.tabs.rules.sections.accessories.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';

  export default {
    name: "possible-dect-bases",
    components: {
      DotMenu,
      Dropdown
    },
    data() {
      return {
        options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removePossibleDectBase', index)
          }
        ]
      }
    },
    methods: {
      addPossible() {
        this.$store.commit('product/addPossibleDectBase');
      },
      updatePossible(index, value) {
        this.$store.commit('product/updatePossibleDectBase', { index, value });
      }
    },
    computed: {
      ...mapState({
        products(state) {
          return [...state.product.short_products].map(product => ({ label: product.title, value: product.uuid }));
        },
        possibles(state) {
          return [...state.product.product.possible_dect_bases];
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
