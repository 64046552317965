export const page = {
    id: '',
    title_de: '',
    title_fr: '',
    title_en: '',
    title_en_au: '',
    body_de: '',
    body_fr: '',
    body_en: '',
    body_en_au: ''
};
