<template>
  <ul class="help-dropdown">
    <li class="head">
      <h4 class="headline headline_4">{{ $t("header.meta_navigation.help.header") }}</h4>
    </li>
    <router-link tag="li" :to="manualLink">
      <span @click="closeParent()">{{ $t("header.meta_navigation.help.manual") }}</span>
    </router-link>
    <li @click="OpenHelpDesk()">{{ $t("header.meta_navigation.help.helpdesk") }}</li>
  </ul>
</template>

<script>
export default {
  name: "help-dropdown",
  methods: {
    closeParent(event) {
      this.$parent.close();
    },
    OpenHelpDesk() {
      //using method because anchor doesn't display properly in dropdown
      window.open("https://helpdesk.auerswald.de/Helpdesk/", "_blank");
    }
  },
  computed: {
    manualLink() {
      const { region, distributor, id } = this.$route.params;

      let target = "/offer";

      if (distributor) {
        target = "/" + distributor + target;
      }

      if (region) {
        target = "/" + region + target;
      }

      return target + "/" + id + "/manual";
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../../res/less/core/config/constants";
@import "../../../../../res/less/core/config/mixins";
@import "../../../../../res/less/themes/theme";

ul {
  margin-bottom: 0;

  li {
    cursor: pointer;
    position: relative;
    padding: @space2 @space3 @space2 @space6;

    &.head {
      cursor: initial;
      padding: @space2 @space3;
      min-height: 70rem;
    }

    .headline {
      margin-bottom: 0;
    }

    &.active {
      background-color: @metaNavigationBackgroundColorActive;
    }

    &:before {
      top: 50%;
      left: @space3;
      content: "";
      width: @space2;
      height: 12rem;
      position: absolute;
      transform: translateY(-50%);
    }
  }
}
</style>
