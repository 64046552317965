export default {
	addPossibleDectExtension(state) {
		state.product.possible_dect_extensions = [...state.product.possible_dect_extensions, null];
	},
	removePossibleDectExtension(state, index) {
		state.product.possible_dect_extensions = state.product.possible_dect_extensions.filter((product, i) => index !== i);
	},
	updatePossibleDectExtension(state, { index, value }) {
		state.product.possible_dect_extensions = state.product.possible_dect_extensions.map((product, i) => index === i ? value : product);
	}
};
