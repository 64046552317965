import axios from 'axios';
import { Service } from 'axios-middleware';
import Application from '../../../index';

new Service(axios).register({
	onRequest(config) {
		const router = Application.getRouter();
		const route = router.history.current;

		if (route.params.distributor) {
			const distributor = JSON.parse(localStorage.getItem('distributor'));

			if (distributor && route.params.distributor === distributor.alias) {
				config.headers['X-Distributor'] = distributor.uuid;
			}
		}

		return config;
	}
});
