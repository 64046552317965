<template>
  <header>
    <meta-navigation/>

    <router-link v-if="isAdminRoute" to="/admin" tag="div" class="logo-wrapper">
      <div class="logo"></div>
    </router-link>
    <router-link v-else to="/" tag="div" class="logo-wrapper">
      <div class="logo"></div>
    </router-link>

    <template v-if="isAdminRoute">
      <main-navigation ></main-navigation>
    </template>
  </header>
</template>

<script>
  import MetaNavigation from './meta-navigation';
  import MainNavigation from './main-navigation';

  export default {
    name: "application-header",
    components: {
      MainNavigation,
      MetaNavigation
    },
    computed: {
      isAdminRoute() {
        return this.$route.path.startsWith('/admin');
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";
  @import "../../../../res/less/core/config/constants";

  header {
    z-index: 6;
    padding-top: @headerPaddingTop;
    position: relative;
    height: @headerHeight;
    margin-bottom: @space6;
  }

  .logo-wrapper {
    cursor: pointer;
    display: inline-block;
    background-color: @logoBackgroundColor;

    .logo {
      height: @logoHeight;
      width: @logoWidth;
      background: @logoPath no-repeat;
      background-position: center;
    }
  }
</style>
