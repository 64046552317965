<template>
  <section>
    <div class="grey-box">
      <h4 class="headline headline_4">{{ $t("offer.offer.steps.present_devices.selected_devices") }}</h4>

      <data-table :fields="fields" :data="selectedProducts">
        <div class="inline right" slot="last-col" slot-scope="{ row }">
          <dot-menu :object="row" :options="options"></dot-menu>
        </div>
        <tr slot="empty">
          <td :colspan="fields.length" class="text-center">
            {{ $t('offer.offer.steps.present_devices.cart_empty') }}
          </td>
        </tr>
      </data-table>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from '../../../shared/components/modal/dot-menu';
  import DataTable from '../../../shared/components/table/table';

  export default {
    name: "selected-devices",
    components: {
      DotMenu,
      DataTable
    },
    data() {
      return {
        fields: [
          { label: 'offer.products.table.id', key: 'product.article_number' },
          { label: 'offer.products.table.name', key: 'product.description' },
          { label: 'offer.products.table.category', key: 'product.category.title' },
          { label: 'offer.products.table.color', key: 'product.color' },
          {
            label: 'offer.products.table.hek',
            key: 'hek',
            type: 'input:number',
            options: {
              classList: ['small'],
              styles: { 'max-width': '100px' },
              min: 0,
              step: 0.01
            },
            callback: async ({ $event, row, value }) => {
              const id = this.$store.state.offer.offer.uuid;
              const position = { ...row, hek: value };

              await this.$store.dispatch('offer/updateResellerProduct', { id, position });
            }
          },
          {
            label: 'offer.products.table.uvp',
            key: 'uvp',
            type: 'input:number',
            options: {
              classList: ['small'],
              styles: { 'max-width': '100px' },
              min: 0,
              step: 0.01
            },
            callback: async ({ $event, row, value }) => {
              const id = this.$store.state.offer.offer.uuid;
              const position = { ...row, uvp: value };

              await this.$store.dispatch('offer/updateResellerProduct', { id, position });
            }
          },
          {
            label: 'offer.products.table.amount',
            key: 'amount',
            type: 'input:number',
            options: {
              classList: ['small'],
              styles: { 'max-width': '75px' }
            },
            callback: async ({ $event, row, value }) => {
              const id = this.$store.state.offer.offer.uuid;
              const position = { ...row, amount: value };

              await this.$store.dispatch('offer/updateResellerProduct', { id, position });
            }
          },
          { label: 'offer.products.table.image', key: 'image', sortable: false }
        ],
        options: [
          {
            title: 'actions.delete',
            callback: async position => {
              const id = this.$store.state.offer.offer.uuid;

              await this.$store.dispatch('offer/removeResellerProduct', { id, position });
            }
          }
        ]
      }
    },
    computed: {
      ...mapState({
        selectedProducts(state) {
          const products = [...state.offer.offer.reseller_product_positions];

          return products.map(product => ({ ...product, id: product.uuid }));
        }
      })
    }
  }
</script>

<style scoped>

</style>
