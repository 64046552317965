<template>
  <div>
    <h3 class="headline headline_3">Händler</h3>

    <router-link tag="button" class="button primary" to="/admin/reseller/add">Händler hinzufügen</router-link>

    <section class="sub">
      <vue-element-loading :active="loading" spinner="bar-fade-scale" />

      <accordion v-for="(reseller, index) of resellers" :key="index">
        <template slot="header">
          {{ reseller.title }}, {{ reseller.reseller_number }}

          <div class="inline right">
            <dot-menu :options="options" :object="reseller" />
          </div>
        </template>

        <div slot="body" class="grid">
          <div :class="reseller.service_contact ? 'col3' : 'col4'">
            <h4 class="headline headline_4">Adresse</h4>

            <p>
              {{ reseller.company }} <br>
              {{ reseller.street }} <br>
              {{ reseller.zip }} {{ reseller.city }}
            </p>
          </div>
          <div :class="reseller.service_contact ? 'col3' : 'col4'">
            <h4 class="headline headline_4">Kontaktdaten</h4>

            <p>
              {{ reseller.title }} <br>
              Telefon: {{ reseller.phone }} <br>
              Fax: {{ reseller.fax }} <br>
              E-Mail: {{ reseller.email }} <br>
              Website: {{ reseller.website }} <br>
            </p>
          </div>
          <div v-if="reseller.service_contact" :class="reseller.service_contact ? 'col3' : 'col4'">
            <h4 class="headline headline_4">Ansprechpartner</h4>

            <p>
              {{ reseller.service_contact.full_name }}<br>
              Telefon: {{ reseller.service_contact.phone }} <br>
              E-Mail: {{ reseller.service_contact.email }} <br>
            </p>
          </div>
          <div :class="reseller.service_contact ? 'col3' : 'col4'">
            <h4 class="headline headline_4">Konto</h4>

            <p v-for="(account, index) of reseller.accounts" :key="index">
              Benutzername: {{ account.username }} <br>
              Passwort: ********** <br>
            </p>
          </div>
        </div>
      </accordion>
    </section>

    <router-link tag="button" to="/admin/reseller" class="button secondary">Alle Händler ansehen</router-link>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Accordion from "../../shared/components/accordion";
  import DotMenu from "../../shared/components/modal/dot-menu";
  import VueElementLoading from "vue-element-loading";

  export default {
    name: "reseller",
    components: {
      DotMenu,
      Accordion,
      VueElementLoading
    },
    computed: {
      ...mapState({
        resellers(state) {
          return [...state.reseller.resellers].map(reseller => ({ ...reseller, id: reseller.uuid }));
        }
      })
    },
    data() {
      return {
        loading: true,
        options: [
          {
            title: 'actions.edit',
            callback: reseller => this.$router.push(`/admin/reseller/${reseller.uuid}`)
          },
          {
            title: 'actions.delete',
            callback: reseller => this.$store.dispatch('reseller/delete', { id: reseller.uuid })
          }
        ]
      }
    },
    async mounted() {
      this.loading = true;
      await this.$store.dispatch('reseller/load', { limit: 5 });
      this.loading = false;
    }
  }
</script>

<style scoped>

</style>
