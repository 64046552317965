<template>
  <div>
    <h3 :class="`headline headline_${headline}`">{{ $t("content.contact.form.headline") }}</h3>

    <contact />

    <section class="sub">
      <div class="input">
        <label for="subject">{{ $t("content.contact.form.subject") }}</label>
        <input type="text" id="subject" v-model="form.subject">
      </div>

      <div class="input">
        <label for="message">{{ $t("content.contact.form.message") }}</label>
        <textarea id="message" v-model="form.message"></textarea>
      </div>
    </section>

    <button class="button primary">{{ $t("content.contact.form.submit") }}</button>
  </div>
</template>

<script>
  import Contact from './contact';

  export default {
    name: "contact-form",
    components: {
      Contact
    },
    props: {
      headline: {
        type: Number,
        default: 3
      },
    },
    data() {
      return {
        form: {
          subject: '',
          message: ''
        }
      }
    }
  }
</script>

<style scoped>

</style>
