<template>
  <section>
    <section class="sub">
      <h3 class="headline headline_3">Header</h3>

      <div class="grid">
        <div class="col6">
            <page-title></page-title>
        </div>
      </div>

      <div class="grid">
        <div class="col12">
          <content-and-image :show_title="false" :show_image="true" namespace="content_top"></content-and-image>
        </div>
      </div>

      <h3 class="headline headline_3">Seite</h3>

      <div class="grid">
        <div class="col4">
          <content-and-image namespace="content_left"></content-and-image>
        </div>
        <div class="col4">
          <content-and-image namespace="content_center"></content-and-image>
        </div>
        <div class="col4">
          <content-and-image namespace="content_right"></content-and-image>
        </div>
      </div>

      <div class="grid">
        <div class="col4">
          <content-and-image namespace="content_midleft"></content-and-image>
        </div>
        <div class="col4">
          &nbsp;
        </div>
        <div class="col4">
          <content-and-image namespace="content_midright"></content-and-image>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
    import Notifications from "../../../shared/components/notifications";
    import ContentAndImage from "../elements/content-and-image";
    import PageTitle from "../elements/page-title";

    export default {
        name: "home",
        components: {
            Notifications,
            ContentAndImage,
            PageTitle
        },
        methods: {
            async save() {
                const page = { ...this.$store.state.page.page };
                await this.$store.dispatch('page/update', { id: page.id, payload: { page } });
            },
            async discard() {
                this.$store.commit('page/reset');
                this.$router.push('/admin/page');
            },
            async changeTitle(title) {
                this.$store.commit('page/updateField', {path: 'page.title', value: title});
            }
        }
    }
</script>

<style scoped>
</style>
