export default {
    hasToken(state) {
        return !!state.token;
    },
    isAdmin(state) {
        // const adminUsers = ['admin', 'o.martin', 'd.wille', 'm.luckau', 's.maushake', 'u.lotz', 'h.eggerstedt'];
        //
        // return adminUsers.map(user => state.token.includes(user)).reduce((isCurrentUser, arrayIncludesCurrentUser) => isCurrentUser || arrayIncludesCurrentUser);
        return !!state.token && state.token.role === 'ROLE_ADMIN';
    },
    isDistributor(state) {
        return !!state.token && state.token.role === 'ROLE_DISTRIBUTOR';
    }
};
