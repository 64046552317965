import SalesConfigurator from './app/index.js';

SalesConfigurator
	.registerModule('admin-dashboard')
	.registerModule('dashboard')
	.registerModule('distributor')
	.registerModule('faq')
	.registerModule('product')
	.registerModule('profile')
	.registerModule('reseller')
	.registerModule('service-contact')
	.registerModule('system_function')
	.registerModule('region')
	.registerModule("page")
	.registerModule('offer')
	.init();

