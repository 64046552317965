import { render, staticRenderFns } from "./meta-navigation-item.vue?vue&type=template&id=1558811c&scoped=true&"
import script from "./meta-navigation-item.vue?vue&type=script&lang=js&"
export * from "./meta-navigation-item.vue?vue&type=script&lang=js&"
import style0 from "./meta-navigation-item.vue?vue&type=style&index=0&id=1558811c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1558811c",
  null
  
)

export default component.exports