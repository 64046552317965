<template>
</template>

<script>

export default {
  name: "matomo-tag",
  mounted() {
    const global = require("../../../config/index")
    const isEnabled = global.default.env.name === 'live'
    if (isEnabled) {
      console.debug('Matomo isEnabled ' + this.isEnabled)
      const siteId = global.default.env.isFontevo ? '20' : '9'

      let script = document.createElement('script')
      script.type = "text/javascript";
      script.innerHTML = `
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
            var u="//assets.auerswald.de/webstatistik/1/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '${siteId}']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
      `;
      document.head.appendChild(script)
    }
  }
}
</script>

<style scoped>

</style>
