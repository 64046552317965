// TODO(soerenuhrbach): Add translations

export default {
    categories: [
        { key: 'TELEPHONE_SYSTEM', title: 'TELEPHONE_SYSTEM' },
        { key: 'CORDLESS_AB_DEVICE', title: 'CORDLESS_AB_DEVICE' },
        { key: 'AB_DEVICE', title: 'AB_DEVICE' },
        { key: 'ENTRYPHONE_AB', title: 'ENTRYPHONE_AB' },
        { key: 'INSTALLATION', title: 'INSTALLATION' },
        { key: 'CORDLESS_SYSTEM_DEVICE', title: 'CORDLESS_SYSTEM_DEVICE' },
        { key: 'ENTRYPHONE_FTZ', title: 'ENTRYPHONE_FTZ' },
        { key: 'CORDLESS_SYSTEM_REPEATER', title: 'CORDLESS_SYSTEM_REPEATER' },
        { key: 'VOIP_DEVICE', title: 'VOIP_DEVICE' },
        { key: 'VOIP_SERVICES', title: 'VOIP_SERVICES' },
        { key: 'VOIP_SERVICES', title: 'VOIP_SERVICES' },
        { key: 'EXTENSION', title: 'EXTENSION' },
        { key: 'VOIP_SYSTEM_DEVICE', title: 'VOIP_SYSTEM_DEVICE' },
        { key: 'SERVICES', title: 'SERVICES' },
        { key: 'SYSTEM_DEVICE', title: 'SYSTEM_DEVICE' },
        { key: 'category', title: 'category' }
    ],
    currencies: [
        { label: 'EUR', value: 'EUR' },
        { label: 'CHF', value: 'CHF' },
        { label: 'USD', value: 'USD' },
        { label: 'GBP', value: 'GBP' }
    ],
    countries: [
        { label: 'Deutschland', value: 'DE' },
        { label: 'Schweiz', value: 'CH' },
        { label: 'Österreich', value: 'AU' }
    ],
    languages: [
        { label: 'Deutsch', value: 'de' },
        { label: 'Englisch', value: 'en' },
        { label: "Französisch", value: 'fr' }
    ],
    clients: [
        { label: 'Auerswald', value: 'auerswald' },
        { label: 'FONtevo', value: 'fontevo' }
    ]
};
