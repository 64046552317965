export default {
    async set({ commit }, messages) {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }
        commit('setMessages', messages);
    },
    async fromRequestException({ commit }, exception) {

        let message = { level: 'danger', title: 'Error' };

        if (!exception.response) {
            message.body = exception.message;
        } else {
            const response = exception.response.data;
            if (response.exception) {
                message.body = response.exception;
            }

            if (response.errors) {
                message.body = '';

                for (const key in response.errors) {
                    if (response.errors.hasOwnProperty(key)) {
                        if (key > 0) {
                            message.body += "<br>";
                        }
                        message.body += response.errors[key];
                    }
                }
            }
        }

        commit('setMessages', [message]);
    }
};
