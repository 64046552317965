<template>
  <section>
    <img class="home_brand" :src="this.image">
    <h2 v-html="this.title" class="headline headline_2"></h2>
    <div v-html="this.body"></div>
  </section>
</template>

<script>
  export default {
    name: "page-header",
    props: {
      pageTitle: {
        required: true
      },
      content: {
        required: true
      }
    },
    computed: {
      title() {
        if (this.pageTitle) {
          const lang = this.$i18n.locale;
          const key = 'title_' + lang;
          return this.pageTitle[key];
        }
      },
      body() {
        if (this.content) {
          const lang = this.$i18n.locale;
          const key = 'body_' + lang;
          return this.content.body[key];
        }
      },
      image() {
        if (this.content) {
          const lang = this.$i18n.locale;
          const key = 'image_' + lang;
          const uuid =  this.content.image[key];
          return this.$store.getters['asset/getPublicUrl']({ uuid } , { height: 200, width: 200 });
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .home_brand {
    float: right;
    width: 200px;
    height: 200px;
    margin-left: 1em;
  }
</style>
