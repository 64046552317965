import AdminGuard from '../../shared/router/guard/admin';

import ListResellersComponent from '../views/list';
import UpdateResellerComponent from '../views/update';

export default [
	{
		path: '/admin/reseller',
		component: ListResellersComponent,
		meta: {
			title: 'reseller.routes.reseller'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/reseller/add',
		component: UpdateResellerComponent,
		meta: {
			title: 'reseller.routes.add_reseller'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/reseller/:id',
		component: UpdateResellerComponent,
		meta: {
			title: 'reseller.routes.edit_reseller'
		},
		beforeEnter: AdminGuard
	}
];
