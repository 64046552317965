<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">
        {{ mode === "add" ? "Produkt hinzufügen" : "Produkt editieren" }}
      </h2>

      <div class="inline right">
        <button @click="save" class="button secondary">Speichern</button>
        <button @click="discard" class="button secondary">Verwerfen</button>
      </div>

      <notifications :max="3"/>

      <div>
        <tabs ref="tabs" @afterChange="tabChanged">
          <tab name="Informationen" data-load-categories>
            <information/>
          </tab>
          <tab name="Technische Daten">
            <technical-data-pbx v-if="category() === 'PBX'"/>
            <technical-data-module v-else-if="category() === 'MODULE'"/>
            <technical-data v-else/>
          </tab>
          <tab name="Regeln" data-load-products>
            <rules/>
          </tab>
        </tabs>
      </div>

      <div class="button-bar">
        <button @click="save(false)" class="button secondary">Speichern</button>
        <button @click="discard" class="button secondary">Verwerfen</button>
      </div>
    </div>
  </section>
</template>

<script>
import Tab from "../../shared/components/tabs/tab";
import Tabs from "../../shared/components/tabs/tabs";
import Rules from "../components/rules";
import Information from "../components/information";
import TechnicalData from "../components/technical-data/default";
import TechnicalDataPbx from "../components/technical-data/pbx";
import TechnicalDataModule from "../components/technical-data/module";
import Notifications from "../../shared/components/notifications";

export default {
  name: "product",
  components: {
    Notifications,
    Tab,
    Tabs,
    Rules,
    Information,
    TechnicalData,
    TechnicalDataPbx,
    TechnicalDataModule,
  },
  data() {
    return {
      mode: "add",
      category: function () {
        let category = this.$store.state.product.product.category.key;
        let subCategory = this.$store.state.product.product.sub_category.key;
        if (
          (category === "PBX" && subCategory === "MODULE") ||
          category === "SYSTEM_FUNCTION"
        ) {
          return "MODULE";
        }

        if (category === "PBX") {
          return "PBX";
        }
      },
    };
  },
  methods: {
    async save(navigate = true) {
      const product = {...this.$store.state.product.product};

      if (product.uuid) {
        await this.$store.dispatch("product/update", {
          id: product.uuid,
          payload: {product},
        });
      } else {
        await this.$store.dispatch("product/create", {payload: {product}});
      }

      if (navigate) {
        this.$router.push(`/admin/product/${product.uuid}`);
      }
    },
    discard() {
      this.$store.commit("product/reset");
      this.$router.push("/admin/product");
    },
    tabChanged() {
      const $tabs = this.$refs.tabs;
      const $currentTab = $tabs.getActiveTab();

      if ($currentTab.$attrs.hasOwnProperty("data-load-products")) {
        this.$store.dispatch("product/loadShort");
      }
    },
  },
  async mounted() {
    const params = this.$route.params;
    this.$store.dispatch("product/loadCategories");
    if (params.id) {
      this.mode = "edit";

      await this.$store.dispatch("product/loadOne", {id: params.id});
      this.$store.commit("product/select", params.id);

      const product = {...this.$store.state.product.product};

      this.$store.dispatch("product/loadCategories", {
        parent: product.category.key,
      });
      this.$store.dispatch("product/systemFunctions/load");
    } else {
      this.$store.commit("product/reset");
    }
  },
};
</script>

<style lang="less" scoped>
.tabs {
  margin-bottom: 0;
}

.button-bar {
  padding-top: 2em;
}
</style>
