<template>
  <div class="login">
    <div class="content-wrapper">
      <div class="main-content">
        <div class="grid">
          <div class="col4"></div>
          <div class="col4">
            <div class="white-box">
              <notifications :max="3" />

              <form @submit="login">
                <alert level="danger" :title="$t('login.error')" v-if="errored">
                  <div v-html="$t('login.wrong_credentials')"></div>
                </alert>

                <div class="input">
                  <label for="username">{{ $t("login.username") }}</label>
                  <input id="username" type="text" v-model="username" />
                </div>

                <div class="input">
                  <label for="password">{{ $t("login.password") }}</label>
                  <input id="password" type="password" v-model="password" />
                </div>

                <button class="button primary" type="submit" @click="login">
                  {{ $t("login.login") }}
                </button>
              </form>
            </div>
          </div>
          <div class="col4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "../components/alert";
import Notifications from "../components/notifications";

export default {
  components: { Alert, Notifications },
  name: "login",
  data() {
    return {
      errored: false,
      username: "",
      password: "",
    };
  },
  methods: {
    async login($event) {
      $event.preventDefault();

      const success = await this.$store.dispatch("auth/login", {
        username: this.username,
        password: this.password,
      });

      this.errored = !success;

      if (success) {
        const role = this.$store.state.auth.token.role;
        if (role === "ROLE_ADMIN") {
          this.$router.push("/admin");
        } else if (role === "ROLE_DISTRIBUTOR") {
          this.$router.push("/distributor");
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../res/less/core/config/constants";
@import "../../../res/less/themes/theme";

.login {
  position: fixed;
  z-index: 99999999;
  top: @bodyMargin;
  right: @bodyMargin;
  left: @bodyMargin;
  bottom: @bodyMargin;
  background: @bodyBackground;
}

.content-wrapper {
  height: 100%;

  .main-content {
    height: 100%;
    background-color: transparent;

    .grid {
      top: 50%;
      width: 100%;
      position: absolute;
      transform: translateY(-50%);
    }
  }
}

.white-box {
  width: 100%;
  padding: @space5 @space5 @space2 @space5;
  background-color: @white-100;

  .button {
    width: 100%;
  }
}
</style>
