let json = false;

try {
    json = JSON.parse(localStorage.getItem('token')); // TODO(soerenuhrbach): Beautify
} catch (exception) {
    // TODO: logging e.g.
}

export default {
    token: json
};
