<template>
  <div>
    <div>
      <h2 class="headline headline_2 inline">
        {{ $t("distributor.dashboard.headline") }}
      </h2>

      <div class="inline right">
        <button class="button primary" @click="save()">
          {{ $t("actions.save") }}
        </button>
      </div>
    </div>

    <section>
      <div class="grid">
        <div class="col12">
          <div>
            <import-prices-modal>
              <button class="button secondary trigger">
                {{ $t("distributor.dashboard.import") }}
              </button>
            </import-prices-modal>

            <button class="button secondary" @click="exportPrices">
              {{ $t("distributor.dashboard.export") }}
            </button>
          </div>

          <notifications :max="3" />

          <data-table :fields="fields" :data="products"></data-table>

          <button class="button primary" @click="save()">
            {{ $t("actions.save") }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "../../shared/components/table/table";
import Notifications from "../../shared/components/notifications";
import ImportPricesModal from "../components/import-prices-modal";

export default {
  components: { ImportPricesModal, DataTable, Notifications },
  name: "dashboard",
  data() {
    return {
      fields: [
        { label: "distributor.dashboard.table.number", key: "article_number" },
        { label: "distributor.dashboard.table.name", key: "title" },
        {
          label: "distributor.dashboard.table.price",
          key: "price",
          type: "input",
          callback: ({ $event, field, row, value: price }) => {
            this.$store.commit("distributor/product/updatePrice", {
              id: row.uuid,
              price,
            });
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("distributor/product", {
      products(state) {
        return state.products.map((product) => ({
          id: product.uuid,
          ...product,
        }));
      },
    }),
  },
  methods: {
    formatPrice(price = 0, currency = "EUR") {
      const locale = this.$i18n.locale;

      if (!currency) {
        currency = "EUR";
      }

      return Number(price).toLocaleString(locale, {
        style: "currency",
        currency,
      });
    },
    async save() {
      await this.$store.dispatch("distributor/product/updateAll");
    },
    async exportPrices() {
      await this.$store.dispatch("distributor/product/export");
    },
  },
  async mounted() {
    await this.$store.dispatch("distributor/product/load");
  },
};
</script>

<style lang="less" scoped>
@import "../../../res/less/core/config/constants";

.trigger {
  margin-right: @space3;
}
</style>
