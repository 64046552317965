import Application from '../../../index';

export default function isAuthenticatedAsReseller(to, from, next) {
	const store = Application.getStore();
	const alias = to.params.distributor;
	const region = to.params.region;
	const distributor = store.state['offer/distributor'].distributor;

	if (!distributor || alias !== distributor.alias) {
		next(`/${region}/${alias}/login`);
	} else {
		next();
	}
}
