<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.system_functions.headline') }}</h4>

    <div class="separator" v-for="(systemFunction, index) of systemFunctions" :key="index">
      <div class="input">
        <label :for="'systemfunction-product-' + index">{{ $t('product.product.tabs.rules.sections.system_functions.product') }}</label>
        <dropdown :id="'systemfunction-product-' + index" :value="systemFunction.product" @change="updateSystemFunction(index, { product: $event })" :data="products" :placeholder="$t('product.product.tabs.rules.sections.system_functions.placeholder')">
          <dot-menu slot="options" :object="{ index }" :options="options"></dot-menu>
        </dropdown>
      </div>

      <div class="input" v-if="systemFunction.type === 'number'">
        <label :for="'systemfunction-value-' + index">{{ $t('product.product.tabs.rules.sections.system_functions.max') }}</label>
        <input type="number" min="1" :id="'systemfunction-value-' + index" :value="systemFunction.value" @change="updateSystemFunction(index, { value: $event.target.value })">
      </div>
      <div class="input" v-else>
        <input type="hidden" :value="1">
      </div>

      <div class="input">
        <checkbox id="override-by-modules" :value="systemFunction.override_by_modules" @change="updateSystemFunction(index, { override_by_modules: $event })">
          Wird von Modulen/Erweiterungen überschrieben (Module wirken nicht additiv)
        </checkbox>
      </div>
    </div>

    <button class="button secondary" @click="addSystemFunction()">{{ $t('product.product.tabs.rules.sections.system_functions.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';
  import Checkbox from "../../../shared/components/form/checkbox";

  export default {
    name: "system-functions",
    components: {
      Checkbox,
      DotMenu,
      Dropdown
    },
    data() {
      return {
        options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removeSystemFunction', index)
          }
        ]
      }
    },
    methods: {
      addSystemFunction() {
        this.$store.commit('product/addSystemFunction');
      },
      updateSystemFunction(index, changes) {
        console.log(changes);
        let sF = this.$store.state['product/systemFunctions'].system_functions.find(sF => sF.uuid === changes.product);
        if (sF) {
          changes.type = sF.type;
        }
        this.$store.commit('product/updateSystemFunction', { index, changes });
      }
    },
    computed: {
      ...mapState({
        products(state) {
          return [...state['product/systemFunctions'].system_functions].map(sF => ({ value: sF.uuid, label: sF.title }));
        },
        systemFunctions(state) {
          return [...state.product.product.system_functions];
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
