<template>
  <div class="modal-wrapper" v-show="show">
    <div v-show="backdrop" class="backdrop"></div>
    <div class="modal">
      <div class="body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "modal",
    data() {
      return {
        show: false
      }
    },
    props: {
      backdrop: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      open() {
        this.show = true;
      },
      close() {
        this.show = false;
      },
      toggle() {
        this.show = !this.show;
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";
  @import "../../../../res/less/core/config/constants";

  .modal-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: @modalIndex;
    position: fixed;

    .backdrop {
      width: 100%;
      height: 100%;
      background-color: fade(@brand-2-100, 25%);
    }

    .body {
      top: 50%;
      left: 50%;
      position: fixed;
      transform: translate(-50%, -50%);
    }
  }
</style>
