import { render, staticRenderFns } from "./import-prices-modal.vue?vue&type=template&id=3568e70d&scoped=true&"
import script from "./import-prices-modal.vue?vue&type=script&lang=js&"
export * from "./import-prices-modal.vue?vue&type=script&lang=js&"
import style0 from "./import-prices-modal.vue?vue&type=style&index=0&id=3568e70d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3568e70d",
  null
  
)

export default component.exports