<template>
  <div class="steps">
    <div class="header">
      <div class="header-wrap" :class="'items' + tabs.length">
        <div class="step"
             v-for="(step, index) in tabs"
             :style="{ width: (100 / tabs.length) + '%' }"
             :class="{ disabled: step.disabled, active: step.active, done: index < active.index, substep:step.substep }"
             @click="selectTab(step.id)"
             :title="step.name">

          <div class="indicator" :data-index="index + 1"></div>

          {{ step.name }}
        </div>
      </div>
    </div>
    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import Tabs from './tabs';

  export default {
    extends: Tabs,
    name: "steps"
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";
  @import "../../../../res/less/core/config/constants";

  @indicatorSize: @space5;

  .buildItems(@columns; @index: 1) when (@index =< @columns) {
    .buildItems(@columns, (@index + 1));

    &.items@{index} {
      &:before {
        @itemSize: 100% / @index;
        @width: 100% - @itemSize;
        @leftOffset: @itemSize / 2;
        @indicatorSizeHalf: @indicatorSize / 2;

        width: ~"calc(@{width} - @{indicatorSize})";
        left: ~"calc(@{leftOffset} + @{indicatorSizeHalf})";
      }
    }
  }

  .steps {
    .header {
      padding: @space5;
      margin-bottom: @space8;
      background-color: @black-05;

      .header-wrap {
        position: relative;

        &:before {
          content: "";
          z-index: 1;
          display: block;
          top: @space5 / 2;
          position: absolute;
          border-bottom: 1px solid @brand-1-100;
        }

        .buildItems(8);

        .step {
          cursor: pointer;
          font-size: @fontSize;
          line-height: @space3;
          z-index: 2;
          position: relative;
          display: inline-block;
          text-align: center;
          vertical-align: top;

          &.active {
            color: @brand-1-100;
            font-weight: bold;

            .indicator {
              background-color: @brand-1-100;

              &:before {
                color: @white-100;
              }
            }
          }

          &.substep {
            line-height: 0rem;
            font-size: 0;

            .indicator {
              width: 7rem;
              height: 7rem;
            }
          }

          &.disabled {
            cursor: not-allowed;
          }

          &.done .indicator {
            position: relative;

            &:before {
              content: "";
              top: 50%;
              left: 50%;
              width: 16px;
              height: 12px;
              position: absolute;
              transform: translate(-50%, -50%);
              .icon('check/check-brand-2-100');
            }
          }

          .indicator {
            width: @indicatorSize;
            background-color: @black-05;
            height: @indicatorSize;
            border: 1px solid @brand-1-100;
            margin: 0 auto @space2 auto;

            &:before {
              content: attr(data-index);
              font-family: "RBNo2.1b-Light", sans-serif;
              line-height: @indicatorSize;
              font-size: @space3;
              color: @brand-1-100;
            }
          }
        }
      }
    }
  }
</style>
