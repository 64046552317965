<template>
  <div class="tabs">
    <div class="header">
      <div
        class="tab-name"
        v-for="tab in tabs"
        :key="tab.name"
        :style="{ width: (100 / tabs.length) + '%' }"
        :class="{ disabled: tab.disabled, active: tab.active }"
        @click="selectTab(tab.id)"
        :title="tab.name"
      >{{ tab.name }}</div>
    </div>
    <div class="body">
      <slot></slot>
    </div>
    <div class="footer">
      <div
        class="tab-name"
        v-for="tab in tabs"
        :key="tab.name"
        :style="{ width: (100 / tabs.length) + '%' }"
        :class="{ disabled: tab.disabled, active: tab.active }"
        @click="selectTab(tab.id)"
        :title="tab.name"
      >{{ tab.name }}</div>
    </div>
  </div>
</template>

<script>
import { wait } from "../../../../res/helper/wait";

export default {
  name: "tabs",
  data() {
    return {
      tabs: [],
      active: {
        index: null,
        id: null
      }
    };
  },
  methods: {
    async update() {
      this.tabs = this.$children.filter(
        $child => $child.$options._componentTag === "tab"
      );
    },
    selectTab(tabId) {
      const tab = this.tabs.find(tab => tab.id === tabId);

      if (tab && !tab.disabled) {
        this.$emit("beforeChange", tab);

        this.tabs.forEach(tab => (tab.active = false));

        tab.active = true;

        this.active.id = tab.id;
        this.active.index = this.tabs.findIndex(tab => tab.id === tabId);

        this.$emit("afterChange", tab);
      }
    },
    getActiveTab() {
      return this.tabs.find(tab => tab.id === this.active.id);
    }
  },
  async mounted() {
    await wait(1200);
    this.update();

    const firstActiveTab = this.tabs.find(tab => {
      return tab.firstactive === true;
    });
    if (firstActiveTab) {
      this.selectTab(firstActiveTab.id);
    } else if (this.tabs[0]) {
      this.selectTab(this.tabs[0].id);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../res/less/themes/theme";

.tabs {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @black-05;
    border-top-left-radius: @borderRadiusTab;
    border-top-right-radius: @borderRadiusTab;

    .tab-name {
      &:first-child {
        border-top-left-radius: @borderRadiusTab;
      }

      &:last-child {
        border-top-right-radius: @borderRadiusTab;
      }

      cursor: pointer;
      border: 1px solid @black-05;
      color: @black-65;
      padding: @space2;
      text-align: center;

      &.active {
        color: @white-100;
        background-color: @brand-1-100;
        border-color: @brand-1-100;
      }

      &.disabled {
        color: @black-40;
      }

      &:hover:not(.active) {
        color: @brand-1-100;
        border-bottom: 1px solid @brand-1-100;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: @black-05;
    border-bottom-left-radius: @borderRadiusTab;
    border-bottom-right-radius: @borderRadiusTab;

    .tab-name {
      &:first-child {
        border-bottom-left-radius: @borderRadiusTab;
      }

      &:last-child {
        border-bottom-right-radius: @borderRadiusTab;
      }

      cursor: pointer;
      border: 1px solid @black-05;
      color: @black-65;
      padding: @space2;
      text-align: center;

      &.active {
        color: @white-100;
        background-color: @brand-1-100;
        border-color: @brand-1-100;
      }

      &.disabled {
        color: @black-40;
      }

      &:hover:not(.active) {
        color: @brand-1-100;
        border-bottom: 1px solid @brand-1-100;
      }
    }
  }

  .body {
    margin-top: @space5;
  }
}
</style>
