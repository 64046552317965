<template>
  <div class="style-guide">
    <div class="grid">
      <div class="col12">
        <section>
          <div class="headline">Typography</div>

          <div>
            <h1 class="headline headline_1">Headline 1</h1>
            <h2 class="headline headline_2">Headline 2</h2>
            <h3 class="headline headline_3" data-index="1">Headline 3 mit Index</h3>
            <h3 class="headline headline_3">Headline 3</h3>
            <h4 class="headline headline_4">Headline 4</h4>

            <div class="quote">
              Blockquote <br>
              The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
            </div>

            <p class="paragraph-intro">
              Paragraph Intro <br>
              The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
            </p>

            <p>
              Paragraph <br>
              The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
            </p>

            <p class="caption">
              Caption <br>
              The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
            </p>


            <a href="#">Link</a>
          </div>

        </section>

        <section>
          <div class="headline">Buttons</div>

          <div class="grid">
            <div class="col2">
              <div class="sub-title">Primary</div>

              <div class="item">
                <button class="button primary">Default</button>
              </div>


              <div class="item">
                <button class="button primary" disabled>disabled</button>
              </div>

              <div class="item">
                <button class="button primary small">small</button>
              </div>
            </div>

            <div class="col2">
              <div class="sub-title">Secondary</div>

              <div class="item">
                <button class="button secondary">Default</button>
              </div>

              <div class="item">
                <button class="button secondary" disabled>disabled</button>
              </div>

              <div class="item">
                <button class="button secondary small">small</button>
              </div>
            </div>

            <div class="col2">
              <div class="sub-title">Ghost</div>

              <div class="item">
                <button class="button ghost">Default</button>
              </div>

              <div class="item">
                <button class="button ghost" disabled>disabled</button>
              </div>

              <div class="item">
                <button class="button ghost small">small</button>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="headline">Inputs</div>

          <div class="grid">
            <div class="col3">
              <div class="sub-title">Input</div>

              <div class="item">
                <input type="text" class="small small-40" placeholder="Input-Small" />
              </div>

              <div class="item">
                <input type="text" placeholder="Default">
              </div>

              <div class="item">
                <input type="text" placeholder="Disabled" disabled>
              </div>

              <div class="item">
                <div class="input-error" data-help="Help Text">
                  <input type="text" placeholder="Error">
                </div>
              </div>

              <div class="item">
                <textarea placeholder="AB" res></textarea>
              </div>

              <div class="item">
                <file-upload></file-upload>
              </div>
            </div>
            <div class="col3">
              <div class="sub-title">Dropdown</div>

              <div class="item">
                <dropdown v-model="dropdownModel1" :data="dropdownData"></dropdown>
              </div>

              <div class="item">
                <dropdown v-model="dropdownModel2" :data="dropdownData">
                  <dot-menu slot="options" :options="dotMenuOptions"></dot-menu>
                </dropdown>
              </div>
            </div>
            <div class="col3">
              <div class="sub-title">Checkbox</div>

              <div class="item">
                <checkbox id="checkbox-1">Default test</checkbox>
              </div>

              <div class="item">
                <checkbox id="checkbox-2" :disabled="true">Disabled</checkbox>
              </div>

              <div class="item">
                <checkbox id="checkbox-3" :disabled="true">Disabled</checkbox>
              </div>
            </div>
            <div class="col3">
              <div class="sub-title">Radio</div>

              <div class="item">
                <radio name="radio-test" id="radio-test-1">Default</radio>
              </div>
              <div class="item">
                <radio name="radio-test" id="radio-test-4">Default</radio>
              </div>

              <div class="item">
                <radio name="radio-test-2" id="radio-test-2" :disabled="true">Disabled</radio>
              </div>

              <div class="item">
                <radio name="radio-test-3" id="radio-test-3" :disabled="true" :value="true">Disabled & Checked</radio>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="headline">UI Elements</div>

          <div class="grid">
            <div class="col12">
              <div class="sub-title">Table</div>

              <div class="item">
                <data-table :fields="tableData.fields" :data="tableData.data">
                  <tr slot="empty">
                    <td :colspan="tableData.fields.length">Es wurden keine Daten gefunden</td>
                  </tr>
                </data-table>
              </div>

              <div class="item">
                <data-table :fields="tableData.fields" :data="tableData.data"></data-table>
              </div>

              <div class="item">
                <checkable-table :fields="tableData.fields" :data="tableData.data"></checkable-table>
              </div>

              <div class="item">
                <checkable-table v-model="radioTableValue" :radio="true" :fields="tableData.fields" :data="tableData.data">
                  <template slot="last-col" slot-scope="scope">
                    <div class="inline right">
                      <dot-menu :options="dotMenuOptions" :object="scope.row"></dot-menu>
                    </div>
                  </template>
                </checkable-table>
              </div>

              <div class="item">
                <data-table :fields="tableData.fields" :data="tableData.data">
                  <template slot="last-col" slot-scope="scope">
                    <div class="inline right">
                      <dot-menu :options="dotMenuOptions" :object="scope.row"></dot-menu>
                    </div>
                  </template>
                </data-table>
              </div>
            </div>

            <div class="col12">
              <div class="sub-title">Accordion</div>

              <div class="item">
                <accordion>
                  <template slot="header">
                    Label
                  </template>
                  <template slot="body">
                    Content
                  </template>
                </accordion>
              </div>

              <div class="item">
                <accordion layout="transparent">
                  <template slot="header">
                    Transparent
                  </template>
                  <template slot="body">
                    Content
                  </template>
                </accordion>
              </div>
            </div>

            <div class="col12">
              <div class="sub-title">Tooltip</div>

              <div class="grid">
                <div class="col2">
                  <div class="item">
                    <tooltip>
                      <template slot="toggle">
                        Tooltip above
                      </template>
                      <template slot="body">
                        Tooltip Inhalt tggg
                      </template>
                    </tooltip>
                  </div>
                </div>

                <div class="col2">
                  <div class="item">
                    <tooltip position="bottom">
                      <template slot="toggle">
                        Tooltip below
                      </template>
                      <template slot="body">
                        Tooltip Inhalt
                      </template>
                    </tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div class="col12">
              <div class="sub-title">Tabs</div>

              <tabs>
                <tab name="First Tab">
                  Content
                </tab>
                <tab name="Second Tab">
                  Content 2
                </tab>
              </tabs>

              <dropdown-tabs>
                <tab name="First Tab">
                  Content
                </tab>
                <tab name="Second Tab">
                  Content 2
                </tab>
                <dot-menu slot="options" slot-scope="scope" :object="scope.data" :options="dotMenuOptions"></dot-menu>
              </dropdown-tabs>

              <div class="sub-title">Steps</div>

              <steps>
                <tab name="First Step">
                  Content
                </tab>
                <tab name="Second Step">
                  Content 2
                </tab>
                <tab name="Third Step">
                  Content 3
                </tab>
                <tab name="Third Step">
                  Content 4
                </tab>
                <tab name="Third Step">
                  Content 5
                </tab>
                <tab name="Third Step">
                  Content 6
                </tab>
              </steps>
            </div>

            <div class="col12">
              <div class="sub-title">Tags</div>

              <div class="tag">Tag</div>
              <p></p>
            </div>

            <div class="col12">
              <div class="sub-title">Modal</div>

            </div>

            <div class="col12">
              <div class="sub-title">Alerts</div>

              <alert title="Title" level="info">
                The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
              </alert>

              <alert title="Title" level="success">
                The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
              </alert>

              <alert title="Title" level="warn">
                The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
              </alert>

              <alert title="Title" level="danger">
                The quick brown fox jumps over the lazy dog. Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum.
              </alert>
            </div>

            <div class="col12">
              <div class="sub-title">Dot-Menu</div>

              <dot-menu :options="dotMenuOptions"></dot-menu>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import Tab from '../components/tabs/tab';
  import Tabs from '../components/tabs/tabs';
  import Steps from '../components/tabs/steps';
  import Radio from '../components/form/radio';
  import Alert from '../components/alert';
  import Tooltip from '../components/tooltip';
  import Dropdown from '../components/form/dropdown';
  import Checkbox from '../components/form/checkbox';
  import DataTable from '../components/table/table';
  import Accordion from '../components/accordion';
  import FileUpload from "../components/form/file-upload";
  import DotMenu from "../components/modal/dot-menu";
  import CheckableTable from "../components/table/checkable-table";
  import DropdownTabs from "../components/tabs/dropdown-tabs";

  export default {
    name: "style-guide",
    components: {
      DropdownTabs,
      CheckableTable,
      Tab,
      Tabs,
      Steps,
      Alert,
      Radio,
      DotMenu,
      Tooltip,
      Checkbox,
      Dropdown,
      DataTable,
      Accordion,
      FileUpload
    },
    data() {
      return {
        tableData: {
          fields: [
            { label: 'name', key: 'name' },
            { label: 'Nicht sortierbar', key: 'name2', sortable: false },
            { label: 'Data-Transform', key: 'image', transform: (item, row) => `<img src="${item}" title="${row.name}" />`, sortable: false },
            { label: 'Data-Transform', key: 'category.0', sortable: false }
          ],
          data: [
            { id: 1, name: 'A', name2: 'F', image: 'https://placehold.it/50x50', category: [9] },
            { id: 2, name: 'B', name2: 'U', image: 'https://placehold.it/50x50', category: [1]},
            { id: 3, name: 'C', name2: 'Z', image: 'https://placehold.it/50x50', category: [1] },
            { id: 4, name: 'D', name2: 'Y', image: 'https://placehold.it/50x50', category: [4] }
          ]
        },
        dropdownData: [
          { value: 'Test', label: 'Test' },
          { value: 'Test 2', label: 'Test 2' },
          { value: 'Test 3', label: 'Test 3' },
          { value: 'Test 4', label: 'Test 4' },
        ],
        dotMenuOptions: [
          { title: 'Say hi', callback: x => console.log(x) },
          { title: 'Say goodbye', callback: x => console.log(x) },
        ],
        radioTableValue: [],
        dropdownModel1: null,
        dropdownModel2: null,
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";
  @import "../../../res/less/core/config/constants";

  section > .headline, .sub-title {
    font-family: "Century Gothic", sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 1px;
    color: #000;
    margin-bottom: @space4;

    &.sub-title {
      font-size: 12px;
      margin-bottom: @space2;
    }
  }
</style>
