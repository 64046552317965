<template>
  <div class="region-select">
    <div class="content-wrapper">
      <div class="main-content">
        <div class="grid">
          <div class="col4"></div>
          <div class="col4">
            <div class="white-box">
              <notifications :max="3" />

              <form @submit="select">
                <div class="input">
                  <label for="region-select-regions">{{
                    $t("region_select.regions")
                  }}</label>
                  <dropdown
                    v-model="region"
                    id="region-select-regions"
                    :data="regions"
                  ></dropdown>
                </div>
                <button class="button primary" type="submit" @click="select">
                  {{ $t("region_select.select") }}
                </button>
              </form>
            </div>
          </div>
          <div class="col4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from "../components/notifications";
import Dropdown from "../components/form/dropdown";
import { mapState } from "vuex";

export default {
  components: { Notifications, Dropdown },
  name: "region-select",
  data() {
    return {
      errored: false,
      region: "",
    };
  },
  computed: {
    ...mapState({
      regions(state) {
        const regions = [...state.region.regions];
        return regions.map((region) => ({
          value: region,
          label: `${region.title_out} (${region.shortcut})`,
        }));
      },
    }),
  },
  methods: {
    async select($event) {
      $event.preventDefault();
      const region = this.region;
      this.$router.push("/" + region.shortcut);
    },
  },
  async mounted() {
    await this.$store.dispatch("region/loadByClient");
    this.region = this.$store.state.region.regions.find(
      (region) => region !== undefined
    );
  },
};
</script>

<style lang="less" scoped>
@import "../../../res/less/core/config/constants";
@import "../../../res/less/themes/theme";

.region-select {
  position: fixed;
  z-index: 99999999;
  top: @bodyMargin;
  right: @bodyMargin;
  left: @bodyMargin;
  bottom: @bodyMargin;
  background: @bodyBackground;
}

.content-wrapper {
  height: 100%;

  .main-content {
    height: 100%;
    background-color: transparent;

    .grid {
      top: 50%;
      width: 100%;
      position: absolute;
      transform: translateY(-50%);
    }
  }
}

.white-box {
  width: 100%;
  padding: @space5 @space5 @space2 @space5;
  background-color: @white-100;

  .button {
    width: 100%;
  }
}
</style>
