import Application from '../../../index';

export default async function isAuthenticated(to, from, next) {
    const store = Application.getStore();
    const hasToken = store.getters['auth/hasToken'];

    if (!hasToken) {
        await store.dispatch('auth/resellerlogin', { username: 'reseller', throwSuccessMessage: false });
    }

    return from ? next() : next('/home');
}
