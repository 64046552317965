<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">Systemfunktion</h2>

      <div class="inline right">
        <button class="button secondary" @click="save()">Speichern</button>
        <button class="button secondary" @click="discard()">Verwerfen</button>
      </div>
    </div>

    <notifications :max="3" />

    <section class="sub">
      <div class="grid">
        <div class="col6">
          <h3 class="headline headline_3">Systemfunktion</h3>

          <language-tabs>
            <tab name="DE" language="de">
              <div class="input">
                <label for="system_function_title">Titel</label>
                <input type="text" id="system_function_title" v-model="title" />
              </div>
            </tab>
            <tab name="EN (AU)" language="en">
              <div class="input">
                <label for="system_function_title_en_au">Titel</label>
                <input
                  type="text"
                  id="system_function_title_en_au"
                  v-model="title_en_au"
                />
              </div>
            </tab>
            <tab name="EN (FON)" language="en">
              <div class="input">
                <label for="system_function_title_en">Titel</label>
                <input
                  type="text"
                  id="system_function_title_en"
                  v-model="title_en"
                />
              </div>
            </tab>
            <tab name="FR" language="fr">
              <div class="input">
                <label for="system_function_title_fr">Titel</label>
                <input
                  type="text"
                  id="system_function_title_fr"
                  v-model="title_fr"
                />
              </div>
            </tab>
          </language-tabs>

          <div class="input">
            <label>Typ</label>
            <dropdown v-model="type" :data="types"></dropdown>
          </div>

          <div class="input">
            <label>Auswahl-Typ</label>
            <dropdown
              v-model="selection_type"
              :data="selectionTypes"
            ></dropdown>
          </div>
        </div>
      </div>
    </section>

    <button class="button secondary" @click="save()">Speichern</button>
    <button class="button secondary" @click="discard()">Verwerfen</button>
  </section>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import Tab from "../../shared/components/tabs/tab";
import DotMenu from "../../shared/components/modal/dot-menu";
import Dropdown from "../../shared/components/form/dropdown";
import Notifications from "../../shared/components/notifications";
import LanguageTabs from "../../shared/components/tabs/language-tabs";

export default {
  name: "system_function",
  components: {
    LanguageTabs,
    Notifications,
    DotMenu,
    Dropdown,
    Tab,
  },
  data() {
    return {
      types: [
        { label: "Checkbox", value: "checkbox" },
        { label: "Nummer", value: "number" },
      ],
      selectionTypes: [
        { label: "Auswahl am Gerät", value: "device" },
        { label: "Auswahl im Angebot", value: "offer" },
        { label: "Automatische Auswahl", value: "auto" },
      ],
    };
  },
  methods: {
    async save() {
      const system_function = {
        ...this.$store.state.systemFunctions.system_function,
      };

      if (system_function.uuid) {
        this.$store.dispatch("systemFunctions/update", {
          id: system_function.uuid,
          payload: { system_function },
        });
      } else {
        this.$store.dispatch("systemFunctions/create", {
          payload: { system_function },
        });
      }

      this.$router.push("/admin/system_functions");
    },
    async discard() {
      this.$store.commit("systemFunctions/reset");
      this.$router.push("/admin/system_functions");
    },
  },
  computed: {
    ...mapFields("systemFunctions", {
      title: "system_function.title",
      title_en: "system_function.title_en",
      title_fr: "system_function.title_fr",
      title_en_au: "system_function.title_en_au",
      type: "system_function.type",
      selection_type: "system_function.selection_type",
    }),
  },
  async mounted() {
    const params = this.$route.params;

    if (params.id) {
      await this.$store.dispatch("systemFunctions/loadOne", { id: params.id });
      this.$store.commit("systemFunctions/select", params.id);
    }
  },
};
</script>

<style scoped>
</style>
