import AdminGuard from '../../shared/router/guard/admin';
import ProductListComponent from '../views/list';
import ProductUpdateComponent from '../views/update';

export default [
	{
		path: '/admin/product',
		component: ProductListComponent,
		meta: {
			title: 'product.routes.products'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/product/add',
		component: ProductUpdateComponent,
		meta: {
			title: 'product.routes.add_product'
		},
		beforeEnter: AdminGuard
	},
	{
		path: '/admin/product/:id',
		component: ProductUpdateComponent,
		meta: {
			title: 'product.routes.edit_product'
		},
		beforeEnter: AdminGuard
	}
];
