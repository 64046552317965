import axios from "axios";
import config from "../../../../config";

const hooks = {
    async update(payload) {
        const profile = { ...payload.profile };

        if (profile.logo) {
            profile.logo = profile.logo.uuid;
        }

        return { profile };
    }
};

export default {
    async load({ commit, dispatch }, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/distributor/profile`, { params });
            const data = response.data;

            commit('loaded', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
    async update({ commit }, { payload, params = {} }) {
        let p = hooks.update && typeof hooks.update === "function" ? await hooks.update({ ...payload }) : payload;

        try {
            const response = await axios.put(`${config.api.endpoint}/distributor/profile`, p, { params });
            const data = response.data;

            commit('updated', { response: data, data: payload });

            this.dispatch('notification/set', {
                level: 'success',
                title: data.message
            })
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    },
};
