import { uniq } from "underscore";
import { updateField } from "vuex-map-fields";

export default {
	async loaded(state, data) {
		state.resellers = uniq([...data.resellers], 'uuid');
		state.pagination = { ...(data.pagination || pagination) };
	},
	updateField
};
