<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.attribute_combinations.headline') }}</h4>

    <button class="button secondary" @click="addCombinationCollection()">{{ $t('product.product.tabs.rules.sections.attribute_combinations.add') }}</button>

    <dropdown-tabs ref="combinationTabs" v-if="collections.length > 0" :label="$t('product.product.tabs.rules.sections.attribute_combinations.combination')">
      <tab v-for="(collection, index) of collections" :key="index" :name="index.toString()">
        <div class="grid" v-for="(combination, index) in collection.combinations" :key="index">
          <div class="col6">
            <div class="input">
              <label :for="`collection-${collection.id}-combination-${combination.id}-type`">{{ $t('product.product.tabs.rules.sections.attribute_combinations.attribute') }}</label>
              <dropdown :id="`collection-${collection.id}-combination-${combination.id}-type`" :data="productAttributes" v-model="combination.type" @change="updateCollectionName(collection.id)">
                <dot-menu slot="options" :options="combinationOptions" :object="{ collection, combination }"></dot-menu>
              </dropdown>
            </div>
          </div>

          <div class="col3">
            <div class="input">
              <label :for="`collection-${collection.id}-combination-${combination.id}-min`">{{ $t('product.product.tabs.rules.sections.attribute_combinations.min') }}</label>
              <input :id="`collection-${collection.id}-combination-${combination.id}-min`" type="number" min="0" v-model="combination.min" @change="updateCollectionName(collection.id)">
            </div>
          </div>

          <div class="col3">
            <div class="input">
              <label :for="`collection-${collection.id}-combination-${combination.id}-max`">{{ $t('product.product.tabs.rules.sections.attribute_combinations.max') }}</label>
              <input :id="`collection-${collection.id}-combination-${combination.id}-max`" type="number" min="0" v-model="combination.max" @change="updateCollectionName(collection.id)">
            </div>
          </div>
        </div>

        <button class="button secondary" @click="addCombinationToCollection(collection.id)">{{ $t('product.product.tabs.rules.sections.attribute_combinations.add_attribute') }}</button>
      </tab>

      <dot-menu slot="options" slot-scope="scope" :object="scope.data" :options="collectionOptions"></dot-menu>
    </dropdown-tabs>
  </section>
</template>

<script>
  import { wait } from "../../../../res/helper/wait";
  import { mapState } from 'vuex';

  import Tab from '../../../shared/components/tabs/tab';
  import DotMenu from '../../../shared/components/modal/dot-menu';
  import Dropdown from '../../../shared/components/form/dropdown';
  import DropdownTabs from '../../../shared/components/tabs/dropdown-tabs';

  export default {
    name: "attribute-combinations",
    components: {
      Tab,
      DotMenu,
      Dropdown,
      DropdownTabs
    },
    data() {
      return {
        combinationCollectionIndex: 0,
        attributeCollectionIndexMap: { },
        collectionOptions: [
          {
            title: 'actions.delete',
            callback: combination => {
              const tabsComponent = this.$refs.combinationTabs;

              this.product.combinations = this.product.combinations.filter(_combination => _combination.name !== combination.label);

              if (tabsComponent) {
                this.asyncUpdateTabs(tabsComponent)
                  .then(tabs => tabsComponent.currentTab = tabs.first().id);
              }
            }
          }
        ],
        combinationOptions: [
          {
            title: 'actions.delete',
            callback: ({ collection, combination }) => {
              const collectionIndex = this.product.combinations.findIndex(c => c.id === collection.id);
              const c = this.product.combinations[collectionIndex].combinations.find(c => c.id === combination.id);

              this.product.combinations[collectionIndex].combinations = this.product.combinations[collectionIndex].combinations.filter(_attribute => _attribute.id !== c.id);
              this.updateCollectionName(combination.id);
            }
          }
        ]
      }
    },
    methods: {
      addCombinationCollection() {
        const id = this.combinationCollectionIndex + 1;
        const tabsComponent = this.$refs.combinationTabs;

        this.product.combinations.push({
          id,
          name: `${this.$t('product.product.tabs.rules.sections.attribute_combinations.combination')} ${id}`,
          combinations: [
            {
              id: 0,
              type: '',
              min: 0,
              max: 0
            }
          ]
        });

        if (tabsComponent) {
          this.asyncUpdateTabs(tabsComponent)
            .then(tabs => tabsComponent.currentTab = tabs.last().id);
        }

        this.combinationCollectionIndex++;
        this.attributeCollectionIndexMap[id] = 1;
      },
      addCombinationToCollection(collectionId) {
        const collectionIndex = this.product.combinations.findIndex(collection => collection.id === collectionId);

        this.product.combinations[collectionIndex].combinations.push({
          id: this.attributeCollectionIndexMap[collectionId] + 1,
          type: '',
          min: 0,
          max: 0
        });

        this.attributeCollectionIndexMap[collectionId]++;
      },
      async updateCollectionName(collectionId)  {
        await wait(100);
        const collectionIndex = this.product.combinations.findIndex(collection => collection.id === collectionId);

        const name = this.product.combinations[collectionIndex].combinations
          .map(combination => {
            const attributeId = combination.type;
            const attribute = this.$store.getters['product/getAttributeById'](attributeId);

            return attribute ? `${this.$t(attribute.name)} (${combination.min}/${combination.max})` : '';
          })
          .filter(combination => combination)
          .join(', ');

        this.product.combinations[collectionIndex].name = name || `${this.$t('product.product.tabs.rules.sections.attribute_combinations.combination')} ${collectionId}`;
      },
      async asyncUpdateTabs($tabs) {
        await wait();
        $tabs.update();
        return Promise.resolve($tabs.tabs);
      },
      initializeIndex() {
        // TODO(soerenuhrbach): Initialize Index if product has been selected?
      }
    },
    computed: {
      ...mapState({
        productAttributes(state) {
          return [...state.product.attributes].map(attribute => ({ label: attribute.name, value: attribute.id }));
        },
        collections(state) {
          return [...state.product.product.attribute_combination_collections];
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
