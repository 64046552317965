class FaqModule {
	boot() {
		//
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');

		return { de, en };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			'faq': require('./store/faq').default,
		};
	}
}

export default FaqModule;
