import { updateField } from "vuex-map-fields";

export default {
    async loaded(state, data) {
        state.profile = { ...data.profile };
    },
    async updated(state, { data }) {
        state.profile = { ...data.profile };
    },
    updateField
};
