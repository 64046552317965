import axios from 'axios/index';
import * as FileSaver from 'file-saver';

class DownloadService {
	static async downloadFileByUrl(url, name) {
		const response = await axios.get(url, { responseType: 'blob' });
		const data = response.data;
		const mime = response.headers["content-type"];
		const blob = new Blob([data], { type: `${mime};charset=utf-8` });

		FileSaver.saveAs(blob, name);
	}
}

export default DownloadService;
