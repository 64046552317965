<template>
  <div>
    <section class="sub icon-headline">
      <h3 class="headline headline_3" data-index="6">{{ $t('offer.offer.steps.complete_offer.headline') }}</h3>
      <router-link :class="'icon help '+client()" :to="baseUrl()+'/manual#step_6'"></router-link>
    </section>

    <!--
    <tabs>
      <tab :name="$t('offer.offeroffer.steps.complete_offer.tabs.offer.tab_name')">
        <offer />
      </tab>
      <tab :name="$t('offer.offeroffer.steps.compl<router-link class="icon help" :to="baseUrl()+'/manual#step_5'"></router-link>ete_offer.tabs.costs.tab_name')">
        <costs />
      </tab>
      <tab :name="$t('offer.offeroffer.steps.complete_offer.tabs.description.tab_name')">
        <description />
      </tab>
    </tabs>
    -->

    <vue-element-loading :active="showSpinner" spinner="bar-fade-scale" />
    <offer />

    <button class="button primary right" @click="download()">{{ $t('offer.offer.steps.complete_offer.download') }}</button>
    <create-edit-code-modal :offer-id="offer.uuid">
      <button class="button secondary right">{{ $t('offer.offer.steps.complete_offer.generate_edit_code') }}</button>
    </create-edit-code-modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Tab from '../../shared/components/tabs/tab';
  import Tabs from '../../shared/components/tabs/tabs';
  import Offer from './complete-offer/offer';
  import Costs from './complete-offer/costs';
  import Description from './complete-offer/description';
  import Download from '../../shared/service/download';
  import CreateEditCodeModal from "./create-edit-code-modal";
  import VueElementLoading from "vue-element-loading";


  export default {
    name: "complete-offer",
    components: {
      CreateEditCodeModal,
      Tab,
      Tabs,
      Offer,
      Costs,
      Description,
      VueElementLoading
    },
    data() {
        return {
            showSpinner: false,
        };
    },
    computed: {
      ...mapState('offer', {
        offer: state => state.offer
      })
    },
    methods: {
      client() {
          const global = require('../../../config/index');
          return global.default.api.client;
      },
      baseUrl() {
          const { region, distributor, id } = this.$route.params;

          let target = '/offer';

          if (distributor) {
              target = '/' + distributor + target;
          }

          if (region) {
              target = '/' + region + target;
          }

          return target + '/' + id;
      },
      async saveAndClose() {
        const offer = { ...this.offer };
        await this.$store.dispatch('offer/update', { id: offer.uuid, payload: { offer } });
        this.$router.push('/offer');
      },
      async download() {
        this.showSpinner = true;
        const downloadUrl = this.$store.getters['offer/getPdfDownloadUrl'](this.offer.uuid);
        const pdfName = this.$store.getters['offer/getSluggedOfferName'](this.offer);
        await Download.downloadFileByUrl(downloadUrl, pdfName+'.pdf');
        this.showSpinner = false;
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/core/config/constants";

  .tabs { margin-bottom: 0; }

  .create-edit-code-modal {
    float: right;
    margin-right: @space2;
  }
</style>
