import { getField } from "vuex-map-fields";

export default {
	getAttributeById(state) {
		return id => state.attributes.find(attribute => attribute.id === id);
	},
	getProductByUuid(state) {
		return id => state.products.find(product => product.uuid === id);
	},
	getField
};
