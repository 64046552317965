export default {
	addEquipment(state) {
		state.product.possible_accessories = [...state.product.possible_accessories, null];
	},
	removeEquipment(state, index) {
		state.product.possible_accessories = state.product.possible_accessories.filter((product, i) => index !== i);
	},
	updateEquipment(state, { index, value }) {
		state.product.possible_accessories = state.product.possible_accessories.map((product, i) => index === i ? value : product);
	}
};
