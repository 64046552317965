import axios from "axios/index";
import config from "../../../../config";

export default {
    async loadShort({ commit }, params = {}) {
        try {
            const response = await axios.get(`${config.api.endpoint}/products.short`, { params });
            const data = response.data;

            commit('loaded_short', data);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
        }
    }
};
