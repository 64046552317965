<template>
<div>
    <editor-menu-bar :editor="editorObj" v-slot="{ commands, isActive }">
      <div class="rte-menu">
        <button
          class="button primary small"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <span class="rte-bold">b</span>
        </button>

        <button
          class="button primary small"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <span class="rte-italic">i</span>
        </button>

        <button
          class="button primary small"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <span class="rte-underline">u</span>
        </button>
      </div>
    </editor-menu-bar>
    <div>
      <div>
        <div>
          <editor-menu-bubble
            class="menububble"
            :editor="editorObj"
            @hide="hideLinkMenu"
            v-slot="{ commands, isActive, getMarkAttrs, menu }"
          >
            <div
              class="menububble"
              :class="{ 'is-active': menu.isActive }"
              :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
            >
              <form
                class="menububble-form"
                v-if="linkMenuIsActive"
                @submit.prevent="setLinkUrl(commands.link, linkUrl)"
              >
                <input
                  class="menububble__input"
                  type="text"
                  v-model="linkUrl"
                  placeholder="https://"
                  ref="linkInput"
                  @keydown.esc="hideLinkMenu"
                />
                <button
                  class="button small secondary"
                  @click="setLinkUrl(commands.link, null)"
                  type="button"
                >
                  [x]
                </button>
              </form>

              <template v-else>
                <button
                  class="button primary small"
                  @click="showLinkMenu(getMarkAttrs('link'))"
                  :class="{ 'is-active': isActive.link() }"
                >
                  <span>{{
                    isActive.link() ? "Update Link" : "Add Link"
                  }}</span>
                </button>
              </template>
            </div>
          </editor-menu-bubble>
        </div>
      </div>
    </div>
    <div>
      <editor-content
        class="rte"
        :editor="editorObj"
      ></editor-content>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from "tiptap";
import { Bold, Italic, Underline, Link } from "tiptap-extensions";

export default {
  name: "rich-text-editor",
  components: {
    Editor,
    EditorContent,
    EditorMenuBar,
    EditorMenuBubble,
    Bold,
    Italic,
    Underline,
    Link,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  watch: {
    content: {
      handler(val, oldVal) {
        this.editorObj.setContent(val);
      },
    },
  },
  data() {
    return {
      editorObj: null,
      linkUrl: null,
      linkMenuIsActive: false,
    };
  },
  async mounted() {
    const editorConfig = {
      extensions: [new Bold(), new Italic(), new Underline(), new Link()],
    };
    this.editorObj = new Editor({
      ...editorConfig,
      content: this.content,
      onUpdate: ({ getHTML }) => {
        this.$emit("onUpdate", getHTML());
      },
    });
  },
  beforeDestroy() {
    this.editorObj.destroy();
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
  },
};
</script>