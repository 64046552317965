import { updateField } from "vuex-map-fields";

export default {
    async loaded(state, data) {
        state.profile = { ...data.profile, price_factors: data.profile.price_factors.map(factor => ({ ...factor, category: factor.category.key })) };
    },
    async updated(state, { data }) {
        state.profile = { ...data.profile };
    },
    async categoriesLoaded(state, data) {
        state.categories = [...data.categories];
    },
    async projectsLoaded(state, data) {
        state.projects = [...data.projects];
    },
    async customersLoaded(state, data) {
        state.customers = [...data.customers];
    },
    async updatePriceFactor(state, { category, factor }) {
        const price = state.profile.price_factors.find(price => price.category === category);

        if (!price) {
            state.profile.price_factors = [...state.profile.price_factors, { category, factor }];
        } else {
            state.profile.price_factors = state.profile.price_factors.map(price => price.category === category ? { ...price, factor } : price);
        }
    },
    updateField
};
