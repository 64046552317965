<template>
  <div>
    <h3 class="headline headline_3">Produkte</h3>

    <router-link tag="button" class="button primary" to="/admin/product/add">Produkt hinzufügen</router-link>

    <section class="sub">
      <vue-element-loading :active="loading" spinner="bar-fade-scale" />

      <data-table :fields="fields" :data="products">
        <div class="inline right" slot="last-col" slot-scope="{ row }">
          <dot-menu :options="options" :object="row"></dot-menu>
        </div>
        <tr slot="empty">
          <td :colspan="fields.length" class="text-center">
            Es konnten keine Produkte gefunden werden.
          </td>
        </tr>
      </data-table>
    </section>

    <router-link tag="button" to="/admin/product" class="button secondary">Alle Produkte</router-link>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import DataTable from "../../shared/components/table/table";
  import DotMenu from "../../shared/components/modal/dot-menu";
  import VueElementLoading from "vue-element-loading";

  export default {
    name: "products",
    components: {
      DotMenu,
      DataTable,
      VueElementLoading
    },
    data() {
      return {
        limit: 6,
        loading: false,
        fields: [
          { label: 'admin-dashboard.products.table.number', key: 'article_number' },
          { label: 'admin-dashboard.products.table.name', key: 'title' },
          { label: 'admin-dashboard.products.table.category', key: 'category.key' },
          { label: 'admin-dashboard.products.table.color', key: 'color' },
          { label: 'admin-dashboard.products.table.hek', key: 'price.hek', transform: (price, product) => this.formatPrice(price, product.price ? product.price.currency : null) },
          { label: 'admin-dashboard.products.table.uvp', key: 'price.uvp', transform: (price, product) => this.formatPrice(price, product.price ? product.price.currency : null) },
          {
            label: 'admin-dashboard.products.table.image',
            key: 'image',
            transform: image => {
              if (!image || !image.uuid) {
                return '';
              }

              const url = this.$store.getters['asset/getPublicUrl'](image, { height: 50, width: 50 });

              return `<img class="no-margin" src="${url}">`;
            },
            sortable: false
          }
        ],
        options: [
          { title: 'actions.edit', callback: row => this.$router.push(`/admin/product/${row.uuid}`) },
          { title: 'actions.delete', callback: row => this.$store.dispatch('product/delete', { id: row.uuid }) }
        ]
      }
    },
    computed: {
      ...mapState({
        products(state) {
          const products = [...state.product.products];

          return products
            .map(product => ({ ...product, id: product.uuid }))
            .slice(0, this.limit);
        }
      })
    },
    methods: {
      formatPrice(price = 0, currency = 'EUR') {
        const locale = this.$i18n.locale;

        if (!currency) {
          currency = 'EUR';
        }

        return Number(price).toLocaleString(locale, { style: 'currency', currency });
      }
    },
    async mounted() {
      this.loading = true;
      await this.$store.dispatch('product/load', { limit: this.limit });
      this.loading = false;
    }
  }
</script>

<style scoped>

</style>
