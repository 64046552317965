<template>
  <section class="sub">
    <h5>{{ $t("offer.offer.steps.complete_offer.tabs.offer.devices") }}</h5>
    <data-table :fields="fields" :data="positions">
      <tr slot="empty">
        <td :colspan="fields.length" class="text-center">
          {{ $t("offer.offer.steps.complete_offer.tabs.offer.no_products") }}
        </td>
      </tr>
    </data-table>

    <data-table :fields="foreignFields" :data="foreignDevices">
      <tr slot="empty">
        <td :aria-colspan="fields.length" class="text-center">
          {{ $t("offer.offer.steps.complete_offer.tabs.offer.no_products") }}
        </td>
      </tr>
    </data-table>

    <h5>
      {{ $t("offer.offer.steps.complete_offer.tabs.offer.modules_extensions") }}
    </h5>
    <data-table :fields="moduleFields" :data="components">
      <tr slot="empty">
        <td :colspan="fields.length" class="text-center">
          {{ $t("offer.offer.steps.complete_offer.tabs.offer.no_modules") }}
        </td>
      </tr>
    </data-table>

    <h5>{{ $t("offer.offer.steps.complete_offer.tabs.offer.amt") }}</h5>
    <data-table :fields="fields_ports" :data="ports">
      <tr slot="empty">
        <td :colspan="fields_ports.length" class="text-center">
          {{
            $t(
              "offer.offer.steps.complete_offer.tabs.description.sections.port_assignment.no_data_found"
            )
          }}
        </td>
      </tr>
    </data-table>

    <div class="grid" v-show="!!this.$route.params.distributor">
      <div class="col7"></div>
      <div class="col3">
        <h5>{{ $t("offer.offer.steps.complete_offer.tabs.offer.total") }}</h5>
        <div class="table-wrapper">
          <table>
            <tr>
              <td>
                <strong>{{
                  $t(
                    "offer.offer.steps.complete_offer.tabs.description.sections.total.total_net"
                  )
                }}</strong>
              </td>
              <td>
                {{
                  formatPrice(
                    prices.totalNet,
                    this.$store.state["offer"].offer.region.currency
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong
                  >{{ prices.tax }} %
                  {{
                    $t(
                      "offer.offer.steps.complete_offer.tabs.description.sections.total.tax"
                    )
                  }}</strong
                >
              </td>
              <td>
                {{
                  formatPrice(
                    prices.tax_price,
                    this.$store.state["offer"].offer.region.currency
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{
                  $t(
                    "offer.offer.steps.complete_offer.tabs.description.sections.total.total"
                  )
                }}</strong>
              </td>
              <td>
                {{
                  formatPrice(
                    prices.total,
                    this.$store.state["offer"].offer.region.currency
                  )
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "../../../shared/components/table/table";

export default {
  name: "offer",
  components: {
    DataTable,
  },
  data() {
    return {
      fields: [
        {
          label:
            "offer.offer.steps.complete_offer.tabs.offer.table.article_number",
          key: "product.article_number",
          sortable: false,
          width: "20%",
        },
        {
          label: "offer.offer.steps.complete_offer.tabs.offer.table.name",
          key: "product.title",
          type: "product:tooltip",
          tooltipKey: "product.tooltip",
          productLinkKey: "product.product_link",
          hint: (row) => row.product.product_hint,
        },
        {
          label: "offer.offer.steps.complete_offer.tabs.offer.table.amount",
          key: "amount",
          sortable: false,
          width: "10%",
        },
        {
          label: "offer.offer.steps.complete_offer.tabs.offer.table.price",
          key: "price.hek",
          sortable: false,
          transform: (price, product) => {
            const currency = this.$store.state["offer"].offer.region.currency;
            return this.formatPrice(price, product.price ? currency : null);
          },
          renderCondition: () => !!this.$route.params.distributor,
          width: "10%",
        },
      ],
      moduleFields: [
        {
          label:
            "offer.offer.steps.complete_offer.tabs.offer.table.article_number",
          key: "component.article_number",
          sortable: false,
          width: "20%",
        },
        {
          label: "offer.offer.steps.complete_offer.tabs.offer.table.name",
          key: "component.title",
          sortable: false,
          type: "product:tooltip",
          tooltipKey: "component.tooltip",
          productLinkKey: "component.product_link",
          hint: (row) => row.component.product_hint
        },
        {
          label: "offer.offer.steps.complete_offer.tabs.offer.table.amount",
          key: "amount",
          width: "10%",
        },
        {
          label: "offer.offer.steps.complete_offer.tabs.offer.table.price",
          key: "price",
          transform: (price, product) => {
            const currency = this.$store.state["offer"].offer.region.currency;
            return this.formatPrice(price, product.price ? currency : null);
          },
          renderCondition: () => !!this.$route.params.distributor,
          width: "10%",
        },
      ],
      fields_ports: [
        {
          label:
            "offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.ports",
          key: "name",
          sortable: false,
        },
        {
          label:
            "offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.used",
          key: "value",
          sortable: false,
          width: "10%",
        },
      ],
      foreignFields: [
        {
          label:
            "offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.foreign_devices",
          key: "name",
          sortable: false,
        },
        {
          label:
            "offer.offer.steps.complete_offer.tabs.description.sections.ict_systems.table.number",
          key: "value",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      positions(state) {
        const positions = [...state.offer.result.positions];

        return positions.map((position) => ({
          ...position,
          id: position.uuid,
        }));
      },
      components(state) {
        return Object.values(state.offer.result.components);
      },
      prices(state) {
        return state.offer.result.total;
      },
      subtotal(state) {
        return this.formatPrice(state.offer.result.subtotal, "GBP");
      },
      total_tax(state) {
        return this.formatPrice(state.offer.result.total_tax, "GBP");
      },
      ports(state) {
        const ports = [];

        for (let name in state.offer.result.exchange_lines) {
          if (state.offer.result.exchange_lines.hasOwnProperty(name)) {
            ports.push({
              id: name,
              name: this.$t(
                `offer.offer.steps.complete_offer.tabs.description.sections.exchange_lines.${name}`
              ),
              value: state.offer.result.exchange_lines[name],
            });
          }
        }
        return ports;
      },
      foreignDevices(state) {
        const devices = [];
        for (let name in state.offer.result.foreign_devices) {
          if (state.offer.result.foreign_devices.hasOwnProperty(name)) {
            devices.push({
              id: name,
              name: this.$t(
                `offer.offer.steps.complete_offer.tabs.description.sections.foreign_devices.${name}`
              ),
              value: state.offer.result.foreign_devices[name],
            });
          }
        }
        return devices;
      },
    }),
  },
  methods: {
    formatPrice(price = 0, currency = "GBP") {
      const locale = this.$i18n.locale;

      return Number(price).toLocaleString(locale, {
        style: "currency",
        currency,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../res/less/themes/theme";
@import "../../../../res/less/core/config/constants";

.price-table {
  margin-bottom: @standard-space;
  color: @brand-1-100;

  td:first-child {
    padding-right: @space4;
  }

  tfoot {
    font-weight: bold;

    tr:first-child td {
      padding-top: @standard-space;
    }
  }
}
</style>
