String.prototype.replaceAll = function(target, replacement) {
	return this.split(target).join(replacement);
};

Array.prototype.first = function () {
	return this[0];
};

Array.prototype.last = function () {
	return this[this.length - 1] || null;
};
