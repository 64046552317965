class SharedKernel {
	boot() {
		require('./router/hook/title.js');
		require('./router/middleware/client');
	}

	getI18n() {
		const de = require('./i18n/de.json');
		const en = require('./i18n/en.json');
		const fr = require('./i18n/fr.json');

		return { de, en, fr };
	}

	getRoutes() {
		return require('./router/routes.js').default;
	}

	getStores() {
		return {
			global: require('./store/global/index').default,
			auth: require('./store/auth/index.js').default,
			asset: require('./store/asset/index.js').default,
			notification: require('./store/notification/index.js').default
		};
	}
}

export default SharedKernel;
