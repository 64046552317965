<template>
  <div>
    <h2 class="headline headline_2">Ihr Dashboard</h2>

    <section>
      <notifications :max="3" />

      <reseller></reseller>
    </section>

    <section>
      <products></products>
    </section>

    <section>
      <faq></faq>
    </section>
  </div>
</template>

<script>
import Faq from "../components/faq";
import Reseller from "../components/reseller";
import Products from "../components/products";
import Notifications from "../../shared/components/notifications";

export default {
  components: {
    Notifications,
    Faq,
    Reseller,
    Products,
  },
  name: "dashboard",
};
</script>

<style scoped>
</style>
