import axios from 'axios/index';
import config from '../../../../config';

export default {
    async login({commit}, {username, password}) {
        try {
            const response = await axios.post(`${config.api.endpoint}/login`, {username: username, password: password});
            commit('setToken', response.data);
            return Promise.resolve(true);
        } catch (exception) {
            this.dispatch('notification/fromRequestException', exception);
            return Promise.resolve(false);
        }
    },
    async resellerlogin({commit}, {username}) {
        commit('setToken', username);
        return Promise.resolve(true);
    },
    async logout({commit}) {
        this.dispatch('notification/set', {
            level: 'success',
            title: 'Successfully logged out!'
        });

        commit('setToken', 'reseller');
        commit('unsetDistributor');
    }
};
