<template>
  <div>
    <section class="sub">
      <h3 class="headline headline_3" data-index="2">{{ $t('offer.offer.steps.present_devices.headline') }}</h3>
    </section>

    <selected-devices />

    <section class="sub">
      <h4 class="headline headline_4">2.2 {{ $t("offer.offer.steps.present_devices.select_devices") }}</h4>

      <div>
        <router-link tag="button" class="button primary" :to="{ path: '/reseller/product/add', query: { ref } }">{{ $t("products.add_foreign_product") }}</router-link>

        <import-foreign-product-modal>
          <button class="button secondary">{{ $t("products.import_foreign_product") }}</button>
        </import-foreign-product-modal>
      </div>

      <div class="search">
        <input type="text" :placeholder="$t('search')" v-model="search">
      </div>
    </section>

    <section class="sub">
      <data-table :fields="fields" :data="products">
        <div class="inline right" slot="last-col" slot-scope="{ row }">
          <dot-menu :object="row" :options="options"></dot-menu>
        </div>
        <tr slot="empty">
          <td :colspan="fields.length" class="text-center">
            {{ $t('offer.offer.steps.present_devices.not_found') }}
          </td>
        </tr>
      </data-table>

      <button class="button secondary">{{ $t("actions.load_more") }}</button>
    </section>

    <button class="button primary" @click="save()">{{ $t("offer.offer.next_step", ['1', '6']) }}</button>
    <!--
    <button class="button secondary" @click="saveAndClose()">{{ $t("actions.continue_later") }}</button>
    <button class="button secondary" @click="discard()">{{ $t("actions.discard") }}</button>
    -->
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import DotMenu from "../../shared/components/modal/dot-menu";
  import TabChild from '../../shared/components/tabs/tab-child';
  import DataTable from "../../shared/components/table/table";
  import ImportForeignProductModal from '../../shared/components/modal/import-foreign-product-modal';
  import SelectedDevices from "./devices/selected-devices";

  export default {
    extends: TabChild,
    name: "devices",
    components: {
      SelectedDevices,
      DotMenu,
      DataTable,
      ImportForeignProductModal
    },
    data() {
      return {
        tabIndex: 4,
        search: '',
        fields: [
          { label: 'offer.products.table.id', key: 'article_number' },
          { label: 'offer.products.table.name', key: 'description' },
          { label: 'offer.products.table.category', key: 'category.title' },
          { label: 'offer.products.table.color', key: 'color' },
         /* { label: 'offer.offer.products.table.hek', key: 'hek', transform: price => this.formatPrice(price) },
          { label: 'offer.offer.products.table.uvp', key: 'uvp', transform: price => this.formatPrice(price) },*/
          { label: 'offer.products.table.image', key: 'image', sortable: false }
        ],
        options: [
          {
            title: 'offer.offer.steps.present_devices.select',
            callback: product => {
              const id = this.$store.state.offer.offer.uuid;
              const position = {
                product: product.uuid,
                hek: product.hek,
                uvp: product.uvp,
                exists: false,
                amount: 1
              };

              this.$store.dispatch('offer/addResellerProduct', { id, position, product });
            }
          }
        ]
      }
    },
    computed: {
      ...mapState({
        products(state) {
          const search = this.search.toLowerCase();
          const products =  [...state.resellerProduct.products];

          return products
            .map(product => ({ ...product, id: product.uuid }))
            .filter(product => {
              const constraints = [
                product.description.toLowerCase().includes(search),
                product.color.toLowerCase().includes(search),
                product.article_number.toLowerCase().includes(search)
              ];

              return constraints.reduce((m, matching) => m || matching);
            });
        }
      }),
      ref() {
        let path = this.$route.path;
        const offerId = this.$store.state.offer.offer.uuid;
        const indexOfAdd = path.indexOf('/add');

        if (indexOfAdd > -1) {
          path = path.slice(0, indexOfAdd);
        }

        if (path.endsWith(`/${this.tabIndex}`)) {
          path.replace(`/${this.tabIndex}`, '');
        }

        return `${path}/${offerId}/${this.tabIndex}`;
      }
    },
    methods: {
      async save(navigate = true) {
        const offer = { ...this.$store.state.offer.offer };

        await this.$store.dispatch('offer/update', { id: offer.uuid, payload: { offer } });

        if (navigate) {
          this.nextTab();
        }
      },
      async saveAndClose() {
        await this.save(false);

        this.$router.push('/offer');
      },
      async discard() {
        const $tab = this.$parent;
        const $tabs = $tab.$parent;
        const $page = $tabs.$parent;

        await $page.discard();
      },
      formatPrice(price = 0, currency = 'EUR') {
        const locale = this.$i18n.locale;

        return Number(price).toLocaleString(locale, { style: 'currency', currency });
      }
    },
    async mounted() {
      await this.$store.dispatch('resellerProduct/load');
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/core/config/constants";

  .tabs {
    margin-bottom: 0;
  }
</style>
