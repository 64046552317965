import { limit } from "../templates";

export default {
	addLimit(state) {
		state.product.limits = [...state.product.limits, { ...limit }];
	},
	removeLimit(state, index) {
		state.product.limits = state.product.limits.filter((limit, i) => i !== index);
	},
	updateLimit(state, { index, changes }) {
		state.product.limits = state.product.limits.map((limit, i) => index === i ? { ...limit, ...changes } : limit);
	}
};
