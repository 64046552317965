<template>
  <div>
    <!--
    <section class="sub">
      <h4 class="headline headline_4">{{ $t("header.meta_navigation.overlays.faq.headline") }}</h4>

      <div class="search">
        <input type="text" :placeholder='$t("search")'>
      </div>
    </section>-->

    <div class="grid">
      <div class="col8">
        <h4 class="headline headline_4">{{ $t("header.meta_navigation.overlays.faq.questions") }}</h4>

        <accordion v-for="(question, i) in questions" :key="i" layout="white">
          <template slot="header">{{ question.question }}</template>
          <template slot="body">
            <div v-html="question.answer"></div>
          </template>
        </accordion>
      </div>
      <div class="col4">
        <h4 class="headline headline_4">{{ $t("header.meta_navigation.overlays.faq.topics") }}</h4>

        <ul>
          <li v-for="(topic, index) in topics" :key="index">
            <router-link tag="a" :to="topic.link">{{ topic.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import Contact from './contact';
  import Accordion from '../../accordion';

  export default {
    name: "admin-help",
    components: {
      Contact,
      Accordion
    },
    data() {
      return {
        topics: [
          { title: 'Thema 1', link: '/' },
          { title: 'Thema 2', link: '/' },
          { title: 'Thema 3', link: '/' },
          { title: 'Thema 4', link: '/' },
          { title: 'Thema 5', link: '/' },
        ],
        questions: [
          {
            question: 'Wie kann ich ein neues Angebot erstellen?',
            answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut'
          },
          {
            question: 'Wie kann ich meine bisherigen Geräte hinterlegen?',
            answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut'
          },
          {
            question: 'Wie kann ich Angebote exportieren?',
            answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut'
          },
          {
            question: 'Wie kann ich Angebote importieren?',
            answer: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut'
          }
        ]
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../../res/less/core/config/constants";

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: @space2;
      }
    }
  }
</style>
