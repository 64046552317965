<template>
  <div>
    <h2 class="headline headline_2">{{ $t("faq.faq.headline") }}</h2>

    <section class="sub">
      <router-link tag="button" to="/admin/faq/add" class="button primary">{{
        $t("faq.faq.add")
      }}</router-link>
    </section>

    <notifications :max="3" />

    <section class="sub">
      <div class="search">
        <input type="text" :placeholder="$t('search')" />
      </div>
    </section>

    <section v-for="(topic, index) in topics" :key="index">
      <h3 class="headline headline_3">{{ topic.name }}</h3>

      <accordion v-for="(question, i) in topic.questions" :key="i">
        <template slot="header">{{ question.question }}</template>
        <template slot="body">
          <div v-html="question.answer"></div>
        </template>
      </accordion>
    </section>
  </div>
</template>

<script>
import Accordion from "../../shared/components/accordion";
import Notifications from "../../shared/components/notifications";

export default {
  name: "faq",
  components: {
    Notifications,
    Accordion,
  },
  data() {
    return {
      topics: [
        {
          name: "Allgemein",
          questions: [
            {
              question: "Wie kann ich ein neues Angebot erstellen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich meine bisherigen Geräte hinterlegen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote exportieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote importieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
          ],
        },
        {
          name: "Thema 1",
          questions: [
            {
              question: "Wie kann ich ein neues Angebot erstellen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich meine bisherigen Geräte hinterlegen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote exportieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote importieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
          ],
        },
        {
          name: "Thema 2",
          questions: [
            {
              question: "Wie kann ich ein neues Angebot erstellen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich meine bisherigen Geräte hinterlegen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote exportieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote importieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
          ],
        },
        {
          name: "Thema 3",
          questions: [
            {
              question: "Wie kann ich ein neues Angebot erstellen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich meine bisherigen Geräte hinterlegen?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote exportieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
            {
              question: "Wie kann ich Angebote importieren?",
              answer:
                "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
