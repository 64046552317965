<template>
  <div>
    <section class="sub">
      <h3 class="headline headline_3" data-index="1">{{ $t("offer.offer.steps.general.headline") }}</h3>
    </section>

    <div class="grid">
      <div class="col5">
        <section class="sub">
          <h4 class="headline headline_4">1.1 {{ $t('offer.offer.steps.general.sections.offer_data.headline') }}</h4>

          <div class="input">
            <label for="offer-id">{{ $t('offer.offer.steps.general.sections.offer_data.label.id') }}</label>
            <input type="text" id="offer-id" v-model="offer_number">
          </div>

          <div class="input">
            <label for="offer-createdAt">{{ $t('offer.offer.steps.general.sections.offer_data.label.createdAt') }}</label>
            <input type="text" id="offer-createdAt" v-model="creation_date" disabled>
          </div>

          <div class="input">
            <label for="offer-description">{{ $t('offer.offer.steps.general.sections.offer_data.label.description') }}*</label>
            <input type="text" id="offer-description" v-model="title">
          </div>

          <!-- TODO(soerenuhrbach): Show dropdown if contact persons laoded from api
          <div class="input">
            <label>{{ $t('offer.offeroffer.steps.general.sections.offer_data.label.contact') }}</label>
            <dropdown :data="contacts" v-model="contact_person" :placeholder="$t('offer.offeroffer.steps.general.sections.offer_data.placeholder.contact')"></dropdown>
          </div>
          -->

          <div class="input">
            <label for="contact_person">{{ $t('offer.offer.steps.general.sections.offer_data.label.contact') }}</label>
            <input type="text" id="contact_person" v-model="contact_person" />
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">1.2 {{ $t('offer.offer.steps.general.sections.project_planning.headline') }}</h4>

          <div class="input">
            <label>{{ $t('offer.offer.steps.general.sections.project_planning.label.project') }}</label>
            <dropdown v-model="project" :data="projects" :placeholder="$t('offer.offer.steps.general.sections.project_planning.placeholder.project')"></dropdown>
          </div>

          <div class="input">
            <label for="offer-number">{{ $t('offer.offer.steps.general.sections.project_planning.label.number') }}</label>
            <input type="text" id="offer-number" v-model="project_number" @input="unlinkProject()">
          </div>

          <div class="input">
            <label for="offer-name">{{ $t('offer.offer.steps.general.sections.project_planning.label.name') }}</label>
            <input type="text" id="offer-name" v-model="project_title" @input="unlinkProject()">
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">1.3 {{ $t("offer.offer.steps.general.sections.customer_data.headline") }}</h4>

          <div class="input">
            <!-- TODO(soerenuhrbach): Check why customer is not selected -->
            <label>{{ $t('offer.offer.steps.general.sections.customer_data.label.existing_customer') }}</label>
            <dropdown v-model="customer" :data="customers" :placeholder="$t('offer.offer.steps.general.sections.customer_data.placeholder.existing_customer')"></dropdown>
          </div>

          <div class="input">
            <label for="offer-customer-id">{{ $t("offer.offer.steps.general.sections.customer_data.label.customer_id") }}</label>
            <input type="text" id="offer-customer-id" v-model="customer_number" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-name">{{ $t("offer.offer.steps.general.sections.customer_data.label.customer_name") }}</label>
            <input type="text" id="offer-customer-name" v-model="customer_name" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-contact">{{ $t("offer.offer.steps.general.sections.customer_data.label.contact") }}</label>
            <input type="text" id="offer-customer-contact" v-model="customer_contact" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-street">{{ $t("offer.offer.steps.general.sections.customer_data.label.street") }}</label>
            <input type="text" id="offer-customer-street" v-model="customer_address_street" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-zip">{{ $t("offer.offer.steps.general.sections.customer_data.label.zip") }}</label>
            <input type="text" id="offer-customer-zip" v-model="customer_address_zip" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-city">{{ $t("offer.offer.steps.general.sections.customer_data.label.city") }}</label>
            <input type="text" id="offer-customer-city" v-model="customer_address_city" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-phone">{{ $t("phone") }}</label>
            <input type="text" id="offer-customer-phone" v-model="customer_contact_data_phone" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-fax">{{ $t("offer.offer.steps.general.sections.customer_data.label.fax") }}</label>
            <input type="text" id="offer-customer-fax" v-model="customer_contact_data_fax" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-homepage">{{ $t("offer.offer.steps.general.sections.customer_data.label.homepage") }}</label>
            <input type="text" id="offer-customer-homepage" v-model="customer_contact_data_web" @input="unlinkCustomer()"/>
          </div>

          <div class="input">
            <label for="offer-customer-mail">{{ $t("offer.offer.steps.general.sections.customer_data.label.mail") }}</label>
            <input type="text" id="offer-customer-mail" v-model="customer_contact_data_email" @input="unlinkCustomer()"/>
          </div>
        </section>

        <section class="sub">
          <h4 class="headline headline_4">1.4 {{ $t('offer.offer.steps.general.sections.country_based_data.headline') }}</h4>

          <div class="input">
            <checkbox v-model="use_list_price">{{ $t("offer.offer.steps.general.sections.country_based_data.label.use_list_price") }}</checkbox>
          </div>

          <div v-if="!use_list_price">
            <div class="input">
              <label for="offer-price-exchange-rate">{{ $t("offer.offer.steps.general.sections.country_based_data.label.exchange") }}</label>
              <input id="offer-price-exchange-rate" v-model="region_settings_currency_factor" type="number" step="0.1">
            </div>

            <div class="input">
              <label>{{ $t("offer.offer.steps.general.sections.country_based_data.label.currency_symbol") }}</label>
              <dropdown :data="currencies" v-model="region_settings_currency" :placeholder="$t('offer.offer.steps.general.sections.country_based_data.placeholder.currency_symbol')"></dropdown>
            </div>

            <div class="input">
              <label>{{ $t("offer.offer.steps.general.sections.country_based_data.label.language") }}</label>
              <dropdown :data="languages"  v-model="region_settings_language" :placeholder="$t('offer.offer.steps.general.sections.country_based_data.placeholder.language')"></dropdown>
            </div>

            <div class="input">
              <label for="offer-price-tax">{{ $t("offer.offer.steps.general.sections.country_based_data.label.tax") }}</label>
              <input id="offer-price-tax" v-model="region_settings_tax" type="number" step="0.1">
            </div>
          </div>
        </section>
      </div>
    </div>

    <button class="button primary" :disabled="!title" @click="save()">{{ $t("offer.offer.next_step", ['2', '6']) }}</button>
    <!--
    <button class="button secondary" @click="saveAndClose()">{{ $t("actions.continue_later") }}</button>
    <button class="button secondary" @click="discard()">{{ $t("actions.discard") }}</button>
    -->
  </div>
</template>

<script>
  import * as moment from 'moment/moment';
  import { mapState } from 'vuex';
  import { mapFields } from 'vuex-map-fields';

  import Dropdown from '../../shared/components/form/dropdown';
  import Checkbox from "../../shared/components/form/checkbox";
  import TabChild from '../../shared/components/tabs/tab-child';

  export default {
    extends: TabChild,
    name: "general",
    components: {
      Checkbox,
      Dropdown
    },
    data() {
      return {
        use_list_price: true
      }
    },
    computed: {
      ...mapFields('offer', {
        offer_number: 'offer.offeroffer_number',
        title: 'offer.offertitle',
        contact_person: 'offer.offercontact_person',
        project: 'offer.offerproject',
        project_number: 'offer.offerproject.number',
        project_title: 'offer.offerproject.title',
        customer: 'offer.offercustomer',
        customer_number: 'offer.offercustomer.number',
        customer_name: 'offer.offercustomer.name',
        customer_contact: 'offer.offercustomer.contact',
        customer_address_street: 'offer.offercustomer.address.street',
        customer_address_zip: 'offer.offercustomer.address.zip',
        customer_address_city: 'offer.offercustomer.address.city',
        customer_contact_data_phone: 'phone',
        customer_contact_data_fax: 'offer.offercustomer.contact_data.fax',
        customer_contact_data_web: 'offer.offercustomer.contact_data.web',
        customer_contact_data_email: 'offer.offercustomer.contact_data.email',
        region_settings_currency_factor: 'offer.offerregion_settings.currency_factor',
        region_settings_currency: 'offer.offerregion_settings.currency',
        region_settings_language: 'offer.offerregion_settings.language',
        region_settings_tax: 'offer.offerregion_settings.tax',
      }),
      ...mapState({
        languages(state) {
          return state.languages;
        },
        currencies(state) {
          return state.currencies;
        },
        creation_date(state) {
          const date = state.offer.offer.creation_date ? moment(state.offer.offer.creation_date) : moment();
          const [year, month, day] = date.format('YYYY-MM-DD').split('-');

          return this.$t('format.date', { year, month, day });
        },
        projects(state) {
          const projects = [...state.profile.projects];

          return projects.map(project => ({ value: project, label: project.title }));
        },
        customers(state) {
          const customers = [...state.profile.customers];

          return customers.map(customer => {
            const c = { ...customer };
            delete c.reseller;
            return ({ value: { ...c }, label: `${customer.name} (${customer.number})` });
          });
        }
      })
    },
    methods: {
      async save(navigate = true) {
        const offer = { ...this.$store.state.offer.offer };

        if (offer && offer.uuid) {
          await this.$store.dispatch('offer/update', { payload: { offer }, id: offer.uuid });
        } else {
          await this.$store.dispatch('offer/create', { payload: { offer } });
        }

        if (navigate) {
          this.nextTab();
        }
      },
      async saveAndClose() {
        await this.save(false);

        this.$router.push('/offer');
      },
      async discard() {
        const $tab = this.$parent;
        const $tabs = $tab.$parent;
        const $page = $tabs.$parent;

        await $page.discard();
      },
      async unlinkProject() {
        const project = { ...this.project };
        delete project.uuid;
        this.project = { ...project };
      },
      async unlinkCustomer() {
        const customer = { ...this.customer };
        delete customer.uuid;
        this.customer = { ...customer };
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
