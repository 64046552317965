<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">
        {{ $t("service-contact.contact.headline") }}
      </h2>

      <div class="inline right">
        <button class="button secondary" @click="save()">
          {{ $t("actions.save") }}
        </button>
        <button class="button secondary" @click="discard()">
          {{ $t("actions.discard") }}
        </button>
      </div>
    </div>

    <notifications :max="3" />

    <section class="sub">
      <div class="grid">
        <div class="col6">
          <h3 class="headline headline_3">
            {{ $t("service-contact.contact.contact.headline") }}
          </h3>

          <div class="input">
            <label>{{
              $t("service-contact.contact.contact.label.image")
            }}</label>
            <file-upload
              :value="filename"
              @change="uploadPicture($event)"
            ></file-upload>
          </div>

          <div class="input">
            <label>{{
              $t("service-contact.contact.contact.label.salutation")
            }}</label>
            <dropdown v-model="salutation" :data="salutations"></dropdown>
          </div>

          <div class="input">
            <label for="contact_forename">{{
              $t("service-contact.contact.contact.label.forename")
            }}</label>
            <input type="text" id="contact_forename" v-model="forename" />
          </div>

          <div class="input">
            <label for="contact_name">{{
              $t("service-contact.contact.contact.label.name")
            }}</label>
            <input type="text" id="contact_name" v-model="surname" />
          </div>

          <div class="input">
            <label for="contact_phone">{{ $t("phone") }}</label>
            <input type="text" id="contact_phone" v-model="phone" />
          </div>

          <div class="input">
            <label for="contact_email">{{
              $t("service-contact.contact.contact.label.email")
            }}</label>
            <input type="text" id="contact_email" v-model="email" />
          </div>
        </div>

        <div class="col6">
          <h3 class="headline headline_3">
            {{ $t("service-contact.contact.reseller.headline") }}
          </h3>

          <section class="sub">
            <div class="input">
              <label>{{
                $t("service-contact.contact.reseller.label.assign_reseller")
              }}</label>
              <dropdown
                :value="null"
                ref="resellerDropdown"
                @change="assignReseller($event)"
                :data="resellers"
                :placeholder="
                  $t(
                    'service-contact.contact.reseller.placeholder.assign_reseller'
                  )
                "
              ></dropdown>
            </div>
          </section>

          <accordion
            v-for="(reseller, index) of assignedResellers"
            :key="index"
          >
            <template slot="header">
              {{ reseller.title }}, {{ reseller.reseller_number }}

              <div class="inline right">
                <dot-menu :options="options" :object="reseller" />
              </div>
            </template>
            <div slot="body" class="grid">
              <div class="col6">
                <h4 class="headline headline_4">
                  {{ $t("service-contact.contact.reseller.address.headline") }}
                </h4>

                <p>
                  {{ reseller.company }} <br />
                  {{ reseller.street }} <br />
                  {{ reseller.zip }} {{ reseller.city }}
                </p>
              </div>
              <div class="col6">
                <h4 class="headline headline_4">
                  {{ $t("service-contact.contact.reseller.contact.headline") }}
                </h4>

                <p>
                  {{ reseller.title }} <br />
                  {{ $t("phone") }}: {{ reseller.phone }} <br />
                  {{ $t("service-contact.contact.contact.label.fax") }}:
                  {{ reseller.fax }} <br />
                  {{ $t("service-contact.contact.contact.label.email") }}:
                  {{ reseller.email }} <br />
                  {{ $t("service-contact.contact.contact.label.web") }}:
                  {{ reseller.website }} <br />
                </p>
              </div>
            </div>
          </accordion>
        </div>
      </div>
    </section>

    <button class="button secondary" @click="save()">
      {{ $t("actions.save") }}
    </button>
    <button class="button secondary" @click="discard()">
      {{ $t("actions.discard") }}
    </button>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import DotMenu from "../../shared/components/modal/dot-menu";
import FileUpload from "../../shared/components/form/file-upload";
import Dropdown from "../../shared/components/form/dropdown";
import Accordion from "../../shared/components/accordion";
import Notifications from "../../shared/components/notifications";

export default {
  name: "contact",
  components: {
    Notifications,
    DotMenu,
    Dropdown,
    Accordion,
    FileUpload,
  },
  data() {
    return {
      salutations: [
        { label: "Herr", value: "Herr" },
        { label: "Frau", value: "Frau" },
      ],
      options: [
        {
          title: "actions.delete",
          callback: (reseller) =>
            (this.assignedResellers = this.assignedResellers.filter(
              (r) => r.uuid !== reseller.uuid
            )),
        },
      ],
    };
  },
  methods: {
    assignReseller($event) {
      const reseller = $event;
      const $dropdown = this.$refs.resellerDropdown;

      this.assignedResellers = [...this.assignedResellers, reseller];

      $dropdown.close();
    },
    async uploadPicture(picture) {
      this.picture = await this.$store.dispatch("asset/upload", picture);
    },
    async save() {
      const contact = { ...this.$store.state["service-contact"].contact };

      if (contact.uuid) {
        this.$store.dispatch("service-contact/update", {
          id: contact.uuid,
          payload: { contact },
        });
      } else {
        this.$store.dispatch("service-contact/create", {
          payload: { contact },
        });
      }

      this.$router.push("/admin/contact");
    },
    async discard() {
      this.$store.commit("service-contact/reset");
      this.$router.push("/admin/contact");
    },
  },
  computed: {
    ...mapFields("service-contact", {
      picture: "contact.picture",
      salutation: "contact.salutation",
      forename: "contact.forename",
      surname: "contact.surname",
      phone: "contact.phone",
      email: "contact.email",
      assignedResellers: "contact.resellers",
    }),
    ...mapState({
      resellers(state) {
        return state["service-contact/reseller"].resellers
          .filter((reseller) => !this.assignedResellers.includes(reseller))
          .map((reseller) => ({ value: reseller, label: reseller.title }));
      },
      filename(state) {
        const picture = state["service-contact"].contact.picture;

        if (!picture) {
          return "";
        }

        return (
          picture.filename + (picture.extension ? "." + picture.extension : "")
        );
      },
    }),
  },
  async mounted() {
    const params = this.$route.params;

    if (params.id) {
      await this.$store.dispatch("service-contact/loadOne", { id: params.id });
      this.$store.commit("service-contact/select", params.id);
    }

    this.$store.dispatch("service-contact/reseller/load");
  },
};
</script>

<style scoped>
</style>
