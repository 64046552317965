// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../res/assets/logos/logo-fontevo.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "header[data-v-5abbd1fa]{height:103rem;margin-bottom:48rem;padding-top:0;position:relative;z-index:6}.logo-wrapper[data-v-5abbd1fa]{background-color:#fff;cursor:pointer;display:inline-block}.logo-wrapper .logo[data-v-5abbd1fa]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:50%;height:103rem;width:160rem}", "",{"version":3,"sources":["webpack://./src/app/shared/components/layout/header.vue"],"names":[],"mappings":"AAIA,wBAIE,aAAA,CACA,mBAAA,CAHA,aAAA,CACA,iBAAA,CAFA,SACF,CAMA,+BAGE,qBAAA,CAFA,cAAA,CACA,oBAHF,CACA,qCAQI,4DAAA,CACA,uBAAA,CAHA,aAAA,CACA,YAFJ","sourcesContent":["\n@import \"../../../../res/less/themes/theme\";\n@import \"../../../../res/less/core/config/constants\";\n\nheader {\n  z-index: 6;\n  padding-top: @headerPaddingTop;\n  position: relative;\n  height: @headerHeight;\n  margin-bottom: @space6;\n}\n\n.logo-wrapper {\n  cursor: pointer;\n  display: inline-block;\n  background-color: @logoBackgroundColor;\n\n  .logo {\n    height: @logoHeight;\n    width: @logoWidth;\n    background: @logoPath no-repeat;\n    background-position: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
