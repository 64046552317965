<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.foreign_product_activation.headline') }}</h4>

    <div class="input">
      <label>{{ $t('product.product.tabs.rules.sections.foreign_product_activation.product') }}</label>
      <dropdown
        :data="products"
        :value="activation"
        @change="activation = $event"
        :placeholder="$t('product.product.tabs.rules.sections.foreign_product_activation.placeholder')">
      </dropdown>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import { mapFields } from 'vuex-map-fields';

  import Dropdown from "../../../shared/components/form/dropdown";
	import DotMenu from "../../../shared/components/modal/dot-menu";

  export default {
		name: "foreign-product-activation",
    components: {
		  Dropdown,
      DotMenu
		},
    computed: {
      ...mapState({
        products(state) {
          return [...state.product.short_products].map(product => ({ value: product.uuid, label: '[' + product.article_number + '] ' + product.title }));
        }
      }),
      ...mapFields('product', {
        activation: 'product.foreign_product_activation'
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
