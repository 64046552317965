import { maxSystemFunction } from "../templates";

export default {
	addSystemFunctionMax(state) {
		if (!state.product.max_system_functions) {
			state.product.max_system_functions = [];
		}
		state.product.max_system_functions = [...state.product.max_system_functions, { ...maxSystemFunction }];
	},
	removeSystemFunctionMax(state, index) {
		state.product.max_system_functions = state.product.max_system_functions.filter((systemFunction, i) => i !== index);
	},
	updateSystemFunctionMax(state, { index, changes }) {
		state.product.max_system_functions = state.product.max_system_functions.map((systemFunction, i) => index === i ? { ...systemFunction, ...changes } : systemFunction);
	}
};
