import { uniq } from "underscore";
import { product, pagination } from "../templates";
import { updateField } from "vuex-map-fields";

import limits from './limits';
import equipments from './equipments';
import combinations from './combinations';
import requirements from './requirements';
import restrictions from './restrictions';
import systemFunctionsSelectable from './system-functions-selectable';
import requiredProducts from './required-products';
import systemFunctions from './system-functions';
import systemFunctionsMax from './max-system-functions';
import possibleDectServers from './possible-dect-servers';
import possibleDectBases from './possible-dect-bases';
import possibleDectExtensions from './possible-dect-extensions';
import incompatibleWithPbxs from "./incompatible-with-pbxs";

function hydrateProduct(p) {
    let product = { ...p };

    if (!product.category) {
        product.category = { key: '', title: '' };
    }

    if (!product.sub_category) {
        product.sub_category = { key: '', title: '' };
    }

    if (!product.selectable_system_functions) {
        product.selectable_system_functions = [];
    }

    if (!product.required_products) {
        product.required_products =  [];
    }

    if (!product.system_functions) {
        product.system_functions = [];
    }

    return product;
}

export default {
    async loaded(state, data) {
        state.products = uniq([...data.products], 'uuid');
        state.pagination = { ...(data.pagination || pagination) };
    },
    async loaded_short(state, data) {
        state.short_products = uniq([...data.products], 'uuid');
    },
    async loaded_product_categories(state, data) {
        state.categories = uniq([...data.categories], 'key');
    },
    async loaded_product_subcategories(state, data) {
        data.categories.unshift({'key': '', 'title': '-'});
        state.subCategories = uniq([...data.categories], 'key');
    },
    async loadedMore(state, data) {
        state.products = uniq([ ...state.products, ...data.products], 'uuid');
        state.pagination = { ...data.pagination };
    },
    async loadedOne(state, data) {
        let index = state.products.findIndex(product => product.uuid = data.product.uuid);
        if (index >= 0) {
            state.products[index] = data.product;
        } else {
            state.products.push(data.product);
        }
    },
    async updated(state, { data }) {
        state.product = { ...state.product, ...data.product };
        state.products = state.products.map(product => product.uuid === state.product.uuid ? ({ ...state.product }) : product);
    },
    async created(state, { data, response }) {
        state.product = { ...state.product, ...data.product, uuid: response.product.uuid };
        state.products = [...state.products, state.product];
    },
    async duplicated(state, { response }) {
        state.product = { ...state.product, ...response.product };
        state.products = [...state.products, state.product];
    },
    async deleted(state, { response, id }) {
        state.products = state.products.filter(product => product.uuid !== id);
    },
    async reset(state) {
        state.product = { ...product };
    },
    async select(state, id) {
        if (state.products) {
            const product = state.products.find(product => product.uuid === id);

            if (product) {
                state.product = hydrateProduct(product);

                this.commit('product/updateRequirementCollectionNames');
            }
        }
    },
    async addPrice(state, price) {
        state.product = { ...state.product, regions: [...state.product.regions, price] };
    },
    async updatePrices(state, prices) {
        state.product = { ...state.product, regions: [...prices] };
    },
    async removeRegionByTitle(state, { title }) {
        state.product.regions = state.product.regions.filter(region => region.region.title !== title);
    },
    async resetPagination(state) {
        state.pagination = { ...pagination }
    },
    ...limits,
    ...equipments,
    ...combinations,
    ...requirements,
    ...incompatibleWithPbxs,
    ...restrictions,
    ...systemFunctionsSelectable,
    ...requiredProducts,
    ...systemFunctions,
    ...systemFunctionsMax,
    ...possibleDectServers,
    ...possibleDectBases,
    ...possibleDectExtensions,
    updateField
};
