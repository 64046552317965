<template>
  <div class="inline">
    <div @click="$refs.modal.open()">
      <slot/>
    </div>

    <modal ref="modal">
      <div class="import-modal-body">
        <h3 class="headline headline_3">Preise importieren</h3>

        <div class="input">
          <label class="file-upload-label">Datei (.csv)</label>
          <file-upload :value="filename" @change="file = $event" :accept="allowedFiles"></file-upload>
        </div>

        <div class="input">
          <label>Region</label>
          <dropdown v-model="region" :data="regions"></dropdown>
        </div>

        <div>
          <button class="button primary small" @click="startImport()">Importieren</button>
          <button class="button secondary small" @click="$refs.modal.close()">Abbrechen</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Modal from '../../shared/components/modal/modal';
  import FileUpload from '../../shared/components/form/file-upload';
  import Dropdown from "../../shared/components/form/dropdown";

  export default {
    name: "import-prices-modal",
    components: {
      Dropdown,
      Modal,
      FileUpload
    },
    data() {
      return {
        file: null,
        allowedFiles: '.csv',
        region: null,
      }
    },
    computed: {
      filename() {
        return this.file ? this.file.name : null;
      },
      ...mapState({
        regions(state) {
          const regions = [...state['product/region'].regions];
          return regions.map(region => ({ label: region.title, value: region.uuid }));
        }
      })
    },
    methods: {
      async startImport() {
        if (this.file && this.region) {
          await this.$store.dispatch('product/import', { file: this.file, region: this.region });

          this.file = null;
          this.region = null;
          this.$refs.modal.close();
          await this.$store.dispatch('product/load');
        }
      }
    },
    mounted() {
      this.$store.dispatch('product/region/load');
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/themes/theme";
  @import "../../../res/less/core/config/constants";

  .import-modal-body {
    padding: @space5 @space5 calc(@space5 - @standard-space) @space5;
    min-width: 400px;
    background-color: @white-100;

    .file-upload-label {
      margin-bottom: @space1;
      color: @brand-1-100;
    }
  }

  @media(max-width: @phone) {
    .inline { width: 100%; }
  }
</style>
