<template>
  <section>
    <section class="sub">
      <h3 class="headline headline_3">Seite</h3>

      <div class="grid">
        <div class="col6">
          <page-title></page-title>
        </div>
      </div>

      <div class="grid">
        <div class="col12">
          <language-tabs>
            <tab name="DE" language="de">
              <div class="input">
                <label for="body_de">Inhalt</label>
                <editor-menu-bar :editor="editorDe" v-slot="{ commands, isActive }">
                  <div class="rte-menu">
                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <span class="rte-bold">b</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <span class="rte-italic">i</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <span class="rte-underline">u</span>
                    </button>
                  </div>
                </editor-menu-bar>
                <div>
                  <div>
                    <div>
                      <editor-menu-bubble class="menububble" :editor="editorDe" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div
                          class="menububble"
                          :class="{ 'is-active': menu.isActive }"
                          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                        >

                          <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                            <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                            <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                              [x]
                            </button>
                          </form>

                          <template v-else>
                            <button
                              class="button primary small"
                              @click="showLinkMenu(getMarkAttrs('link'))"
                              :class="{ 'is-active': isActive.link() }"
                            >
                              <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                            </button>
                          </template>

                        </div>
                      </editor-menu-bubble>
                    </div>
                  </div>
                </div>
                <div>
                  <editor-content class="rte" :editor="editorDe" id="body_de"></editor-content>
                </div>
              </div>
            </tab>
            <tab name="EN (AU)" language="en">
              <div class="input">
                <label for="body_en_au">Inhalt</label>
                <editor-menu-bar :editor="editorEnAu" v-slot="{ commands, isActive }">
                  <div class="rte-menu">
                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <span class="rte-bold">b</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <span class="rte-italic">i</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <span class="rte-underline">u</span>
                    </button>
                  </div>
                </editor-menu-bar>
                <div>
                  <div>
                    <div>
                      <editor-menu-bubble class="menububble" :editor="editorEnAu" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div
                          class="menububble"
                          :class="{ 'is-active': menu.isActive }"
                          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                        >

                          <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                            <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                            <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                              [x]
                            </button>
                          </form>

                          <template v-else>
                            <button
                              class="button primary small"
                              @click="showLinkMenu(getMarkAttrs('link'))"
                              :class="{ 'is-active': isActive.link() }"
                            >
                              <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                            </button>
                          </template>

                        </div>
                      </editor-menu-bubble>
                    </div>
                  </div>
                </div>
                <div>
                  <editor-content class="rte" :editor="editorEnAu" id="body_en_au"></editor-content>
                </div>
              </div>
            </tab>
            <tab name="EN (FON)" language="en">
              <div class="input">
                <label for="body_en">Inhalt</label>
                <editor-menu-bar :editor="editorEn" v-slot="{ commands, isActive }">
                  <div class="rte-menu">
                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <span class="rte-bold">b</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <span class="rte-italic">i</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <span class="rte-underline">u</span>
                    </button>
                  </div>
                </editor-menu-bar>
                <div>
                  <div>
                    <div>
                      <editor-menu-bubble class="menububble" :editor="editorEn" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div
                          class="menububble"
                          :class="{ 'is-active': menu.isActive }"
                          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                        >

                          <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                            <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                            <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                              [x]
                            </button>
                          </form>

                          <template v-else>
                            <button
                              class="button primary small"
                              @click="showLinkMenu(getMarkAttrs('link'))"
                              :class="{ 'is-active': isActive.link() }"
                            >
                              <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                            </button>
                          </template>

                        </div>
                      </editor-menu-bubble>
                    </div>
                  </div>
                </div>
                <div>
                  <editor-content class="rte" :editor="editorEn" id="body_en"></editor-content>
                </div>
              </div>
            </tab>
            <tab name="FR" language="fr">
              <div class="input">
                <label for="body_fr">Inhalt</label>
                <editor-menu-bar :editor="editorFr" v-slot="{ commands, isActive }">
                  <div class="rte-menu">
                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <span class="rte-bold">b</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <span class="rte-italic">i</span>
                    </button>

                    <button
                      class="button primary small"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <span class="rte-underline">u</span>
                    </button>
                  </div>
                </editor-menu-bar>
                <div>
                  <div>
                    <div>
                      <editor-menu-bubble class="menububble" :editor="editorFr" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div
                          class="menububble"
                          :class="{ 'is-active': menu.isActive }"
                          :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                        >

                          <form class="menububble-form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                            <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                            <button class="button small secondary" @click="setLinkUrl(commands.link, null)" type="button">
                              [x]
                            </button>
                          </form>

                          <template v-else>
                            <button
                              class="button primary small"
                              @click="showLinkMenu(getMarkAttrs('link'))"
                              :class="{ 'is-active': isActive.link() }"
                            >
                              <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                            </button>
                          </template>

                        </div>
                      </editor-menu-bubble>
                    </div>
                  </div>
                </div>
                <div>
                  <editor-content class="rte" :editor="editorFr" id="body_fr"></editor-content>
                </div>
              </div>
            </tab>
          </language-tabs>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
    import { mapFields } from 'vuex-map-fields';
    import PageTitle from "../elements/page-title";
    import Notifications from "../../../shared/components/notifications";
    import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap';
    import Tab from "../../../shared/components/tabs/tab";
    import LanguageTabs from "../../../shared/components/tabs/language-tabs";
    import {
        Bold,
        Italic,
        Underline,
        Link
    } from 'tiptap-extensions';

    export default {
        name: "man",
        components: {
            Notifications,
            Editor,
            PageTitle,
            EditorMenuBar,
            EditorMenuBubble,
            Bold,
            Italic,
            Underline,
            EditorContent,
            Tab,
            LanguageTabs
        },
        data() {
            return {
                editorDe: null,
                editorEnAu: null,
                editorEn: null,
                editorFr: null,
                linkUrl: null,
                linkMenuIsActive: false,
            }
        },
        computed: {
            ...mapFields('page', {
                title_de: 'page.title.title_de',
                title_en: 'page.title.title_en',
                title_en_au: 'page.title.title_en_au',
                title_fr: 'page.title.title_fr',
                page: 'page'
            }),
        },
        methods: {
            async save() {
                const page = { ...this.$store.state.page.page };
                await this.$store.dispatch('page/update', { id: page.id, payload: { page } });
            },
            async discard() {
                this.$store.commit('page/reset');
                this.$router.push('/admin/page');
            },
            async changeTitle(title) {
                this.$store.commit('page/updateField', {path: 'page.title', value: title});
            },
            showLinkMenu(attrs) {
                this.linkUrl = attrs.href
                this.linkMenuIsActive = true
                this.$nextTick(() => {
                    this.$refs.linkInput.focus()
                })
            },
            hideLinkMenu() {
                this.linkUrl = null;
                this.linkMenuIsActive = false;
            },
            setLinkUrl(command, url) {
                command({ href: url });
                this.hideLinkMenu();
            },
        },
        watch: {
            page: {
                deep: true,
                handler(prev, current) {
                    const { page } = this;

                    if (prev.id !== current.id) {
                        this.editorDe.setContent(page.body.body_de);
                        this.editorFr.setContent(page.body.body_fr);
                        this.editorEn.setContent(page.body.body_en);
                        this.editorEnAu.setContent(page.body.body_en_au);
                    }
                }
            }
        },
        async mounted() {
            const editorConfig = {
                extensions: [
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Link()
                ]
            };

            this.editorDe = new Editor({
                ...editorConfig,
                onUpdate: ({ getHTML }) => {
                    this.$store.commit("page/updateField", { path: 'page.body.body_de', value: getHTML() });
                }
            });

            this.editorFr = new Editor({...editorConfig,
                onUpdate: ({ getHTML }) => {
                    this.$store.commit("page/updateField", { path: 'page.body.body_fr', value: getHTML() });
                }
            });
            this.editorEn = new Editor({...editorConfig,
                onUpdate: ({ getHTML }) => {
                    this.$store.commit("page/updateField", { path: 'page.body.body_en', value: getHTML() });
                }
            });
            this.editorEnAu = new Editor({...editorConfig,
                onUpdate: ({ getHTML }) => {
                    this.$store.commit("page/updateField", { path: 'page.body.body_en_au', value: getHTML() });
                }
            });

            this.editorDe.setContent(this.$store.state.page.page.body.body_de);
            this.editorFr.setContent(this.$store.state.page.page.body.body_fr);
            this.editorEn.setContent(this.$store.state.page.page.body.body_en);
            this.editorEnAu.setContent(this.$store.state.page.page.body.body_en_au);
        },
        beforeDestroy() {
            this.editorDe.destroy();
            this.editorFr.destroy();
            this.editorEn.destroy();
            this.editorEnAu.destroy();
        }
    }
</script>

<style scoped>
</style>
