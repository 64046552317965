<template>
  <section>
    <div>
      <h2 class="inline headline headline_2">Seiten & Handbuch</h2>

      <div class="inline right">
        <button class="button secondary" @click="save()">Speichern</button>
        <button class="button secondary" @click="discard()">Verwerfen</button>
      </div>
    </div>

    <notifications :max="3" />

    <section class="sub">
      <home v-if="category() === 'home'" />
      <site v-if="category() === 'site'" />
      <man v-if="category() === 'man'" />
    </section>

    <button class="button secondary" @click="save()">Speichern</button>
    <button class="button secondary" @click="discard()">Verwerfen</button>
  </section>
</template>

<script>
import Notifications from "../../shared/components/notifications";
import Home from "../components/types/home";
import Site from "../components/types/site";
import Man from "../components/types/man";

export default {
  name: "page",
  components: {
    Notifications,
    Home,
    Site,
    Man,
  },
  methods: {
    async save() {
      const page = { ...this.$store.state.page.page };

      await this.$store.dispatch("page/update", {
        id: page.id,
        payload: { page },
      });
    },
    async discard() {
      this.$store.commit("page/reset");
      this.$router.push("/admin/page");
    },
    category() {
      return this.$store.state.page.page.category;
    },
  },
  async mounted() {
    await this.$store.dispatch("page/load");
    const params = this.$route.params;

    if (params.id) {
      this.$store.commit("page/select", params.id);
    }
  },
};
</script>

<style scoped>
</style>
