import DistributorGuard from "../router/guard/distributor";

import ProfileComponent from '../views/profile';
import DashboardComponent from '../views/dashboard';

export default [
	{
		path: '/distributor',
		redirect: '/distributor/dashboard',
		beforeEnter: DistributorGuard
	},
	{
		path: '/distributor/dashboard',
		component: DashboardComponent,
		meta: {
			title: 'distributor.routes.dashboard'
		},
		beforeEnter: DistributorGuard
	},
	{
		path: '/distributor/profile',
		component: ProfileComponent,
		meta: {
			title: 'distributor.routes.profile'
		},
		beforeEnter: DistributorGuard
	}
];

