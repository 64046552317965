export const pagination = {
    current_page: 0,
    limit: null,
    pages: null,
    max_count: null,
    next_page: null
};

export const contact = {
    uuid: null,
    picture: {
        filename: '',
        extension: '',
        uuid: null
    },
    salutation: '',
    forename: '',
    surname: '',
    phone: '',
    email: '',
    resellers: []
};
