export default {
    profile: {
        city: "",
        company: "",
        country: "",
        email: "",
        fax: "",
        logo: {
            filename: '',
            extension: '',
            uuid: null
        },
        phone: "",
        reseller_number: "",
        street: "",
        title: "",
        uuid: "",
        website: "",
        zip: ""
    }
}
