<template>
  <nav class="main-navigation" v-show="show">
    <div class="item">
      <router-link v-for="{ key, link } in (isAdmin ? adminLinks : links)" :key="key" :to="link" tag="span">
        {{ $t(key) }}
      </router-link>
    </div>
  </nav>
</template>

<script>
  export default {
    name: "main-navigation",
    data() {
      return {
        links: [
          { key: "header.navigation.home", link: '/home' },
          { key: "header.navigation.offers", link: '/offer' },
          { key: "header.navigation.foreign-products", link: '/reseller/product' }
        ],
        adminLinks: [
          { key: "Dashboard", link: '/admin/dashboard' },
          { key: "Händler", link: '/admin/reseller' },
          { key: "Produkte", link: '/admin/product' },
          { key: "Systemfunktionen", link: '/admin/system_functions' },
          { key: "Regionen", link: '/admin/regions' },
          { key: "Inhalte", link: '/admin/page' }
        ]
      }
    },
    computed: {
      isAdmin() {
        return this.$route.path.startsWith('/admin');
      },
      show() {
        return !this.$route.path.includes('login');
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/themes/theme";
  @import "../../../../res/less/core/config/constants";

  .main-navigation {
    float: right;
    height: 100%;

    .item {
      font-size: 18rem;
      color: @white-100;
      display: inline-block;
      line-height: @headerHeight;

      span {
        cursor: pointer;

        &.router-link-exact-active {
          font-weight: bolder;
        }

        &:not(:last-child) {
          margin-right: @space8;
        }
      }
    }
  }
</style>
