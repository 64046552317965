<template>
  <div>
    <section class="sub icon-headline">
      <h3 class="headline headline_3" data-index="2">{{ $t("offer.offer.steps.connections.headline") }}</h3>
      <router-link :class="'icon help '+client()" :to="baseUrl()+'/manual#step_2'"></router-link>
    </section>

    <div class="grid">
      <div class="col5">
        <section class="sub">
          <h4 class="headline headline_4">2.1 {{ $t("offer.offer.steps.connections.exchange.headline") }}</h4>

          <div class="input">
            <label for="offer-voip-required-channels">{{ $t("offer.offer.steps.connections.sections.voip.headline") }}</label>
            <input type="number" min="0" id="offer-voip-required-channels" v-model="needs_voip_amt">
          </div>

          <div class="input">
            <label for="offer-isdn-required-channels"> {{ $t("offer.offer.steps.connections.sections.isdn.headline") }}</label>
            <input type="number" min="0" id="offer-isdn-required-channels" v-model="needs_isdn_amt">
          </div>

          <div class="input">
            <label for="offer-analog_connections-required-channels">{{ $t("offer.offer.steps.connections.sections.analog_connections.headline") }}</label>
            <input type="number" min="0" id="offer-analog_connections-required-channels" v-model="needs_analog_amt">
          </div>
        </section>
      </div>
    </div>

    <div class="grid">
      <div class="col5">
        <section class="sub">
          <h4 class="headline headline_4">2.2 {{ $t("offer.offer.steps.connections.spare.headline") }}</h4>

          <div class="input">
            <label for="offer-voip-spare-channels">{{ $t("offer.offer.steps.connections.sections.voip.headline") }}</label>
            <input type="number" min="0" id="offer-voip-spare-channels" v-model="needs_voip_spare">
          </div>

          <div class="input">
            <label for="offer-isdn-spare-channels"> {{ $t("offer.offer.steps.connections.sections.isdn.headline") }}</label>
            <input type="number" min="0" id="offer-isdn-spare-channels" v-model="needs_isdn_spare">
          </div>

          <div class="input">
            <label for="offer-up0-spare-channels"> {{ $t("offer.offer.steps.connections.sections.up0.headline") }}</label>
            <input type="number" min="0" id="offer-up0-spare-channels" v-model="needs_up0_spare">
          </div>

          <div class="input">
            <label for="offer-analog_connections-required-channels">{{ $t("offer.offer.steps.connections.sections.analog_connections.headline") }}</label>
            <input type="number" min="0" id="offer-analog_connections-spare-channels" v-model="needs_analog_spare">
          </div>
        </section>
      </div>
    </div>

    <button class="button primary" @click="save()">{{ $t("offer.offer.next_step", ['3', '6']) }}</button>
    <!--
    <button class="button secondary" @click="saveAndClose()">{{ $t("actions.continue_later") }}</button>
    <button class="button secondary" @click="discard()">{{ $t("actions.discard") }}</button>
    -->
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields';

  import TabChild from '../../shared/components/tabs/tab-child';

  export default {
    extends: TabChild,
    name: "connections",
    computed: {
      ...mapFields('offer', [
        'offer.needs_isdn_amt',
        'offer.needs_analog_amt',
        'offer.needs_voip_amt',

        'offer.needs_isdn_spare',
        'offer.needs_up0_spare',
        'offer.needs_analog_spare',
        'offer.needs_voip_spare',
      ])
    },
    methods: {
      client() {
          const global = require('../../../config/index');
          return global.default.api.client;
      },
      baseUrl() {
          const { region, distributor, id } = this.$route.params;

          let target = '/offer';

          if (distributor) {
              target = '/' + distributor + target;
          }

          if (region) {
              target = '/' + region + target;
          }

          return target + '/' + id;
      },
      async save(navigate = true) {
        const offer = { ...this.$store.state.offer.offer };

        await this.$store.dispatch('offer/update', { id: offer.uuid, payload: { offer } });

        if (navigate) {
          this.nextTab();
        }
      },
      async saveAndClose() {
        await this.save(false);

        this.$router.push('/offer');
      },
      async discard() {
        const $tab = this.$parent;
        const $tabs = $tab.$parent;
        const $page = $tabs.$parent;

        await $page.discard();
      }
    }
  }
</script>

<style scoped>

</style>
