<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.port_restrictions.headline') }}</h4>

    <div class="separator" v-for="(restriction, index) of restrictions" :key="index">
      <div>
        <div class="inline margin-right">
          <radio :value="restriction.port === available_ports.S0" @change="updateRestriction(index, { port: available_ports.S0 })" :name="'restriction' + index">{{ $t('product.product.tabs.rules.sections.port_restrictions.s0') }}</radio>
        </div>
        <div class="inline">
          <radio :value="restriction.port === available_ports.UP0" @change="updateRestriction(index, { port: available_ports.UP0 })" :name="'restriction' + index">{{ $t('product.product.tabs.rules.sections.port_restrictions.up0') }}</radio>
        </div>
        <div class="inline right">
          <dot-menu :options="options" :object="{ index }"></dot-menu>
        </div>
      </div>
      <div class="grid">
        <div class="col4">
          <div class="input">
            <label :for="'restriction-adapter-' + index">{{ $t('product.product.tabs.rules.sections.port_restrictions.adapter') }}</label>
            <input :id="'restriction-adapter-' + index" type="number" :value="restriction.adapters" @change="updateRestriction(index, { adapters: $event.target.value })" min="0">
          </div>
        </div>
        <div class="col4">
          <div class="input">
            <label :for="'restriction-s0-' + index">{{ $t('product.product.tabs.rules.sections.port_restrictions.s0') }}</label>
            <input :id="'restriction-s0-' + index" type="number" :value="restriction.s0" @change="updateRestriction(index, { s0: $event.target.value })" min="0">
          </div>
        </div>
        <div class="col4">
          <div class="input">
            <label :for="'restriction-up0-' + index">{{ $t('product.product.tabs.rules.sections.port_restrictions.up0') }}</label>
            <input :id="'restriction-up0-' + index" type="number" :value="restriction.up0" @change="updateRestriction(index, { up0: $event.target.value })" min="0">
          </div>
        </div>
      </div>
    </div>

    <button class="button secondary" @click="addRestriction()">{{ $t('product.product.tabs.rules.sections.port_restrictions.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import Radio from '../../../shared/components/form/radio';
  import DotMenu from '../../../shared/components/modal/dot-menu';

  export default {
    name: "port-restrictions",
    components: {
      Radio,
      DotMenu
    },
    data() {
      return {
        options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removePortRestriction', index)
          }
        ],
        available_ports: {
          UP0: 'PORTUP0',
          S0: 'PORTS0',
        }
      }
    },
    methods: {
      addRestriction() {
        this.$store.commit('product/addPortRestriction');
      },
      updateRestriction(index, changes = {}) {
        this.$store.commit('product/updatePortRestriction', { index, changes });
      }
    },
    computed: {
      ...mapState({
        restrictions(state) {
          return state.product.product.port_restrictions;
        }
      })
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }

  .margin-right {
    margin-right: @space3;
  }
</style>
