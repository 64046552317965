<template>
  <div>
    <alert
      v-for="(notification, index) of notifications"
      :key="index"
      :level="notification.level"
      :title="$t(notification.title)"
    >
      <div v-if="notification.body" v-html="$t(notification.body)"></div>
    </alert>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Alert from "./alert";

export default {
  components: {
    Alert,
  },
  name: "notifications",
  props: {
    max: Number,
  },
  computed: {
    ...mapState({ notifications: (state) => state.notification.messages }),
  },
  watch: {
    notifications: function () {
      if (
        Array.isArray(this.notifications) &&
        this.notifications.some((n) => n.level !== "success")
      ) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
</style>
