import Application from '../../../index';

export default async function isAuthenticatedAsDistributor(to, from, next) {
	const store = Application.getStore();
	const token = store.state.auth.token;

	if (token && (token.role === "ROLE_DISTRIBUTOR" || token.role === "ROLE_ADMIN")) {
		next();
	} else {
		next('/');
	}
}
