import {offer, pagination, result} from './templates';

export default {
    offers: [],
    offer: {...offer},
    possibleProducts: [],
    possibleProductsPagination: {...pagination},
    possiblePbxPagination: {...pagination},
    neededPbxModules: [],
    pagination: {...pagination},
    result: {...result},
    projects: [],
    systemFunctions: [],
    edit_code_map: {},
    summary: [],
};
