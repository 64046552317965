<template>
  <section class="sub">
    <h4 class="headline headline_4">{{ $t('product.product.tabs.rules.sections.max_system_functions.headline') }}</h4>

    <div class="separator" v-for="(systemFunction, index) of systemFunctions" :key="index">
      <div class="input">
        <label>{{ $t('product.product.tabs.rules.sections.max_system_functions.product') }}</label>
        <dropdown
          :data="products"
          :value="systemFunction.system_function"
          @change="updateSystemFunction(index, { system_function: $event })"
          :placeholder="$t('product.product.tabs.rules.sections.max_system_functions.placeholder')">

          <dot-menu slot="options" :object="{ index }" :options="options"></dot-menu>
        </dropdown>
      </div>

      <div class="input">
        <label :for="`max${index}`">{{ $t('product.product.tabs.rules.sections.max_system_functions.max') }}</label>
        <input :id="`max{index}`" type="number" min="1" :value="systemFunction.max" @change="updateSystemFunction(index, { max: $event.target.value })" />
      </div>
    </div>

    <button class="button secondary" @click="addSystemFunction()">{{ $t('product.product.tabs.rules.sections.max_system_functions.add') }}</button>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
	import Dropdown from "../../../shared/components/form/dropdown";
	import DotMenu from "../../../shared/components/modal/dot-menu";

  export default {
		name: "max-system-functions",
    components: {
		  Dropdown,
      DotMenu
		},
    data() {
		  return {
		    options: [
          {
            title: 'actions.delete',
            callback: ({ index }) => this.$store.commit('product/removeSystemFunctionMax', index)
          }
        ]
      }
    },
    computed: {
      ...mapState({
        products(state) {
          return [...state['product/systemFunctions'].system_functions].map(sF => ({ value: sF.uuid, label: sF.title }));
        },
        systemFunctions(state) {
          const max_system_functions = state.product.product.max_system_functions || [];
          return [...max_system_functions];
        }
      })
    },
    methods: {
      addSystemFunction() {
        this.$store.commit('product/addSystemFunctionMax');
      },
      updateSystemFunction(index, changes) {
        this.$store.commit('product/updateSystemFunctionMax', { index, changes });
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../../res/less/core/config/constants";
  @import "../../../../res/less/themes/theme";

  .separator {
    &:not(:last-of-type) {
      border-bottom: 1px dashed @brand-2-100;
      margin-bottom: @standard-space;
    }
  }
</style>
