<template>
  <section>
    <h2 class="headline headline_2">Regionen</h2>

    <router-link tag="button" class="button primary" to="/admin/regions/add"
      >Region hinzufügen</router-link
    >

    <notifications :max="3" />

    <div class="search">
      <input type="text" v-model="search" placeholder="Suche" />
    </div>

    <section class="sub">
      <vue-element-loading :active="loading" spinner="bar-fade-scale" />

      <data-table :fields="fields" :data="regions">
        <div class="inline right" slot="last-col" slot-scope="{ row }">
          <dot-menu :options="options" :object="row"></dot-menu>
        </div>
        <tr slot="empty">
          <td :colspan="fields.length" class="text-center">
            Es konnten keine Regionen gefunden werden.
          </td>
        </tr>
      </data-table>
    </section>

    <button class="button secondary" v-if="hasMore" @click="loadMore">
      Mehr laden
    </button>
  </section>
</template>

<script>
import { wait } from "../../../res/helper/wait";
import { mapState } from "vuex";
import DotMenu from "../../shared/components/modal/dot-menu";
import Notifications from "../../shared/components/notifications";
import DataTable from "../../shared/components/table/table";
import VueElementLoading from "vue-element-loading";

export default {
  name: "regions",
  components: {
    Notifications,
    DotMenu,
    DataTable,
    VueElementLoading,
  },
  data() {
    return {
      search: "",
      limit: 15,
      loading: false,
      fields: [
        { label: "region.regions.table.title", key: "title" },
        {
          label: "region.regions.table.active",
          key: "hidden",
          transform: (hidden) => (hidden ? "Nein" : "Ja"),
        },
        { label: "region.regions.table.shortcut", key: "shortcut" },
        { label: "region.regions.table.tax", key: "tax" },
        { label: "region.regions.table.currency", key: "currency" },
      ],
      options: [
        {
          title: "actions.edit",
          callback: (reseller) =>
            this.$router.push(`/admin/regions/${reseller.uuid}`),
        },
        {
          title: "actions.delete",
          callback: (reseller) =>
            this.$store.dispatch("region/delete", { id: reseller.uuid }),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      regions(state) {
        const regions = [...state["region"].regions];

        return regions.map((region) => ({ ...region, id: region.uuid }));
      },
      hasMore(state) {
        return !!state.region.pagination.next_page;
      },
    }),
  },
  methods: {
    async loadMore() {
      await this.$store.dispatch("region/loadMore");
    },
    async load(params = {}) {
      this.loading = true;
      await this.$store.dispatch("region/load", params);
      this.loading = false;
    },
  },
  watch: {
    async search(search) {
      await wait(1000);

      if (search === this.search) {
        let params = search
          ? { search, limit: this.limit }
          : { limit: this.limit };

        this.$store.commit("region/resetPagination");
        this.load(params);
      }
    },
  },
  async mounted() {
    await this.load({ limit: this.limit });
  },
};
</script>

<style scoped>
</style>
