import {uniq} from 'underscore';
import {updateField} from "vuex-map-fields";
import {offer, pagination, result} from './templates';

function hydrateOffer(o) {
    let offer = {...o};

    if (!offer.customer) {
        offer.customer = {
            address: {},
            contact_data: {}
        };
    }

    if (!offer.project) {
        offer.project = {
            number: '',
            title: ''
        }
    }

    return offer;
}

export default {
    async handleSystemFunctionRequirement(state, {id, systemFunction, event}) {
        state.offer.system_function_requirements = state.offer.system_function_requirements.filter(sF => sF.system_function !== systemFunction.uuid);

        if (systemFunction.type === 'checkbox' && event) {
            let sF = {
                system_function: systemFunction.uuid,
                min: 1
            };

            state.offer.system_function_requirements.push(sF);
        } else if (systemFunction.type === 'number' && event) {
            let sF = {
                system_function: systemFunction.uuid,
                min: event.target.value
            };

            state.offer.system_function_requirements.push(sF);
        }
    },
    async loaded(state, data) {
        state.offers = uniq([...data.offers], 'uuid');
        state.pagination = {...(data.pagination || pagination)};
    },
    async loadedMore(state, data) {
        state.offers = uniq([...data.offers, ...state.offers], 'uuid');
        state.pagination = {...data.pagination};
    },
    async loadedOne(state, data) {
        state.offers = uniq([...state.offers, data.offer], 'uuid');
        state.offer = {...state.offer, ...data.offer};
    },
    async updated(state, {response, data}) {
        state.offer = {...state.offer, ...data.offer};
        state.offers = state.offers.map(offer => offer.uuid === state.offer.uuid ? ({...state.offer}) : offer);
    },
    async created(state, {response, data}) {
        state.offer = {...state.offer, ...data.offer, ...response.offer};
        state.offers = [...state.offers, state.offer];
    },
    async deleted(state, {response, id}) {
        state.offers = state.offers.filter(offer => offer.uuid !== id);
    },
    async reset(state) {
        state.offer = offer;
        state.possibleProducts = [];
    },
    async possibleProductsLoaded(state, {products, pagination}) {
        products = products.map((p) => ({...p, amount: 1}));
        state.possibleProducts = pagination.current_page === 1 ? [...products] : [...state.possibleProducts, ...products];
        state.possibleProductsPagination = {...pagination};
    },
    async possiblePbxLoaded(state, {products, pagination}) {
        state.possibleProducts = pagination.current_page === 1 ? [...products] : [...state.possibleProducts, ...products];
        state.possibleProductsPagination = {...pagination};
    },
    async neededPbxModulesLoaded(state, {pbx, modules}) {
        let pbxData = {
            id: pbx,
            modules: modules
        };

        let pbxModules = [...state.neededPbxModules];
        pbxModules = pbxModules.filter(data => data.id !== pbx);
        pbxModules.push(pbxData);

        state.neededPbxModules = pbxModules;
    },
    async gotSystemFunctions(state, {system_functions}) {
        state.systemFunctions = {...system_functions};
    },
    async positionAdded(state, position) {
        let offer = {...state.offer};
        offer.positions.push(position);
        state.offer = offer;
    },
    async positionRemoved(state, position) {
        let offer = {...state.offer};
        offer.positions = offer.positions.filter(p => p.uuid !== position.uuid);
        state.offer = offer;
    },
    async positionUpdated(state, position) {
        let offer = {...state.offer};
        offer.positions = offer.positions.map(p => p.uuid === position.uuid ? position : p);
        state.offer = offer;
    },
    async select(state, id) {
        if (state.offers) {
            const offer = state.offers.find(offer => offer.uuid === id);

            state.offer = hydrateOffer(offer);
        }
    },
    async resellerProductPositionAdded(state, position) {
        let offer = {...state.offer};
        offer.reseller_product_positions.push(position);
        state.offer = offer;
    },
    async resellerProductPositionRemoved(state, position) {
        let offer = {...state.offer};
        offer.reseller_product_positions = offer.reseller_product_positions.filter(p => p.uuid !== position.uuid);
        state.offer = offer;
    },
    async resellerProductPositionUpdated(state, position) {
        let offer = {...state.offer};
        offer.reseller_product_positions = offer.reseller_product_positions.map(p => p.uuid === position.uuid ? position : p);
        state.offer = offer;
    },
    async resetProductPagination(state) {
        state.possibleProductsPagination = {...pagination};
    },
    async resetPbxPagination(state) {
        state.possiblePbxPagination = {...pagination};
    },
    async offerResultLoaded(state, data) {
        state.result = {...data.result};
    },
    async offerResultResetted(state) {
        state.result = {...result};
    },
    async projectsLoaded(state, data) {
        const projects = [];

        for (const key in data.offers) {
            if (data.offers.hasOwnProperty(key)) {
                let value = data.offers[key];
                let project = {
                    ...value.project,
                    offers: value.offers
                };

                if (!project.number) {
                    project.number = key;
                }

                projects.push(project);
            }
        }

        state.projects = projects;
        state.pagination = {...(data.pagination || pagination)};
    },
    async updateProductAmount(state, data) {
        const products = [...state.possibleProducts];

        state.possibleProducts = products.map(p => {
            if (p.uuid === data.product.uuid) {
                return {...p, amount: parseInt(data.amount)}
            } else {
                return p;
            }
        });
    },
    async updateProductPort(state, data) {
        const products = [...state.possibleProducts];

        state.possibleProducts = products.map(p => {
            if (p.uuid === data.product.uuid) {
                return {...p, port: data.port}
            } else {
                return p;
            }
        });
    },
    async updateProductSelectableSystemFunction(state, data) {
        const products = [...state.possibleProducts];

        state.possibleProducts = products.map(function (product) {
            if (product.uuid === data.product.uuid) {
                if (!product.selectedSystemFunctions) {
                    product.selectedSystemFunctions = [];
                }

                product.selectedSystemFunctions = product.selectedSystemFunctions.filter(sF => sF.product !== data.item.uuid);

                if (data.checked === true) {
                    product.selectedSystemFunctions.push({
                        'product': data.item.uuid,
                        'value': 1,
                        'title': data.item.title
                    });
                } else if (data.checked !== false && data.checked.target.value) {
                    const $target = data.checked.target;
                    let value = $target.value;

                    if ($target.type === "number") {
                        value = Number($target.value);
                    }

                    if (value) {
                        product.selectedSystemFunctions.push({
                            'product': data.item.uuid,
                            'value': value,
                            'title': data.item.title
                        });
                    }
                }
            }

            return product;
        });
    },
    async generatedEditCode(state, {data, id}) {
        state.edit_code_map[data.code] = id;
    },
    async foundEditCode(state, {code, data}) {
        state.edit_code_map[code] = data.offer.uuid;
    },
    updateField
}
