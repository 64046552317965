<template>
  <div class="alert" :class="level">
    <div class="header">{{ title }}</div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: "alert",
    props: {
      title: {
        type: String,
        default: ''
      },
      level: {
        type: String,
        required: true,
        validator(level) {
          return ['info', 'warn', 'danger', 'success'].includes(level);
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/core/config/constants";
  @import "../../../res/less/themes/theme";

  .alert {
    border: 1px solid;
    border-radius: @borderRadiusInput;
    padding: @space2;

    .header {
      position: relative;
      padding-left: @space2 + @space1;

      &:before {
        content: "";
        width: @space2;
        height: @space2;
        top: 0;
        left: 0;
        position: absolute;
      }
    }

    .body:not(:empty) { padding-top: @space2; }

    &.info {
      background-color: @brand-1-10;
      border-color: @brand-1-100;

      .header:before {
        .icon('alert/alert-info');
      }
    }

    &.success {
      background-color: @green-10;
      border-color: @green-100;

      .header:before {
        .icon('alert/alert-success');
      }
    }

    &.warn {
      background-color: @yellow-10;
      border-color: @yellow-100;

      .header:before {
        .icon('alert/alert-warn');
      }
    }

    &.danger {
      background-color: @red-10;
      border-color: @red-100;

      .header:before {
        .icon('alert/alert-danger');
      }
    }
  }
</style>
