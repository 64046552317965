import axios from 'axios';
import { Service } from 'axios-middleware';

new Service(axios).register({
    onRequest(config) {
        const global = require('../../../../config/index');
        config.headers['X-Client'] = global.default.api.client;
        return config;
    }
});
