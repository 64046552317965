<template>
  <div>
    <section class="sub">
      <h3 class="headline headline_3">{{ $t("dashboard.offers.headline") }}</h3>

      <div>
        <router-link tag="button" class="button primary" to="/offer/add">{{ $t("dashboard.offers.create_offer") }}</router-link>

        <import-offer-modal>
          <button class="button secondary">{{ $t("dashboard.offers.import_offer") }}</button>
        </import-offer-modal>

        <button v-show="showExport" @click="exportSelected()" class="button secondary">{{ $t("dashboard.offers.export_selected") }}</button>
      </div>

      <accordion v-for="project of projects" :key="project.id">
        <template slot="header">{{ project.title || $t('dashboard.offers.project_default') }} ({{ project.offers.length }})</template>

        <checkable-data-table slot="body" :fields="fields" :data="project.offers" :value="selected[project.number]" @input="selectOffers(project.number, $event)">
          <div class="inline right" slot="last-col" slot-scope="{ row }">
            <dot-menu :object="row" :options="options"></dot-menu>
          </div>
          <tr slot="empty">
            <td :colspan="fields.length + 1" class="text-center">
              {{ $t('dashboard.offers.not_found') }}
            </td>
          </tr>
        </checkable-data-table>
      </accordion>
    </section>

    <router-link tag="button" class="button secondary" to="/offer">{{ $t("dashboard.offers.show_all") }}</router-link>
  </div>
</template>

<script>
  import * as moment from 'moment/moment';
  import { mapState } from 'vuex';
  import DotMenu from '../../shared/components/modal/dot-menu';
  import Accordion from "../../shared/components/accordion";
  import Download from '../../shared/service/download';
  import ImportOfferModal from "../../offer/components/import-offer-modal";
  import CheckableDataTable from "../../shared/components/table/checkable-table";

  export default {
    name: "offers",
    components: {
      DotMenu,
      Accordion,
      ImportOfferModal,
      CheckableDataTable
    },
    data() {
      return {
        limit: 10,
        fields: [
          { label: 'dashboard.offers.table.id', key: 'offer_number' },
          { label: 'dashboard.offers.table.description', key: 'title' },
          {
            label: 'dashboard.offers.table.createdAt',
            key: 'creation_date',
            transform: date => {
              const [day, month, year] = moment(date).format('DD.MM.YYYY').split('.');

              return this.$t('format.date', { day, month, year });
            }
          }
        ],
        options: [
          {
            title: 'actions.edit',
            callback: offer => this.$router.push(`/offer/${offer.uuid}`)
          },
          {
            title: 'actions.export_as_pdf',
            callback: async offer => {
              const downloadUrl = this.$store.getters['offer/getPdfDownloadUrl'](offer.uuid);

              await Download.downloadFileByUrl(downloadUrl, 'offer.pdf');
            }
          },
          {
            title: 'actions.delete',
            callback: offer => this.$store.dispatch('offer/delete', { id: offer.uuid })
          }
        ],
        selected: {}
      }
    },
    computed: {
      ...mapState({
        projects(state) {
          const projects = [...state.offer.projects];

          return projects.map(p => {
            const project = { ...p };
            project.offers = project.offers.map(offer => ({ ...offer, id: offer.uuid }));

            if (!this.selected[project.number]) {
              this.selected[project.number] = [];
            }

            return project;
          });
        }
      }),
      showExport() {
        const selected = { ...this.selected };

        for (const project in selected) {
          if (selected.hasOwnProperty(project)) {
            if (selected[project].length > 0) {
              return true;
            }
          }
        }

        return false;
      }
    },
    methods: {
      exportSelected() {
        this.selected = {};
      },
      selectOffers(project, offers) {
        this.selected = {
          ...this.selected,
          [project]: offers
        }
      }
    },
    async mounted() {
      await this.$store.dispatch('offer/loadProjects', { limit: this.limit });
    }
  }
</script>

<style lang="less" scoped>
  @import "../../../res/less/core/config/constants";

  .button + .inline:not(:last-child) {
    margin-right: @space3;
  }
</style>
